import { render, staticRenderFns } from "./DashboardsList.vue?vue&type=template&id=3079ea90"
import script from "./DashboardsList.vue?vue&type=script&lang=ts"
export * from "./DashboardsList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3079ea90')) {
      api.createRecord('3079ea90', component.options)
    } else {
      api.reload('3079ea90', component.options)
    }
    module.hot.accept("./DashboardsList.vue?vue&type=template&id=3079ea90", function () {
      api.rerender('3079ea90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/publicDashboard/DashboardsList.vue"
export default component.exports