var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "nav",
        {
          staticClass: "breadcrumb multi-header",
          attrs: { "aria-label": "breadcrumb" },
        },
        [
          _c("h1", { staticClass: "title has-text-centered-touch" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                  ]
                ),
              ]),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "dashboard",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "home" } }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "settings",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("component.settings.title")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "configuration_devices",
                          params: {
                            lang: _vm.$route.params.lang,
                            departmentId: _vm.$route.params.departmentId,
                          },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.device.list.title")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("li", { staticClass: "is-active" }, [
                _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                  _vm._v(_vm._s(_vm.device.deviceName)),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      !_vm.isLoading &&
      _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.DEVICE)
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "tile is-child box" },
                [
                  _c("DeviceInfo", {
                    attrs: {
                      device: _vm.device,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: { reloadDevice: _vm.loadDeviceInfo },
                  }),
                ],
                1
              ),
            ]),
            _vm.buzzerIsAllowed &&
            _vm.$ability.can(
              _vm.$permActions.MANAGEOWN,
              _vm.$permSubjects.COMPANY
            )
              ? _c("div", { staticClass: "tile is-parent" }, [
                  _c(
                    "div",
                    { staticClass: "tile is-child box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
                        },
                        [
                          _c("h2", { staticClass: "subtitle is-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.device.list.settings.title")
                                ) +
                                " "
                            ),
                          ]),
                          !_vm.isEditingSettings
                            ? _c("b-button", {
                                attrs: {
                                  "icon-right": "pencil-alt",
                                  type: "is-white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSettings()
                                  },
                                },
                              })
                            : _c(
                                "b-button",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    "icon-left": "times-circle",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSettings()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("generals.cancel_edit")) + " "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.device.list.settings.buzzer_label"
                            ),
                            horizontal: "",
                          },
                        },
                        [
                          _c("b-switch", {
                            attrs: {
                              disabled: !_vm.isEditingSettings,
                              name: _vm.$t(
                                "component.device.list.settings.buzzer_label"
                              ),
                            },
                            model: {
                              value: _vm.deviceIsBuzzer,
                              callback: function ($$v) {
                                _vm.deviceIsBuzzer = $$v
                              },
                              expression: "deviceIsBuzzer",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isEditingSettings
                        ? _c(
                            "div",
                            { staticClass: "has-text-centered mt-1" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "is-primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveChanges()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("generals.save")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }