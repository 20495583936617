import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
var departmentRepository;
let SelectCompanyModal = class SelectCompanyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.companyList = new Array();
        this.company = null;
        this.name = '';
    }
    created() {
        departmentRepository = new DepartmentRepository(this);
        this.loadData();
    }
    updated() {
        this.$refs.companyName.focus();
    }
    async loadData() {
        this.isLoading = true;
        this.companyList = (await departmentRepository.getAllDepartmentsAdmin()).getData();
        this.isLoading = false;
    }
    get filteredCompanyList() {
        return this.companyList.filter((option) => {
            return option.companyName.toLowerCase().indexOf(this.name.toLowerCase()) >= 0;
        });
    }
    select() {
        this.$emit('companySelected', this.company);
    }
};
__decorate([
    Prop({ type: Object })
], SelectCompanyModal.prototype, "logger", void 0);
SelectCompanyModal = __decorate([
    Component({})
], SelectCompanyModal);
export default SelectCompanyModal;
