import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SystemSettingsComponent from '@/views/components/admin/system/SystemSettingsComponent.vue';
import BackgroundJobsComponent from '@/views/components/admin/system/BackgroundJobsComponent.vue';
import SystemRepository from '@/services/repository/SystemRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import VueUtilities from '@/services/VueUtilities';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var systemRepository;
let SystemSettingsManagement = class SystemSettingsManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.systemSettings = [];
        this.backgroundJobs = [];
        this.dateTimeManager = null;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        systemRepository = new SystemRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(systemRepository.getSystemSettings());
        allPromises.push(systemRepository.getBackgroundJobs());
        Promise.all(allPromises).then((resp) => {
            this.systemSettings = resp[0];
            this.backgroundJobs = resp[1];
            this.isLoading = false;
        });
    }
    async updateSetting(key, newKey, value) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let resp = await systemRepository.updateSystemSetting(key, newKey, value);
        if (resp === ApiResponseCodes.SYSTEM_SETTING_EXISTS) {
            VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
            this.isLoading = false;
        }
        else if (resp == true) {
            VueUtilities.openSuccessToast(this, this.$t('system_settings.update.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('system_settings.update.failure'));
            this.isLoading = false;
        }
    }
    async saveNewSetting(key, value) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let resp = await systemRepository.createSystemSetting(key, value);
        if (resp === ApiResponseCodes.SYSTEM_SETTING_EXISTS) {
            VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
            this.isLoading = false;
        }
        else if (resp == true) {
            VueUtilities.openSuccessToast(this, this.$t('system_settings.create.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('system_settings.create.failure'));
            this.isLoading = false;
        }
    }
};
SystemSettingsManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.EDIT, Subjects.ADMIN_SYSTEM_SETTINGS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_SYSTEM_TASKS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { SystemSettingsComponent, BackgroundJobsComponent }
    })
], SystemSettingsManagement);
export default SystemSettingsManagement;
