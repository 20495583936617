var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.devices.createFirmwareModel.title")
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.devices.createFirmwareModel.version"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.createFirmwareModel.version"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.devices.createFirmwareModel.version")
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.devices.createFirmwareModel.version"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.createFirmwareModel.version"
                  ),
                },
                model: {
                  value: _vm.version,
                  callback: function ($$v) {
                    _vm.version = $$v
                  },
                  expression: "version",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.devices.createFirmwareModel.file"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("admin.component.devices.createFirmwareModel.file")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.devices.createFirmwareModel.file")
                ),
              },
            },
            [
              _c(
                "b-upload",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    expanded: "",
                    "drag-drop": "",
                    accept: ".bin",
                    placeholder: _vm.$t(
                      "admin.component.devices.createFirmwareModel.file"
                    ),
                    name: _vm.$t(
                      "admin.component.devices.createFirmwareModel.file"
                    ),
                  },
                  on: {
                    input: function ($event) {
                      return _vm.checkFileName($event)
                    },
                  },
                  model: {
                    value: _vm.uploadedFile,
                    callback: function ($$v) {
                      _vm.uploadedFile = $$v
                    },
                    expression: "uploadedFile",
                  },
                },
                [
                  _c("section", { staticClass: "section" }, [
                    _c("div", { staticClass: "content has-text-centered" }, [
                      _c(
                        "p",
                        [
                          _c("b-icon", {
                            attrs: { icon: "upload", size: "is-large" },
                          }),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.devices.createFirmwareModel.upload"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.uploadedFile
            ? _c("span", { staticClass: "tag is-primary mb-2" }, [
                _vm._v(" " + _vm._s(_vm.uploadedFile.name) + " "),
                _c("button", {
                  staticClass: "delete is-small",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteDropFile()
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.devices.createFirmwareModel.fileName"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.createFirmwareModel.fileName"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("admin.component.devices.createFirmwareModel.fileName")
                ),
              },
            },
            [
              _c("b-input", {
                attrs: {
                  disabled: "",
                  placeholder: _vm.$t(
                    "admin.component.devices.createFirmwareModel.fileName"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.createFirmwareModel.fileName"
                  ),
                },
                model: {
                  value: _vm.fileName,
                  callback: function ($$v) {
                    _vm.fileName = $$v
                  },
                  expression: "fileName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.devices.createFirmwareModel.description"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.devices.createFirmwareModel.description"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.devices.createFirmwareModel.description"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                attrs: {
                  type: "textarea",
                  placeholder: _vm.$t(
                    "admin.component.devices.createFirmwareModel.description"
                  ),
                  name: _vm.$t(
                    "admin.component.devices.createFirmwareModel.description"
                  ),
                },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.createFirmware()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.devices.createFirmwareModel.confirm")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }