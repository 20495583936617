import { ReportConfiguration } from './models/ReportConfiguration';
import { NotificationMeasuredRuleScope } from './notifications/NotificationMeasuredRuleScope';
import NotificationScheduleType from './notifications/NotificationScheduleType';
export class DepartmentUserSettings {
    constructor() {
        this.preferences = { favoriteGroupId: '', eventsRefresh: { enabled: 'false', interval: '5' } };
        this.notifications = {
            rules: { mode: NotificationMeasuredRuleScope.COMPANY },
            pause: { msgDismissedAt: '0', until: '0' },
            schedule: { mode: NotificationScheduleType.DEFAULT }
        };
        this.reports = {
            records: { filterByBoundary: 'true' },
            configuration: ReportConfiguration.getDefaultConfiguration(),
            receiveByEmail: 'false',
            customSettings: null
        };
        this.rawSettings = null;
        this.customSettings = [];
    }
    static fromApi(data) {
        return DepartmentUserSettings.define(data);
    }
    static define(data) {
        let userSettings = new DepartmentUserSettings();
        userSettings.rawSettings = data;
        userSettings.preferences.favoriteGroupId =
            data?.preferences?.favoriteGroupId || userSettings.preferences.favoriteGroupId;
        userSettings.preferences.eventsRefresh.enabled =
            data?.preferences?.eventsRefresh?.enabled || userSettings.preferences.eventsRefresh.enabled;
        userSettings.preferences.eventsRefresh.interval =
            data?.preferences?.eventsRefresh?.interval || userSettings.preferences.eventsRefresh.interval;
        userSettings.notifications.rules.mode = data?.notifications?.rules?.mode || userSettings.notifications.rules.mode;
        userSettings.notifications.rules.scheduleMode = data?.notifications?.rules?.scheduleMode;
        userSettings.notifications.pause.until =
            data?.notifications?.pause?.until || userSettings.notifications.pause.until;
        userSettings.notifications.pause.msgDismissedAt =
            data?.notifications?.pause?.msgDismissedAt || userSettings.notifications.pause.msgDismissedAt;
        userSettings.notifications.schedule.mode =
            data?.notifications?.schedule?.mode || userSettings.notifications.schedule.mode;
        userSettings.reports.records = data?.reports?.records || userSettings.reports.records;
        if (data?.reports?.customSettings?.length > 0) {
            userSettings.reports.customSettings =
                JSON.parse(data?.reports?.customSettings) || userSettings.reports.customSettings;
        }
        userSettings.reports.receiveByEmail = data?.reports?.receiveByEmail || userSettings.reports.receiveByEmail;
        if (data?.reports?.configuration?.length > 0) {
            userSettings.reports.configuration = ReportConfiguration.fromApi(data?.reports?.configuration);
        }
        else {
            userSettings.reports.configuration = ReportConfiguration.getDefaultConfiguration();
        }
        userSettings.customSettings = data?.customSettings || userSettings.customSettings;
        return userSettings;
    }
}
