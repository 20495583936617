var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "section-header-level" }, [
      _c(
        "div",
        { staticClass: "title-with-edit" },
        [
          _c("h2", { staticClass: "subtitle is-4 mr-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.user.detail.userCompanySettings.title"
                  )
                ) +
                " "
            ),
          ]),
          _vm.departments.length !== 0
            ? _c(
                "b-field",
                { staticClass: "pl-3" },
                [
                  _c(
                    "b-select",
                    {
                      on: { input: _vm.departmentSelected },
                      model: {
                        value: _vm.localSelectedDepartment,
                        callback: function ($$v) {
                          _vm.localSelectedDepartment = $$v
                        },
                        expression: "localSelectedDepartment",
                      },
                    },
                    _vm._l(_vm.departments, function (department) {
                      return _c(
                        "option",
                        {
                          key: department.Department.Id,
                          domProps: { value: department },
                        },
                        [_vm._v(" " + _vm._s(department.Department.Name) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.componentIsLoading
      ? _c(
          "div",
          [_c("b-skeleton", { attrs: { height: "90px", animated: true } })],
          1
        )
      : _vm.departments.length === 0
      ? _c("div", { staticClass: "pt-4" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "admin.component.user.detail.notificationSettings.no_department"
                )
              ) +
              " "
          ),
        ])
      : _c(
          "div",
          [
            _c("AdminUserGeneralSettings", {
              attrs: {
                userId: _vm.user.id,
                selectedDepartment: _vm.localSelectedDepartment,
                adminUserSettings: _vm.adminUserSettings,
                departments: _vm.departments,
                dateTimeManager: _vm.dateTimeManager,
                departmentUserSettings: _vm.departmentUserSettings,
              },
              on: {
                openSuccessToast: _vm.openSuccessToast,
                reloadData: _vm.reloadData,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }