var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
    _vm.connectedDevice == null
      ? _c(
          "div",
          { staticClass: "tile is-parent" },
          [
            _c("AdminDeviceSelectComponent", {
              staticClass: "box tile is-child",
              attrs: { deviceList: _vm.deviceList },
              on: { connectToDevice: _vm.connectToDevice },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.connectedDevice != null
      ? _c(
          "div",
          { staticClass: "tile is-parent" },
          [
            _c("AdminRegisteredLoggersComponent", {
              staticClass: "box tile is-child",
              attrs: {
                loggerList: _vm.loggerList,
                dateTimeManager: _vm.dateTimeManager,
              },
              on: { deleteLogger: _vm.deleteLogger },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }