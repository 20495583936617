var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "nav",
        {
          staticClass: "breadcrumb multi-header",
          attrs: { "aria-label": "breadcrumb" },
        },
        [
          _c("h1", { staticClass: "title has-text-centered-touch" }, [
            _c(
              "ul",
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [
                      _vm._m(0),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                    ]
                  ),
                ]),
                !_vm.isOnAdminPage
                  ? [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "dashboard",
                                  params: {
                                    lang: _vm.$route.params.lang,
                                    departmentId:
                                      _vm.$route.params.departmentId,
                                  },
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "home" } }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generals.home"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "notifications",
                                  params: {
                                    lang: _vm.$route.params.lang,
                                    departmentId:
                                      _vm.$route.params.departmentId,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.notifications_list.title")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "notifications",
                                  params: {
                                    lang: _vm.$route.params.lang,
                                    departmentId:
                                      _vm.$route.params.departmentId,
                                  },
                                  query: { activeTab: 1 },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("component.issues.title")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("li", { staticClass: "is-active" }, [
                        _c(
                          "a",
                          { attrs: { href: "", "aria-current": "page" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("component.issues.detail.title"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminCompanies",
                                  params: {
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "warehouse" } }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.sidebar.companies"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminCompanyDetail",
                                  params: {
                                    companyId: _vm.companyId,
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [
                              _vm.company
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.company.companyName)),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminCompanyDetail",
                                  params: {
                                    companyId: _vm.companyId,
                                    lang: _vm.$route.params.lang,
                                  },
                                  query: { activeTab: 4 },
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("component.issues.title"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("li", { staticClass: "is-active" }, [
                        _c(
                          "a",
                          { attrs: { href: "", "aria-current": "page" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("component.issues.detail.title"))
                            ),
                          ]
                        ),
                      ]),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
      !_vm.isLoading && _vm.issue
        ? _c("div", { staticClass: "tile is-ancestor" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "tile is-child box" },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "custom-layout-tabs",
                      attrs: { type: "is-boxed" },
                      on: {
                        input: function ($event) {
                          return _vm.changedActiveTab($event)
                        },
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t("component.issues.detail.title"),
                          },
                        },
                        [
                          _c("IssueInfo", {
                            attrs: {
                              issue: _vm.issue,
                              device: _vm.device,
                              source: _vm.source,
                              companyLogger: _vm.companyLogger,
                              sourceGroup: _vm.sourceGroup,
                              isOnAdminPage: _vm.isOnAdminPage,
                              dateTimeManager: _vm.dateTimeManager,
                              totalNotification: _vm.issueNotifications.length,
                            },
                            on: {
                              openNotificationTab: _vm.openNotificationTab,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.issues.detail.notifications.title"
                            ),
                          },
                        },
                        [
                          _c("IssueNotifications", {
                            attrs: {
                              issueNotifications: _vm.issueNotifications,
                              dateTimeManager: _vm.dateTimeManager,
                              date: "",
                            },
                            on: { changeRouterUrl: _vm.changeRouterUrl },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }