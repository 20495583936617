var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("OrganizationCard", {
                  staticClass: "box is-child column is-full p-5",
                  attrs: {
                    departments: _vm.departments,
                    issuesSummary: _vm.issuesSummary,
                    favoriteDepartmentId: _vm.favoriteDepartmentId,
                  },
                  on: {
                    reload: _vm.loadDataForPage,
                    favoriteSelected: _vm.favoriteSelected,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("NotificationCard", {
                  staticClass: "box is-child column is-full p-5",
                  attrs: {
                    notifications: _vm.notifications,
                    allNotificationCount: _vm.notificationsCount,
                    paused: _vm.notificationsPaused,
                    pausedTill: _vm.pauseNotificationsUntil,
                    dateTimeManager: _vm.dateTimeManager,
                    buttonEnabled: false,
                  },
                  on: { notificationRemoved: _vm.notificationRemoved },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile is-parent", attrs: { id: "invitation" } },
              [
                _c("InvitationCard", {
                  staticClass: "box is-child column is-full p-5",
                  attrs: {
                    invitations: _vm.invitations,
                    dateTimeManager: _vm.dateTimeManager,
                    deptId: _vm.deptId,
                  },
                  on: {
                    acceptInvite: _vm.acceptInvite,
                    declineInvite: _vm.declineInvite,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }