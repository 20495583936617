var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("main", { staticClass: "bsod container error-content mt-6" }, [
      _c(
        "h1",
        { staticClass: "neg title is-size-1 has-text-centered" },
        [
          _c("span", { staticClass: "bg mr-5" }, [
            _vm._v(_vm._s(_vm.$t("public_dashboard_not_found.title"))),
          ]),
          _c("b-icon", { attrs: { pack: "fas", icon: "link" } }),
          _c("font-awesome-icon", { attrs: { icon: "link-slash" } }),
        ],
        1
      ),
      _c("h2", { staticClass: "is-size-4 has-text-centered px-3" }, [
        _vm._v(_vm._s(_vm.$t("public_dashboard_not_found.error_occurred"))),
      ]),
      _c("p", { staticClass: "has-text-centered px-3" }, [
        _vm._v(_vm._s(_vm.$t("public_dashboard_not_found.options"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }