var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("h1", { staticClass: "title mt-3" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.logger.assignSourceList.list.emptyNamesDialog.title"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body has-text-centered" }, [
      _c("p", { staticClass: "has-text-weight-bold" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc(
                "component.logger.assignSourceList.list.emptyNamesDialog.message",
                _vm.loggesWithoutNameNO
              )
            ) +
            " "
        ),
      ]),
      _c("p", { staticClass: "is-italic mt-3" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.logger.assignSourceList.list.emptyNamesDialog.note"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot is-flex is-justify-content-space-between",
      },
      [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.resultRetrieved(false)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.logger.assignSourceList.list.emptyNamesDialog.deny"
                    )
                  )
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.onlyActivate()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$tc(
                      "component.logger.assignSourceList.list.emptyNamesDialog.confirmNoAssign",
                      _vm.loggesWithoutNameNO
                    )
                  )
                ),
              ]
            ),
            _c(
              "b-button",
              {
                attrs: { type: "is-success" },
                on: {
                  click: function ($event) {
                    return _vm.createSources()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$tc(
                      "component.logger.assignSourceList.list.emptyNamesDialog.confirm",
                      _vm.loggesToAssignNO
                    )
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }