var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.settings.profile.user_info.verification_modal.title"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c(
        "p",
        { staticClass: "has-text-centered" },
        [
          _c("b-icon", {
            attrs: {
              pack: "fas",
              icon: "user-times",
              size: "is-large",
              type: "is-primary",
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("p", { staticClass: "has-text-centered has-text-weight-medium" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.settings.profile.user_info.verification_modal.body1"
              )
            ) +
            " "
        ),
      ]),
      _c("br"),
      _c("p", { staticClass: "has-text-centered has-text-weight-medium" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.settings.profile.user_info.verification_modal.body2"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.resend()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "component.settings.profile.user_info.verification_modal.confirm_btn"
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }