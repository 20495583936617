import { render, staticRenderFns } from "./VerifyResend.vue?vue&type=template&id=455691f6"
import script from "./VerifyResend.vue?vue&type=script&lang=ts"
export * from "./VerifyResend.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('455691f6')) {
      api.createRecord('455691f6', component.options)
    } else {
      api.reload('455691f6', component.options)
    }
    module.hot.accept("./VerifyResend.vue?vue&type=template&id=455691f6", function () {
      api.rerender('455691f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/authentication/VerifyResend.vue"
export default component.exports