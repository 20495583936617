import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let EditServiceModal = class EditServiceModal extends Vue {
    constructor() {
        super(...arguments);
        this.newEndTime = new Date();
        this.customAnnotationText = '';
        this.hourFormat = '24';
    }
    get minEndTime() {
        let currentTime = new Date();
        let startTime = new Date(this.source.activeService.startTime * 1000);
        if (currentTime.getTime() > startTime.getTime()) {
            return currentTime;
        }
        else {
            return startTime;
        }
    }
    created() {
        if (this.source.activeService == null) {
            this.closeModal();
        }
        else {
            if (this.source.activeService.endTime)
                this.newEndTime = new Date(this.source.activeService.endTime * 1000);
            else
                this.newEndTime = new Date();
            this.customAnnotationText = this.source.activeService.annotation;
        }
    }
    save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let serviceDto = {
                    EndTime: this.newEndTime.toISOString(),
                    Annotation: this.customAnnotationText
                };
                this.$emit('editService', serviceDto);
            }
        });
    }
    endNow() {
        this.newEndTime = new Date();
        this.save();
    }
    closeModal() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: Object })
], EditServiceModal.prototype, "source", void 0);
__decorate([
    Prop({ type: Object })
], EditServiceModal.prototype, "dateTimeManager", void 0);
EditServiceModal = __decorate([
    Component({
        components: {}
    })
], EditServiceModal);
export default EditServiceModal;
