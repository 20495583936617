var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            position: "is-bottom-left",
            "append-to-body": "",
            "aria-role": "list",
          },
          scopedSlots: _vm._u(
            [
              _vm.hasButton
                ? {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.loggers.detail.actions.title"
                              ),
                              position: "is-left",
                              type: "is-dark",
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { staticStyle: { "font-size": "1rem" } },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    type: "is-primary",
                                    icon: "ellipsis-v",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              "icon-pack": "fa",
                              "icon-right": "angle-down",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.detail.actions.title"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: { custom: "", focusable: false, "aria-role": "listitem" },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "is-italic has-text-weight-bold has-text-grey has-text-grey-dark-mode",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.loggers.detail.actions.sales_group"
                      )
                    )
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canActivate() ||
                  !_vm.$ability.can(
                    _vm.$permActions.ACTIVATE,
                    _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.activateClicked()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left mr-1" },
                  [
                    _c("b-icon", { attrs: { icon: "plus" } }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.detail.actions.activate"
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.activateNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canReturn() ||
                  !_vm.$ability.can(
                    _vm.$permActions.RETURN,
                    _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.returnFromDepartmentClicked()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "minus" } }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.actions.return"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.returnNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canRevert() ||
                  !_vm.$ability.can(
                    _vm.$permActions.REVERT,
                    _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.revertDevice()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "redo-alt" } }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.actions.revert"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.revertNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: { custom: "", focusable: false, "aria-role": "listitem" },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "is-italic has-text-weight-bold has-text-grey has-text-grey-dark-mode",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.loggers.detail.actions.tech_group"
                      )
                    )
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canDeactivate() ||
                  !_vm.$ability.can(
                    _vm.$permActions.DEACTIVATE,
                    _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.deactivateClicked()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "minus" } }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.actions.deactivate"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.deactivateNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canPublish() ||
                  !_vm.$ability.can(
                    _vm.$permActions.PUBLISH,
                    _vm.$permSubjects.ADMIN_LOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.publishClicked()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "heading" } }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.detail.actions.publish"
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.publishNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canUnpublish() ||
                  !_vm.$ability.can(
                    _vm.$permActions.UNPUBLISH,
                    _vm.$permSubjects.ADMIN_LOGGERS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.unpublishClicked()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "remove-format" } }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.detail.actions.unpublish"
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "level-right pl-1" },
                  [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          type: "is-dark",
                          label: _vm.$t(
                            "admin.component.loggers.detail.actions.unpublishNote"
                          ),
                          position: "is-left",
                          multilined: "",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            size: "is-small",
                            icon: "question-circle",
                            type: "is-grey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: {
                "aria-role": "listitem",
                disabled:
                  !_vm.canReadData() ||
                  !_vm.$ability.can(
                    _vm.$permActions.READDATA,
                    _vm.$permSubjects.ADMIN_LOGGERS_COMMANDS
                  ) ||
                  !_vm.$ability.can(
                    _vm.$permActions.READ,
                    _vm.$permSubjects.ADMIN_GATEWAYS
                  ),
              },
              on: {
                click: function ($event) {
                  return _vm.openReadDataModal()
                },
              },
            },
            [
              _c("b-icon", { attrs: { icon: "book-open" } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("admin.component.loggers.detail.actions.readData")
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: { "aria-role": "listitem" },
              on: {
                click: function ($event) {
                  return _vm.addVerificationKey()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "key" } }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.actions.addVerificationKey"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "custom-dropdown-item",
              attrs: { "aria-role": "listitem" },
              on: {
                click: function ($event) {
                  return _vm.deleteVerificationKey()
                },
              },
            },
            [
              _c("div", { staticClass: "level is-mobile" }, [
                _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", { attrs: { icon: "trash" } }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.loggers.detail.actions.deleteVerificationKey"
                            )
                          )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }