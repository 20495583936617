var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "subtitle is-4" }, [
        _vm._v(_vm._s(_vm.$t("tiers.change_request_list"))),
      ]),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.changeRequests,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
        },
        [
          _c("b-table-column", {
            attrs: { field: "id", label: _vm.$t("tiers.id"), sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [
                    _c("span", { staticClass: "is-family-monospace" }, [
                      _vm._v(_vm._s(changeRequests.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "requestedTier",
              label: _vm.$t("tiers.request_tier"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [_vm._v(_vm._s(changeRequests.row.requestedTier))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "text",
              label: _vm.$t("tiers.request_text"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [_vm._v(_vm._s(changeRequests.row.text))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "createdAt",
              label: _vm.$t("tiers.created_at"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(changeRequests.row.createdAt * 1000),
                            null,
                            null,
                            null
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "requestor",
              label: _vm.$t("tiers.requestor"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [_vm._v(_vm._s(changeRequests.row.requestor))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "company",
              label: _vm.$t("tiers.company"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [_vm._v(_vm._s(changeRequests.row.company))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "state",
              label: _vm.$t("tiers.request_state"),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (changeRequests) {
                  return [_vm._v(_vm._s(changeRequests.row.state))]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [_vm._v(" " + _vm._s(_vm.$t("tiers.no_requests")) + " ")]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }