var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h2", { staticClass: "subtitle is-4" }, [
      _vm._v(" " + _vm._s(_vm.$t("component.settings.about.manual")) + " "),
    ]),
    _c("section", [
      _c(
        "div",
        { staticClass: "is-flex is-flex-direction-row is-align-items-center" },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("component.settings.about.manual_desc"))),
          ]),
          _c(
            "b-button",
            {
              staticClass: "ml-2",
              attrs: {
                type: "is-primary",
                tag: "a",
                href: _vm.pdf.default,
                "icon-pack": "fa",
                "icon-left": "file-pdf",
                download: "Manual",
              },
            },
            [_vm._v(_vm._s(_vm.$t("component.settings.about.manual_download")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }