import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoggerStates from '@/entities/enums/LoggerStates';
import AppConfig from '@/configLoader';
let LoggerActionsDropdown = class LoggerActionsDropdown extends Vue {
    canPublish() {
        return this.logger.state == LoggerStates.AVAILABLE;
    }
    canUnpublish() {
        return this.logger.state == LoggerStates.DEACTIVATED || this.logger.state == LoggerStates.PUBLISHED;
    }
    canActivate() {
        return this.logger.state == LoggerStates.PUBLISHED;
    }
    canReturn() {
        return this.logger.state == LoggerStates.ACTIVATED;
    }
    canDeactivate() {
        return this.logger.state == LoggerStates.ACTIVATED;
    }
    canRevert() {
        return this.logger.state == LoggerStates.ACTIVATED && this.logger.currentlyPublishedAs != null;
    }
    canReadData() {
        return (this.logger?.currentlyPublishedAs?.isOnline === true && AppConfig.getConfig().features.read_logger_data === true);
    }
    revertDevice() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.detail.info.revert.title'),
            message: this.$t('admin.component.loggers.detail.info.revert.message', {
                name: this.logger.currentlyPublishedAs.serialNumber
            }),
            confirmText: this.$t('admin.component.loggers.detail.info.revert.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.$emit('revertDevice', this.logger);
            }
        });
    }
    deleteVerificationKey() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.deleteVerificationKeyModal.title'),
            message: this.$t('admin.component.loggers.deleteVerificationKeyModal.message'),
            confirmText: this.$t('admin.component.loggers.deleteVerificationKeyModal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.$emit('deleteVerificationKey', this.logger)
        });
    }
    addVerificationKey() {
        this.$emit('addVerificationKey', this.logger);
    }
    openReadDataModal() {
        this.$emit('openReadDataModal', this.logger);
    }
    unpublishClicked() {
        this.$buefy.dialog.confirm({
            title: ((this.$t('admin.component.loggers.unpublish_modal.title') +
                (this.logger?.currentlyPublishedAs?.serialNumber
                    ? ' (' + this.logger.currentlyPublishedAs.serialNumber + ')'
                    : ''))),
            message: this.$t('admin.component.loggers.unpublish_modal.message'),
            confirmText: this.$t('admin.component.loggers.unpublish_modal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.$emit('unpublishClicked', this.logger)
        });
    }
    publishClicked() {
        this.$emit('publishClicked', this.logger);
    }
    deactivateClicked() {
        this.$buefy.dialog.confirm({
            title: ((this.$t('admin.component.loggers.deactivate_modal.title') +
                (this.logger?.currentlyPublishedAs?.serialNumber
                    ? ' (' + this.logger.currentlyPublishedAs.serialNumber + ')'
                    : ''))),
            message: this.$t('admin.component.loggers.deactivate_modal.message'),
            confirmText: this.$t('admin.component.loggers.deactivate_modal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.$emit('deactivateClicked', this.logger)
        });
    }
    returnFromDepartmentClicked() {
        this.$buefy.dialog.confirm({
            title: ((this.$t('admin.component.loggers.return_from_dep_modal.title') +
                (this.logger?.currentlyPublishedAs?.serialNumber
                    ? ' (' + this.logger.currentlyPublishedAs.serialNumber + ')'
                    : ''))),
            message: this.$t('admin.component.loggers.return_from_dep_modal.message'),
            confirmText: this.$t('admin.component.loggers.return_from_dep_modal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.$emit('returnFromDepartmentClicked', this.logger);
            }
        });
    }
    activateClicked() {
        this.$emit('activateClicked', this.logger);
    }
};
__decorate([
    Prop({ type: Object })
], LoggerActionsDropdown.prototype, "logger", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], LoggerActionsDropdown.prototype, "hasButton", void 0);
LoggerActionsDropdown = __decorate([
    Component({})
], LoggerActionsDropdown);
export default LoggerActionsDropdown;
