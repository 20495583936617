import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LoggerServiceLength } from '@/entities/enums/LoggerServiceLength';
import { PredefinedText } from '@/entities/models/Annotation';
import VueUtilities from '@/services/VueUtilities';
let SetServiceModal = class SetServiceModal extends Vue {
    constructor() {
        super(...arguments);
        this.LoggerServiceLength = LoggerServiceLength;
        this.PredefinedText = PredefinedText;
        this.initialCurrentTime = new Date();
        this.selectedServiceLength = LoggerServiceLength.ONE_HOUR;
        this.customTimeLength = new Date();
        this.createAnnotation = true;
        this.startImmediately = true;
        this.scheduledStartTime = new Date();
        this.hourFormat = '24';
        this.customAnnotationText = '';
        this.predefinedAnnotationText = PredefinedText.MAINTENANCE;
    }
    get minEndTime() {
        if (this.startImmediately === false) {
            return this.scheduledStartTime;
        }
        else {
            return new Date();
        }
    }
    created() {
        this.customTimeLength.setHours(this.customTimeLength.getHours() + 1);
    }
    save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let start = null;
                let end = null;
                let annotation = null;
                if (this.startImmediately === true) {
                    start = new Date();
                }
                else {
                    start = this.scheduledStartTime;
                }
                if (this.selectedServiceLength === LoggerServiceLength.RANGE) {
                    end = this.customTimeLength;
                }
                else if (this.selectedServiceLength === LoggerServiceLength.UNKNOWN) {
                    end = null;
                }
                else {
                    end = new Date(start.getTime());
                    end.setMinutes(end.getMinutes() + this.selectedServiceLength);
                }
                if (end && end.getTime() <= start.getTime()) {
                    VueUtilities.openErrorToast(this, this.$t('component.logger.service.modal.time_error'));
                    return;
                }
                if (this.createAnnotation === true) {
                    annotation =
                        this.predefinedAnnotationText === PredefinedText.OWN
                            ? this.customAnnotationText
                            : this.$t(`component.report.create_annotation.predefined.${this.predefinedAnnotationText}`);
                }
                let service = {
                    StartTime: start.toISOString(),
                    EndTime: end ? end.toISOString() : null,
                    Annotation: annotation
                };
                this.$emit('createService', service);
            }
        });
    }
    closeModal() {
        this.$emit('modalClosed');
    }
};
__decorate([
    Prop({ type: Object })
], SetServiceModal.prototype, "source", void 0);
__decorate([
    Prop({ type: Object })
], SetServiceModal.prototype, "dateTimeManager", void 0);
SetServiceModal = __decorate([
    Component({
        components: {}
    })
], SetServiceModal);
export default SetServiceModal;
