var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "section-header-level" },
      [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("tiers.list_title")) + " "),
        ]),
        _vm.$ability.can(_vm.$permActions.CREATE, _vm.$permSubjects.ADMIN_TIERS)
          ? _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "adminCreateTier",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "is-hidden-mobile",
                    attrs: { type: "is-primary" },
                  },
                  [_vm._v(_vm._s(_vm.$t("tiers.create_tier")))]
                ),
                _c("b-button", {
                  staticClass: "is-hidden-tablet",
                  attrs: { type: "is-primary", "icon-left": "plus" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c("section", [
      _c(
        "div",
        { staticClass: "columns is-multiline is-mobile tier-container" },
        _vm._l(_vm.tiers, function (tier, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "column is-one-quarter-tablet is-three-quarters-mobile",
            },
            [_c("TierCard", { attrs: { tier: tier } })],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }