import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminLoggerList from '@/views/components/admin/loggers/AdminLoggerList.vue';
import AdminLoggersCalibrationExpires from '@/views/components/admin/loggers/AdminLoggersCalibrationExpires.vue';
import LoggerRepository from '@/services/repository/LoggerRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import GenerateStickerModal from '@/views/components/admin/loggers/GenerateStickersModal.vue';
import CreateLoggerModal from '@/views/components/admin/loggers/CreateLoggerModal.vue';
import DateTimeManager from '@/services/DateTimeManager';
import ApiResponse from '@/entities/ApiResponse';
import { CalibrationExpires } from '@/entities/enums/CalibrationExpires';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import VueUtilities from '@/services/VueUtilities';
var loggerRepository;
let AdminLoggerManagement = class AdminLoggerManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.adminLoggers = new ApiResponse();
        this.loggersCalibrationExpires = new ApiResponse();
        this.isCsvModalActive = false;
        this.isCreateModalActive = false;
        this.currentFilter = [];
        this.currentFilterCalibrationExpires = [];
        this.dateTimeManager = null;
        this.objStringAdminLogger = null;
        this.objStringCalibrationExpires = null;
        this.tableAdminLoggerQueries = 'adminLogger';
        this.tableCalibrationExpiresQueries = 'calibrationExpires';
        this.currentDateTime = Math.floor(Date.now() / 1000);
        this.selectedCalibrationExpire = CalibrationExpires.THREEYEARS;
    }
    async created() {
        this.isLoading = true;
        loggerRepository = new LoggerRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PREV_ROUTE);
        this.isLoading = false;
    }
    async loadLoggers(pagination, sort, currentFilter) {
        let allPromises = [];
        allPromises.push(loggerRepository.getAdminLoggers(pagination, sort, currentFilter));
        Promise.all(allPromises).then((response) => {
            this.adminLoggers = response[0];
        });
    }
    async onOptionsChange(options, filters = []) {
        this.callOptions = options;
        this.currentFilter = filters;
        this.loadLoggers(options.pagination, options.sort, filters);
    }
    async createVerificationKey(logger, verificationKey) {
        let res = await loggerRepository.adminAddLoggerVerificationKey(logger.id, verificationKey);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.createVerificationKeyModal.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.createVerificationKeyModal.failure'));
        }
    }
    async deleteVerificationKey(logger) {
        let res = await loggerRepository.adminDeleteLoggerVerificationKey(logger.id);
        if (res === true) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.deleteVerificationKeyModal.success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.deleteVerificationKeyModal.failure'));
        }
    }
    async loadLoggersCalibrationExpires(selectedCalibrationExpire, pagination, sort, currentFilter) {
        this.selectedCalibrationExpire = selectedCalibrationExpire;
        this.loggersCalibrationExpires = await loggerRepository.getLoggersCalibrationExpires(pagination, sort, currentFilter);
        this.isLoading = false;
    }
    async onOptionsChangeLoggersCalibrationExpires(selectedCalibrationExpire, options, filters = []) {
        this.callOptionsCalibrationExpires = options;
        this.currentFilterCalibrationExpires = filters;
        this.loadLoggersCalibrationExpires(selectedCalibrationExpire, options.pagination, options.sort, filters);
    }
    registerNewLogger() {
        this.$router.push({
            name: 'adminRegisterNewLoggers',
            params: {
                lang: this.$route.params.lang
            }
        });
    }
    showCvsModal() {
        this.isCsvModalActive = true;
    }
    changeRouterUrlFromLogger(objStringAdminLogger) {
        this.objStringAdminLogger = objStringAdminLogger;
        this.changeRouterUrl(this.objStringAdminLogger, this.objStringCalibrationExpires);
    }
    changeRouterUrlFromCalibrationExpires(objStringCalibrationExpires) {
        this.objStringCalibrationExpires = objStringCalibrationExpires;
        this.changeRouterUrl(this.objStringAdminLogger, this.objStringCalibrationExpires);
    }
    changeRouterUrl(objStringAdminLogger, objStringCalibrationExpires) {
        this.$router
            .push({
            query: {
                [this.tableAdminLoggerQueries]: objStringAdminLogger,
                [this.tableCalibrationExpiresQueries]: objStringCalibrationExpires
            }
        })
            .catch((err) => err);
    }
    showCreateModal() {
        this.isCreateModalActive = true;
    }
    closeModal() {
        this.isCsvModalActive = false;
    }
    closeCreateLoggerModal(result) {
        if (result == true) {
            this.loadLoggers();
        }
        this.isCreateModalActive = false;
    }
};
AdminLoggerManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_LOGGERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminLoggerList,
            AdminLoggersCalibrationExpires,
            GenerateStickerModal,
            CreateLoggerModal
        }
    })
], AdminLoggerManagement);
export default AdminLoggerManagement;
