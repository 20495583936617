var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      [
        _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _vm.isLayoutList
              ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.reseller.detail.resellerLoggers.title"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "level-right" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "level-item is-hidden-mobile",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "admin.component.company.list.actions.columns"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      { staticStyle: { "font-size": "1rem" } },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "list",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.tableColumns, function (column) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: column.field,
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              focusable: false,
                              custom: "",
                              "aria-role": "listitem",
                            },
                          },
                          [
                            _c(
                              "b-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    column.field ==
                                    _vm.tableColumns.serialNumber.field,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.chooseTableColumns(
                                      column.field,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: column.visible,
                                  callback: function ($$v) {
                                    _vm.$set(column, "visible", $$v)
                                  },
                                  expression: "column.visible",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `admin.component.loggers.loggerList.table.${column.field}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "b-table",
          {
            ref: "table",
            staticClass: "mobile-filter res-table",
            attrs: {
              data: _vm.loggers,
              paginated: "",
              "current-page": _vm.page,
              "per-page": _vm.pageSize,
              total: _vm.totalSize,
              "default-sort-direction": _vm.sortDirection,
              "default-sort": _vm.sortField,
              striped: "",
              "page-input": true,
              "pagination-order": "is-centered",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
              "page-change": _vm.onPageChange,
              sort: _vm.sorted,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "bottom-left",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notifications_list.table.row_per_page"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-field",
                        { staticClass: "pl-2 mb-4-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              on: { input: _vm.rowsPerPageSelected },
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.loggers && _vm.loggers.length > 0
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-justify-content-flex-end",
                            },
                            [
                              _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.list.table.results",
                                        {
                                          from: _vm.fromTableData,
                                          to: _vm.toTableData,
                                          total: _vm.totalSize,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          [
            _c("b-table-column", {
              attrs: {
                field: "id",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.loggerId.field}`
                ),
                visible: _vm.tableColumns.loggerId.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      _c("span", { staticClass: "is-family-monospace" }, [
                        _vm._v(_vm._s(loggerList.row.id)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "macAddress",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.macAddress.field}`
                ),
                visible: _vm.tableColumns.macAddress.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminLoggerDetail",
                              params: {
                                loggerId: loggerList.row.id,

                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(loggerList.row.macAddress))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "currentlyPublishedAs.serialNumber",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.serialNumber.field}`
                ),
                visible: _vm.tableColumns.serialNumber.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isSerialNoSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.loggers.loggerList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  expanded: "",
                                },
                                on: {
                                  input: _vm.handleFilterSerialNumberDebounce,
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.filterChange(
                                      _vm.tableColumns.serialNumber.field,
                                      _vm.serialNumberSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.serialNumberSearch,
                                  callback: function ($$v) {
                                    _vm.serialNumberSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "serialNumberSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterSerialNumberDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterChange(
                                  _vm.tableColumns.serialNumber.field,
                                  _vm.serialNumberSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.serialNumberSearch,
                              callback: function ($$v) {
                                _vm.serialNumberSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "serialNumberSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      loggerList.row.currentlyPublishedAs
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminLoggerDetail",
                                  params: {
                                    loggerId: loggerList.row.id,

                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  loggerList.row.currentlyPublishedAs
                                    .serialNumber
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "currentlyPublishedAs.company.companyName",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.companyName.field}`
                ),
                visible: _vm.tableColumns.companyName.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      loggerList.row.currentlyPublishedAs &&
                      loggerList.row.currentlyPublishedAs.company &&
                      loggerList.row.currentlyPublishedAs.company.isDeleted ==
                        true
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    loggerList.row.currentlyPublishedAs.company
                                      .companyName
                                  ) +
                                  " "
                              ),
                              _c(
                                "b-tag",
                                { attrs: { type: "is-primary", rounded: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.devices.deviceList.table.deleted"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : loggerList.row.currentlyPublishedAs &&
                          loggerList.row.currentlyPublishedAs.company
                        ? [
                            _vm.hasPermissionToViewDepartment(
                              loggerList.row.currentlyPublishedAs.company
                                .companyId
                            )
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminCompanyDetail",
                                        params: {
                                          companyId:
                                            loggerList.row.currentlyPublishedAs
                                              .company.companyId,
                                          title: _vm.$t(
                                            "admin.component.loggers.loggerList.title"
                                          ),
                                          lang: _vm.$route.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        loggerList.row.currentlyPublishedAs
                                          .company.companyName
                                      )
                                    ),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      loggerList.row.currentlyPublishedAs
                                        .company.companyName
                                    )
                                  ),
                                ]),
                          ]
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "lastSeen",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.lastSeen.field}`
                ),
                visible: _vm.tableColumns.lastSeen.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(loggerList.row.lastSeen * 1000),
                              null,
                              null,
                              null
                            )
                          )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "additionalProperties.isOnline",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.isOnline.field}`
                ),
                visible: _vm.tableColumns.isOnline.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isOnlineSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.loggers.loggerList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t("generals.search"),
                                      },
                                      on: {
                                        input: _vm.handleFilterIsOnlineDebounce,
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          _vm.filterChange(
                                            _vm.tableColumns.isOnline.field,
                                            _vm.onlineSearch.toString()
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.onlineSearch,
                                        callback: function ($$v) {
                                          _vm.onlineSearch = $$v
                                        },
                                        expression: "onlineSearch",
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          staticClass: "clear-filter-option",
                                          domProps: { value: null },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(`generals.clearFilter`)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { domProps: { value: true } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.last_events.table.has_signal"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { domProps: { value: false } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.last_events.table.no_signal"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { placeholder: _vm.$t("generals.search") },
                              on: { input: _vm.handleFilterIsOnlineDebounce },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  _vm.filterChange(
                                    _vm.tableColumns.isOnline.field,
                                    _vm.onlineSearch.toString()
                                  )
                                },
                              },
                              model: {
                                value: _vm.onlineSearch,
                                callback: function ($$v) {
                                  _vm.onlineSearch = $$v
                                },
                                expression: "onlineSearch",
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  staticClass: "clear-filter-option",
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(`generals.clearFilter`)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("option", { attrs: { value: "true" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.last_events.table.has_signal"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("option", { attrs: { value: "false" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.last_events.table.no_signal"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      loggerList.row.additionalProperties.isOnline == "false"
                        ? _c(
                            "b-tag",
                            { attrs: { type: "is-primary", rounded: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.last_events.table.no_signal"
                                  )
                                )
                              ),
                            ]
                          )
                        : loggerList.row.additionalProperties.isOnline == "true"
                        ? _c(
                            "b-tag",
                            { attrs: { type: "is-success", rounded: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.last_events.table.has_signal"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "state",
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.state.field}`
                ),
                visible: _vm.tableColumns.state.visible,
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function (loggerList) {
                    return [
                      _vm.isStateSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.loggers.loggerList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                [
                                  loggerList.column.field == "state"
                                    ? _c(
                                        "b-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "admin.component.loggers.loggerList.table.state_placeholder"
                                            ),
                                          },
                                          on: {
                                            input:
                                              _vm.handleFilterStateDebounce,
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.filterChange(
                                                _vm.tableColumns.state.field,
                                                _vm.stateSearch
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.stateSearch,
                                            callback: function ($$v) {
                                              _vm.stateSearch = $$v
                                            },
                                            expression: "stateSearch",
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              staticClass:
                                                "clear-filter-option",
                                              domProps: { value: null },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      `generals.clearFilter`
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.loggerStates,
                                            function (option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option,
                                                  domProps: { value: option },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          `admin.component.loggers.state.${option.toLowerCase()}`
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          loggerList.column.field == "state"
                            ? _c(
                                "b-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "admin.component.loggers.loggerList.table.state_placeholder"
                                    ),
                                  },
                                  on: { input: _vm.handleFilterStateDebounce },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.filterChange(
                                        _vm.tableColumns.state.field,
                                        _vm.stateSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.stateSearch,
                                    callback: function ($$v) {
                                      _vm.stateSearch = $$v
                                    },
                                    expression: "stateSearch",
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      staticClass: "clear-filter-option",
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(`generals.clearFilter`)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.loggerStates, function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option,
                                        domProps: { value: option },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `admin.component.loggers.state.${option.toLowerCase()}`
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `admin.component.loggers.state.${loggerList.row.state.toLowerCase()}`
                            )
                          )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.battery.field,
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.battery.field}`
                ),
                visible: _vm.tableColumns.battery.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      loggerList.row.battery && loggerList.row.battery.unit
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(loggerList.row.battery.value) +
                                _vm._s(loggerList.row.battery.unit) +
                                " "
                            ),
                          ])
                        : loggerList.row.rawBattery
                        ? _c("p", [
                            _vm._v(_vm._s(loggerList.row.rawBattery) + "%"),
                          ])
                        : _c("p", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.calibrated.field,
                label: _vm.$t(
                  `admin.component.loggers.loggerList.table.${_vm.tableColumns.calibrated.field}`
                ),
                visible: _vm.tableColumns.calibrated.visible,
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isCalibrationSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.loggers.loggerList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t("generals.search"),
                                        "icon-pack": "fas",
                                        icon: "search",
                                        expanded: "",
                                      },
                                      on: {
                                        input:
                                          _vm.handleFilterCalibratedDebounce,
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          _vm.filterChange(
                                            _vm.tableColumns.calibrated.field,
                                            _vm.calibrationSearch.toString()
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.calibrationSearch,
                                        callback: function ($$v) {
                                          _vm.calibrationSearch = $$v
                                        },
                                        expression: "calibrationSearch",
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          domProps: {
                                            value:
                                              _vm.CalibrationStatus.CALIBRATED
                                                .name,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "calibration_status.calibrated"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        {
                                          domProps: {
                                            value:
                                              _vm.CalibrationStatus.EXPIRED
                                                .name,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "calibration_status.expired"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        {
                                          domProps: {
                                            value:
                                              _vm.CalibrationStatus
                                                .NOT_CALIBRATED.name,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "calibration_status.not_calibrated"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterCalibratedDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      _vm.filterChange(
                                        _vm.tableColumns.calibrated.field,
                                        _vm.calibrationSearch.toString()
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.calibrationSearch,
                                    callback: function ($$v) {
                                      _vm.calibrationSearch = $$v
                                    },
                                    expression: "calibrationSearch",
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value:
                                          _vm.CalibrationStatus.CALIBRATED.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "calibration_status.calibrated"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value:
                                          _vm.CalibrationStatus.EXPIRED.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("calibration_status.expired")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value:
                                          _vm.CalibrationStatus.NOT_CALIBRATED
                                            .name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "calibration_status.not_calibrated"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (loggerList) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-align-items-center ml-3-mobile",
                        },
                        [
                          loggerList.row.currentlyPublishedAs
                            ? _c(
                                "b-tag",
                                {
                                  attrs: {
                                    type: loggerList.row.currentlyPublishedAs
                                      .calibration.status.type,
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `calibration_status.${loggerList.row.currentlyPublishedAs.calibration.status.name}`
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "b-tag",
                                {
                                  attrs: {
                                    type: _vm.CalibrationStatus.NOT_CALIBRATED
                                      .type,
                                    rounded: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `calibration_status.${_vm.CalibrationStatus.NOT_CALIBRATED.name}`
                                      )
                                    )
                                  ),
                                ]
                              ),
                          _vm.hasCalibrationProtocolToDownload(
                            loggerList.row.currentlyPublishedAs
                          ) &&
                          _vm.$ability.can(
                            _vm.$permActions.PROTOCOL,
                            _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                          )
                            ? [
                                _c(
                                  "b-tooltip",
                                  {
                                    staticClass: "is-hidden-mobile",
                                    attrs: {
                                      label: _vm.$t("generals.download"),
                                      position: "is-left",
                                      type: "is-light",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "file-download" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.downloadCalibration(
                                            loggerList.row.currentlyPublishedAs
                                              .companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "is-hidden-tablet" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "control-icon clickable",
                                      attrs: { icon: "file-download" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.downloadCalibration(
                                            loggerList.row.currentlyPublishedAs
                                              .companyLoggerId
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "action",
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerLoggers.list.action"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (loggers) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c("b-icon", {
                            staticClass: "control-icon clickable",
                            attrs: { icon: "trash" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.actionSelect(loggers.row)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.loggerList.table.empty"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }