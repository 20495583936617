var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _vm.isLayoutList
            ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.deviceCommands.title"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item is-hidden-mobile",
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFilter()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
              ),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field == _vm.tableColumns.id.field ||
                                  column.field ==
                                    _vm.tableColumns.allowMultiple.field ||
                                  column.field ==
                                    _vm.tableColumns.progress.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `admin.component.devices.detail.deviceCommands.table.${column.field}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter table-wrap",
          attrs: {
            data: _vm.commands,
            paginated: "",
            "current-page": _vm.page,
            total: _vm.total,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            striped: "",
            "per-page": _vm.pageSize,
            "page-input": true,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.commands.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.total,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            staticClass: "wrap-if-long",
            attrs: {
              field: _vm.tableColumns.id.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.id.field}`
              ),
              visible: _vm.tableColumns.id.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function (commands) {
                  return [
                    _vm.isIdSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.devices.detail.deviceCommands.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                expanded: "",
                              },
                              model: {
                                value: commands.filters[commands.column.field],
                                callback: function ($$v) {
                                  _vm.$set(
                                    commands.filters,
                                    commands.column.field,
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "commands.filters[commands.column.field]",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            expanded: "",
                          },
                          model: {
                            value: commands.filters[commands.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                commands.filters,
                                commands.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "commands.filters[commands.column.field]",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _c("p", { staticClass: "is-family-monospace" }, [
                      _vm._v(_vm._s(commands.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            staticClass: "wrap-if-long",
            attrs: {
              field: _vm.tableColumns.type.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.type.field}`
              ),
              visible: _vm.tableColumns.type.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function (commands) {
                  return [
                    _vm.isTypeSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `admin.component.devices.detail.deviceCommands.table.${option.field}`
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-input", {
                              attrs: {
                                placeholder: _vm.$t("generals.search"),
                                expanded: "",
                              },
                              model: {
                                value: commands.filters[commands.column.field],
                                callback: function ($$v) {
                                  _vm.$set(
                                    commands.filters,
                                    commands.column.field,
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "commands.filters[commands.column.field]",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "level-item",
                                    attrs: { type: "is-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearFilter()
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "times-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            expanded: "",
                          },
                          model: {
                            value: commands.filters[commands.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                commands.filters,
                                commands.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "commands.filters[commands.column.field]",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (commands) {
                  return [_c("p", [_vm._v(_vm._s(commands.row.type))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.progress.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.progress.field}`
              ),
              visible: _vm.tableColumns.progress.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    commands.row.progress
                      ? _c("p", [
                          _vm._v(" " + _vm._s(commands.row.progress) + " "),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.allowMultiple.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.allowMultiple.field}`
              ),
              visible: _vm.tableColumns.allowMultiple.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _c("b-icon", {
                      attrs: {
                        icon: commands.row.allowMultiple ? "check" : "times",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(commands.row.createdAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.scheduleAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.scheduleAt.field}`
              ),
              visible: _vm.tableColumns.scheduleAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(commands.row.scheduleAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.confirmedAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.confirmedAt.field}`
              ),
              visible: _vm.tableColumns.confirmedAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(commands.row.confirmedAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.disabledAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.disabledAt.field}`
              ),
              visible: _vm.tableColumns.disabledAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(commands.row.disabledAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.updatedAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.deviceCommands.table.${_vm.tableColumns.updatedAt.field}`
              ),
              visible: _vm.tableColumns.updatedAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(commands.row.updatedAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              "cell-class": "actions",
              label: _vm.$t(
                "admin.component.devices.detail.deviceCommands.table.action"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (commands) {
                  return [
                    !commands.row.confirmedAt &&
                    !commands.row.disabledAt &&
                    _vm.$ability.can(
                      _vm.$permActions.DISABLE,
                      _vm.$permSubjects.ADMIN_GATEWAYS_COMMANDS
                    )
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openDisableModal(commands.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.devices.detail.deviceCommands.table.actions.disable"
                                  )
                                )
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.devices.detail.deviceCommands.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }