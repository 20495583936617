var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "multi-header" },
        [
          _c(
            "h1",
            { staticClass: "title is-3 is-spaced has-text-centered-touch" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("component.public_dashboard.dashboard_detail.title")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmDashboardDelete()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.remove")))]
          ),
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("DashboardInfo", {
                staticClass: "box",
                attrs: {
                  dashboard: _vm.dashboard,
                  sources: _vm.sources,
                  devices: _vm.devices,
                  sourceGroups: _vm.sourceGroups,
                  users: _vm.users,
                  dateTimeManager: _vm.dateTimeManager,
                  departmentId: _vm.$route.params.departmentId,
                },
                on: { reload: _vm.loadData },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }