"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const DEFAULT_SVG_HEIGHT = 200;
const DEFAULT_PREVIEW_HEIGHT = 0;
class ChartSettingsEntity {
    constructor(settings) {
        if (settings == null)
            throw new Error('Chart settings are required');
        this.previewChartHeight = settings.previewChartHeight || DEFAULT_PREVIEW_HEIGHT;
        this.gapBetweenCharts = settings.gapBetweenCharts || 20;
        this.svgHeight = settings.fullHeight || DEFAULT_SVG_HEIGHT;
        this.margin = this.createMainMargin(settings.margin);
        this.marginPreview = this.createPreviewMargin(settings.marginPreview);
    }
    createMainMargin(margin) {
        if (!margin)
            return { top: 20, bottom: this.previewChartHeight + this.gapBetweenCharts, left: 60, right: 50 };
        else
            return { top: margin.top || 20, bottom: margin.bottom || this.previewChartHeight + this.gapBetweenCharts, left: margin.left || 60, right: margin.right || 50 };
    }
    createPreviewMargin(margin) {
        if (!margin)
            return { top: this.svgHeight - this.previewChartHeight, bottom: 20, left: 60, right: 50 };
        else
            return {
                top: margin.top || this.svgHeight - this.previewChartHeight, bottom: margin.bottom || 20, left: margin.left || 60, right: margin.right || 50
            };
    }
}
exports.default = ChartSettingsEntity;
