var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal-card about-modal",
      staticStyle: { "border-radius": "7px" },
    },
    [
      _c("section", { staticClass: "modal-card-body p-0" }, [
        _c(
          "div",
          { staticClass: "columns is-mobile is-centered m-0 primary-bgm" },
          [
            _c("div", { staticClass: "is-flex my-5" }, [
              _c("img", {
                staticStyle: { height: "70px" },
                attrs: { src: require("@/assets/img/loghub_logo_trans.svg") },
              }),
              _c(
                "h1",
                { staticClass: "title is-3 mx-3 my-auto has-text-white" },
                [_vm._v(_vm._s(_vm.$t("app_name")))]
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "p-5" }, [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(_vm._s(_vm.$t("about.aboutUs"))),
          ]),
          _c("p", { staticClass: "has-text-black" }, [
            _vm._v(_vm._s(_vm.$t("about.message"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mx-5 mb-5 mt-0" },
          [
            _c(
              "b-table",
              {
                ref: "table",
                staticClass: "res-table",
                attrs: {
                  data: _vm.thirdPartyLicences,
                  "opened-detailed": [1],
                  detailed: "",
                  "detail-key": "name",
                  "show-detail-icon": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "detail",
                    fn: function (props) {
                      return [
                        _c("article", [
                          _c("div", { staticClass: "content" }, [
                            _c("p", { staticClass: "has-text-black" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("about.list.copyright")) +
                                  " © : "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(props.row.copyright)),
                              ]),
                            ]),
                            _c("p", { staticClass: "has-text-black" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("about.list.licenseType")) +
                                  " : "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(props.row.licenseType)),
                              ]),
                            ]),
                            _c("p", {
                              staticClass: "has-text-black",
                              domProps: {
                                innerHTML: _vm._s(props.row.licenseText),
                              },
                            }),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("b-table-column", {
                  attrs: { field: "name", label: _vm.$t("about.list.name") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [_vm._v(" " + _vm._s(props.row.name) + " ")]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "author",
                    label: _vm.$t("about.list.author"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [[_vm._v(" " + _vm._s(props.row.author) + " ")]]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: { field: "email", label: _vm.$t("about.list.email") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.email
                            ? _c("span", [_vm._v(_vm._s(props.row.email))])
                            : _vm._e(),
                          _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("b-table-column", {
                  attrs: {
                    field: "source",
                    label: _vm.$t("about.list.source"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "has-text-primary",
                              attrs: {
                                href: props.row.source,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(props.row.source))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }