import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import ReportSettings from '@/views/components/reports/ReportSettings.vue';
import ReportSettingsModal from '../components/reports/ReportSettingsModal.vue';
import { SourceGroup } from '@/entities/models/SourceGroup';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import UserRepository from '@/services/repository/UserRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import LoggedUserManager from '@/services/LoggedUserManager';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import { vxm } from '@/store/store.vuex';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import AddNotificationRule from '../components/reports/AddNotificationRule.vue';
import VueUtilities from '@/services/VueUtilities';
import ReportRecordRepository from '@/services/repository/PeriodicReportsRepository';
var vxDepartmentStore = vxm.departmentStore;
var sourceGroupRepository;
var sourceRepository;
var userSettingsRepository;
var userRepository;
var reportRecordRepository;
var eventTypeRepository;
var notificationSubscriptionsRepository;
let ReportManagement = class ReportManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isReportConfigModalActive = false;
        this.dateTimeManager = null;
        this.sourceGroups = [];
        this.showEmpty = true;
        this.sources = [];
        this.configuration = null;
        this.departmentUserSettings = null;
        this.currentUser = null;
        this.userList = [];
        this.eventTypes = [];
        this.notificationSubscriptions = [];
        this.isUserHasAutoPdfGenerated = false;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.periodicReports = [];
        this.selectedPeriodicReport = null;
        this.isPeriodicReportEdit = false;
    }
    async created() {
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        userRepository = new UserRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        reportRecordRepository = new ReportRecordRepository(this);
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.loadData();
    }
    get isAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    async loadData() {
        this.isLoading = true;
        this.currentUser = await userRepository.getCurrentUser();
        this.departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, true);
        this.configuration = this.departmentUserSettings.reports.configuration;
        this.eventTypes = await eventTypeRepository.getAllTypes();
        let initialPromises = [];
        initialPromises.push(sourceGroupRepository.getSourceGroups());
        initialPromises.push(sourceRepository.getVisibleSources());
        initialPromises.push(reportRecordRepository.getPeriodicReports());
        initialPromises.push(userRepository.getUsers());
        await Promise.all(initialPromises).then((response) => {
            this.processInitialDataForPage(response[0], response[1], response[2], response[3]);
        });
        if (!this.isAdmin) {
            this.notificationSubscriptions = await notificationSubscriptionsRepository.getAllNotificationSubscriptions(this.currentUser.apiUserId);
            this.subscriptionsForCurrentCompany = this.notificationSubscriptions.filter((x) => x.scopeId == this.getSelectedDepartmentId());
            this.isUserHasAutoPdfGenerated = this.subscriptionsForCurrentCompany.find((x) => x.notificationType == this.NotificationSubscriptionType.AUTOMATIC_REPORTS_GENERATED)
                ? true
                : false;
        }
        this.isLoading = false;
    }
    async deletePeriodicReport(id) {
        this.isLoading = true;
        let result = await reportRecordRepository.deletePeriodicReport(id);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('reports.report_settings.deleteReportConfigModal.success'));
            this.reload();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('reports.report_settings.deleteReportConfigModal.failure'));
        }
        this.isLoading = false;
    }
    /**
     * Process loaded data.
     */
    async processInitialDataForPage(sourceGroups, sources, periodicReports, userList) {
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        this.sources = sources.getData();
        this.periodicReports = periodicReports ? periodicReports : [];
        this.userList = JSON.parse(JSON.stringify(userList));
    }
    async reload() {
        this.isLoading = true;
        this.periodicReports = await reportRecordRepository.getPeriodicReports();
        this.isLoading = false;
    }
    async closePdfModal(result) {
        this.isReportConfigModalActive = false;
    }
    openReportSettingModal(periodicReport = null, isEdit) {
        this.selectedPeriodicReport = isEdit ? periodicReport : this.periodicReports[0];
        this.isPeriodicReportEdit = isEdit;
        this.isReportConfigModalActive = true;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
ReportManagement = __decorate([
    Component({
        beforeRouteUpdate(to, from, next) {
            this.loadData();
            next(); // to resolve hook
        },
        components: { ReportSettings, ReportSettingsModal, AddNotificationRule }
    })
], ReportManagement);
export default ReportManagement;
