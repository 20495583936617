var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "section-header-level" }, [
      _c(
        "div",
        { staticClass: "title-with-edit" },
        [
          _c("h2", { staticClass: "subtitle is-4 mr-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.user.detail.userSettings.title")
                ) +
                " "
            ),
          ]),
          (!_vm.componentIsLoading || !_vm.fillingValues) &&
          _vm.$ability.can(
            _vm.$permActions.UPDATE,
            _vm.$permSubjects.ADMIN_USERS_SETTINGS
          )
            ? [
                _c(
                  "b-button",
                  {
                    attrs: {
                      "icon-left": _vm.isEditing
                        ? "times-circle"
                        : "pencil-alt",
                      type: "is-white",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.edit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isEditing ? _vm.$t("generals.cancel_edit") : ""
                        )
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm.componentIsLoading || _vm.fillingValues
      ? _c(
          "div",
          [
            _vm.departments.length === 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.user.detail.userSettings.no_department"
                        )
                      ) +
                      " "
                  ),
                ])
              : _c("b-skeleton", { attrs: { height: "90px", animated: true } }),
          ],
          1
        )
      : _c("div", { staticClass: "columns columns-info-form label-grow-5" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                { attrs: { label: "datetime.clockFormat" } },
                [
                  _vm.isEditing
                    ? [
                        _c("div", { staticClass: "field-body" }, [
                          _c(
                            "div",
                            { staticClass: "field has-addons" },
                            _vm._l(_vm.clockFormats, function (option) {
                              return _c(
                                "b-radio-button",
                                {
                                  key: option,
                                  attrs: {
                                    expanded: "",
                                    "native-value": option,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.addToChanges(
                                        [
                                          _vm.userSettingsKeys.PROFILE,
                                          _vm.userSettingsKeys.DATETIME,
                                          _vm.userSettingsKeys.CLOCKFORMAT,
                                        ],
                                        _vm.selectedClockFormat,
                                        _vm.isClockFormatChanged
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedClockFormat,
                                    callback: function ($$v) {
                                      _vm.selectedClockFormat = $$v
                                    },
                                    expression: "selectedClockFormat",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(option))])]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]
                    : _vm.userSettings.datetime &&
                      _vm.userSettings.datetime.clockFormat
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.userSettings.datetime.clockFormat) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.settingsWithDefaults.datetime.clockFormat
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                2
              ),
              _c(
                "b-field",
                { attrs: { label: "datetime.dateFormat" } },
                [
                  _vm.isEditing
                    ? _c(
                        "b-select",
                        {
                          attrs: { expanded: "" },
                          on: {
                            input: function ($event) {
                              return _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PROFILE,
                                  _vm.userSettingsKeys.DATETIME,
                                  _vm.userSettingsKeys.DATEFORMAT,
                                ],
                                _vm.selectedDateFormat,
                                _vm.isDateFormatChanged
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedDateFormat,
                            callback: function ($$v) {
                              _vm.selectedDateFormat = $$v
                            },
                            expression: "selectedDateFormat",
                          },
                        },
                        _vm._l(_vm.dateFormats, function (option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateTimeManager.formatTime(
                                      _vm.actualTime,
                                      _vm.selectedTimezone,
                                      option,
                                      ""
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm.userSettings.datetime &&
                      _vm.userSettings.datetime.dateFormat
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.userSettings.datetime.dateFormat) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.settingsWithDefaults.datetime.dateFormat
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                1
              ),
              _c("b-field", { attrs: { label: "datetime.format" } }, [
                _c("p", [_vm._v(" " + _vm._s(_vm.selectedFormat) + " ")]),
              ]),
              _c(
                "b-field",
                { attrs: { label: "datetime.timezone" } },
                [
                  _vm.isEditing
                    ? _c(
                        "b-select",
                        {
                          attrs: { expanded: "" },
                          on: {
                            input: function ($event) {
                              return _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PROFILE,
                                  _vm.userSettingsKeys.DATETIME,
                                  _vm.userSettingsKeys.TIMEZONE,
                                ],
                                _vm.selectedTimezone,
                                _vm.isTimezoneChanged
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedTimezone,
                            callback: function ($$v) {
                              _vm.selectedTimezone = $$v
                            },
                            expression: "selectedTimezone",
                          },
                        },
                        _vm._l(_vm.timeZones, function (option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [_vm._v(" " + _vm._s(option) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm.userSettings.datetime &&
                      _vm.userSettings.datetime.timezone
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.userSettings.datetime.timezone) + " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.settingsWithDefaults.datetime.timezone
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "datetime.tzInFormat" } },
                [
                  _vm.isEditing
                    ? _c("b-checkbox", {
                        on: {
                          input: function ($event) {
                            return _vm.addToChanges(
                              [
                                _vm.userSettingsKeys.PROFILE,
                                _vm.userSettingsKeys.DATETIME,
                                _vm.userSettingsKeys.TZ_IN_FORMAT,
                              ],
                              _vm.selectedTzInFormat,
                              _vm.isTzInFormatChanged
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedTzInFormat,
                          callback: function ($$v) {
                            _vm.selectedTzInFormat = $$v
                          },
                          expression: "selectedTzInFormat",
                        },
                      })
                    : _vm.userSettings.datetime &&
                      _vm.userSettings.datetime.tzInFormat
                    ? _c("b-icon", {
                        attrs: {
                          icon: _vm.getBooleanValue(
                            _vm.userSettings.datetime.tzInFormat
                          )
                            ? "check"
                            : "times",
                        },
                      })
                    : _c("b-icon", {
                        staticClass: "has-text-grey-light is-italic",
                        attrs: {
                          icon: _vm.getBooleanValue(
                            _vm.settingsWithDefaults.datetime.tzInFormat
                          )
                            ? "check"
                            : "times",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                { attrs: { label: "language" } },
                [
                  _vm.isEditing
                    ? [
                        _c("div", { staticClass: "field-body" }, [
                          _c(
                            "div",
                            { staticClass: "field has-addons" },
                            _vm._l(_vm.languages, function (option) {
                              return _c(
                                "b-radio-button",
                                {
                                  key: option,
                                  attrs: {
                                    "native-value": option,
                                    expanded: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.addToChanges(
                                        [
                                          _vm.userSettingsKeys.PROFILE,
                                          _vm.userSettingsKeys.LANGUAGE,
                                        ],
                                        _vm.selectedLanguage,
                                        _vm.isLanguageChanged
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedLanguage,
                                    callback: function ($$v) {
                                      _vm.selectedLanguage = $$v
                                    },
                                    expression: "selectedLanguage",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(option))])]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]
                    : _vm.userSettings.language
                    ? _c("p", [
                        _vm._v(" " + _vm._s(_vm.userSettings.language) + " "),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.settingsWithDefaults.language) +
                              " "
                          ),
                        ]
                      ),
                ],
                2
              ),
              _c(
                "b-field",
                { attrs: { label: "notifications.pause.until" } },
                [
                  _vm.isEditing
                    ? _c("b-input", {
                        on: {
                          input: function ($event) {
                            return _vm.addToChanges(
                              [
                                _vm.userSettingsKeys.NOTIFICATIONS,
                                _vm.userSettingsKeys.PAUSE,
                                _vm.userSettingsKeys.UNTIL,
                              ],
                              _vm.selectedNotificationsPauseUntil,
                              _vm.isNotificationsPauseUntilChanged
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedNotificationsPauseUntil,
                          callback: function ($$v) {
                            _vm.selectedNotificationsPauseUntil = $$v
                          },
                          expression: "selectedNotificationsPauseUntil",
                        },
                      })
                    : _vm.userSettings &&
                      _vm.userSettings.notifications &&
                      _vm.userSettings.notifications.pause &&
                      _vm.userSettings.notifications.pause.until
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.userSettings.notifications.pause.until) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.settingsWithDefaults.notifications.pause
                                  .until
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "notifications.pause.msgDismissedAt" } },
                [
                  _vm.isEditing
                    ? _c("b-input", {
                        on: {
                          input: function ($event) {
                            return _vm.addToChanges(
                              [
                                _vm.userSettingsKeys.NOTIFICATIONS,
                                _vm.userSettingsKeys.PAUSE,
                                _vm.userSettingsKeys.DISMISSED,
                              ],
                              _vm.selectedNotificationsPauseMsgDissmisedAt,
                              _vm.isNotificationsPauseMsgDissmisedAtChanged
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedNotificationsPauseMsgDissmisedAt,
                          callback: function ($$v) {
                            _vm.selectedNotificationsPauseMsgDissmisedAt = $$v
                          },
                          expression:
                            "selectedNotificationsPauseMsgDissmisedAt",
                        },
                      })
                    : _vm.userSettings &&
                      _vm.userSettings.notifications &&
                      _vm.userSettings.notifications.pause &&
                      _vm.userSettings.notifications.pause.msgDismissedAt
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.userSettings.notifications.pause
                                .msgDismissedAt
                            ) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [_vm._v("-")]
                      ),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "preferences.chartHeight" } },
                [
                  _vm.isEditing
                    ? _c(
                        "b-select",
                        {
                          attrs: { expanded: "" },
                          on: {
                            input: function ($event) {
                              return _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PROFILE,
                                  _vm.userSettingsKeys.PREFERENCES,
                                  _vm.userSettingsKeys.CHARTHEIGHT,
                                ],
                                _vm.selectedChartHeight,
                                _vm.isChartHeightChanged
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedChartHeight,
                            callback: function ($$v) {
                              _vm.selectedChartHeight = $$v
                            },
                            expression: "selectedChartHeight",
                          },
                        },
                        _vm._l(_vm.ChartHeight, function (option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `component.group_dashboard.chart_options.${option}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm.userSettings.preferences &&
                      _vm.userSettings.preferences.chartHeight
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `component.group_dashboard.chart_options.${_vm.userSettings.preferences.chartHeight}`
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.group_dashboard.chart_options.${_vm.settingsWithDefaults.preferences.chartHeight}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "preferences.favoriteDepartmentId" } },
                [
                  _vm.isEditing
                    ? _c("b-input", {
                        on: {
                          input: function ($event) {
                            return _vm.addToChanges(
                              [
                                _vm.userSettingsKeys.PROFILE,
                                _vm.userSettingsKeys.PREFERENCES,
                                _vm.userSettingsKeys.FAVORITE_DEPARTMENT_ID,
                              ],
                              _vm.selectedFavoriteDepartmentId,
                              _vm.isFavoriteDepartmentIdChanged
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedFavoriteDepartmentId,
                          callback: function ($$v) {
                            _vm.selectedFavoriteDepartmentId = $$v
                          },
                          expression: "selectedFavoriteDepartmentId",
                        },
                      })
                    : _vm.userSettings.preferences
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.userSettings.preferences.favoriteDepartmentId
                            ) +
                            " "
                        ),
                      ])
                    : _c("p"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                { attrs: { label: "preferences.measurementSort" } },
                [
                  _vm.isEditing
                    ? [
                        _c("div", { staticClass: "field-body" }, [
                          _c(
                            "div",
                            { staticClass: "field has-addons" },
                            _vm._l(_vm.measurementSorts, function (option) {
                              return _c(
                                "b-radio-button",
                                {
                                  key: option,
                                  attrs: {
                                    expanded: "",
                                    "native-value": option,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.addToChanges(
                                        [
                                          _vm.userSettingsKeys.PROFILE,
                                          _vm.userSettingsKeys.PREFERENCES,
                                          _vm.userSettingsKeys.MEASUREMENT_SORT,
                                        ],
                                        _vm.selectedMeasurementSort,
                                        _vm.isMeasurementSortChanged
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedMeasurementSort,
                                    callback: function ($$v) {
                                      _vm.selectedMeasurementSort = $$v
                                    },
                                    expression: "selectedMeasurementSort",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(option))])]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]
                    : _vm.userSettings.preferences &&
                      _vm.userSettings.preferences.measurementSort
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.userSettings.preferences.measurementSort
                            ) +
                            " "
                        ),
                      ])
                    : _c(
                        "p",
                        { staticClass: "has-text-grey-light is-italic" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.settingsWithDefaults.preferences
                                  .measurementSort
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                2
              ),
              _vm._l(_vm.filteredEventTypes, function (type, index) {
                return _c(
                  "b-field",
                  {
                    key: type.eventTypeId,
                    attrs: {
                      label:
                        type.slug === "uptime" ||
                        type.slug === "batteryage" ||
                        type.slug === "temperaturetime"
                          ? "unit.time"
                          : "unit." + type.slug,
                    },
                  },
                  [
                    _vm.isEditing
                      ? [
                          _c("div", { staticClass: "field-body" }, [
                            _c(
                              "div",
                              { staticClass: "field has-addons" },
                              _vm._l(_vm.units[type.type], function (option) {
                                return _c(
                                  "b-radio-button",
                                  {
                                    key: option.identifier,
                                    attrs: {
                                      expanded: "",
                                      "native-value": option.identifier,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.addToChanges(
                                          [
                                            _vm.userSettingsKeys.PROFILE,
                                            _vm.userSettingsKeys.EVENTS,
                                            _vm.userSettingsKeys.UNITS,
                                            type.type,
                                          ],
                                          _vm.selectedUnits[index].value,
                                          _vm.areUnitsChanged
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.selectedUnits[index].value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedUnits[index],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "selectedUnits[index].value",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(`${option.unit}`)),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                          ]),
                        ]
                      : _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getUserEventTypeUnit(
                                  _vm.units[type.type],
                                  _vm.userUnits[index].value
                                )
                              ) +
                              " "
                          ),
                        ]),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
    _vm.isEditing
      ? _c(
          "div",
          { staticClass: "has-text-right" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                on: {
                  click: function ($event) {
                    return _vm.saveChanges()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.save_changes")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "section",
      [
        _c("hr"),
        _c(
          "div",
          { staticClass: "section-header-level" },
          [
            _c("h3", { staticClass: "subtitle is-4" }, [
              _vm._v(" " + _vm._s(_vm.$t("custom_settings.title")) + " "),
            ]),
            _vm.$ability.can(
              _vm.$permActions.UPDATE,
              _vm.$permSubjects.ADMIN_USERS_SETTINGS
            )
              ? _c("b-button", {
                  staticClass: "is-hidden-mobile",
                  attrs: { type: "is-primary", label: _vm.$t("generals.add") },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                })
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.UPDATE,
              _vm.$permSubjects.ADMIN_USERS_SETTINGS
            )
              ? _c("b-button", {
                  staticClass: "is-hidden-tablet",
                  attrs: { type: "is-primary", "icon-left": "plus" },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.isAdding
          ? _c("section", [
              _c("h3", { staticClass: "subtitle is-5" }, [
                _vm._v(_vm._s(_vm.$t("custom_settings.add_new"))),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-justify-content-flex-start is-flex-direction-row",
                },
                [
                  _c(
                    "b-field",
                    { attrs: { grouped: "", "group-multiline": "" } },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "is-flex-grow-2 mr-1 mb-5",
                          attrs: {
                            label: _vm.$t("custom_settings.key"),
                            "label-position": "on-border",
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t("custom_settings.key")
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("custom_settings.key")
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              type: "textarea",
                              expanded: "",
                              name: _vm.$t("custom_settings.key"),
                            },
                            model: {
                              value: _vm.selectedFieldKey,
                              callback: function ($$v) {
                                _vm.selectedFieldKey = $$v
                              },
                              expression: "selectedFieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-flex-grow-3 mr-1 mb-5",
                          attrs: {
                            label: _vm.$t("custom_settings.value"),
                            "label-position": "on-border",
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t("custom_settings.value")
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("custom_settings.value")
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              type: "textarea",
                              name: _vm.$t("custom_settings.value"),
                              expanded: "",
                            },
                            model: {
                              value: _vm.selectedFieldValue,
                              callback: function ($$v) {
                                _vm.selectedFieldValue = $$v
                              },
                              expression: "selectedFieldValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttons is-centered" },
                [
                  _c("b-button", {
                    attrs: {
                      type: "is-danger",
                      label: _vm.$t("generals.close"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelAdding()
                      },
                    },
                  }),
                  _c("b-button", {
                    attrs: {
                      type: "is-success",
                      label: _vm.$t("generals.save"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveNewSetting()
                      },
                    },
                  }),
                ],
                1
              ),
              _c("hr"),
            ])
          : _vm._e(),
        _c(
          "b-table",
          {
            staticClass: "res-table",
            attrs: { data: _vm.customUserSettings, striped: "" },
          },
          [
            _c("b-table-column", {
              attrs: { field: "Key", label: _vm.$t("custom_settings.key") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [_c("span", [_vm._v(" " + _vm._s(custom.row.key))])]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "Value",
                label: _vm.$t("company_settings.value"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [
                      _vm.fieldEditing == custom.row.key
                        ? _c(
                            "b-field",
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "textarea",
                                  name: `${custom.row.key}_value`,
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedFieldValue,
                                  callback: function ($$v) {
                                    _vm.selectedFieldValue = $$v
                                  },
                                  expression: "selectedFieldValue",
                                },
                              }),
                            ],
                            1
                          )
                        : [
                            _c("p", { staticClass: "text-wrap-anywhere" }, [
                              _vm._v(" " + _vm._s(custom.row.value) + " "),
                            ]),
                          ],
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: { label: _vm.$t("generals.action") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [
                      _vm.fieldEditing == custom.row.key
                        ? [
                            _c("b-button", {
                              attrs: {
                                "icon-right": "times-circle",
                                type: "is-white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEdit()
                                },
                              },
                            }),
                            _c("b-button", {
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("generals.save"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEdit(custom.row.key)
                                },
                              },
                            }),
                          ]
                        : [
                            _vm.$ability.can(
                              _vm.$permActions.UPDATE,
                              _vm.$permSubjects.ADMIN_USERS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "pencil-alt",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSettings(custom.row.key)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$ability.can(
                              _vm.$permActions.DELETE,
                              _vm.$permSubjects.ADMIN_USERS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "trash",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmSettingDelete(
                                        custom.row.key
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("custom_settings.empty_table")) + " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }