import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Languages from '@/entities/enums/languages';
import MeasurementsSort from '@/entities/enums/MeasurementsSort';
let UserSettingsComponent = class UserSettingsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.actualTime = new Date();
        this.DateFormats = [];
        this.TimeZones = [];
        this.ClockFormats = [];
        this.selectedDateFormat = '';
        this.selectedTimezone = '';
        this.selectedClockFormat = '';
        this.selectedShowTzInfo = false;
        this.actualTimeFormatted = '';
        this.Languages = Languages;
        this.MeasurementsSort = MeasurementsSort;
        this.refreshInterval = null;
        this.requestFinished = false;
        this.filterRecords = false;
    }
    onRequestPending() {
        if (this.requestPending == false) {
            this.requestFinished = true;
        }
    }
    created() {
        this.DateFormats = this.dateTimeManager.getAllDateFormats();
        this.TimeZones = this.dateTimeManager.getLimitedTimezones();
        this.ClockFormats = this.dateTimeManager.getAllClockFormats();
        this.selectedDateFormat = this.dateTimeManager.getSelectedDateFormat();
        this.selectedClockFormat = this.dateTimeManager.getSelectedClockFormat();
        this.selectedTimezone = this.dateTimeManager.getSelectedTimezone();
        this.selectedShowTzInfo = this.dateTimeManager.getTzInClockFormatPreference();
        this.refreshInterval = parseInt(this.departmentUserSettings?.preferences.eventsRefresh.interval);
        this.filterRecords = this.departmentUserSettings?.reports.records.filterByBoundary == 'true' ? true : false;
    }
    async dateFormatSelected(val) {
        this.showSelectedTimeFormat();
        this.$emit('dateFormatSelected', this.selectedDateFormat);
    }
    async clockFormatSelected(val) {
        this.showSelectedTimeFormat();
        this.$emit('clockFormatSelected', this.selectedClockFormat);
    }
    async timezoneSelected(val) {
        this.showSelectedTimeFormat();
        this.$emit('timezoneSelected', this.selectedTimezone);
    }
    async showTzChanged(val) {
        this.showSelectedTimeFormat();
        this.$emit('showTzChanged', this.selectedShowTzInfo.toString());
    }
    showSelectedTimeFormat() {
        this.actualTimeFormatted = this.dateTimeManager.formatTime(new Date(), this.selectedTimezone, this.selectedDateFormat, this.selectedClockFormat, this.selectedShowTzInfo);
    }
    async changeLanguage(language) {
        this.$emit('languageChanged', language);
        this.$i18n.locale = language;
        this.$validator.localize(language);
    }
    isCurrentLanguage(lang) {
        if (!this.settings)
            return false;
        else if (this.settings.language == lang)
            return true;
        return false;
    }
    async changeMeasurementSort(sort) {
        this.$emit('measurementSortChanged', sort);
    }
    filterRecordsChanged() {
        this.$emit('filterRecordsChanged', this.filterRecords);
    }
    isCurrentMeasurementSort(sort) {
        if (!this.settings)
            return false;
        else if (this.settings.preferences.measurementSort == sort)
            return true;
        return false;
    }
    changeRefreshInterval(value) {
        if (this.requestPending)
            return;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.$emit('refreshIntervalChanged', value);
            }
        });
    }
};
__decorate([
    Prop({ type: Object })
], UserSettingsComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], UserSettingsComponent.prototype, "settings", void 0);
__decorate([
    Prop({ type: Object, default: null })
], UserSettingsComponent.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: Boolean })
], UserSettingsComponent.prototype, "requestPending", void 0);
__decorate([
    Watch('requestPending')
], UserSettingsComponent.prototype, "onRequestPending", null);
UserSettingsComponent = __decorate([
    Component({ components: {} })
], UserSettingsComponent);
export default UserSettingsComponent;
