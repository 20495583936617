import { __decorate } from "tslib";
import NotificationSchedule from '@/entities/notifications/NotificationSchedule';
import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line vue/max-len
import MeasuredDataNotification from '@/views/components/notification/configurationPerType/MeasuredDataNotification.vue';
// eslint-disable-next-line vue/max-len
import EntityMeasuredDataNotification from '@/views/components/notification/configurationPerType/EntityMeasuredDataNotification.vue';
// eslint-disable-next-line vue/max-len
import GeneralNotificationComponent from '@/views/components/notification/configurationPerType/GeneralNotificationComponent.vue';
import NotificationSubscriptionType from '@/entities/notifications/NotificationSubscriptionType';
import NotificationSubscription from '@/entities/notifications/NotificationSubscription';
import NotificationSubscriptionCategory from '@/entities/notifications/NotificationSubscriptionCategory';
import LoggedUserManager from '@/services/LoggedUserManager';
import { NotificationMeasuredRuleScope } from '@/entities/notifications/NotificationMeasuredRuleScope';
import NotificationScopeType from '@/entities/notifications/NotificationScopeType';
import NotificationScheduleType from '@/entities/notifications/NotificationScheduleType';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import VueUtilities from '@/services/VueUtilities';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
var notificationSubscriptionsRepository;
var userSettingsRepository;
const NEW_SCHEDULE_IDENTIFIER = 'NEW';
let Timetable = class Timetable extends Vue {
    constructor() {
        super(...arguments);
        this.NEW_SCHEDULE_IDENTIFIER = NEW_SCHEDULE_IDENTIFIER;
        this.NotificationSubscriptionType = NotificationSubscriptionType;
        this.NotificationMeasuredRuleScope = NotificationMeasuredRuleScope;
        this.isLoading = false;
        this.selectedScheduleId = null;
        this.selectedSchedule = null;
        this.timetableSchedules = [];
        this.isChangingSchedulesTab = false;
        this.selectedPresetTimetable = null;
        this.newSchedulesCounter = 1;
        this.schedulesToRemove = [];
        this.days = [0, 1, 2, 3, 4, 5, 6];
        this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        //List of colors for schedules. For now, FE supports 9 schedules but this limitation is only in app
        this.colors = [
            '#B9FBC0',
            '#8EECF5',
            '#A3C4F3',
            '#F1C0E8',
            '#FDE4CF',
            '#6b705c',
            '#cb997e',
            '#9a8c98',
            '#5e60ce'
        ];
        this.copiedDay = null;
        this.haveChangesToSave = false;
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigateOnExit = null;
        this.scheduleBackup = [];
        this.timetableTab = '';
        this.measuredRuleEnabled = null;
        this.duplicatedSchedule = null;
    }
    get measuredTypes() {
        return this.availableTypes.filter((x) => x.category == NotificationSubscriptionCategory.MEASUREMENT_RULE);
    }
    get currentTzOffset() {
        return this.dateTimeManager.getUsedTzOffsetInMinutes();
    }
    get has30MinOffset() {
        let offset = this.dateTimeManager.getUsedTzOffsetInMinutes();
        return Math.abs(offset) % 60 === 30;
    }
    calculateHoursValue(hours) {
        let val = hours * 60 - this.currentTzOffset;
        if (this.has30MinOffset === true)
            val += 30;
        return this.normalizeHoursValue(val);
    }
    normalizeHoursValue(val) {
        if (val >= 168 * 60)
            return val - 168 * 60;
        else if (val < 0)
            return val + 168 * 60;
        else
            return val;
    }
    singleMeasuredRuleTimetable(scheduleId) {
        return this.timetableSchedules
            .find((x) => x.id === scheduleId)
            ?.subscriptions?.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
            x.scopeType === NotificationScopeType.COMPANY);
    }
    getMeasuredRuleEnabled(scheduleId) {
        return this.singleMeasuredRuleTimetable(scheduleId) ? this.singleMeasuredRuleTimetable(scheduleId).enabled : false;
    }
    switchedValue(event) {
        this.measuredRuleEnabled = event;
    }
    measuredRulesTimetable(scheduleId) {
        return this.timetableSchedules
            .find((x) => x.id === scheduleId)
            ?.subscriptions?.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
            (x.scopeType === NotificationScopeType.SOURCE || x.scopeType === NotificationScopeType.SOURCE_GROUP));
    }
    async created() {
        this.scheduleBackup = JSON.parse(JSON.stringify(this.schedules));
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        this.timetableSchedules = this.schedules;
        this.timetableSchedules.forEach((x) => (x.additionalProperties.color = this.colors.shift()));
        //assign selectedMeasuredRulesModeTimetable for all schedules
        this.timetableSchedules.forEach((schedule) => {
            schedule.additionalProperties.selectedMeasuredRulesModeTimetable =
                this.departmentUserSettings.notifications.rules.scheduleMode?.[schedule.id] || NotificationMeasuredRuleScope.COMPANY;
        });
        if (this.$route.query.timetableTab)
            this.timetableTab = this.$route.query.timetableTab;
        if (this.timetableTab) {
            this.selectedSchedule = this.timetableSchedules.find((x) => x.id == this.timetableTab)
                ? this.timetableSchedules.find((x) => x.id == this.timetableTab)
                : this.timetableSchedules.find((x) => x.name == this.timetableTab);
            if (this.selectedSchedule) {
                this.selectedScheduleId = this.selectedSchedule.id;
            }
            else {
                this.selectDefaultSchedule();
            }
        }
        else {
            this.selectDefaultSchedule();
        }
    }
    selectDefaultSchedule() {
        this.selectedSchedule = this.timetableSchedules[0];
        this.selectedScheduleId = this.selectedSchedule.id;
    }
    duplicateSchedule(schedule) {
        this.duplicatedSchedule = schedule;
        this.scheduleTabSelected(NEW_SCHEDULE_IDENTIFIER, true);
    }
    /**
     * Triggered when user clicks on the schedule tab
     */
    scheduleTabSelected(selectedId, isDuplicate = false) {
        this.isChangingSchedulesTab = true;
        this.selectedPresetTimetable = null;
        if (selectedId === NEW_SCHEDULE_IDENTIFIER) {
            let newSchedule = new NotificationSchedule();
            newSchedule.type = NotificationScheduleType.TIMETABLE;
            newSchedule.id = new Date().getTime().toString(); //set unique ID for new schedule. It is used only on FE.
            newSchedule.name = `${this.$t('notifications.configuration.schedule_name_default')} ${this.newSchedulesCounter}`;
            newSchedule.additionalProperties.isNew = true;
            if (isDuplicate)
                newSchedule.subscriptions = this.duplicatedSchedule.subscriptions;
            newSchedule.additionalProperties.color = this.colors.shift();
            newSchedule.additionalProperties.selectedMeasuredRulesModeTimetable = NotificationMeasuredRuleScope.COMPANY;
            this.timetableSchedules.push(newSchedule);
            this.$nextTick(() => {
                this.selectedSchedule = newSchedule;
                this.selectedScheduleId = newSchedule.id;
                this.newSchedulesCounter++;
            });
            this.changeTimeTableTab(this.selectedSchedule.name);
        }
        else {
            this.selectedSchedule = this.timetableSchedules.find((x) => x.id === selectedId);
            this.selectedScheduleId = this.selectedSchedule.id;
            if (this.selectedSchedule.additionalProperties.isNew)
                this.changeTimeTableTab(this.selectedSchedule.name);
            else
                this.changeTimeTableTab(this.selectedSchedule.id);
        }
        this.isChangingSchedulesTab = false;
    }
    changeTimeTableTab(timeTableTab) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                timetableTab: timeTableTab
            })
        })
            .catch((err) => err);
    }
    getType(name) {
        return this.availableTypes.find((x) => x.name === name);
    }
    getRuleForTimetable(name, scheduleId) {
        return this.timetableSchedules
            .find((x) => x.id === scheduleId)
            ?.subscriptions?.find((x) => x.notificationType === name);
    }
    /**
     * Triggered when any subscription is changed.
     */
    timetableSubscriptionChanged(rule, scheduleId) {
        let selectedSchedule = this.timetableSchedules.find((x) => x.id === scheduleId);
        //if changed subscription is measured type, check if there is not any other measured subscription for same entity
        //every entity can be assigned to only one subscription
        if (rule.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
            rule.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
            let indexOfMeasured = selectedSchedule.additionalProperties.editedSubscriptions.findIndex((x) => (x.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                x.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) &&
                x.ScopeId === rule.ScopeId);
            if (indexOfMeasured >= 0) {
                //remove this measured rule by index
                selectedSchedule.additionalProperties.editedSubscriptions.splice(indexOfMeasured, 1);
            }
        }
        else {
            //remove current subscription from list
            selectedSchedule.additionalProperties.editedSubscriptions =
                selectedSchedule.additionalProperties.editedSubscriptions.filter((x) => x.NotificationType !== rule.NotificationType);
        }
        selectedSchedule.additionalProperties.editedSubscriptions.push(rule);
        this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
    }
    async applyPresetTimetable(schedule, preset) {
        let presetWithSubscriptions = null;
        this.measuredRuleEnabled = false;
        //Load preset with subscriptions
        let response = null;
        if (!LoggedUserManager.isSystemAdmin()) {
            response = preset;
        }
        else {
            response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(preset.id);
        }
        //handle error response
        if (typeof response == 'string') {
            VueUtilities.openErrorToast(this, response);
            return;
        }
        else {
            presetWithSubscriptions = response;
        }
        schedule.additionalProperties.usedPreset = presetWithSubscriptions;
        this.isChangingSchedulesTab = true;
        schedule.additionalProperties.editedSubscriptions = [];
        schedule.additionalProperties.selectedMeasuredRulesModeTimetable = NotificationMeasuredRuleScope.COMPANY;
        schedule.subscriptions.forEach((sub) => {
            sub.enabled = false;
        });
        schedule.additionalProperties.usedPreset.subscriptions.forEach((subs) => {
            if (subs.NotificationType === NotificationSubscriptionType.MEASURED_DATA_ALARM ||
                subs.NotificationType === NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM) {
                schedule.subscriptions = schedule.subscriptions.filter((x) => x.subscriptionCategory !== NotificationSubscriptionCategory.MEASUREMENT_RULE);
            }
            else {
                schedule.subscriptions = schedule.subscriptions.filter((x) => x.notificationType !== subs.NotificationType);
            }
            schedule.subscriptions.push(NotificationSubscription.convertFromPreset(subs, this.departmentId));
        });
        this.$nextTick(() => {
            this.isChangingSchedulesTab = false;
        });
    }
    removeSchedule(scheduleId) {
        this.$buefy.dialog.confirm({
            title: this.$t('notifications.configuration.delete_schedule'),
            message: this.$t('notifications.configuration.delete_schedule_msg', { name: this.selectedSchedule.name }),
            confirmText: this.$t('notifications.configuration.delete_schedule_confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                let scheduleToRemove = this.timetableSchedules.find((x) => x.id === scheduleId);
                //push color back to array
                this.colors.push(scheduleToRemove.additionalProperties.color);
                //if schedule is already saved in DB it has to be removed
                if (scheduleToRemove.additionalProperties.isNew !== true) {
                    this.schedulesToRemove.push(scheduleId);
                }
                this.timetableSchedules = this.timetableSchedules.filter((x) => x.id !== scheduleId);
                this.selectedSchedule = this.timetableSchedules[0];
                this.selectedScheduleId = this.selectedSchedule?.id;
                this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
            }
        });
    }
    async changeModeTimetable(selectedMode, scheduleId) {
        this.isChangingSchedulesTab = true;
        this.measuredRuleEnabled = true;
        this.selectedSchedule.additionalProperties.selectedMeasuredRulesModeTimetable = selectedMode;
        this.selectedSchedule.additionalProperties.editedSubscriptions =
            this.selectedSchedule.additionalProperties.editedSubscriptions.filter((x) => x.NotificationType !== NotificationSubscriptionType.MEASURED_DATA_ALARM &&
                x.NotificationType !== NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM);
        this.isChangingSchedulesTab = false;
    }
    formatHour(h) {
        if (this.has30MinOffset) {
            return h < 10 ? `0${h}:30` : `${h}:30`;
        }
        else
            return h < 10 ? `0${h}:00` : `${h}:00`;
    }
    copyDay(day) {
        this.copiedDay = day;
    }
    nameChanged() {
        this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
    }
    pasteDay(day) {
        for (let hour = 0; hour < 24; hour++) {
            let copiedValue = this.calculateHoursValue(this.copiedDay * 24 + hour);
            let timetable = this.timetableSchedules.find((x) => x.weekMinutes.includes(copiedValue));
            let newValue = this.calculateHoursValue(day * 24 + hour);
            let timetableWithNewValue = this.timetableSchedules.find((x) => x.weekMinutes.includes(newValue));
            if (timetableWithNewValue) {
                timetableWithNewValue.weekMinutes = timetableWithNewValue.weekMinutes.filter((x) => x !== newValue);
            }
            if (timetable) {
                timetable.weekMinutes.push(newValue);
            }
        }
        this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
    }
    hourSelected(weekHourValue, scheduleId) {
        this.copiedDay = null;
        let scheduleWithValue = this.timetableSchedules.find((x) => x.weekMinutes.includes(weekHourValue));
        const isNew = scheduleWithValue == null;
        this.timetableSchedules.forEach((sch) => {
            sch.weekMinutes = sch.weekMinutes.filter((x) => x != weekHourValue);
        });
        if (isNew || scheduleWithValue.id !== scheduleId)
            this.selectedSchedule.weekMinutes.push(weekHourValue);
        this.selectedSchedule.weekMinutes.sort();
        this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
    }
    daySelected(day, scheduleId) {
        this.copiedDay = null;
        if (this.selectedSchedule.isAllDay(day)) {
            for (let value = day * 24; value < day * 24 + 24; value++) {
                this.timetableSchedules.forEach((sch) => {
                    sch.weekMinutes = sch.weekMinutes.filter((x) => x != this.calculateHoursValue(value));
                });
            }
        }
        else {
            for (let value = day * 24; value < day * 24 + 24; value++) {
                this.timetableSchedules.forEach((sch) => {
                    sch.weekMinutes = sch.weekMinutes.filter((x) => x != this.calculateHoursValue(value));
                });
                this.selectedSchedule.weekMinutes.push(this.calculateHoursValue(value));
            }
        }
        this.selectedSchedule.weekMinutes.sort();
        this.haveChangesToSave = !NotificationSchedule.compareSchedules(this.scheduleBackup, this.timetableSchedules);
    }
    /**
     * This method is used to save all changes in timetable. All changes are saved when user clicks on the Save button.
     */
    async save() {
        if (this.selectedSchedule.additionalProperties.isNew)
            this.changeTimeTableTab(this.selectedSchedule.name);
        else
            this.changeTimeTableTab(this.selectedSchedule.id);
        //check if all schedules have time assigned
        const schedulesWithoutTime = this.timetableSchedules.filter((x) => x.weekMinutes?.length <= 0);
        if (schedulesWithoutTime.length > 0) {
            VueUtilities.openErrorToast(this, this.$t('notifications.configuration.timetable.no_time_error', {
                names: schedulesWithoutTime.map((x) => x.name).join(', ')
            }));
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let errors = [];
                let promises = [];
                //calls to remove schedules
                this.schedulesToRemove.forEach((toDel) => {
                    promises.push(notificationSubscriptionsRepository.deleteNotificationSchedules(toDel, this.currentUser.apiUserId, this.departmentId));
                });
                //delete rules
                await Promise.all(promises).then((response) => {
                    errors = errors.concat(response.filter((x) => typeof x === 'string'));
                    promises = [];
                });
                //new schedules
                let toCreate = this.timetableSchedules.filter((x) => x.additionalProperties.isNew === true);
                toCreate.forEach((schedule) => {
                    //remove new schedule identifier used by FE
                    schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                        if (sub.ScheduleId === NEW_SCHEDULE_IDENTIFIER)
                            delete sub.ScheduleId;
                    });
                    //assign presetId if preset was applied
                    if (schedule.additionalProperties.usedPreset) {
                        //check if preset is same as rules
                        let presetIsSameAsSubs = schedule.additionalProperties.usedPreset.comparePresetWithSubscriptions(schedule.additionalProperties.editedSubscriptions);
                        //if they are same, set preset id in all subscriptions
                        if (presetIsSameAsSubs == true) {
                            schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                sub.PresetId = schedule.additionalProperties.usedPreset.id;
                            });
                        }
                        else {
                            //if they are not same, set preset id to null
                            schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                sub.PresetId = null;
                            });
                        }
                    }
                    let dto = {
                        Name: schedule.name,
                        Type: NotificationScheduleType.TIMETABLE,
                        Subscriptions: schedule.additionalProperties.editedSubscriptions,
                        WeekMinutes: schedule.weekMinutes,
                        ValidFrom: null,
                        ValidTo: null
                    };
                    //calls to create new schedules
                    promises.push(notificationSubscriptionsRepository.createNotificationSchedule(dto, this.currentUser.apiUserId, this.departmentId));
                });
                //create rules
                await Promise.all(promises).then((response) => {
                    promises = [];
                    response.forEach((resp, index) => {
                        if (typeof resp === 'string') {
                            errors.push(resp);
                        }
                        else {
                            //save selected measured rules mode to settings
                            promises.push(userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.SCHEDULE_MODE, resp.id], toCreate[index].additionalProperties.selectedMeasuredRulesModeTimetable, this.departmentId));
                        }
                    });
                });
                //schedules to update
                let toUpdate = this.timetableSchedules.filter((x) => x.additionalProperties.isNew !== true);
                toUpdate.forEach(async (schedule) => {
                    let rulesToCreate = [];
                    let dto = {
                        Name: schedule.name,
                        Type: NotificationScheduleType.TIMETABLE,
                        WeekMinutes: schedule.weekMinutes,
                        ValidFrom: null,
                        ValidTo: null
                    };
                    //update schedules
                    promises.push(notificationSubscriptionsRepository.updateNotificationSchedule(schedule.id, dto, this.currentUser.apiUserId, this.departmentId));
                    //save selected measured rules mode to settings
                    promises.push(userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.NOTIFICATIONS, UserSettingsKeys.RULES, UserSettingsKeys.SCHEDULE_MODE, schedule.id], schedule.additionalProperties.selectedMeasuredRulesModeTimetable, this.departmentId));
                    //if measured rules mode was changed, we have to remove subscriptions used with previous mode
                    if (schedule.additionalProperties.selectedMeasuredRulesModeTimetable === NotificationMeasuredRuleScope.COMPANY) {
                        //if company (basic) mode is used, remove measured subscriptions for sources and groups
                        let measuredForEntity = schedule.additionalProperties.originalSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                            [NotificationScopeType.SOURCE, NotificationScopeType.SOURCE_GROUP].includes(x.scopeType));
                        measuredForEntity.forEach((subToDel) => {
                            promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.departmentId));
                        });
                    }
                    else if (schedule.additionalProperties.selectedMeasuredRulesModeTimetable ===
                        NotificationMeasuredRuleScope.SOURCE_OR_GROUP) {
                        //if entity (advanced) mode is used, remove measured subscriptions for company
                        let measuredForCompany = schedule.additionalProperties.originalSubscriptions.filter((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                            x.scopeType === NotificationScopeType.COMPANY);
                        measuredForCompany.forEach((subToDel) => {
                            promises.push(notificationSubscriptionsRepository.deleteNotificationSubscription(subToDel.notificationSubscriptionId, this.currentUser.apiUserId, this.departmentId));
                        });
                    }
                    //assign presetId if preset was applied
                    if (schedule.additionalProperties.usedPreset) {
                        //check if preset is same as rules
                        let presetIsSameAsSubs = schedule.additionalProperties.usedPreset.comparePresetWithSubscriptions(schedule.additionalProperties.editedSubscriptions);
                        //if they are same, set preset id in all subscriptions
                        if (presetIsSameAsSubs == true) {
                            schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                sub.PresetId = schedule.additionalProperties.usedPreset.id;
                            });
                        }
                        else {
                            //if they are not same, set preset id to null
                            schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                sub.PresetId = null;
                            });
                        }
                    }
                    else {
                        //if preset was not applied, we have to check previously assigned presetIds
                        let presetIds = [...new Set(schedule.additionalProperties.editedSubscriptions.map((x) => x.PresetId))];
                        //if there is one preset id in subscriptions and it is not null
                        if (presetIds.length === 1 && presetIds[0] != null) {
                            //Load preset with subscriptions
                            let response = null;
                            if (!LoggedUserManager.isSystemAdmin()) {
                                response = this.presets.find((x) => x.id === presetIds[0]);
                            }
                            else {
                                response = await notificationSubscriptionsRepository.adminGetNotificationPresetById(presetIds[0]);
                            }
                            //handle error response
                            if (typeof response == 'string') {
                                VueUtilities.openErrorToast(this, response);
                                return;
                            }
                            let loadedPreset = response;
                            if (!loadedPreset) {
                                //if preset does not exists anymore, set preset id to null
                                schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                    sub.PresetId = null;
                                });
                            }
                            else {
                                //if preset exists, check if all rules are same
                                let presetIsSameAsSubs = loadedPreset.comparePresetWithSubscriptions(schedule.additionalProperties.editedSubscriptions);
                                //if they are same, set preset id
                                if (presetIsSameAsSubs == true) {
                                    schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                        sub.PresetId = loadedPreset.id;
                                    });
                                }
                                else {
                                    //if they are not same, set preset id to null
                                    schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                        sub.PresetId = null;
                                    });
                                }
                            }
                        }
                        else {
                            /* if there are some subscriptions with different preset ids or
                             * preset id is null, set preset id to null in all subscriptions
                             */
                            schedule.additionalProperties.editedSubscriptions.forEach((sub) => {
                                sub.PresetId = null;
                            });
                        }
                    }
                    schedule.additionalProperties.editedSubscriptions.forEach((subscription) => {
                        //try to find measured subscription with same scope exists
                        let idOfExisting = null;
                        if (this.availableTypes.find((x) => x.name === subscription.NotificationType).category ==
                            NotificationSubscriptionCategory.MEASUREMENT_RULE) {
                            idOfExisting = schedule.additionalProperties.originalSubscriptions.find((x) => x.subscriptionCategory === NotificationSubscriptionCategory.MEASUREMENT_RULE &&
                                x.scopeType === subscription.ScopeType &&
                                x.scopeId === subscription.ScopeId)?.notificationSubscriptionId;
                        }
                        else {
                            //try to find subscription with same type
                            idOfExisting = schedule.additionalProperties.originalSubscriptions.find((x) => x.notificationType === subscription.NotificationType)?.notificationSubscriptionId;
                        }
                        //if such subscription exists
                        if (idOfExisting) {
                            let subsUpdateDto = {
                                Channels: subscription.Channels,
                                NotificationType: subscription.NotificationType,
                                PresetId: subscription.PresetId,
                                Priority: subscription.Priority,
                                DisplayMode: subscription.DisplayMode,
                                Enabled: subscription.Enabled
                            };
                            //update subscription
                            promises.push(notificationSubscriptionsRepository.updateNotificationSubscription(idOfExisting, subsUpdateDto, this.currentUser.apiUserId, this.departmentId));
                        }
                        else {
                            //if it is new subscription
                            let newRule = {
                                ScopeType: subscription.ScopeType,
                                ScopeId: subscription.ScopeId,
                                NotificationType: subscription.NotificationType,
                                Enabled: subscription.Enabled,
                                Channels: subscription.Channels,
                                DisplayMode: subscription.DisplayMode,
                                PresetId: subscription.PresetId,
                                ScheduleId: schedule.id
                            };
                            rulesToCreate.push(newRule);
                            //create new subscription
                        }
                    });
                    if (rulesToCreate.length > 0) {
                        promises.push(notificationSubscriptionsRepository.createNotificationSubscription(rulesToCreate, this.currentUser.apiUserId, this.departmentId, schedule.id));
                    }
                });
                await Promise.all(promises).then((response) => {
                    errors = errors.concat(response.filter((x) => typeof x === 'string'));
                });
                //check if all calls succeeded
                if (errors.length <= 0) {
                    VueUtilities.openSuccessToast(this, this.$t('notifications.configuration.non_working_list.success'));
                    this.$emit('reloadSchedules');
                    this.timetableSchedules = [];
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('notifications.configuration.non_working_list.failure', {
                        error: errors.join(', ')
                    }));
                }
                this.isLoading = false;
            }
            else {
                //scroll view to first error field
                if (this.$validator.errors.items)
                    this.$el
                        .querySelector('[name="' + this.$validator.errors.items[0].field + '"]')
                        .scrollIntoView({ block: 'center' });
            }
        });
        this.isUnsavedChangesModalActive = false;
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
    saveUnsavedChanges() {
        this.closeUnsavedChangesModal();
        this.save();
    }
};
__decorate([
    Prop({ type: Array })
], Timetable.prototype, "availableTypes", void 0);
__decorate([
    Prop({ type: Array })
], Timetable.prototype, "schedules", void 0);
__decorate([
    Prop({ type: Object })
], Timetable.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Object })
], Timetable.prototype, "departmentUserSettings", void 0);
__decorate([
    Prop({ type: Array })
], Timetable.prototype, "presets", void 0);
__decorate([
    Prop({ type: String })
], Timetable.prototype, "departmentId", void 0);
__decorate([
    Prop({ type: Object })
], Timetable.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], Timetable.prototype, "tier", void 0);
Timetable = __decorate([
    Component({
        components: {
            MeasuredDataNotification,
            EntityMeasuredDataNotification,
            GeneralNotificationComponent,
            UnsavedChangesDialog
        }
    })
], Timetable);
export default Timetable;
