import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import FormatUtilities from '@/services/FormatUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var deviceRepository;
let PublishDeviceModal = class PublishDeviceModal extends Vue {
    constructor() {
        super(...arguments);
        this.registrationKey = null;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
        this.registrationKey = this.isReuse ? this.device.registrationKey : null;
    }
    publishLogger() {
        if (this.isLoading === true)
            return;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let createResult;
                if (this.isReuse) {
                    let device = await deviceRepository.adminReturnDevice(this.device.deviceId);
                    createResult = await deviceRepository.adminPublishDevice(device.deviceId, this.registrationKey);
                }
                else
                    createResult = await deviceRepository.adminPublishDevice(this.device.deviceId, this.registrationKey);
                this.isLoading = false;
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.isReuse
                        ? this.$t('admin.component.devices.detail.deviceInfo.publish.success')
                        : this.$t('admin.component.devices.detail.deviceInfo.reuse.success'));
                    this.resultRetrieved(true);
                }
                else if (createResult.code == ApiResponseCodes.VALIDATION_ERROR) {
                    let fields = Object.values(createResult.errors).join(',');
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.activate_device.validation_error', {
                        fields: fields
                    }));
                }
                else if (createResult.code == ApiResponseCodes.REG_KEY_ALREADY_USED) {
                    VueUtilities.openErrorToast(this, createResult.title);
                }
                else {
                    VueUtilities.openErrorToast(this, this.isReuse
                        ? this.$t('admin.component.devices.detail.deviceInfo.publish.failure')
                        : this.$t('admin.component.devices.detail.deviceInfo.reuse.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    formatKey() {
        let formatResponse = FormatUtilities.formatRegistrationKey(this.registrationKey);
        var regKeyInput = document.getElementById('reg-key');
        this.registrationKey = formatResponse.RegistrationKey;
        this.$nextTick(() => {
            regKeyInput.selectionStart = formatResponse.Start + formatResponse.CurrentOffset;
            regKeyInput.selectionEnd = formatResponse.Start + formatResponse.CurrentOffset;
        });
    }
};
__decorate([
    Prop({ type: Object })
], PublishDeviceModal.prototype, "device", void 0);
__decorate([
    Prop({ type: Boolean, default: null })
], PublishDeviceModal.prototype, "isReuse", void 0);
PublishDeviceModal = __decorate([
    Component({})
], PublishDeviceModal);
export default PublishDeviceModal;
