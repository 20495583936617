var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.sourceGroupSources.length > 0
      ? _c(
          "div",
          [
            _c(
              "b-tabs",
              {
                attrs: { type: "is-boxed", multiline: true },
                on: { input: _vm.tabChanged },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              _vm._l(_vm.sourceGroupSources, function (source) {
                return _c(
                  "b-tab-item",
                  { key: source.id, attrs: { label: source.name } },
                  [
                    _c("p", { staticClass: "text-wrap-anywhere" }, [
                      _vm._v(_vm._s(source.description)),
                    ]),
                  ]
                )
              }),
              1
            ),
            _c("StatisticsTable", {
              attrs: {
                activeSource: _vm.activeSource,
                services: _vm.activeSourceServices,
                activeStatistics: _vm.activeStatistics,
                dateFilter: _vm.dateFilter,
                dateTimeManager: _vm.dateTimeManager,
                onPublicPage: _vm.onPublicPage,
              },
            }),
            _c("hr"),
            _vm.hasDataForTimerange
              ? _c(
                  "div",
                  { staticClass: "buttons chart-controls" },
                  [
                    _c("b-button", {
                      staticClass: "zoom_btn is-small",
                      class: _vm.chartId,
                      attrs: {
                        type: "is-primary",
                        "icon-right": "plus",
                        id: "zoom_in",
                      },
                    }),
                    _c("b-button", {
                      staticClass: "zoom_btn is-small",
                      class: _vm.chartId,
                      attrs: {
                        type: "is-primary",
                        "icon-right": "minus",
                        id: "zoom_out",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("section", [
              !_vm.hasDataForTimerange
                ? _c("div", { staticClass: "empty-chart-data" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("component.report.no_data"))),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { attrs: { id: _vm.chartId, width: "100%" } }),
            ]),
            _vm.hasDataForTimerange
              ? _c(
                  "section",
                  [
                    _c("hr"),
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex flex-direction-row is-align-items-center is-flex-wrap-wrap",
                      },
                      [
                        _c("h1", { staticClass: "subtitle is-4 mb-1 mr-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.report.record_table.title")
                              ) +
                              " "
                          ),
                        ]),
                        _vm.filterRecords
                          ? _c("span", { staticClass: "is-italic" }, [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.$t(
                                      "component.report.record_table.record_filtering_on"
                                    )
                                  ) +
                                  ") "
                              ),
                            ])
                          : _c("span", { staticClass: "is-italic" }, [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.$t(
                                      "component.report.record_table.record_filtering_off"
                                    )
                                  ) +
                                  ") "
                              ),
                            ]),
                      ]
                    ),
                    !_vm.onPublicPage
                      ? _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-switch",
                                  {
                                    on: { input: _vm.filterRecordsChanged },
                                    model: {
                                      value: _vm.filterRecordsValue,
                                      callback: function ($$v) {
                                        _vm.filterRecordsValue = $$v
                                      },
                                      expression: "filterRecordsValue",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "component.report.record_table.filtering"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("RecordsTable", {
                      attrs: {
                        records: _vm.activeRecords,
                        dateTimeManager: _vm.dateTimeManager,
                        services: _vm.activeSourceServices,
                        annotationsForSource: _vm.annotationsForSource,
                        annotationAllowed:
                          !_vm.onPublicPage &&
                          _vm.$featuresManager.isAvailable(
                            _vm.$features.REPORTS_ANNOTATIONS
                          ),
                      },
                      on: { reloadRecords: _vm.reloadRecords },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticClass: "empty-chart-data" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("component.report.no_data")))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }