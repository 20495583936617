import { __decorate } from "tslib";
import VueUtilities from '@/services/VueUtilities';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DeviceRepository from '@/services/repository/DeviceRepository';
import Device from '@/entities/Device';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import DeviceOfflineTemplate from '@/views/components/device/DeviceOfflineTemplate.vue';
import FormatUtilities from '@/services/FormatUtilities';
var deviceRepository;
let DeviceRegistrationComponent = class DeviceRegistrationComponent extends Vue {
    constructor() {
        super(...arguments);
        this.registrationKey = '';
        this.tutorialVisible = true;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
            if (result) {
                this.register();
                return;
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('error_messages.invalid_form').toString());
            }
        });
    }
    async register() {
        this.isLoading = true;
        let result = await deviceRepository.registerDevice(this.registrationKey);
        if (result instanceof Device) {
            VueUtilities.openSuccessToast(this, this.$t('component.device.register.modal.success', {
                name: result.deviceName
            }).toString());
            this.$emit('result', result.deviceId);
        }
        else if (result.code == ApiResponseCodes.INVALID_REG_KEY || result.code == ApiResponseCodes.INVALID_KEY) {
            VueUtilities.openErrorToast(this, `${this.$t('component.device.register.modal.invalid_key').toString()}`);
        }
        else if (result.code == ApiResponseCodes.DEVICE_ALREADY_REGISTERED) {
            VueUtilities.openErrorToast(this, `${this.$t('component.device.register.modal.device_already_registered').toString()}`);
        }
        else if (result.code == ApiResponseCodes.DEVICE_NOT_ACTIVE) {
            this.changeActiveState(false);
        }
        else {
            VueUtilities.openErrorToast(this, `${this.$t('component.device.register.modal.failure').toString()}`);
        }
        this.isLoading = false;
    }
    changeActiveState(state) {
        this.$emit('deviceIsActive', state);
    }
    formatKey() {
        let formatResponse = FormatUtilities.formatRegistrationKey(this.registrationKey);
        var regKeyInput = document.getElementById('reg-key');
        this.registrationKey = formatResponse.RegistrationKey;
        this.$nextTick(() => {
            regKeyInput.selectionStart = formatResponse.Start + formatResponse.CurrentOffset;
            regKeyInput.selectionEnd = formatResponse.Start + formatResponse.CurrentOffset;
        });
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], DeviceRegistrationComponent.prototype, "isModal", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], DeviceRegistrationComponent.prototype, "deviceIsActiveFlag", void 0);
DeviceRegistrationComponent = __decorate([
    Component({ components: { DeviceOfflineTemplate } })
], DeviceRegistrationComponent);
export default DeviceRegistrationComponent;
