var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c(
          "table",
          {
            staticClass:
              "info-table table-layout-equal table-borderless card-table",
          },
          [
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.issues.table.type")) + " "
                ),
              ]),
              _c(
                "td",
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("notification_rule_types." + _vm.issue.type)
                        ) +
                        " "
                    ),
                  ]),
                  _vm.issue.type ==
                    _vm.NotificationSubscriptionType.MEASURED_DATA_ALARM &&
                  _vm.MeasurementStatuses.ALARM.name ===
                    _vm.issue.metadata.level
                    ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `measurement_status.${_vm.issue.metadata.level}`
                            )
                          ) + " "
                        ),
                      ])
                    : _vm.issue.type ==
                        _vm.NotificationSubscriptionType.MEASURED_DATA_ALARM &&
                      _vm.MeasurementStatuses.WARN.name ===
                        _vm.issue.metadata.level
                    ? _c("b-tag", { attrs: { type: "is-warning" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `measurement_status.${_vm.issue.metadata.level}`
                            )
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.issues.table.startedAt")) + " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.dateTimeManager.formatTime(
                      new Date(_vm.issue.startedAt * 1000)
                    )
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.issues.table.createdAt")) + " "
                ),
              ]),
              _c("td", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(_vm.issue.createdAt * 1000)
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.issues.table.updatedAt")) + " "
                ),
              ]),
              _c("td", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(_vm.issue.updatedAt * 1000)
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.issues.table.resolved")) + " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.dateTimeManager.formatTime(
                      new Date(_vm.issue.resolvedAt * 1000)
                    )
                  )
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "column" }, [
        _c(
          "table",
          {
            staticClass:
              "info-table table-layout-equal table-borderless card-table",
          },
          [
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.issues.table.acknowledgedAt")) +
                    " "
                ),
              ]),
              _c("td", [
                _vm.issue.acknowledgedAt
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.issue.acknowledgedAt * 1000)
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v(" - ")]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.issues.table.acknowledgedBy")) +
                    " "
                ),
              ]),
              _c("td", [
                _vm.issue.acknowledgedBy
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.issue.acknowledgedBy.fullName) + " "
                      ),
                    ])
                  : _c("span", [_vm._v(" - ")]),
              ]),
            ]),
            _vm.issue.type == _vm.NotificationSubscriptionType.DEVICE_OFFLINE
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.issues.table.name")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _vm.isOnAdminPage &&
                        _vm.device &&
                        _vm.device.deviceId ==
                          _vm.issue.metadata.deviceInstanceId
                          ? _c("span", [_vm._v(_vm._s(_vm.device.deviceName))])
                          : _vm.device && _vm.device.deviceId
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "deviceConf",
                                    params: {
                                      deviceId:
                                        _vm.issue.metadata.deviceInstanceId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.device.deviceName) + " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.unknown")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.issue.type == _vm.NotificationSubscriptionType.SOURCE_OFFLINE
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.issues.table.name")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _vm.isOnAdminPage &&
                        _vm.companyLogger &&
                        _vm.companyLogger.serialNumber
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminLoggerDetail",
                                    params: {
                                      loggerId: _vm.companyLogger.loggerId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.companyLogger.serialNumber))]
                            )
                          : _vm.source && _vm.source.id
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "logger_detail",
                                    params: {
                                      locationId: _vm.issue.metadata.sourceId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.source.logger.serialNumber) +
                                    " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.unknown")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.issue.type == _vm.NotificationSubscriptionType.LOW_BATTERY
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.issues.table.name")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _vm.isOnAdminPage &&
                        _vm.companyLogger &&
                        _vm.companyLogger.companyLoggerId ==
                          _vm.issue.metadata.companyLoggerId
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminLoggerDetail",
                                    params: {
                                      loggerId: _vm.companyLogger.loggerId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.companyLogger.serialNumber))]
                            )
                          : _vm.source &&
                            _vm.source.logger &&
                            _vm.source.logger.companyLoggerId ==
                              _vm.issue.metadata.companyLoggerId
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "logger_detail",
                                    params: {
                                      locationId: _vm.source.id,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.source.logger.serialNumber) +
                                    " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.unknown")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.issues.table.batteryLevel")
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm.issue.metadata.batteryLevel) + " "
                      ),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm.issue.type ==
            _vm.NotificationSubscriptionType.MEASURED_DATA_ALARM
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.issues.table.name")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _vm.isOnAdminPage &&
                        _vm.companyLogger &&
                        _vm.companyLogger.serialNumber
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "adminLoggerDetail",
                                    params: {
                                      loggerId: _vm.companyLogger.loggerId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.companyLogger.serialNumber))]
                            )
                          : _vm.source &&
                            _vm.source.logger &&
                            _vm.source.logger.serialNumber
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "logger_detail",
                                    params: {
                                      locationId: _vm.issue.metadata.sourceId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.source.logger.serialNumber) +
                                    " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.unknown")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.issue.type ==
            _vm.NotificationSubscriptionType.GROUP_MEASURED_DATA_ALARM
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.issues.table.name")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        !_vm.isOnAdminPage &&
                        _vm.sourceGroup &&
                        _vm.sourceGroup.id
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "group_detail",
                                    params: {
                                      groupId: _vm.issue.metadata.sourceGroupId,
                                      lang: _vm.$route.params.lang,
                                    },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.sourceGroup.name) + " ")]
                            )
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.issues.table.unknown")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
            _c("tr", [
              _c("td", { staticClass: "row-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.issues.table.notifications")) +
                    " "
                ),
              ]),
              _c("td", [
                _vm.totalNotification
                  ? _c("a", { on: { click: _vm.openNotificationTab } }, [
                      _vm._v(" " + _vm._s(_vm.totalNotification) + " "),
                    ])
                  : _c("span", [_vm._v("-")]),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }