import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import TiersRepository from '@/services/repository/TiersRepository';
import { FeaturesTypes } from '@/entities/tiers/FeaturesTypes';
var tiersRepository;
let AssignTierToCompanyModal = class AssignTierToCompanyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.tiers = [];
        this.currentStep = Steps.SELECT;
        this.selectedTier = null;
        this.name = null;
        this.Steps = Steps;
        this.FeaturesTypes = FeaturesTypes;
    }
    created() {
        tiersRepository = new TiersRepository(this);
        this.loadData();
    }
    loadData() {
        this.isLoading = true;
        if (!this.activeTier)
            this.$emit('loadTier');
        let allPromises = [];
        allPromises.push(tiersRepository.getTiers());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    processLoadedDataForPage(tiers) {
        this.tiers = tiers.filter((x) => x.active === true);
        this.isLoading = false;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    async assignTier() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let dto = {
                    TierId: this.selectedTier.id,
                    Name: this.name,
                    CustomizedFeatures: this.selectedTier.tierFeatures,
                    ActivateAt: Math.floor(new Date().getTime() / 1000) + 10
                };
                let res = await tiersRepository.assignCompanyTier(this.company.companyId, dto);
                if (res.id) {
                    let actRes = false;
                    if (!this.activeTier) {
                        let actDto = {
                            ActivateAt: Math.floor(new Date().getTime() / 1000)
                        };
                        actRes = await tiersRepository.activateTierForCompany(this.company.companyId, res.id, actDto);
                    }
                    else {
                        let deactDto = {
                            NextTierId: res.id,
                            DeactivateAt: Math.floor(new Date().getTime() / 1000)
                        };
                        actRes = await tiersRepository.deactivateTierForCompany(this.company.companyId, this.activeTier.id, deactDto);
                    }
                    if (actRes == true)
                        this.resultRetrieved(true);
                }
                this.isLoading = false;
            }
        });
    }
    tierSelected(tier) {
        this.selectedTier = tier;
        this.name = tier.displayName;
        this.currentStep = Steps.CUSTOMIZE;
    }
    changeTier() {
        this.selectedTier = null;
        this.currentStep = Steps.SELECT;
    }
    stepChanged(step) { }
    setUnlimited(feature) {
        feature.Value.Quota = -1;
    }
};
__decorate([
    Prop({ type: Object })
], AssignTierToCompanyModal.prototype, "company", void 0);
__decorate([
    Prop({ type: Object })
], AssignTierToCompanyModal.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AssignTierToCompanyModal.prototype, "activeTier", void 0);
AssignTierToCompanyModal = __decorate([
    Component({})
], AssignTierToCompanyModal);
export default AssignTierToCompanyModal;
var Steps;
(function (Steps) {
    Steps["SELECT"] = "1";
    Steps["CUSTOMIZE"] = "2";
})(Steps || (Steps = {}));
