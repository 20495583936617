var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title is-3 has-text-centered-touch" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("component.notifications_list.title")) + " "
        ),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _vm.pauseComponentVisible
                  ? _c("PauseNotificationsMessage", {
                      staticClass: "tile is-child",
                      on: { reloadPause: _vm.reloadNotificationPause },
                    })
                  : _vm._e(),
                !_vm.isLoading && _vm.notificationsPaused
                  ? _c(
                      "b-message",
                      {
                        staticClass: "tile is-child",
                        attrs: { type: "is-warning" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.notification.pause.paused_msg",
                                {
                                  time: _vm.dateTimeManager.formatTime(
                                    new Date(
                                      _vm.pauseNotificationsUntil * 1000
                                    ),
                                    null,
                                    "",
                                    null
                                  ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.dateTimeManager
          ? _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "section",
                { staticClass: "box tile is-child" },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "custom-layout-tabs",
                      attrs: { type: "is-boxed" },
                      on: {
                        input: function ($event) {
                          return _vm.changedActiveTab($event)
                        },
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab-item",
                        {
                          attrs: {
                            label: _vm.$t("component.notifications_list.title"),
                          },
                        },
                        [
                          _c("NotificationsTable", {
                            ref: "notificationsTable",
                            attrs: {
                              notifications: _vm.notificationsResult,
                              dateTimeManager: _vm.dateTimeManager,
                              departmentFilter: _vm.getSelectedDepartment(),
                              canDelete: true,
                              inNotificationDepartmentPage: true,
                            },
                            on: {
                              notificationRemoved: _vm.notificationRemoved,
                              onOptionsChange: _vm.onOptionsChange,
                              loadNotifications: _vm.loadNotifications,
                              removeAllNotifications:
                                _vm.removeAllNotifications,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.$ability.can(
                        _vm.$permActions.READ,
                        _vm.$permSubjects.ISSUES
                      )
                        ? _c(
                            "b-tab-item",
                            {
                              attrs: {
                                label: _vm.$t("component.issues.title"),
                              },
                            },
                            [
                              _c("IssuesTable", {
                                ref: "issuesTable",
                                attrs: {
                                  dateTimeManager: _vm.dateTimeManager,
                                  departmentIssues: _vm.departmentIssues,
                                  devices: _vm.devices,
                                  sources: _vm.sources,
                                  sourceGroups: _vm.sourceGroups,
                                  issuesLoaded: _vm.issuesLoaded,
                                },
                                on: {
                                  acknowledgeIssue: _vm.acknowledgeIssueDialog,
                                  onOptionsChange:
                                    _vm.onIssuesTableOptionsChange,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }