var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("PublicHeader"),
      _c(
        "div",
        { staticClass: "site-content main-column ml-0" },
        [_vm._t("default")],
        2
      ),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }