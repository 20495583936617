var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c(
      "header",
      { staticClass: "modal-card-head" },
      [
        _c("b-icon", {
          staticClass: "mr-2",
          attrs: { icon: "warehouse", size: "is-medium" },
        }),
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.title"
                )
              ) +
              " "
          ),
        ]),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "admin.component.reseller.detail.resellerDepartments.addDepartments"
              ),
            },
          },
          [
            _c(
              "b-table",
              {
                staticClass: "select-location-table border select-user-table",
                attrs: {
                  data: _vm.availableDepartments,
                  "checked-rows": _vm.selectedDepartments,
                  paginated: true,
                  "per-page": 5,
                  narrowed: "",
                  checkable: "",
                  "mobile-cards": false,
                  "row-class": (row) =>
                    _vm.departments.filter((x) => x.companyId === row.companyId)
                      .length > 0
                      ? "is-disabled has-text-grey"
                      : "",
                  "is-row-checkable": (row) =>
                    _vm.departments.filter((x) => x.companyId === row.companyId)
                      .length === 0,
                },
                on: {
                  "update:checkedRows": function ($event) {
                    _vm.selectedDepartments = $event
                  },
                  "update:checked-rows": function ($event) {
                    _vm.selectedDepartments = $event
                  },
                  check: _vm.selectedDepartment,
                },
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "companyName",
                    label: _vm.$t(
                      "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.form.selectAll"
                    ),
                    searchable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "searchable",
                      fn: function (props) {
                        return [
                          _c("b-input", {
                            attrs: { size: "is-small" },
                            model: {
                              value: props.filters[props.column.field],
                              callback: function ($$v) {
                                _vm.$set(props.filters, props.column.field, $$v)
                              },
                              expression: "props.filters[props.column.field]",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (department) {
                        return [
                          _c("span", [
                            _c(
                              "div",
                              {
                                staticClass: "field flex-space-between",
                                class: !_vm.departments.find(
                                  (x) =>
                                    x.companyId === department.row.companyId
                                )
                                  ? "is-clickable"
                                  : "",
                              },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(department.row.companyName)),
                                ]),
                                _vm.isAssignUserToDeps &&
                                _vm.selectedDepartments.find(
                                  (dep) =>
                                    department.row.companyId == dep.companyId
                                )
                                  ? _c(
                                      "b-select",
                                      {
                                        attrs: {
                                          size: "is-small",
                                          placeholder: _vm.$t(
                                            "admin.component.company.createCompanyModal.form.selectRole"
                                          ),
                                          name: _vm.$t(
                                            "admin.component.company.createCompanyModal.form.selectRole"
                                          ),
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.selectRole(
                                              department.row,
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: (
                                            _vm.assignedDepartments.find(
                                              (x) =>
                                                x.companyId ===
                                                department.row.companyId
                                            ) || {}
                                          ).userRole,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.assignedDepartments.find(
                                                (x) =>
                                                  x.companyId ===
                                                  department.row.companyId
                                              ) || {},
                                              "userRole",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "(assignedDepartments.find((x) => x.companyId === department.row.companyId) || {}).userRole",
                                        },
                                      },
                                      _vm._l(
                                        _vm.availableUserRoles,
                                        function (option) {
                                          return _c(
                                            "option",
                                            {
                                              key: option,
                                              domProps: { value: option },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      `users.roles.${option}`
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.list.selected"
              ),
            },
          },
          [
            _c(
              "b-table",
              {
                staticClass:
                  "select-location-table border select-user-table-list",
                attrs: {
                  data: _vm.isAssignUserToDeps
                    ? _vm.assignedDepartments
                    : _vm.selectedDepartments,
                  striped: "",
                },
              },
              [
                _c("b-table-column", {
                  attrs: {
                    field: "companyName",
                    label: _vm.$t(
                      "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.list.name"
                    ),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("span", [
                            _c(
                              "div",
                              { staticClass: "field flex-space-between" },
                              [
                                _vm._v(
                                  " " + _vm._s(props.row.companyName) + " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.isAssignUserToDeps
                  ? _c("b-table-column", {
                      attrs: {
                        field: "userRole",
                        label: _vm.$t(
                          "admin.component.user.detail.userInfo.role"
                        ),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (props) {
                              return [
                                _c("span", [
                                  _c(
                                    "div",
                                    { staticClass: "field flex-space-between" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `users.roles.${props.row.userRole}`
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2366601892
                      ),
                    })
                  : _vm._e(),
                _c("template", { slot: "empty" }, [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.list.empty"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.addDepartments()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "admin.component.reseller.detail.resellerDepartments.list.addDepartmentModal.confirm"
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }