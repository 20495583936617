var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    { staticClass: "navbar has-shadow is-fixed-top is-primary" },
    [
      _c(
        "div",
        { staticClass: "navbar-brand" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-item",
              attrs: {
                to: {
                  name: "adminCompanies",
                  params: {
                    lang: _vm.$route.params.lang,
                  },
                },
              },
            },
            [
              _c("img", {
                staticClass: "p-1",
                attrs: {
                  src: require("@/assets/img/loghub_logo_trans.svg"),
                  alt: "",
                },
              }),
              _c("h1", [_vm._v(_vm._s(_vm.$t("app_name_admin")))]),
            ]
          ),
        ],
        1
      ),
      _vm.$route.meta.needsLogin
        ? _c("div", { staticClass: "navbar-menu", attrs: { id: "navMenu" } }, [
            _c("div", { staticClass: "navbar-end" }, [
              _c(
                "div",
                {
                  staticClass: "loghub-theme navbar-item is-hidden-mobile",
                  on: { click: _vm.toggleTheme },
                },
                [
                  _vm.selectedTheme === "dark"
                    ? _c("i", {
                        staticClass: "fas fa-sun is-size-4 is-size-6-mobile",
                      })
                    : _vm._e(),
                  _vm.selectedTheme === "light"
                    ? _c("i", {
                        staticClass: "fas fa-moon is-size-4 is-size-6-mobile",
                      })
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeNotificationMenu,
                      expression: "closeNotificationMenu",
                    },
                  ],
                  staticClass: "navbar-item has-dropdown",
                  class: { "is-active": _vm.showNotificationMenu },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-link",
                      on: {
                        click: function ($event) {
                          _vm.showNotificationMenu = !_vm.showNotificationMenu
                          _vm.showUserMenu = false
                        },
                      },
                    },
                    [
                      _c("div", { attrs: { id: "notifications-icon" } }, [
                        _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.notificationsCount)),
                        ]),
                        _c("i", { staticClass: "fas fa-bell is-size-5" }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "navbar-dropdown is-right navbar-notification-container",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "navbar-item multi-header" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "component.header.notifications.count",
                                  _vm.notificationsCount
                                )
                              )
                            ),
                          ]),
                          _vm.notificationsCount == 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: { disabled: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showNotificationMenu = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("generals.view_all")) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminNotifications",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button",
                                      attrs: {
                                        disabled: _vm.notificationsCount == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showNotificationMenu = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("generals.view_all")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "navbar-item notif-item notifications-column",
                        },
                        [
                          _vm._l(
                            _vm.notifications || [],
                            function (item, index) {
                              return _c(
                                "div",
                                { key: item.notificationId },
                                [
                                  _c("NotificationItem", {
                                    attrs: {
                                      notificationItem: item,
                                      dateTimeManager: _vm.dateTimeManager,
                                    },
                                    on: {
                                      notificationRemoved:
                                        _vm.notificationRemoved,
                                    },
                                  }),
                                  index < _vm.notifications.length - 1
                                    ? _c("hr")
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          _vm.notificationsCount > _vm.notifications.length
                            ? _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: { id: _vm.MORE_BTN_ID },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loadNotifications(true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("generals.view_more")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeUserMenu,
                      expression: "closeUserMenu",
                    },
                  ],
                  staticClass: "navbar-item has-dropdown",
                  class: { "is-active": _vm.showUserMenu },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-link",
                      on: {
                        click: function ($event) {
                          _vm.showUserMenu = !_vm.showUserMenu
                          _vm.showNotificationMenu = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-user is-size-5" })]
                  ),
                  _c("div", { staticClass: "navbar-dropdown is-right" }, [
                    _c("div", { staticClass: "navbar-item credentials" }, [
                      _c("strong", { attrs: { data: _vm.sessionPassword } }, [
                        _vm._v(_vm._s(_vm.username)),
                      ]),
                    ]),
                    _c("hr", { staticClass: "navbar-divider" }),
                    _c("div", { staticClass: "navbar-item" }, [
                      _vm._v(" " + _vm._s(_vm.$t("navbar.language")) + ": "),
                      _vm.isCurrentLanguage("sk")
                        ? _c("span", { staticClass: "has-l-m" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                          ])
                        : _c(
                            "a",
                            {
                              staticClass: "has-l-m",
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage(
                                    _vm.Languages.SLOVAK
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("navbar.slovak")))]
                          ),
                      _vm.isCurrentLanguage("en")
                        ? _c("span", { staticClass: "has-l-m" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.english"))),
                          ])
                        : _c(
                            "a",
                            {
                              staticClass: "has-l-m",
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage(
                                    _vm.Languages.ENGLISH
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("navbar.english")))]
                          ),
                    ]),
                    _c("hr", { staticClass: "navbar-divider" }),
                    _c("div", { staticClass: "navbar-item" }, [
                      _c("table", { staticClass: "navbar-time-format-table" }, [
                        _c("tr", [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.date_format"))),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeholders.select_format"
                                        ),
                                      },
                                      on: { input: _vm.dateFormatSelected },
                                      model: {
                                        value: _vm.selectedDateFormat,
                                        callback: function ($$v) {
                                          _vm.selectedDateFormat = $$v
                                        },
                                        expression: "selectedDateFormat",
                                      },
                                    },
                                    _vm._l(_vm.DateFormats, function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option,
                                          domProps: { value: option },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateTimeManager.formatTime(
                                                  _vm.actualTime,
                                                  _vm.selectedTimezone,
                                                  option,
                                                  ""
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.time_format"))),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeholders.select_format"
                                        ),
                                      },
                                      on: { input: _vm.clockFormatSelected },
                                      model: {
                                        value: _vm.selectedClockFormat,
                                        callback: function ($$v) {
                                          _vm.selectedClockFormat = $$v
                                        },
                                        expression: "selectedClockFormat",
                                      },
                                    },
                                    _vm._l(_vm.ClockFormats, function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option,
                                          domProps: { value: option },
                                        },
                                        [_vm._v(" " + _vm._s(option) + " ")]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.timezone"))),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        expanded: "",
                                        placeholder: _vm.$t(
                                          "placeholders.select_format"
                                        ),
                                      },
                                      on: { input: _vm.timezoneSelected },
                                      model: {
                                        value: _vm.selectedTimezone,
                                        callback: function ($$v) {
                                          _vm.selectedTimezone = $$v
                                        },
                                        expression: "selectedTimezone",
                                      },
                                    },
                                    _vm._l(_vm.TimeZones, function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option,
                                          domProps: { value: option },
                                        },
                                        [_vm._v(" " + _vm._s(option) + " ")]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.tzInfo"))),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-field",
                                [
                                  _c("b-checkbox", {
                                    on: { input: _vm.showTzChanged },
                                    model: {
                                      value: _vm.selectedShowTzInfo,
                                      callback: function ($$v) {
                                        _vm.selectedShowTzInfo = $$v
                                      },
                                      expression: "selectedShowTzInfo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(_vm._s(_vm.$t("navbar.selected_format"))),
                          ]),
                          _c("td", [
                            _c("p", { staticClass: "actual-time" }, [
                              _vm._v(_vm._s(_vm.actualTimeFormatted)),
                            ]),
                          ]),
                        ]),
                        _c("tr", { staticClass: "is-hidden-tablet" }, [
                          _c("td", { staticClass: "row-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.settings.profile.user_settings.dark_mode"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-field",
                                [
                                  _c("b-switch", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.setTheme(
                                          _vm.selectedDarkMode
                                            ? "dark"
                                            : "light"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.selectedDarkMode,
                                      callback: function ($$v) {
                                        _vm.selectedDarkMode = $$v
                                      },
                                      expression: "selectedDarkMode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _vm.needsReload()
                      ? _c("div", { staticClass: "navbar-item" }, [
                          _c("p", { staticClass: "reload-alert" }, [
                            _vm._v(
                              _vm._s(_vm.$t("error_messages.page_reload"))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("hr", { staticClass: "navbar-divider" }),
                    _c(
                      "a",
                      {
                        staticClass: "navbar-item",
                        on: { click: _vm.logoutAsync },
                      },
                      [
                        _vm._m(0),
                        _vm._v("   " + _vm._s(_vm.$t("navbar.logout")) + " "),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "navbar-burger",
                    class: { "is-active": _vm.isSideMenuVisible },
                    attrs: {
                      id: _vm.BURGER_BTN_ID,
                      "aria-label": "menu",
                      "aria-expanded": "false",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.mobileExpand()
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-sign-out-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }