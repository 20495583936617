import { __decorate } from "tslib";
import Languages from '@/entities/enums/languages';
import { Component, Vue } from 'vue-property-decorator';
import NotificationItem from '@/views/components/notification/NotificationItem.vue';
import LoggedUserManager from '@/services/LoggedUserManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
var userSettingsRepository;
import UserPreferences from '@/services/UserPreferences';
let PublicHeader = class PublicHeader extends Vue {
    constructor() {
        super(...arguments);
        this.showUserMenu = false;
        this.settings = null;
        this.Languages = Languages;
        this.loggedIn = LoggedUserManager.storageContainLoginCredentials(this.$cookies);
        this.selectedTheme = '';
    }
    async created() {
        this.$i18n.locale = this.$route.params.lang;
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        if (this.loggedIn)
            this.settings = await userSettingsRepository.loadUserSettings();
        this.selectedTheme = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.Theme);
        this.setTheme(this.selectedTheme);
    }
    toggleTheme() {
        this.setTheme(this.selectedTheme === 'dark' ? 'light' : 'dark');
    }
    setTheme(value) {
        this.selectedTheme = value;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.Theme, value);
        if (value === 'dark') {
            document.documentElement.setAttribute('data-theme', 'dark');
            document.body.classList.add('dark-mode');
        }
        else {
            document.documentElement.setAttribute('data-theme', 'light');
            document.body.classList.remove('dark-mode');
        }
    }
    closeUserMenu(event) {
        this.showUserMenu = false;
    }
    async changeLanguage(language) {
        if (this.loggedIn) {
            this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.LANGUAGE], language);
        }
        this.$i18n.locale = language;
        this.$validator.localize(language);
        let params = this.$route.params;
        params.lang = language;
        this.$router
            .push({
            name: this.$route.name,
            params: params,
            query: this.$route.query
        })
            .catch((err) => err);
    }
    isCurrentLanguage(lang) {
        if (this.$route.params.lang == lang)
            return true;
        return false;
    }
};
PublicHeader = __decorate([
    Component({ components: { NotificationItem } })
], PublicHeader);
export default PublicHeader;
