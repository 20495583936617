var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-steps",
        {
          attrs: {
            animated: "",
            type: "is-primary",
            size: "is-medium",
            "has-navigation": false,
          },
          on: {
            input: function ($event) {
              return _vm.stepChanged($event)
            },
          },
          model: {
            value: _vm.stepInBar,
            callback: function ($$v) {
              _vm.stepInBar = $$v
            },
            expression: "stepInBar",
          },
        },
        [
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.instructions"),
              "icon-pack": "fa",
              icon: "info",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.INSTRUCTIONS),
              type: {
                "is-success": _vm.stepInBar > _vm.WizardSteps.INSTRUCTIONS,
              },
            },
          }),
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.device"),
              "icon-pack": "fa",
              icon: "microchip",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.DEVICE),
              type: { "is-success": _vm.stepInBar > _vm.WizardSteps.DEVICE },
            },
          }),
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.logger"),
              "icon-pack": "fa",
              icon: "thermometer-half",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.LOGGERS),
              type: { "is-success": _vm.stepInBar > _vm.WizardSteps.LOGGERS },
            },
          }),
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.boundaries"),
              "icon-pack": "fa",
              icon: "location-arrow",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.BOUNDARIES),
              type: {
                "is-success": _vm.stepInBar > _vm.WizardSteps.BOUNDARIES,
              },
            },
          }),
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.notification"),
              "icon-pack": "fa",
              icon: "bell",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.NOTIFICATIONS),
              type: {
                "is-success": _vm.stepInBar > _vm.WizardSteps.NOTIFICATIONS,
              },
            },
          }),
          _c("b-step-item", {
            attrs: {
              label: _vm.$t("component.wizard.steps.done"),
              "icon-pack": "fa",
              icon: "check",
              clickable: _vm.stepIsClickable(_vm.WizardSteps.DONE),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }