var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("nav", { staticClass: "navbar has-shadow is-fixed-top is-primary" }, [
      _c(
        "div",
        { staticClass: "navbar-brand" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-item",
              attrs: {
                to: {
                  name: "dashboard",
                  params: {
                    lang: _vm.$route.params.lang,
                    departmentId: (_vm.selectedDepartment || {}).id,
                  },
                },
              },
            },
            [
              _c("img", {
                staticClass: "p-1",
                attrs: {
                  src: require("@/assets/img/loghub_logo_trans.svg"),
                  alt: "",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-flex-direction-column is-align-items-flex-end",
                },
                [
                  _c("h1", { staticClass: "is-hidden-mobile" }, [
                    _vm._v(_vm._s(_vm.$t("app_name"))),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.$route.meta.needsLogin && !_vm.isLoading
        ? _c("div", { staticClass: "navbar-menu", attrs: { id: "navMenu" } }, [
            _vm.routeHasHiddenSelect !== true && !_vm.isWizard
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeDepartmentsMenu,
                        expression: "closeDepartmentsMenu",
                      },
                    ],
                    staticClass:
                      "navbar-department navbar-item has-dropdown expanded",
                    class: { "is-active": _vm.showDepartmentsMenu },
                  },
                  [
                    _vm.selectedDepartment
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "navbar-link pl-3 pl-1-mobile is-size-5 is-flex is-flex-direction-column is-align-items-start is-justify-content-center department-name",
                            on: { click: _vm.toggleMainDepartmentsMenu },
                          },
                          [
                            _vm.hasMultipleOrganizations
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "trimmed is-size-7 navbar-org name",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedDepartment.organization
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "trimmed is-size-7 navbar-org name",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("navbar.department")) +
                                        " "
                                    ),
                                  ]
                                ),
                            _c(
                              "span",
                              { staticClass: "trimmed is-size-6-mobile name" },
                              [_vm._v(_vm._s(_vm.selectedDepartment.name))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "navbar-dropdown p-0" }, [
                      _c(
                        "div",
                        { staticClass: "dropdown-content p-0" },
                        [
                          _vm.hasMultipleOrganizations == true
                            ? _c(
                                "div",
                                { staticClass: "columns m-0 is-mobile" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "column is-half-tablet is-full-mobile p-0 org-clmn",
                                      class: !_vm.showOrgClmn
                                        ? "is-hidden-mobile"
                                        : "",
                                    },
                                    [
                                      _vm.selectedDepartment
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.departmentOrganizations,
                                              function (depOrg) {
                                                return _c("b-button", {
                                                  key: depOrg.organization,
                                                  staticClass:
                                                    "is-fullwidth org-btn",
                                                  class:
                                                    depOrg.organization ===
                                                    _vm.selectedDepartment
                                                      .organization
                                                      ? "has-text-weight-bold"
                                                      : "",
                                                  attrs: {
                                                    type: "is-black",
                                                    inverted: "",
                                                    label: depOrg.organization,
                                                    "icon-right":
                                                      "fas fa-chevron-right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleDepartmentsMenu(
                                                        depOrg.organization
                                                      )
                                                    },
                                                    mouseover: function (
                                                      $event
                                                    ) {
                                                      return _vm.toggleDepartmentsMenu(
                                                        depOrg.organization
                                                      )
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "organizations",
                                              params: {
                                                lang: _vm.$route.params.lang,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("b-button", {
                                            staticClass:
                                              "is-fullwidth all-org-btn",
                                            attrs: {
                                              type: "is-black",
                                              inverted: "",
                                              label: _vm.$t(
                                                "navbar.all_departments"
                                              ),
                                            },
                                            on: {
                                              click: _vm.closeDepartmentsMenu,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.selectedDepartment
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "column is-half-tablet is-full-mobile p-0 department-clmn",
                                          class: !_vm.showDepartmentsClmn
                                            ? "is-hidden-mobile"
                                            : "",
                                        },
                                        [
                                          _c(
                                            "h1",
                                            {
                                              staticClass: "is-hidden-tablet",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleDepartmentsMenu(
                                                    _vm
                                                      .departmentOrganizations[0]
                                                      .organization
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: "fas fa-chevron-left",
                                                  size: "is-small",
                                                  type: "is-black",
                                                },
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedDepartment
                                                    .organization
                                                ) + " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            _vm._l(
                                              _vm.departmentsForOrg,
                                              function (department) {
                                                return _c("b-button", {
                                                  key: department.id,
                                                  staticClass:
                                                    "is-fullwidth org-btn",
                                                  class:
                                                    department.name ===
                                                    _vm.selectedDepartment.name
                                                      ? "has-text-weight-bold"
                                                      : "",
                                                  attrs: {
                                                    type: "is-black",
                                                    inverted: "",
                                                    label: department,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeDepartment(
                                                        department
                                                      )
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : [
                                _c(
                                  "div",
                                  { staticClass: "single-select" },
                                  [
                                    _vm.isLoggedFromAdmin && !_vm.isWizard
                                      ? _c("div", { staticClass: "m-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "admin_notice is-hidden-desktop",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("navbar.adminNotice")
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.backToAdminInterface()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "navbar.adminNoticeLeave"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.isLoggedFromAdmin
                                      ? _c(
                                          "b-field",
                                          { staticClass: "m-2" },
                                          [
                                            _c("b-input", {
                                              ref: "departmentName",
                                              attrs: {
                                                placeholder:
                                                  _vm.$t("generals.search"),
                                                tabindex: 0,
                                                "icon-pack": "fas",
                                                icon: "search",
                                                "icon-right": "close-circle",
                                                "icon-right-clickable": "",
                                              },
                                              on: {
                                                "icon-right-click": function (
                                                  $event
                                                ) {
                                                  _vm.departmentName = ""
                                                },
                                              },
                                              nativeOn: {
                                                keydown: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      $event.ctrlKey ||
                                                      $event.shiftKey ||
                                                      $event.altKey ||
                                                      $event.metaKey
                                                    )
                                                      return null
                                                    $event.preventDefault()
                                                    return _vm.keyArrows.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      $event.ctrlKey ||
                                                      $event.shiftKey ||
                                                      $event.altKey ||
                                                      $event.metaKey
                                                    )
                                                      return null
                                                    $event.preventDefault()
                                                    return _vm.keyArrows.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                              model: {
                                                value: _vm.departmentName,
                                                callback: function ($$v) {
                                                  _vm.departmentName = $$v
                                                },
                                                expression: "departmentName",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedDepartment
                                      ? _c("div", [
                                          _c(
                                            "h1",
                                            { staticClass: "is-hidden-tablet" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedDepartment
                                                      .organization
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            {
                                              ref: "departments",
                                              staticClass: "department-select",
                                            },
                                            _vm._l(
                                              _vm.filteredDepartments,
                                              function (department) {
                                                return _c(
                                                  "li",
                                                  { key: department.id },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "is-fullwidth org-btn px-3 py-2 is-flex",
                                                        class:
                                                          department.name ===
                                                          _vm.selectedDepartment
                                                            .name
                                                            ? "has-text-weight-bold"
                                                            : "",
                                                        attrs: {
                                                          href: "#",
                                                          inverted: "",
                                                          disabled:
                                                            department.member &&
                                                            department.member
                                                              .Enabled == false,
                                                        },
                                                        on: {
                                                          keydown: [
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              if (
                                                                $event.ctrlKey ||
                                                                $event.shiftKey ||
                                                                $event.altKey ||
                                                                $event.metaKey
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                              return _vm.focusPrevious()
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              if (
                                                                $event.ctrlKey ||
                                                                $event.shiftKey ||
                                                                $event.altKey ||
                                                                $event.metaKey
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                              return _vm.focusNext()
                                                            },
                                                          ],
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeDepartment(
                                                              department
                                                            )
                                                          },
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.changeDepartment(
                                                              department
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            department.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "organizations",
                                            params: {
                                              lang: _vm.$route.params.lang,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c("b-button", {
                                          staticClass:
                                            "is-fullwidth all-org-btn",
                                          attrs: {
                                            type: "is-black",
                                            inverted: "",
                                            label: _vm.$t(
                                              "navbar.all_departments"
                                            ),
                                          },
                                          on: {
                                            click: _vm.closeDepartmentsMenu,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.isLoggedFromAdmin
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "is-flex is-mobile has-background-white-bis",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "column is-flex is-mobile",
                                              },
                                              [
                                                _c("b-tag", {
                                                  staticClass:
                                                    "pl-2 pr-0 has-background-grey-lighter mr-1",
                                                  attrs: {
                                                    size: "is-small",
                                                    icon: "long-arrow-alt-up",
                                                  },
                                                }),
                                                _c("b-tag", {
                                                  staticClass:
                                                    "pl-2 pr-0 has-background-grey-lighter",
                                                  attrs: {
                                                    size: "is-small",
                                                    icon: "long-arrow-alt-down",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "navbar.navigate"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "column is-flex is-mobile",
                                              },
                                              [
                                                _c(
                                                  "b-tag",
                                                  {
                                                    staticClass:
                                                      "has-background-grey-lighter px-2",
                                                    attrs: { size: "is-small" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("navbar.enter")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("navbar.select")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "navbar-end is-flex" }, [
              _vm.isLoggedFromAdmin && !_vm.isWizard
                ? _c("div", { staticClass: "admin_notice is-hidden-touch" }, [
                    _vm._v(" " + _vm._s(_vm.$t("navbar.adminNotice")) + " "),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.backToAdminInterface()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.adminNoticeLeave")))]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "loghub-theme navbar-item",
                  on: { click: _vm.toggleTheme },
                },
                [
                  _vm.selectedTheme === "dark"
                    ? _c("i", {
                        staticClass: "fas fa-sun is-size-4 is-size-6-mobile",
                      })
                    : _vm._e(),
                  _vm.selectedTheme === "light"
                    ? _c("i", {
                        staticClass: "fas fa-moon is-size-4 is-size-6-mobile",
                      })
                    : _vm._e(),
                ]
              ),
              _c("div", [
                _vm.routeHasHiddenSelect !== true || _vm.isOrganizations
                  ? _c(
                      "a",
                      {
                        staticClass: "navbar-burger",
                        class: { "is-active": _vm.isSideMenuVisible },
                        attrs: {
                          role: "button",
                          id: _vm.BURGER_BTN_ID,
                          "aria-label": "menu",
                          "aria-expanded": "false",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.mobileExpand()
                          },
                        },
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }),
                        _c("span", { attrs: { "aria-hidden": "true" } }),
                        _c("span", { attrs: { "aria-hidden": "true" } }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm.routeHasHiddenSelect !== true &&
              !_vm.isWizard &&
              !_vm.isOrganizations
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeNotificationMenu,
                          expression: "closeNotificationMenu",
                        },
                      ],
                      staticClass: "navbar-item has-dropdown is-hidden-touch",
                      class: { "is-active": _vm.showNotificationMenu },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "navbar-link",
                          on: {
                            click: function ($event) {
                              _vm.showNotificationMenu =
                                !_vm.showNotificationMenu
                              _vm.showUserMenu = false
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { id: "notifications-icon" } }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.notificationsCount)),
                            ]),
                            _c("i", {
                              staticClass:
                                "fas fa-bell is-size-4 is-size-6-mobile",
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "navbar-dropdown is-right navbar-notification-container",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "navbar-item multi-header" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tc(
                                      "component.header.notifications.count",
                                      _vm.notificationsCount
                                    )
                                  )
                                ),
                              ]),
                              _vm.notificationsCount == 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button",
                                      attrs: { disabled: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showNotificationMenu = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("generals.view_all")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "notificationsOrg",
                                          params: {
                                            lang: _vm.$route.params.lang,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button",
                                          attrs: {
                                            disabled:
                                              _vm.notificationsCount == 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showNotificationMenu = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("generals.view_all")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "navbar-item notif-item notifications-column",
                            },
                            [
                              _vm._l(
                                _vm.notifications || [],
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: item.notificationId },
                                    [
                                      _c("NotificationItem", {
                                        attrs: {
                                          notificationItem: item,
                                          dateTimeManager: _vm.dateTimeManager,
                                        },
                                        on: {
                                          notificationRemoved:
                                            _vm.notificationRemoved,
                                        },
                                      }),
                                      index < _vm.notifications.length - 1
                                        ? _c("hr")
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.isLoadingNotifications
                                ? _c("b-loading", {
                                    attrs: {
                                      "is-full-page": true,
                                      active: _vm.isLoadingNotifications,
                                      "can-cancel": false,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoadingNotifications = $event
                                      },
                                    },
                                  })
                                : _vm.notificationsCount >
                                  _vm.notifications.length
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button mt-2",
                                      attrs: { id: _vm.MORE_BTN_ID },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadNotifications(true)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("generals.view_more")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.routeHasHiddenSelect !== true || _vm.isOrganizations
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeUserMenu,
                          expression: "closeUserMenu",
                        },
                      ],
                      staticClass: "navbar-item has-dropdown is-hidden-touch",
                      class: { "is-active": _vm.showUserMenu },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "navbar-link",
                          on: {
                            click: function ($event) {
                              _vm.showUserMenu = !_vm.showUserMenu
                              _vm.showNotificationMenu = false
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-user is-size-4 is-size-6-mobile",
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "navbar-dropdown is-right" }, [
                        _c("div", { staticClass: "navbar-item credentials" }, [
                          _c(
                            "strong",
                            { attrs: { data: _vm.sessionPassword } },
                            [_vm._v(_vm._s(_vm.username))]
                          ),
                          _c("div", { staticClass: "divider" }),
                          _vm.selectedDepartment
                            ? _c("strong", [
                                _vm._v(_vm._s(_vm.selectedDepartment.name)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("hr", { staticClass: "navbar-divider" }),
                        _c("div", { staticClass: "navbar-item" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("navbar.language")) + ": "
                          ),
                          _vm.isCurrentLanguage(_vm.Languages.SLOVAK)
                            ? _c("span", { staticClass: "has-l-m" }, [
                                _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                              ])
                            : _c(
                                "a",
                                {
                                  staticClass: "has-l-m",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLanguage(
                                        _vm.Languages.SLOVAK
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("navbar.slovak")))]
                              ),
                          _vm.isCurrentLanguage(_vm.Languages.ENGLISH)
                            ? _c("span", { staticClass: "has-l-m" }, [
                                _vm._v(_vm._s(_vm.$t("navbar.english"))),
                              ])
                            : _c(
                                "a",
                                {
                                  staticClass: "has-l-m",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLanguage(
                                        _vm.Languages.ENGLISH
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("navbar.english")))]
                              ),
                          _vm.isCurrentLanguage(_vm.Languages.CZECH)
                            ? _c("span", { staticClass: "has-l-m" }, [
                                _vm._v(_vm._s(_vm.$t("navbar.czech"))),
                              ])
                            : _c(
                                "a",
                                {
                                  staticClass: "has-l-m",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLanguage(
                                        _vm.Languages.CZECH
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("navbar.czech")))]
                              ),
                        ]),
                        _c("hr", { staticClass: "navbar-divider" }),
                        _c("div", { staticClass: "navbar-item" }, [
                          _c(
                            "table",
                            { staticClass: "navbar-time-format-table" },
                            [
                              _c("tr", [
                                _c("td", { staticClass: "row-label" }, [
                                  _vm._v(_vm._s(_vm.$t("navbar.date_format"))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-field",
                                      [
                                        _c(
                                          "b-select",
                                          {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholders.select_format"
                                              ),
                                            },
                                            on: {
                                              input: _vm.dateFormatSelected,
                                            },
                                            model: {
                                              value: _vm.selectedDateFormat,
                                              callback: function ($$v) {
                                                _vm.selectedDateFormat = $$v
                                              },
                                              expression: "selectedDateFormat",
                                            },
                                          },
                                          _vm._l(
                                            _vm.DateFormats,
                                            function (option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option,
                                                  domProps: { value: option },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.dateTimeManager.formatTime(
                                                          _vm.actualTime,
                                                          _vm.selectedTimezone,
                                                          option,
                                                          ""
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "row-label" }, [
                                  _vm._v(_vm._s(_vm.$t("navbar.time_format"))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-field",
                                      [
                                        _c(
                                          "b-select",
                                          {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholders.select_format"
                                              ),
                                            },
                                            on: {
                                              input: _vm.clockFormatSelected,
                                            },
                                            model: {
                                              value: _vm.selectedClockFormat,
                                              callback: function ($$v) {
                                                _vm.selectedClockFormat = $$v
                                              },
                                              expression: "selectedClockFormat",
                                            },
                                          },
                                          _vm._l(
                                            _vm.ClockFormats,
                                            function (option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option,
                                                  domProps: { value: option },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(option) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "row-label" }, [
                                  _vm._v(_vm._s(_vm.$t("navbar.timezone"))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-field",
                                      [
                                        _c(
                                          "b-select",
                                          {
                                            attrs: {
                                              expanded: "",
                                              placeholder: _vm.$t(
                                                "placeholders.select_format"
                                              ),
                                            },
                                            on: { input: _vm.timezoneSelected },
                                            model: {
                                              value: _vm.selectedTimezone,
                                              callback: function ($$v) {
                                                _vm.selectedTimezone = $$v
                                              },
                                              expression: "selectedTimezone",
                                            },
                                          },
                                          _vm._l(
                                            _vm.TimeZones,
                                            function (option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option,
                                                  domProps: { value: option },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(option) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "row-label" }, [
                                  _vm._v(_vm._s(_vm.$t("navbar.tzInfo"))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-field",
                                      [
                                        _c("b-checkbox", {
                                          on: { input: _vm.showTzChanged },
                                          model: {
                                            value: _vm.selectedShowTzInfo,
                                            callback: function ($$v) {
                                              _vm.selectedShowTzInfo = $$v
                                            },
                                            expression: "selectedShowTzInfo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "row-label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("navbar.selected_format")) +
                                      " "
                                  ),
                                ]),
                                _c("td", [
                                  _c("p", { staticClass: "actual-time" }, [
                                    _vm._v(_vm._s(_vm.actualTimeFormatted)),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm.needsReload()
                          ? _c("div", { staticClass: "navbar-item" }, [
                              _c("p", { staticClass: "reload-alert" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("error_messages.page_reload")
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("hr", { staticClass: "navbar-divider" }),
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            on: { click: _vm.logoutAsync },
                          },
                          [
                            _vm._m(0),
                            _vm._v(
                              "   " + _vm._s(_vm.$t("navbar.logout")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-sign-out-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }