"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ServiceEntity {
    constructor(service) {
        this.name = service.name;
        this.sign = service.sign;
        this.id = service.id;
        this.axisPosition = service.axisPosition;
    }
}
exports.default = ServiceEntity;
