var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card logger-modal-header" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.wizard.wizardBoundaries.createGroup.title")
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "component.wizard.wizardBoundaries.createGroup.name_label"
              ),
              type: {
                "is-danger":
                  _vm.errors.has(
                    _vm.$t(
                      "component.wizard.wizardBoundaries.createGroup.name_label"
                    )
                  ) || _vm.nameExists,
              },
              message: _vm.nameExists
                ? _vm.$t("component.group.create.msg.unique_name")
                : _vm.errors.first(
                    _vm.$t(
                      "component.wizard.wizardBoundaries.createGroup.name_label"
                    )
                  ),
              horizontal: "",
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                placeholder: _vm.$t(
                  "component.wizard.wizardBoundaries.createGroup.name_label"
                ),
                name: _vm.$t(
                  "component.wizard.wizardBoundaries.createGroup.name_label"
                ),
              },
              on: { input: _vm.checkIfGroupNameExists },
              model: {
                value: _vm.groupName,
                callback: function ($$v) {
                  _vm.groupName = $$v
                },
                expression: "groupName",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot is-block" }, [
      _c("div", { staticClass: "level is-mobile" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                on: {
                  click: function ($event) {
                    return _vm.resultRetrieved()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.close")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                attrs: { type: "is-primary" },
                on: { click: _vm.createGroup },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.wizardBoundaries.createGroup.create"
                    )
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }