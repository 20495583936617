export class SourceService {
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => SourceService.define(et));
        }
        else if (data != null) {
            return SourceService.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let sourceService = new SourceService();
        sourceService.id = data.Id;
        sourceService.startTime = data.StartTime;
        sourceService.endTime = data.EndTime;
        sourceService.annotation = data.Annotation;
        sourceService.createAnnotation = data.CreateAnnotation;
        return sourceService;
    }
}
