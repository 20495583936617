var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("tiers.assign_tier")) + " "),
        ]),
      ]),
      !_vm.isLoading
        ? _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c(
                "b-steps",
                {
                  attrs: { "has-navigation": false },
                  on: {
                    input: function ($event) {
                      return _vm.stepChanged($event)
                    },
                  },
                  model: {
                    value: _vm.currentStep,
                    callback: function ($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep",
                  },
                },
                [
                  _c(
                    "b-step-item",
                    {
                      attrs: {
                        label: _vm.$t("tiers.assign_tier_steps.select"),
                        icon: "hand-pointer",
                        value: _vm.Steps.SELECT,
                        clickable: false,
                      },
                    },
                    [_c("div", { staticClass: "p-0" })]
                  ),
                  _vm.$ability.can(
                    _vm.$permActions.CUSTOMIZE,
                    _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
                  )
                    ? _c(
                        "b-step-item",
                        {
                          attrs: {
                            label: _vm.$t("tiers.assign_tier_steps.customize"),
                            icon: "edit",
                            value: _vm.Steps.CUSTOMIZE,
                            clickable: false,
                          },
                        },
                        [_c("div", { staticClass: "p-0" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.currentStep == _vm.Steps.SELECT
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-centered has-text-primary is-size-4 mb-4",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("tiers.select_tier_for_company")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "columns is-multiline tier-container is-mobile",
                      },
                      _vm._l(_vm.tiers, function (tier, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "column is-one-third-tablet is-three-quarters-mobile",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "has-text-centered box is-full-height tier-card",
                                class: [
                                  {
                                    selected:
                                      _vm.selectedTier &&
                                      _vm.selectedTier.id == tier.id,
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.tierSelected(tier)
                                  },
                                },
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "is-size-5 has-text-weight-bold has-text-primary",
                                  },
                                  [_vm._v(" " + _vm._s(tier.displayName) + " ")]
                                ),
                                _c("h4", [
                                  _vm._v("(" + _vm._s(tier.name) + ")"),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mt-4 has-text-weight-bold is-size-6 has-text-dark",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("tiers.features_list")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "has-text-dark has-text-left",
                                  },
                                  _vm._l(
                                    tier.tierFeatures,
                                    function (feature, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "is-size-6 is-italic",
                                          class:
                                            tier.enabledFeatures.find(
                                              (x) => x.Name == feature.Name
                                            ) == null
                                              ? "has-text-weight-light has-text-grey has-text-grey-dark-mode"
                                              : "has-text-weight-semibold",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "tiers.features.short." +
                                                    feature.Name
                                                )
                                              ) + " "
                                            ),
                                          ]),
                                          feature.Value.Quota
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(feature.Value.Quota)
                                                ),
                                                feature.Value.AllowOverflow
                                                  ? _c("span", [_vm._v("+")])
                                                  : _vm._e(),
                                                _vm._v(")"),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm.currentStep == _vm.Steps.CUSTOMIZE
                ? _c("div", [
                    _c("div", { staticClass: "has-text-centered" }, [
                      _c("p", { staticClass: "has-text-primary is-size-4" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("tiers.selected_tier")) + " "
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "p",
                          [
                            _vm._v(
                              " " + _vm._s(_vm.selectedTier.displayName) + " "
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "is-small",
                                  type: "is-primary",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeTier()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("tiers.change_tier")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "is-italic" }, [
                        _vm._v(_vm._s(_vm.$t("tiers.customize_info"))),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "pt-4" },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: _vm.$t("tiers.name"),
                              "label-position": "on-border",
                              type: {
                                "is-danger": _vm.errors.has(
                                  _vm.$t("tiers.name")
                                ),
                              },
                              message: _vm.errors.first(_vm.$t("tiers.name")),
                            },
                          },
                          [
                            _c("b-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                placeholder: _vm.$t("tiers.name"),
                                type: "text",
                                name: _vm.$t("tiers.name"),
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "has-text-weight-bold is-size-5" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("tiers.features_list")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-1" },
                            _vm._l(
                              _vm.selectedTier.tierFeatures,
                              function (feature, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-1",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pr-2",
                                        class: {
                                          "has-text-primary has-text-weight-bold":
                                            _vm.selectedTier.enabledFeatures.find(
                                              (x) => x.Name === feature.Name
                                            ) != null,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "tiers.features." + feature.Name
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "is-flex is-flex-direction-row is-align-items-center",
                                      },
                                      [
                                        feature.Type === _vm.FeaturesTypes.BOOL
                                          ? _c(
                                              "b-field",
                                              [
                                                _c(
                                                  "b-switch",
                                                  {
                                                    model: {
                                                      value:
                                                        feature.Value.Allowed,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          feature.Value,
                                                          "Allowed",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "feature.Value.Allowed",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "tiers.features.allowed"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        feature.Type === _vm.FeaturesTypes.QUOTA
                                          ? _c(
                                              "b-field",
                                              {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  "label-position": "on-border",
                                                  label: _vm.$t(
                                                    "tiers.features.quota"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-numberinput", {
                                                  attrs: { controls: false },
                                                  model: {
                                                    value: feature.Value.Quota,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        feature.Value,
                                                        "Quota",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "feature.Value.Quota",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  { staticClass: "control" },
                                                  [
                                                    _c("b-button", {
                                                      attrs: {
                                                        type: "is-primary",
                                                        label: _vm.$t(
                                                          "tiers.features.unlimited"
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setUnlimited(
                                                            feature
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        feature.Type ===
                                        _vm.FeaturesTypes.MONTHLY
                                          ? _c(
                                              "b-field",
                                              {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  "label-position": "on-border",
                                                  label: _vm.$t(
                                                    "tiers.features.quota"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-numberinput", {
                                                  attrs: { controls: false },
                                                  model: {
                                                    value: feature.Value.Quota,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        feature.Value,
                                                        "Quota",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "feature.Value.Quota",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        feature.Type ===
                                        _vm.FeaturesTypes.MONTHLY
                                          ? _c(
                                              "b-field",
                                              { staticClass: "ml-2" },
                                              [
                                                _c(
                                                  "b-switch",
                                                  {
                                                    model: {
                                                      value:
                                                        feature.Value
                                                          .AllowOverflow,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          feature.Value,
                                                          "AllowOverflow",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "feature.Value.AllowOverflow",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "tiers.features.allowOverflow"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", disabled: _vm.selectedTier == null },
              on: {
                click: function ($event) {
                  return _vm.assignTier()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("tiers.assign_tier")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }