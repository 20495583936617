var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "nav",
        { staticClass: "breadcrumb", attrs: { "aria-label": "breadcrumb" } },
        [
          _c("h1", { staticClass: "title has-text-centered-touch" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({
                          name: "adminTiers",
                          params: { lang: _vm.$route.params.lang },
                        })
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-chevron-left is-size-5",
                      staticStyle: { "padding-right": "10px" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("tiers.management_title")) + " "
                    ),
                  ]
                ),
              ]),
              _c("li", { staticClass: "is-active" }, [
                _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                  _vm._v(_vm._s(_vm.$t("tiers.create_tier"))),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_TIERS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("CreateTier", {
                      staticClass: "tile is-child box",
                      attrs: { tierFeatures: _vm.tierFeatures },
                      on: { createTier: _vm.createTier },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }