var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("admin.component.loggers.generateStickersModal.title")
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.type"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.generateStickersModal.type")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.type")
              ),
            },
          },
          [
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.type"
                  ),
                  "native-value": _vm.LoggerType.CCL,
                },
                on: { input: _vm.typeSelected },
                model: {
                  value: _vm.loggerType,
                  callback: function ($$v) {
                    _vm.loggerType = $$v
                  },
                  expression: "loggerType",
                },
              },
              [_vm._v(_vm._s(_vm.$t("logger_types.ccl")))]
            ),
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.type"
                  ),
                  "native-value": _vm.LoggerType.TEMPEGG,
                },
                on: { input: _vm.typeSelected },
                model: {
                  value: _vm.loggerType,
                  callback: function ($$v) {
                    _vm.loggerType = $$v
                  },
                  expression: "loggerType",
                },
              },
              [_vm._v(_vm._s(_vm.$t("logger_types.tempegg")))]
            ),
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.type"
                  ),
                  "native-value": _vm.LoggerType.MINI,
                },
                on: { input: _vm.typeSelected },
                model: {
                  value: _vm.loggerType,
                  callback: function ($$v) {
                    _vm.loggerType = $$v
                  },
                  expression: "loggerType",
                },
              },
              [_vm._v(_vm._s(_vm.$t("logger_types.mini")))]
            ),
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.type"
                  ),
                  "native-value": _vm.LoggerType.LITE,
                },
                on: { input: _vm.typeSelected },
                model: {
                  value: _vm.loggerType,
                  callback: function ($$v) {
                    _vm.loggerType = $$v
                  },
                  expression: "loggerType",
                },
              },
              [_vm._v(_vm._s(_vm.$t("logger_types.lite")))]
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.services"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t(
                    "admin.component.loggers.generateStickersModal.services"
                  )
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.services")
              ),
            },
          },
          [
            _c(
              "b-checkbox-button",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.services"
                  ),
                  "native-value": _vm.Services.TEM,
                },
                on: { input: _vm.serviceSelected },
                model: {
                  value: _vm.selectedServices,
                  callback: function ($$v) {
                    _vm.selectedServices = $$v
                  },
                  expression: "selectedServices",
                },
              },
              [_vm._v(_vm._s(_vm.$t("services.temperature")))]
            ),
            _c(
              "b-checkbox-button",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.services"
                  ),
                  "native-value": _vm.Services.HUM,
                  disabled: _vm.loggerType == _vm.LoggerType.TEMPEGG,
                },
                on: { input: _vm.serviceSelected },
                model: {
                  value: _vm.selectedServices,
                  callback: function ($$v) {
                    _vm.selectedServices = $$v
                  },
                  expression: "selectedServices",
                },
              },
              [_vm._v(_vm._s(_vm.$t("services.humidity")))]
            ),
            _c(
              "b-checkbox-button",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.services"
                  ),
                  "native-value": _vm.Services.SHO,
                  disabled: _vm.loggerType == _vm.LoggerType.TEMPEGG,
                },
                on: { input: _vm.serviceSelected },
                model: {
                  value: _vm.selectedServices,
                  callback: function ($$v) {
                    _vm.selectedServices = $$v
                  },
                  expression: "selectedServices",
                },
              },
              [_vm._v(_vm._s(_vm.$t("services.shock")))]
            ),
            _c(
              "b-checkbox-button",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "admin.component.loggers.generateStickersModal.services"
                  ),
                  "native-value": _vm.Services.PRE,
                  disabled: _vm.loggerType == _vm.LoggerType.TEMPEGG,
                },
                on: { input: _vm.serviceSelected },
                model: {
                  value: _vm.selectedServices,
                  callback: function ($$v) {
                    _vm.selectedServices = $$v
                  },
                  expression: "selectedServices",
                },
              },
              [_vm._v(_vm._s(_vm.$t("services.pressure")))]
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.custom"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.generateStickersModal.custom")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.custom")
              ),
            },
          },
          [
            _c("b-switch", {
              attrs: {
                name: _vm.$t(
                  "admin.component.loggers.generateStickersModal.custom"
                ),
              },
              model: {
                value: _vm.isCustom,
                callback: function ($$v) {
                  _vm.isCustom = $$v
                },
                expression: "isCustom",
              },
            }),
          ],
          1
        ),
        _vm.isCustom
          ? _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.loggers.generateStickersModal.startNumber"
                  ),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t(
                        "admin.component.loggers.generateStickersModal.startNumber"
                      )
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t(
                      "admin.component.loggers.generateStickersModal.startNumber"
                    )
                  ),
                },
              },
              [
                _c("b-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric|min_value:1",
                      expression: "'required|numeric|min_value:1'",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(
                      "admin.component.loggers.generateStickersModal.startNumber"
                    ),
                    name: _vm.$t(
                      "admin.component.loggers.generateStickersModal.startNumber"
                    ),
                  },
                  model: {
                    value: _vm.startNumber,
                    callback: function ($$v) {
                      _vm.startNumber = $$v
                    },
                    expression: "startNumber",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.count"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.generateStickersModal.count")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.count")
              ),
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|numeric|min_value:1",
                  expression: "'required|numeric|min_value:1'",
                },
              ],
              attrs: {
                placeholder: _vm.$t(
                  "admin.component.loggers.generateStickersModal.count"
                ),
                name: _vm.$t(
                  "admin.component.loggers.generateStickersModal.count"
                ),
              },
              model: {
                value: _vm.count,
                callback: function ($$v) {
                  _vm.count = $$v
                },
                expression: "count",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.line1"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.generateStickersModal.line1")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.line1")
              ),
            },
          },
          [
            _c("b-input", {
              attrs: {
                type: "text",
                name: _vm.$t(
                  "admin.component.loggers.generateStickersModal.line1"
                ),
              },
              on: { input: _vm.line1Changed },
              model: {
                value: _vm.line1,
                callback: function ($$v) {
                  _vm.line1 = $$v
                },
                expression: "line1",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.generateStickersModal.line2"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.generateStickersModal.line2")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.generateStickersModal.line2")
              ),
            },
          },
          [
            _c("b-input", {
              attrs: {
                type: "text",
                name: _vm.$t(
                  "admin.component.loggers.generateStickersModal.line2"
                ),
              },
              model: {
                value: _vm.line2,
                callback: function ($$v) {
                  _vm.line2 = $$v
                },
                expression: "line2",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.downloadFile()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t("admin.component.loggers.generateStickersModal.btn")
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }