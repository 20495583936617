import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let AllowedTypesModal = class AllowedTypesModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedEventTypes = [];
        this.isLoading = true;
    }
    async created() {
        this.selectedEventTypes = this.companyLogger.allowedTypes.map((x) => x.slug);
    }
    async selected() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.$emit('typesSelected', this.selectedEventTypes);
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], AllowedTypesModal.prototype, "companyLogger", void 0);
AllowedTypesModal = __decorate([
    Component({})
], AllowedTypesModal);
export default AllowedTypesModal;
