export var LoggerServiceLength;
(function (LoggerServiceLength) {
    LoggerServiceLength[LoggerServiceLength["QUARTER_HOUR"] = 15] = "QUARTER_HOUR";
    LoggerServiceLength[LoggerServiceLength["HALF_HOUR"] = 30] = "HALF_HOUR";
    LoggerServiceLength[LoggerServiceLength["ONE_HOUR"] = 60] = "ONE_HOUR";
    LoggerServiceLength[LoggerServiceLength["TWO_HOURS"] = 120] = "TWO_HOURS";
    LoggerServiceLength[LoggerServiceLength["FOUR_HOURS"] = 240] = "FOUR_HOURS";
    LoggerServiceLength[LoggerServiceLength["EIGHT_HOURS"] = 480] = "EIGHT_HOURS";
    LoggerServiceLength["RANGE"] = "range";
    LoggerServiceLength["UNKNOWN"] = "unknown";
})(LoggerServiceLength || (LoggerServiceLength = {}));
