var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-notification", {
    staticClass: "always-in-view",
    attrs: {
      type: "is-info",
      "has-icon": "",
      "aria-close-label": "Close notification",
      icon: "question-circle",
      indefinite: "",
      message: _vm.text,
    },
    on: { close: _vm.notificationClosed },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }