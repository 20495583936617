import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UnitsSettings from '@/views/components/profile/UnitsSettings.vue';
import UserInfoComponent from '@/views/components/profile/UserInfoComponent.vue';
import UserSettingsComponent from '@/views/components/profile/UserSettingsComponent.vue';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import UserRepository from '@/services/repository/UserRepository';
import DateTimeManager from '@/services/DateTimeManager';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import LoggedUserManager from '@/services/LoggedUserManager';
import { UserSettings } from '@/entities/UserSettings';
import VueUtilities from '@/services/VueUtilities';
import EventDomain from '@/entities/enums/eventDomains';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { DepartmentUserSettings } from '@/entities/DepartmentUserSettings';
import { vxm } from '@/store/store.vuex';
import FeedbackCategories from '@/entities/enums/FeedbackCategories';
import SalesRepository from '@/services/repository/SalesRepository';
import UnitNames from '@/entities/enums/UnitNames';
var eventTypeRepository;
var userSettingsRepository;
var userRepository;
var authenticationCalls;
var salesRepository;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
let ProfileManagement = class ProfileManagement extends Vue {
    constructor() {
        super(...arguments);
        this.UnitNames = UnitNames;
        this.units = null;
        this.settings = new UserSettings();
        this.departmentUserSettings = new DepartmentUserSettings();
        this.currentUser = null;
        this.isLoading = true;
        this.userRequestPending = false;
        this.unitRequestPending = false;
        this.eventTypes = [];
    }
    async created() {
        eventTypeRepository = EventTypeRepository.getInstance(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
        authenticationCalls = new AuthenticationCalls(this);
        salesRepository = new SalesRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData(reloadUserSettingsForDepartment = false) {
        this.isLoading = true;
        this.currentUser = await userRepository.getCurrentUser();
        let allPromises = [];
        allPromises.push(userSettingsRepository.loadUserSettings());
        allPromises.push(eventTypeRepository.getAllUnits());
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser.apiUserId, reloadUserSettingsForDepartment));
        allPromises.push(eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true));
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3]);
        });
    }
    processLoadedDataForPage(settings, units, departmentUserSettings, eventTypes) {
        this.settings = settings;
        this.eventTypes = eventTypes;
        this.units = units;
        this.departmentUserSettings = departmentUserSettings;
        this.isLoading = false;
    }
    async updateUser(fullName) {
        let data = {
            FullName: fullName
        };
        let updatedUser = await userRepository.updateCurrentUser(data);
        if (updatedUser) {
            this.currentUser = updatedUser;
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_info.updateUser.success').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_info.updateUser.failure').toString());
        }
    }
    async reloadUser() {
        this.isLoading = true;
        let user = await userRepository.getCurrentUser();
        this.currentUser = user;
        this.isLoading = false;
    }
    unitChanged(type, valueIdentifier) {
        this.unitRequestPending = true;
        userSettingsRepository
            .saveUserSetting([UserSettingsKeys.EVENTS, UserSettingsKeys.UNITS, type.type.toString()], valueIdentifier)
            .then((res) => {
            let serviceName = type.slug == UnitNames.TEMPERATURE.name
                ? this.$t(`services.${type.slug}`) + ' / ' + this.$t(`services.dewpoint`)
                : this.$t(`services.${type.slug}`);
            this.unitRequestPending = false;
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.units.unitChangeSuccessMsg', {
                service: serviceName
            }));
        })
            .catch((err) => {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.units.unitChangeFailureMsg').toString());
            console.log(err);
        });
    }
    async languageChanged(language) {
        this.userRequestPending = true;
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.LANGUAGE], language);
        this.userRequestPending = false;
        this.$router
            .push({
            name: this.$route.name,
            params: {
                lang: this.settings.language,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    async measurementSortChanged(sort) {
        this.userRequestPending = true;
        this.settings = await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.MEASUREMENT_SORT], sort);
        this.userRequestPending = false;
    }
    async filterRecordsChanged(filter) {
        this.userRequestPending = true;
        this.departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.REPORTS, UserSettingsKeys.RECORDS, UserSettingsKeys.FILTER_BY_BOUNDARY], filter);
        this.userRequestPending = false;
    }
    async refreshIntervalChanged(value) {
        this.userRequestPending = true;
        this.departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser.apiUserId, [UserSettingsKeys.PREFERENCES, UserSettingsKeys.EVENTS_REFRESH, UserSettingsKeys.INTERVAL], value);
        this.userRequestPending = false;
    }
    async signOut() {
        let result = await authenticationCalls.logout();
        this.handleSignoutResult(result);
    }
    async signOutAllDevices() {
        let result = await authenticationCalls.deleteUserSessions();
        this.handleSignoutResult(result);
    }
    async changePassword(oldPwd, newPwd) {
        let success = await authenticationCalls.changeCurrentUserPassword(oldPwd, newPwd);
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_settings.change_password.success').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_settings.change_password.failure').toString());
        }
    }
    handleSignoutResult(result) {
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router
                .push({
                name: 'home',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.$route.params.departmentId
                }
            })
                .catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    async dateFormatSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedDateFormat(val);
        this.userRequestPending = false;
    }
    async clockFormatSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedClockFormat(val);
        this.userRequestPending = false;
    }
    async timezoneSelected(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setSelectedTimezone(val);
        this.userRequestPending = false;
    }
    async showTzChanged(val) {
        this.userRequestPending = true;
        await this.dateTimeManager.setTzInClockFormatPreference(val);
        this.userRequestPending = false;
    }
    async deleteAccount() {
        let data = {
            Text: this.$t('component.settings.profile.user_settings.delete_account.text'),
            Category: FeedbackCategories.USER_DELETE_REQUEST.name,
            Subject: this.$t('component.settings.profile.user_settings.delete_account.title')
        };
        let result = await salesRepository.sendFeedback(data);
        if (result === true) {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_settings.delete_account.success'));
        }
    }
};
ProfileManagement = __decorate([
    Component({
        beforeRouteUpdate(to, from, next) {
            this.loadData(true);
            next(); // to resolve hook
        },
        components: { UnitsSettings, UserInfoComponent, UserSettingsComponent }
    })
], ProfileManagement);
export default ProfileManagement;
