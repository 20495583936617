var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "is-flex is-mobile is-justify-content-space-between" },
        [
          _c(
            "h2",
            { staticClass: "subtitle is-4 is-spaced has-text-left mb-4 mr-2" },
            [
              _vm.dashboard.name
                ? _c("span", [_vm._v(" " + _vm._s(_vm.dashboard.name) + " ")])
                : _c(
                    "span",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.public_dashboard.dashboard.title")
                          ) +
                          " "
                      ),
                      _vm.dateTimeManager
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(_vm.dashboard.createdAt * 1000),
                                    null,
                                    null,
                                    null
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
            ]
          ),
          _c(
            "div",
            [
              !_vm.isEditing
                ? _c("b-button", {
                    attrs: { "icon-right": "pencil-alt" },
                    on: {
                      click: function ($event) {
                        return _vm.editPublicDashboard()
                      },
                    },
                  })
                : _c("b-button", {
                    attrs: { "icon-left": "times" },
                    on: {
                      click: function ($event) {
                        return _vm.editPublicDashboard()
                      },
                    },
                  }),
            ],
            1
          ),
        ]
      ),
      _c("b-message", { staticClass: "mt-3", attrs: { type: "is-primary" } }, [
        _vm.dashboard.hasSourcesOrGroups()
          ? _c(
              "span",
              [
                _c("router-link", {
                  attrs: {
                    to: {
                      name: "publicDashboard",
                      params: {
                        dashboardKey: _vm.dashboard.dashboardKey,
                        lang: _vm.$route.params.lang,
                      },
                    },
                    custom: "",
                    target: "_blank",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ href }) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "is-italic is-size-6 has-text-primary",
                                staticStyle: { "overflow-wrap": "break-word" },
                                attrs: { href: href },
                              },
                              [_vm._v(_vm._s(_vm.origin) + _vm._s(href))]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                _c("qrcode-vue", {
                                  ref: "qrcode",
                                  attrs: {
                                    background: "#fdf6f7",
                                    value: _vm.origin + href,
                                    size: 300,
                                    level: "H",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    480547248
                  ),
                }),
                _c(
                  "div",
                  { staticClass: "buttons mt-5" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-primary",
                          "icon-pack": "fa",
                          "icon-left": "download",
                        },
                        on: { click: _vm.downloadQrCode },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.public_dashboard.dashboard.download_qr"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-primary",
                          "icon-pack": "fa",
                          "icon-left": "download",
                        },
                        on: { click: _vm.downloadPdf },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "component.public_dashboard.dashboard.download_pdf"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "span",
              { staticClass: "has-text-weight-bold has-text-primary" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.public_dashboard.dashboard_detail.no_sources"
                      )
                    ) +
                    " "
                ),
              ]
            ),
      ]),
      _c("div", { staticClass: "columns columns-info-form mt-5" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.dashboard_title"
                  ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("p", [
                      _vm.dashboard.name
                        ? _c("span", [_vm._v(_vm._s(_vm.dashboard.name))])
                        : _c("span", [_vm._v("-")]),
                    ])
                  : _c("b-input", {
                      attrs: {
                        expanded: "",
                        placeholder: _vm.$t(
                          "component.public_dashboard.create.title_placeholder"
                        ),
                      },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.id"
                  ),
                },
              },
              [_c("p", [_vm._v(" " + _vm._s(_vm.dashboard.id) + " ")])]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.created_at"
                  ),
                },
              },
              [_c("p", [_vm._v(_vm._s(_vm.dashboard.createdBy.fullName))])]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.created_by"
                  ),
                },
              },
              [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(_vm.dashboard.createdAt * 1000)
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.components"
                  ),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t("component.public_dashboard.create.components")
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t("component.public_dashboard.create.components")
                  ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dashboard.allowedComponents
                              .filter(
                                (x) =>
                                  x != _vm.PublicDashboardComponents.ANNOTATIONS
                              )
                              .map((x) =>
                                _vm.$t(
                                  "component.public_dashboard.allowedComponents." +
                                    x
                                )
                              )
                              .join(", ")
                          ) +
                          " "
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.LAST_MEASUREMENTS,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents
                                        .LAST_MEASUREMENTS
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.CHART,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents.CHART
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "my-1",
                            attrs: {
                              "native-value":
                                _vm.PublicDashboardComponents.REPORTS,
                              name: _vm.$t(
                                "component.public_dashboard.create.components"
                              ),
                            },
                            model: {
                              value: _vm.allowedComponents,
                              callback: function ($$v) {
                                _vm.allowedComponents = $$v
                              },
                              expression: "allowedComponents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.public_dashboard.allowedComponents." +
                                      _vm.PublicDashboardComponents.REPORTS
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            ),
            _vm.allowedComponents.includes(
              _vm.PublicDashboardComponents.REPORTS
            )
              ? _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t(
                        "component.public_dashboard.create.show_annotations"
                      ),
                      horizontal: "",
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c("span", [
                          _vm.allowedComponents.includes(
                            _vm.PublicDashboardComponents.ANNOTATIONS
                          )
                            ? _c("i", { staticClass: "fas fa-check" })
                            : _c("i", { staticClass: "fas fa-times" }),
                        ])
                      : _c("b-switch", {
                          attrs: {
                            "native-value":
                              _vm.PublicDashboardComponents.ANNOTATIONS,
                          },
                          model: {
                            value: _vm.showAnnotations,
                            callback: function ($$v) {
                              _vm.showAnnotations = $$v
                            },
                            expression: "showAnnotations",
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.public_dashboard.dashboard_detail.public"
                  ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("span", [
                      _vm.dashboard.isPublic
                        ? _c("i", { staticClass: "fas fa-check" })
                        : _c("i", { staticClass: "fas fa-times" }),
                    ])
                  : _c("b-switch", {
                      model: {
                        value: _vm.isPublic,
                        callback: function ($$v) {
                          _vm.isPublic = $$v
                        },
                        expression: "isPublic",
                      },
                    }),
              ],
              1
            ),
            (!_vm.isEditing && !_vm.dashboard.isPublic) ||
            (_vm.isEditing && !_vm.isPublic)
              ? _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.isEditing
                        ? _vm.$t(
                            "component.public_dashboard.dashboard_detail.editing.selectUsers"
                          )
                        : _vm.$t(
                            "component.public_dashboard.dashboard_detail.users"
                          ),
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c(
                          "ul",
                          _vm._l(_vm.dashboard.userList, function (userId) {
                            return _c("li", { key: userId }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.users.find((x) => x.apiUserId == userId)
                                      .username
                                  ) +
                                  " "
                              ),
                            ])
                          }),
                          0
                        )
                      : _c(
                          "b-table",
                          {
                            staticClass: "select-location-table border",
                            attrs: {
                              data: _vm.users,
                              "checked-rows": _vm.selectedUsers,
                              paginated: true,
                              "per-page": 5,
                              narrowed: "",
                              checkable: "",
                              "pagination-simple": true,
                            },
                            on: {
                              "update:checkedRows": function ($event) {
                                _vm.selectedUsers = $event
                              },
                              "update:checked-rows": function ($event) {
                                _vm.selectedUsers = $event
                              },
                            },
                          },
                          [
                            _c("b-table-column", {
                              attrs: {
                                field: "name",
                                label: _vm.$t(
                                  "component.public_dashboard.create.name"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (users) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(users.row.username)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                877991824
                              ),
                            }),
                            _c("template", { slot: "bottom-left" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.public_dashboard.create.selected_count"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                " : " + _vm._s(_vm.selectedUsers.length) + " "
                              ),
                            ]),
                          ],
                          2
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.isEditing
                    ? _vm.$t(
                        "component.public_dashboard.dashboard_detail.editing.selectSources"
                      )
                    : _vm.$t(
                        "component.public_dashboard.dashboard_detail.sources"
                      ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("span", [
                      _vm.dashboard.sourceList &&
                      _vm.dashboard.sourceList.length > 0
                        ? _c(
                            "ul",
                            _vm._l(
                              _vm.dashboard.sourceList,
                              function (sourceId) {
                                return _c("li", { key: sourceId }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.sources.find(
                                            (x) => x.id == sourceId
                                          ) || {}
                                        ).name
                                      ) +
                                      " "
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("span", [_vm._v("-")]),
                    ])
                  : _c(
                      "b-table",
                      {
                        staticClass: "select-location-table border",
                        attrs: {
                          data: _vm.sources,
                          "checked-rows": _vm.selectedSources,
                          paginated: true,
                          "per-page": 5,
                          narrowed: "",
                          checkable: "",
                          "pagination-simple": true,
                        },
                        on: {
                          "update:checkedRows": function ($event) {
                            _vm.selectedSources = $event
                          },
                          "update:checked-rows": function ($event) {
                            _vm.selectedSources = $event
                          },
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            field: "name",
                            label: _vm.$t(
                              "component.public_dashboard.create.name"
                            ),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (sources) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(sources.row.name)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("template", { slot: "bottom-left" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.public_dashboard.create.selected_count"
                                )
                              )
                            ),
                          ]),
                          _vm._v(
                            " : " + _vm._s(_vm.selectedSources.length) + " "
                          ),
                        ]),
                      ],
                      2
                    ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.isEditing
                    ? _vm.$t(
                        "component.public_dashboard.dashboard_detail.editing.selectGroups"
                      )
                    : _vm.$t(
                        "component.public_dashboard.dashboard_detail.groups"
                      ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("span", [
                      _vm.dashboard.sourceGroupList.length > 0
                        ? _c(
                            "ul",
                            _vm._l(
                              _vm.dashboard.sourceGroupList,
                              function (groupId) {
                                return _c("li", { key: groupId }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.sourceGroups.find(
                                          (x) => x.id == groupId
                                        ).name
                                      ) +
                                      " "
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("span", [_vm._v("-")]),
                    ])
                  : _c(
                      "b-table",
                      {
                        staticClass: "select-location-table border",
                        attrs: {
                          data: _vm.sourceGroups,
                          "checked-rows": _vm.selectedSourceGroups,
                          paginated: true,
                          "per-page": 5,
                          narrowed: "",
                          checkable: "",
                          "pagination-simple": true,
                        },
                        on: {
                          "update:checkedRows": function ($event) {
                            _vm.selectedSourceGroups = $event
                          },
                          "update:checked-rows": function ($event) {
                            _vm.selectedSourceGroups = $event
                          },
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            field: "name",
                            label: _vm.$t(
                              "component.public_dashboard.create.name"
                            ),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (groups) {
                                return [
                                  _c("span", [_vm._v(_vm._s(groups.row.name))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("template", { slot: "bottom-left" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.public_dashboard.create.selected_count"
                                )
                              )
                            ),
                          ]),
                          _vm._v(
                            " : " +
                              _vm._s(_vm.selectedSourceGroups.length) +
                              " "
                          ),
                        ]),
                      ],
                      2
                    ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.isEditing
                    ? _vm.$t(
                        "component.public_dashboard.dashboard_detail.editing.selectDevices"
                      )
                    : _vm.$t(
                        "component.public_dashboard.dashboard_detail.devices"
                      ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("span", [
                      _vm.dashboard.gatewayList.length > 0
                        ? _c(
                            "ul",
                            _vm._l(
                              _vm.dashboard.gatewayList,
                              function (deviceId) {
                                return _c("li", { key: deviceId }, [
                                  _vm.getDeviceName(deviceId)
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getDeviceName(deviceId)
                                                .deviceName
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "span",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.public_dashboard.dashboard_detail.unknown"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "b-tag",
                                            {
                                              attrs: {
                                                type: "is-primary",
                                                rounded: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.public_dashboard.dashboard_detail.deviceRemoved"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("span", [_vm._v("-")]),
                    ])
                  : _c(
                      "b-table",
                      {
                        staticClass: "select-location-table border",
                        attrs: {
                          data: _vm.devices,
                          "checked-rows": _vm.selectedDevices,
                          paginated: true,
                          "per-page": 5,
                          narrowed: "",
                          checkable: "",
                          "pagination-simple": true,
                        },
                        on: {
                          "update:checkedRows": function ($event) {
                            _vm.selectedDevices = $event
                          },
                          "update:checked-rows": function ($event) {
                            _vm.selectedDevices = $event
                          },
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            field: "name",
                            label: _vm.$t(
                              "component.public_dashboard.create.name"
                            ),
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (devices) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(devices.row.deviceName)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("template", { slot: "bottom-left" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.public_dashboard.create.selected_count"
                                )
                              )
                            ),
                          ]),
                          _vm._v(
                            " : " + _vm._s(_vm.selectedDevices.length) + " "
                          ),
                        ]),
                      ],
                      2
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.isEditing
        ? _c(
            "div",
            { staticClass: "has-text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-3",
                  on: {
                    click: function ($event) {
                      return _vm.editPublicDashboard()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.cancel")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePublicDashboard()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.save")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }