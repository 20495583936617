import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import NotificationCard from '@/views/components/notification/NotificationCard.vue';
import OrganizationCard from '@/views/components/organization/OrganizationCard.vue';
import NotificationRepository from '@/services/repository/NotificationRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import DateTimeManager from '@/services/DateTimeManager';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import LoggedUserManager from '@/services/LoggedUserManager';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import VueUtilities from '@/services/VueUtilities';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { vxm } from '@/store/store.vuex';
import InvitationCard from '@/views/components/invitation/InvitationCard.vue';
import UserRepository from '@/services/repository/UserRepository';
const MAX_RECENT_NOTIFICATIONS = 5;
var notificationRepository;
var userSettingsRepository;
var departmentRepository;
var userRepository;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
let OrgHomePage = class OrgHomePage extends Vue {
    constructor() {
        super(...arguments);
        this.notifications = [];
        this.notificationsCount = 0;
        this.isLoading = true;
        this.pauseNotificationsUntil = 0;
        this.favoriteDepartmentId = null;
        this.deptId = null;
    }
    get notificationsPaused() {
        let now = Math.floor(new Date().getTime() / 1000);
        return this.pauseNotificationsUntil > now;
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    getFavoriteDepartmentId() {
        return vxDepartmentStore.favoriteDepartment
            ? vxDepartmentStore.favoriteDepartment.id
            : vxDepartmentStore.selectedDepartment.id;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        notificationRepository = new NotificationRepository(this);
        departmentRepository = new DepartmentRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        userRepository = new UserRepository(this);
        await this.loadDataForPage();
        if (this.$route.query?.redirect === 'true') {
            this.$router.replace({
                name: 'dashboard',
                params: {
                    lang: this.$route.params.lang,
                    departmentId: this.getFavoriteDepartmentId()
                }
            });
        }
        if (this.$route.query?.deptId) {
            this.deptId = this.$route.query?.deptId.toString();
            const element = document.getElementById('invitation');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    async loadDataForPage() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(this.loadNotifications());
        allPromises.push(userSettingsRepository.loadUserSettings());
        if (this.isLoggedFromAdmin) {
            allPromises.push(departmentRepository.getAllDepartmentsAdmin());
        }
        else {
            allPromises.push(departmentRepository.getDepartments());
        }
        allPromises.push(userRepository.getInvitationsList());
        if (this.isLoggedFromAdmin) {
            allPromises.push(departmentRepository.getAllDepartmentIssuesAdmin());
        }
        else {
            allPromises.push(departmentRepository.getDepartmentIssues());
        }
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[1], response[2], response[3], response[4]);
        });
    }
    processLoadedDataForPage(settings, departments, invitations, issuesSummary) {
        this.loadNotificationsConfig();
        this.pauseNotificationsUntil = parseInt(settings.notifications.pause.until);
        this.favoriteDepartmentId = settings.preferences.favoriteDepartmentId;
        this.invitations = invitations.filter((x) => Date.now() <= x.validUntil * 1000);
        this.issuesSummary = issuesSummary;
        if (this.isLoggedFromAdmin) {
            this.departments = AdminDepartment.convertToDepartmentsArray(departments.getData());
        }
        else {
            this.departments = departments.getData();
            vxDepartmentStore.departments = this.departments.getData();
        }
        this.isLoading = false;
    }
    loadNotificationsConfig() {
        this.notifications = vxNotificationStore.notifications;
        this.notificationsCount = vxNotificationStore.totalSize;
    }
    async loadNotifications() {
        let pagination = {
            page: 1,
            pageSize: MAX_RECENT_NOTIFICATIONS
        };
        return notificationRepository.loadNotificationsToStore(pagination);
    }
    async notificationRemoved(notifIds) {
        await notificationRepository.markNotificationsAsRead(notifIds);
        vxNotificationStore.clearData();
        await this.loadNotifications();
        this.loadNotificationsConfig();
    }
    async favoriteSelected(id) {
        await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.FAVORITE_DEPARTMENT_ID], id);
        VueUtilities.openSuccessToast(this, this.$t('component.settings.administration.update_success'));
        this.favoriteDepartmentId = id;
        vxDepartmentStore.setSelectedDepartmentById(id);
    }
    async acceptInvite(invitationId) {
        await userRepository.acceptInvitation(invitationId);
        VueUtilities.openSuccessToast(this, this.$t('component.invitation.accept_success'));
        this.loadDataForPage();
    }
    async declineInvite(invitationId) {
        await userRepository.declineInvitation(invitationId);
        VueUtilities.openSuccessToast(this, this.$t('component.invitation.decline_success'));
        this.loadDataForPage();
    }
};
OrgHomePage = __decorate([
    Component({
        components: {
            InvitationCard,
            OrganizationCard,
            NotificationCard
        }
    })
], OrgHomePage);
export default OrgHomePage;
