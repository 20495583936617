var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
      },
      [
        _c("h2", { staticClass: "subtitle is-4 mb-5" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.companyAdministration.info.title")) +
              " "
          ),
        ]),
        [
          !_vm.isEditing
            ? _c("b-button", {
                attrs: { "icon-right": "pencil-alt", type: "is-white" },
                on: {
                  click: function ($event) {
                    return _vm.editCompanyInfo()
                  },
                },
              })
            : _c(
                "b-button",
                {
                  staticClass: "ml-1",
                  attrs: { "icon-left": "times-circle", type: "is-white" },
                  on: {
                    click: function ($event) {
                      return _vm.editCompanyInfo()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")))]
              ),
        ],
      ],
      2
    ),
    _c("div", { staticClass: "columns columns-info-form" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "component.companyAdministration.info.companyName"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("component.companyAdministration.info.companyName")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("component.companyAdministration.info.companyName")
                ),
              },
            },
            [
              !_vm.isEditing ||
              !_vm.$ability.can(
                _vm.$permActions.MANAGEOWN,
                _vm.$permSubjects.COMPANY
              )
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.departmentName || _vm.currentDepartment.name
                        ) +
                        " "
                    ),
                  ])
                : _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:50",
                        expression: "'required|max:50'",
                      },
                    ],
                    attrs: {
                      name: _vm.$t(
                        "component.companyAdministration.info.companyName"
                      ),
                      expanded: "",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.departmentName,
                      callback: function ($$v) {
                        _vm.departmentName = $$v
                      },
                      expression: "departmentName",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("component.companyAdministration.info.createdAt"),
              },
            },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.currentDepartment.createdAt * 1000)
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("component.companyAdministration.info.favorite"),
              },
            },
            [
              _vm.isEditing
                ? _c("b-checkbox", {
                    model: {
                      value: _vm.newFavorite,
                      callback: function ($$v) {
                        _vm.newFavorite = $$v
                      },
                      expression: "newFavorite",
                    },
                  })
                : _c("b-icon", {
                    attrs: { icon: _vm.isFavorite ? "check" : "times" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "column" }),
    ]),
    _vm.hasChanges
      ? _c(
          "div",
          {
            staticClass:
              "has-text-centered is-size-4 has-text-primary has-text-weight-semibold",
          },
          [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("component.group.detail.table.unsaved_changes"))
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.isEditing &&
    _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
      ? _c(
          "div",
          { staticClass: "has-text-right" },
          [
            _c(
              "b-button",
              {
                attrs: { disabled: !_vm.hasChanges, type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.saveChanges()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.location.detail.saveChanges_btn"))
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }