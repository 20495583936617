var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "small" }, [
    _c("div", [
      _c("h2", { staticClass: "title is-2 has-text-centered" }, [
        _vm._v(" " + _vm._s(_vm.$t("component.password_recover.verify")) + " "),
      ]),
      _vm.askForEmail
        ? _c(
            "div",
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("generals.email"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("generals.email")),
                    },
                    message: _vm.errors.first(_vm.$t("generals.email")),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: _vm.$t("generals.email"),
                      placeholder: _vm.$t(
                        "component.password_recover.account_email"
                      ),
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-info" },
                  on: {
                    click: function ($event) {
                      return _vm.resendVerificationEmail()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.send")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.sent
        ? _c("div", [
            _c("p", { staticClass: "has-text-centered" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("component.password_recover.verification_sent")
                  ) +
                  " "
              ),
            ]),
            _c(
              "h3",
              { staticClass: "has-text-centered has-text-weight-bold" },
              [_vm._v(_vm._s(_vm.email))]
            ),
            _c("p", { staticClass: "has-text-centered" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("component.password_recover.check_email")) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }