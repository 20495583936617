var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-notification",
        {
          attrs: {
            type: "is-warning",
            "has-icon": "",
            "aria-close-label": "Close notification",
            role: "alert",
          },
          on: { close: _vm.dismiss },
        },
        [
          _c("p", { staticClass: "pb-2 has-text-weight-semibold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.notification.pause.msg")) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.pauseNotifications(
                        _vm.PauseNotificationsInterval.FIFTEEN_MIN
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.notification.pause.pause15")) + " "
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.pauseNotifications(
                        _vm.PauseNotificationsInterval.THIRTY_MIN
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.notification.pause.pause30")) + " "
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.pauseNotifications(
                        _vm.PauseNotificationsInterval.ONE_HOUR
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.notification.pause.pause60")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }