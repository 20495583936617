var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _vm.isLayoutList
            ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.instanceHistory.title"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                { staticStyle: { "font-size": "1rem" } },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled: column.field == _vm.tableColumns.id.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `admin.component.devices.detail.instanceHistory.table.${column.field}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: {
            data: _vm.deviceInstances,
            striped: "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.id.field,
              label: _vm.$t(
                `admin.component.devices.detail.instanceHistory.table.${_vm.tableColumns.id.field}`
              ),
              visible: _vm.tableColumns.id.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _c("span", { staticClass: "is-family-monospace" }, [
                      _vm._v(_vm._s(instance.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.instanceHistory.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(instance.row.createdAt * 1000)
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.returnedAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.instanceHistory.table.${_vm.tableColumns.returnedAt.field}`
              ),
              visible: _vm.tableColumns.returnedAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _vm._v(
                      _vm._s(
                        instance.row.returnedAt
                          ? _vm.dateTimeManager.formatTime(
                              new Date(instance.row.returnedAt * 1000)
                            )
                          : "-"
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "company.companyName",
              label: _vm.$t(
                `admin.component.devices.detail.instanceHistory.table.${_vm.tableColumns.company.field}`
              ),
              visible: _vm.tableColumns.company.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [
                    _vm.device.company && _vm.device.company.isDeleted == true
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              " " + _vm._s(_vm.device.company.companyName) + " "
                            ),
                            _c(
                              "b-tag",
                              { attrs: { type: "is-primary", rounded: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.devices.detail.deviceInfo.deleted"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    instance.row.company
                      ? [
                          _vm.hasPermissionToViewDepartment(
                            instance.row.company.companyId
                          )
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminCompanyDetail",
                                      params: {
                                        companyId:
                                          instance.row.company.companyId,
                                        title: _vm.$t(
                                          "admin.component.devices.deviceList.title"
                                        ),
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(instance.row.company.companyName)
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(instance.row.company.companyName)
                                ),
                              ]),
                        ]
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.state.field,
              label: _vm.$t(
                `admin.component.devices.detail.instanceHistory.table.${_vm.tableColumns.state.field}`
              ),
              visible: _vm.tableColumns.state.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (instance) {
                  return [_vm._v(_vm._s(instance.row.state))]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.devices.detail.instanceHistory.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }