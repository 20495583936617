var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "is-flex is-justify-content-space-between mx-0 mb-5" },
      [
        _c("h2", { staticClass: "level-item mb-0 subtitle is-4 mr-2" }, [
          _vm._v(" " + _vm._s(_vm.$t("component.floorPlan.title")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c("b-button", {
              staticClass: "mr-0-mobile",
              attrs: { "icon-left": "plus", type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.uploadPlan()
                },
              },
            }),
            !_vm.isLayoutList
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "is-hidden-mobile",
                    attrs: {
                      label: _vm.$t("admin.component.layout.listView"),
                      position: "is-left",
                      type: "is-dark",
                    },
                  },
                  [
                    _c("b-button", {
                      attrs: { "icon-left": "stream" },
                      on: {
                        click: function ($event) {
                          return _vm.changeLayout()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isLayoutList
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "is-hidden-mobile",
                    attrs: {
                      label: _vm.$t("admin.component.layout.gridView"),
                      position: "is-left",
                      type: "is-dark",
                    },
                  },
                  [
                    _c("b-button", {
                      attrs: { "icon-left": "th-large" },
                      on: {
                        click: function ($event) {
                          return _vm.changeLayout()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm.floorPlans.length > 0
      ? _c(
          "div",
          {
            staticClass: "columns columns-info-form pb-4",
            class: _vm.isLayoutList ? "is-flex is-flex-direction-column" : "",
          },
          [
            _vm.floorPlans.length > 1 &&
            _vm.floorPlans.length === this.floorPlanThumbnails.length &&
            !_vm.isLayoutList
              ? _c(
                  "div",
                  {
                    staticClass: "column is-1 is-hidden-mobile",
                    staticStyle: { "overflow-y": "auto" },
                  },
                  _vm._l(_vm.floorPlanThumbnails, function (img, i) {
                    return _c("b-image", {
                      key: _vm.floorPlans[i].id,
                      staticClass: "is-clickable is-word-break-all mb-2",
                      style:
                        _vm.floorPlans[i].id === _vm.selectedFloorPlan.id
                          ? ""
                          : "opacity: 0.2;",
                      attrs: {
                        src: img,
                        alt: "[" + _vm.floorPlans[i].name + "]",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setSelectedFloorplan(_vm.floorPlans[i].id)
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "column",
                class: _vm.isLayoutList
                  ? "is-full"
                  : _vm.floorPlans.length > 1
                  ? "is-5"
                  : "is-6",
              },
              [
                _c("div", { staticStyle: { position: "relative" } }, [
                  _vm.selectedFloorPlanImage &&
                  _vm.selectedFloorPlanLocationData
                    ? _c("canvas", {
                        attrs: { id: "canvas", height: "1px" },
                        on: {
                          touchstart: _vm.handleTouchStart,
                          touchend: _vm.handleTouchEnd,
                        },
                      })
                    : _c("span", { staticClass: "is-word-break-all" }, [
                        _vm._v(
                          " [" +
                            _vm._s(
                              _vm.$t("component.floorPlan.floorplanPreview")
                            ) +
                            " " +
                            _vm._s(this.selectedFloorPlan.name) +
                            "] "
                        ),
                      ]),
                ]),
              ]
            ),
            (_vm.isLayoutList || _vm.isMobileView) && _vm.floorPlans.length > 1
              ? _c(
                  "div",
                  { staticClass: "is-flex is-justify-content-center my-2" },
                  _vm._l(_vm.floorPlans, function (floorPlan) {
                    return _c("b-icon", {
                      key: floorPlan.id,
                      staticClass: "mx-1 is-clickable",
                      attrs: {
                        size: "is-small",
                        pack:
                          floorPlan.id === _vm.selectedFloorPlan.id
                            ? "fas"
                            : "far",
                        icon: "circle",
                        type: "is-primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setSelectedFloorplan(floorPlan.id)
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "column",
                class: _vm.isLayoutList ? "is-full" : "is-half",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-justify-content-space-between is-align-items-center is-word-break-all",
                  },
                  [
                    _vm.selectedFloorPlan
                      ? _c(
                          "p",
                          { staticClass: "has-text-weight-semibold is-size-5" },
                          [_vm._v(_vm._s(_vm.selectedFloorPlan.name))]
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "admin.component.devices.detail.actions.title"
                                        ),
                                        position: "is-left",
                                        type: "is-dark",
                                      },
                                    },
                                    [
                                      _c("b-button", {
                                        staticClass: "ml-1",
                                        attrs: { "icon-left": "ellipsis-v" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          384105343
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.updatePlan()
                              },
                            },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "pencil-alt" } }),
                            _vm._v(" " + _vm._s(_vm.$t("generals.edit")) + " "),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFloorPlan()
                              },
                            },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "trash" } }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("generals.delete")) + " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectedFloorPlanLocationData
                  ? _c(
                      "b-table",
                      {
                        attrs: {
                          data: _vm.selectedFloorPlanLocationData,
                          "mobile-cards": false,
                        },
                      },
                      [
                        _c("b-table-column", {
                          attrs: {
                            label: _vm.$t("component.floorPlan.table.index"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (entity) {
                                  return [
                                    _vm._v(_vm._s(entity.index + 1 + ".")),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2254690200
                          ),
                        }),
                        _c("b-table-column", {
                          attrs: {
                            label: _vm.$t("component.floorPlan.table.name"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (entity) {
                                  return [
                                    entity.row.entity ==
                                    _vm.FloorPlanEntities.SOURCE
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getLoggerName(
                                                entity.row.entityId
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    entity.row.entity ==
                                    _vm.FloorPlanEntities.GATEWAY
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDeviceName(
                                                entity.row.entityId
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2153946860
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("component.floorPlan.loadingError"))
                      ),
                    ]),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }