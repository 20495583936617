var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.devices.importDevices.title")) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body has-text-centered" },
        [
          _c(
            "b-field",
            [
              _c(
                "b-upload",
                {
                  attrs: { "drag-drop": "", accept: ".csv" },
                  model: {
                    value: _vm.uploadedFile,
                    callback: function ($$v) {
                      _vm.uploadedFile = $$v
                    },
                    expression: "uploadedFile",
                  },
                },
                [
                  _c("section", { staticClass: "section" }, [
                    _c("div", { staticClass: "content has-text-centered" }, [
                      _c(
                        "p",
                        [
                          _c("b-icon", {
                            attrs: { icon: "upload", size: "is-large" },
                          }),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "admin.component.devices.importDevices.upload"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.uploadedFile
            ? _c("span", { staticClass: "tag is-primary" }, [
                _vm._v(" " + _vm._s(_vm.uploadedFile.name) + " "),
                _c("button", {
                  staticClass: "delete is-small",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteDropFile()
                    },
                  },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.importFile()
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("admin.component.devices.importDevices.confirm"))
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }