import { render, staticRenderFns } from "./FeedbackList.vue?vue&type=template&id=579ce68e"
import script from "./FeedbackList.vue?vue&type=script&lang=ts"
export * from "./FeedbackList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('579ce68e')) {
      api.createRecord('579ce68e', component.options)
    } else {
      api.reload('579ce68e', component.options)
    }
    module.hot.accept("./FeedbackList.vue?vue&type=template&id=579ce68e", function () {
      api.rerender('579ce68e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/admin/feedback/FeedbackList.vue"
export default component.exports