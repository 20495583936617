import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserSettings } from '@/entities/UserSettings';
import UnitNames from '@/entities/enums/UnitNames';
import Language from '@/entities/enums/languages';
import MeasurementsSort from '@/entities/enums/MeasurementsSort';
import { getReportingIntervals } from '@/entities/enums/ReportingInterval';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import NotificationRuleMode from '@/entities/enums/NotificationRuleMode';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import VueUtilities from '@/services/VueUtilities';
import { ChartHeight } from '@/entities/enums/ChartHeight';
var userSettingsRepository;
let AdminUserSettingsEdit = class AdminUserSettingsEdit extends Vue {
    constructor() {
        super(...arguments);
        this.fillingValues = true;
        this.isEditing = false;
        this.isAdding = false;
        this.changes = [];
        this.userSettingsKeys = UserSettingsKeys;
        this.Language = Language;
        this.ChartHeight = ChartHeight;
        this.MeasurementsSort = MeasurementsSort;
        this.NotificationRuleMode = NotificationRuleMode;
        this.UnitNames = UnitNames;
        this.convertedUnits = [];
        this.actualTime = new Date();
        this.dateFormats = [];
        this.timeZones = [];
        this.clockFormats = [];
        this.languages = [];
        this.notificationsRulesModes = [];
        this.measurementSorts = [];
        this.userUnits = [];
        this.reportingIntervals = [];
        this.selectedTzInFormat = false;
        this.selectedFavoriteDepartmentId = '';
        this.selectedFormat = '';
        this.selectedLanguage = '';
        this.selectedMeasurementSort = '';
        this.selectedClockFormat = '';
        this.selectedTimezone = '';
        this.selectedDateFormat = '';
        this.selectedUnits = [];
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
        this.fieldEditing = null;
        this.selectedNotificationsPauseUntil = '';
        this.selectedNotificationsPauseMsgDissmisedAt = '';
        this.selectedChartHeight = null;
    }
    startLoading() {
        if (!this.componentIsLoading)
            this.loadData();
    }
    get settingsWithDefaults() {
        return UserSettings.fromApi(this.adminUserSettings?.profile);
    }
    get isChartHeightChanged() {
        return this.selectedChartHeight !== this.userSettings?.preferences?.chartHeight;
    }
    get isNotificationsPauseUntilChanged() {
        return this.selectedNotificationsPauseUntil !== this.userSettings?.notifications?.pause?.until;
    }
    get isNotificationsPauseMsgDissmisedAtChanged() {
        return this.selectedNotificationsPauseMsgDissmisedAt !== this.userSettings?.notifications?.pause?.msgDismissedAt;
    }
    get userSettings() {
        return this.settingsWithDefaults?.rawSettings;
    }
    get customUserSettings() {
        if (this.userSettings?.customSettings) {
            return Object.entries(this.userSettings.customSettings).map((o) => {
                return { key: o[0], value: o[1] };
            });
        }
        else
            return [];
    }
    get filteredEventTypes() {
        return this.allEventTypes.filter((type, index, self) => {
            return [0, 3, 5, 9].includes(type.type) && self.findIndex((e) => e.type === type.type) === index;
        });
    }
    get isTzInFormatChanged() {
        return this.selectedTzInFormat !== this.getBooleanValue(this.userSettings?.datetime?.tzInFormat);
    }
    get isFavoriteDepartmentIdChanged() {
        return this.selectedFavoriteDepartmentId !== this.userSettings?.preferences?.favoriteDepartmentId;
    }
    get isFormatChanged() {
        return this.selectedFormat !== this.userSettings?.datetime?.format;
    }
    get isLanguageChanged() {
        return this.selectedLanguage !== this.userSettings?.language;
    }
    get isMeasurementSortChanged() {
        return this.selectedMeasurementSort !== this.userSettings?.preferences?.measurementSort;
    }
    get isClockFormatChanged() {
        return this.selectedClockFormat !== this.userSettings?.datetime?.clockFormat;
    }
    get isTimezoneChanged() {
        return this.selectedTimezone !== this.userSettings?.datetime?.timezone;
    }
    get isDateFormatChanged() {
        return this.selectedDateFormat !== this.userSettings?.datetime?.dateFormat;
    }
    get areUnitsChanged() {
        return JSON.stringify(this.selectedUnits) !== JSON.stringify(this.userUnits);
    }
    get hasChanges() {
        return (this.isTzInFormatChanged ||
            this.isFavoriteDepartmentIdChanged ||
            this.isFormatChanged ||
            this.isLanguageChanged ||
            this.isMeasurementSortChanged ||
            this.isClockFormatChanged ||
            this.isTimezoneChanged ||
            this.isDateFormatChanged ||
            this.areUnitsChanged ||
            this.isChartHeightChanged ||
            this.isNotificationsPauseUntilChanged ||
            this.isNotificationsPauseMsgDissmisedAtChanged);
    }
    created() {
        userSettingsRepository = UserSettingsRepository.getInstance(this);
    }
    mounted() {
        if (!this.componentIsLoading)
            this.loadData();
    }
    loadData() {
        this.fillingValues = true;
        this.languages = Object.values(Language);
        this.dateFormats = this.dateTimeManager.getAllDateFormats();
        this.timeZones = this.dateTimeManager.getLimitedTimezones();
        this.clockFormats = this.dateTimeManager.getAllClockFormats();
        this.notificationsRulesModes = Object.values(NotificationRuleMode);
        this.measurementSorts = Object.values(MeasurementsSort);
        this.reportingIntervals = getReportingIntervals();
        this.selectedTzInFormat = this.getBooleanValue(this.userSettings?.datetime?.tzInFormat);
        this.selectedFavoriteDepartmentId = this.userSettings?.preferences?.favoriteDepartmentId;
        this.selectedFormat = this.userSettings?.datetime?.format;
        this.selectedLanguage = this.userSettings?.language;
        this.selectedMeasurementSort = this.userSettings?.preferences?.measurementSort;
        this.selectedClockFormat = this.userSettings?.datetime?.clockFormat;
        this.selectedTimezone = this.userSettings?.datetime?.timezone;
        this.selectedDateFormat = this.userSettings?.datetime?.dateFormat;
        this.selectedNotificationsPauseUntil = this.userSettings?.notifications?.pause?.until;
        this.selectedNotificationsPauseMsgDissmisedAt = this.userSettings?.notifications?.pause?.msgDismissedAt;
        this.selectedChartHeight = this.userSettings?.preferences?.chartHeight;
        this.fillUnitValues();
        this.fillingValues = false;
    }
    getBooleanValue(prop) {
        return prop === 'true' || prop === 'True' ? true : false;
    }
    edit() {
        this.isEditing = !this.isEditing;
    }
    fillUnitValues() {
        this.userUnits = [];
        this.filteredEventTypes.forEach((type) => {
            this.userUnits.push({
                id: type.type,
                value: this.userSettings?.events?.units[type.type] || 'Default'
            });
        });
        this.selectedUnits = JSON.parse(JSON.stringify(this.userUnits));
    }
    getUserEventTypeUnit(units, selectedUnit) {
        let value = units.find((type) => type.identifier === selectedUnit);
        if (!value)
            return 'null';
        return value.name + ' (' + value.unit + ')';
    }
    // function which adds attribute to changes
    addToChanges(keys, value, isValueChanged) {
        let path = keys.join('.');
        let index = this.changes.map((x) => x.Key).indexOf(path);
        // if user clicked on value which was set before edit, remove previous one from array
        if (!isValueChanged && index !== -1) {
            this.changes.splice(index, 1);
        }
        else {
            // if not in array
            if (index === -1) {
                this.changes.push({ Key: path, Value: value });
            }
            // else replace previous one with new
            else {
                this.changes.splice(index, 1);
                this.changes.push({ Key: path, Value: value });
            }
        }
        //update date format
        if (path.includes(this.userSettingsKeys.DATETIME)) {
            this.selectedFormat = this.getFormat();
            if (!path.includes(this.userSettingsKeys.FORMAT)) {
                this.addToChanges([this.userSettingsKeys.PROFILE, this.userSettingsKeys.DATETIME, this.userSettingsKeys.FORMAT], this.selectedFormat, true);
            }
        }
    }
    getUnit(unitId) {
        let unit = this.selectedUnits.find((x) => x.id === unitId);
        return unit ? unit.value : 'Default';
    }
    async saveChanges() {
        let response = await userSettingsRepository.adminSaveUserSettings(this.userId, this.changes);
        if (response)
            this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
        this.isEditing = false;
        this.changes = [];
        this.$emit('reloadData', this.userId);
    }
    addNew() {
        this.isAdding = true;
    }
    cancelAdding() {
        this.isAdding = false;
        this.fieldEditing = null;
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
    }
    async saveNewSetting() {
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.checkIfKeyExists(this.selectedFieldKey)) {
                let keys = [UserSettingsKeys.PROFILE, UserSettingsKeys.CUSTOM, this.selectedFieldKey];
                let path = keys.join('.');
                this.changes.push({ Key: path, Value: this.selectedFieldValue });
                let response = await userSettingsRepository.adminSaveUserSettings(this.userId, this.changes);
                if (response) {
                    this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
                    this.$emit('reloadData', this.userId);
                    this.cancelAdding();
                }
                this.changes = [];
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('system_settings.already_exists'));
            }
        });
    }
    checkIfKeyExists(key) {
        if (this.customUserSettings.find((x) => x.key === key) != null)
            return true;
        else
            return false;
    }
    editSettings(key) {
        this.fieldEditing = key;
        this.selectedFieldKey = key;
        let setting = this.customUserSettings.find((x) => x.key == key);
        this.selectedFieldValue = setting.value;
    }
    cancelEdit() {
        this.fieldEditing = null;
        this.selectedFieldKey = null;
        this.selectedFieldValue = null;
    }
    async saveEdit(key) {
        let keys = [UserSettingsKeys.PROFILE, UserSettingsKeys.CUSTOM, key];
        let path = keys.join('.');
        this.changes.push({ Key: path, Value: this.selectedFieldValue });
        let response = await userSettingsRepository.adminSaveUserSettings(this.userId, this.changes);
        if (response) {
            this.$emit('openSuccessToast', this.$t('generals.changes_saved').toString());
            this.$emit('reloadData', this.userId);
        }
        this.changes = [];
        this.cancelEdit();
    }
    confirmSettingDelete(key) {
        let keys = [UserSettingsKeys.PROFILE, UserSettingsKeys.CUSTOM, key];
        let path = keys.join('.');
        this.$buefy.dialog.confirm({
            title: this.$t('custom_settings.delete_title'),
            message: this.$t('custom_settings.delete_msg', { key: key }),
            cancelText: this.$t('generals.close'),
            confirmText: this.$t('custom_settings.delete_confirm'),
            type: 'is-danger',
            onConfirm: () => this.deleteSetting(path)
        });
    }
    async deleteSetting(key) {
        let res = await userSettingsRepository.adminRemoveUserSetting(this.userId, key);
        this.$emit('reloadData', this.userId);
    }
    getFormat() {
        return `${this.selectedDateFormat} ${this.dateTimeManager.getValueForClockFormat(this.selectedClockFormat)} ${this.selectedTzInFormat === true ? ' zz' : ''}`;
    }
    concatString(str1, str2) {
        return str1.concat('.', str2);
    }
};
__decorate([
    Prop({ type: Boolean })
], AdminUserSettingsEdit.prototype, "componentIsLoading", void 0);
__decorate([
    Prop({ type: String })
], AdminUserSettingsEdit.prototype, "userId", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserSettingsEdit.prototype, "adminUserSettings", void 0);
__decorate([
    Prop({ type: Object })
], AdminUserSettingsEdit.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserSettingsEdit.prototype, "units", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserSettingsEdit.prototype, "allEventTypes", void 0);
__decorate([
    Prop({ type: Array })
], AdminUserSettingsEdit.prototype, "departments", void 0);
__decorate([
    Watch('componentIsLoading')
], AdminUserSettingsEdit.prototype, "startLoading", null);
AdminUserSettingsEdit = __decorate([
    Component({})
], AdminUserSettingsEdit);
export default AdminUserSettingsEdit;
