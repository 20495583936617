"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PREDEFINED_COLORS = ["#3c2d80", "#809b31", "#8270f6", "#3f4c08", "#f612a8", "#3aa609", "#ab12e2", "#e37010", "#0a7fb2", "#8f0f12", "#05957a", "#f73931", "#0c4152", "#e2698a", "#5a310c"];
const BOUNDARY_PREDEFINED_COLORS = ["#D05E44", "#DC6C3F", "#E67B3A", "#EE8B33", "#F49C2C", "#F6AE25", "#F6C01F", "#F4D31F", "#EEE626", "#E6F934"];
class ColorGenerator {
    constructor() {
        this._pickedColors = [];
    }
    generateHexLinesColor(colorCount) {
        this._lineColors = this.createHslaColors(360, 80, 40, colorCount);
        return this._lineColors;
    }
    createHslaColors(scale, satur, light, amount) {
        const colorsArr = [];
        const delta = Math.trunc(scale / amount);
        for (let i = 0; i < amount; i++) {
            const h = i * delta;
            colorsArr.push(this.HSLAToHexa(h, satur, light));
        }
        return colorsArr;
    }
    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    HSLAToHexa(h, s, l) {
        s /= 100;
        l /= 100;
        const c = (1 - Math.abs(2 * l - 1)) * s, x = c * (1 - Math.abs((h / 60) % 2 - 1)), m = l - c / 2;
        let r = 0, g = 0, b = 0;
        if (0 <= h && h < 60) {
            r = c;
            g = x;
            b = 0;
        }
        else if (60 <= h && h < 120) {
            r = x;
            g = c;
            b = 0;
        }
        else if (120 <= h && h < 180) {
            r = 0;
            g = c;
            b = x;
        }
        else if (180 <= h && h < 240) {
            r = 0;
            g = x;
            b = c;
        }
        else if (240 <= h && h < 300) {
            r = x;
            g = 0;
            b = c;
        }
        else if (300 <= h && h < 360) {
            r = c;
            g = 0;
            b = x;
        }
        const r_hex = Math.round((r + m) * 255).toString(16);
        const g_hex = Math.round((g + m) * 255).toString(16);
        const b_hex = Math.round((b + m) * 255).toString(16);
        return "#" + r_hex + g_hex + b_hex;
    }
    getBoundaryRectanglesColors(amount) {
        return this.createHslaColors(120, 80, 40, amount);
    }
    getLineColor(index) {
        return this._lineColors[index];
    }
    getBoundaryPointColor() {
        return BOUNDARY_PREDEFINED_COLORS[0];
    }
    getEventPointsColors(length) {
        let colors = PREDEFINED_COLORS.slice(1, length + 1);
        if (colors.length < length) {
            const newColors = [];
            for (let i = 0; i < (length - colors.length); i++) {
                newColors.push(this.getRandomColor());
            }
            colors = colors.concat(newColors);
        }
        return colors;
    }
    addToPickedColors(color) {
        this._pickedColors.push(color);
    }
}
exports.default = ColorGenerator;
