import { __decorate } from "tslib";
import VueUtilities from '@/services/VueUtilities';
import { Component, Vue } from 'vue-property-decorator';
let CreateVerificationKeyModal = class CreateVerificationKeyModal extends Vue {
    constructor() {
        super(...arguments);
        this.verificationKey = '';
    }
    save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.isHex(this.verificationKey))
                    this.$emit('createVerificationKey', this.verificationKey);
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.createVerificationKeyModal.verificationKeyMessage'));
                }
            }
        });
    }
    isHex(verificationKey) {
        return verificationKey.match(/^([0-9A-Fa-f])+$/i) ? true : false;
    }
    close() {
        this.$emit('modalClosed');
    }
};
CreateVerificationKeyModal = __decorate([
    Component({ components: {} })
], CreateVerificationKeyModal);
export default CreateVerificationKeyModal;
