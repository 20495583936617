var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.logger.service.modal.title", {
                name: _vm.source.name,
              })
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "p",
          {
            staticClass:
              "has-text-centered has-text-weight-bold has-text-primary mb-3",
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("component.logger.service.modal.msg")) + " "
            ),
          ]
        ),
        _c("hr"),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("component.logger.service.modal.start_label"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.logger.service.modal.start_btn_label")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.logger.service.modal.start_btn_label")
              ),
            },
          },
          [
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "component.logger.service.modal.start_btn_label"
                  ),
                  "native-value": true,
                },
                model: {
                  value: _vm.startImmediately,
                  callback: function ($$v) {
                    _vm.startImmediately = $$v
                  },
                  expression: "startImmediately",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("component.logger.service.modal.start_btn_label")
                  )
                ),
              ]
            ),
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "component.logger.service.modal.start_btn_label"
                  ),
                  "native-value": false,
                },
                model: {
                  value: _vm.startImmediately,
                  callback: function ($$v) {
                    _vm.startImmediately = $$v
                  },
                  expression: "startImmediately",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.logger.service.modal.schedule_service_btn_label"
                    )
                  )
                ),
              ]
            ),
          ],
          1
        ),
        _vm.startImmediately === false
          ? _c(
              "b-field",
              {
                attrs: {
                  label: _vm.$t(
                    `component.logger.service.modal.start_time_label`
                  ),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t("component.logger.service.modal.start_time_name")
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t("component.logger.service.modal.start_time_name")
                  ),
                },
              },
              [
                _c("b-datetimepicker", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    inline: "",
                    name: _vm.$t(
                      "component.logger.service.modal.start_time_name"
                    ),
                    placeholder: _vm.$t(
                      "component.logger.service.modal.start_time_label"
                    ),
                    "first-day-of-week": 1,
                    "mobile-native": false,
                    "min-datetime": _vm.initialCurrentTime,
                    timepicker: { hourFormat: _vm.hourFormat },
                    "datetime-formatter": (date) =>
                      _vm.dateTimeManager.formatDateTimeInInput(date),
                  },
                  model: {
                    value: _vm.scheduledStartTime,
                    callback: function ($$v) {
                      _vm.scheduledStartTime = $$v
                    },
                    expression: "scheduledStartTime",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("component.logger.service.modal.time_label"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.logger.service.modal.time_label")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.logger.service.modal.time_label")
              ),
            },
          },
          [
            _c(
              "b-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "component.logger.service.modal.time_label"
                  ),
                  name: _vm.$t("component.logger.service.modal.time_label"),
                  expanded: "",
                },
                model: {
                  value: _vm.selectedServiceLength,
                  callback: function ($$v) {
                    _vm.selectedServiceLength = $$v
                  },
                  expression: "selectedServiceLength",
                },
              },
              [
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.QUARTER_HOUR } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.QUARTER_HOUR
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.HALF_HOUR } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.HALF_HOUR
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.ONE_HOUR } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.ONE_HOUR
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.TWO_HOURS } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.TWO_HOURS
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.FOUR_HOURS } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.FOUR_HOURS
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.EIGHT_HOURS } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `minutes_to_text.` +
                              _vm.LoggerServiceLength.EIGHT_HOURS
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.RANGE } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`component.logger.service.modal.custom_length`)
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "option",
                  { domProps: { value: _vm.LoggerServiceLength.UNKNOWN } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `component.logger.service.modal.unknown_length`
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _vm.selectedServiceLength === _vm.LoggerServiceLength.RANGE
          ? _c(
              "b-field",
              {
                attrs: {
                  label: _vm.$t(`component.logger.service.modal.custom_length`),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t("component.logger.service.modal.custom_length")
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t("component.logger.service.modal.custom_length")
                  ),
                },
              },
              [
                _c("b-datetimepicker", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    name: _vm.$t(
                      "component.logger.service.modal.custom_length"
                    ),
                    placeholder: _vm.$t(
                      "component.logger.service.modal.custom_length"
                    ),
                    "first-day-of-week": 1,
                    "mobile-native": false,
                    "min-datetime": _vm.minEndTime,
                    timepicker: { hourFormat: _vm.hourFormat },
                    "datetime-formatter": (date) =>
                      _vm.dateTimeManager.formatDateTimeInInput(date),
                  },
                  model: {
                    value: _vm.customTimeLength,
                    callback: function ($$v) {
                      _vm.customTimeLength = $$v
                    },
                    expression: "customTimeLength",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("component.logger.service.modal.annotation_chck"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.logger.service.modal.annotation_chck_name")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.logger.service.modal.annotation_chck_name")
              ),
            },
          },
          [
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "component.logger.service.modal.annotation_chck_name"
                  ),
                  "native-value": true,
                },
                model: {
                  value: _vm.createAnnotation,
                  callback: function ($$v) {
                    _vm.createAnnotation = $$v
                  },
                  expression: "createAnnotation",
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.yes")))]
            ),
            _c(
              "b-radio",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: _vm.$t(
                    "component.logger.service.modal.annotation_chck_name"
                  ),
                  "native-value": false,
                },
                model: {
                  value: _vm.createAnnotation,
                  callback: function ($$v) {
                    _vm.createAnnotation = $$v
                  },
                  expression: "createAnnotation",
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.no")))]
            ),
          ],
          1
        ),
        _vm.createAnnotation === true
          ? _c(
              "b-field",
              {
                attrs: {
                  label: _vm.$t("component.report.create_annotation.text"),
                },
              },
              [
                _c(
                  "b-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: _vm.$t("component.report.create_annotation.text"),
                      placeholder: _vm.$t(
                        "component.report.create_annotation.placeholder"
                      ),
                      expanded: "",
                    },
                    model: {
                      value: _vm.predefinedAnnotationText,
                      callback: function ($$v) {
                        _vm.predefinedAnnotationText = $$v
                      },
                      expression: "predefinedAnnotationText",
                    },
                  },
                  _vm._l(_vm.PredefinedText, function (annotation) {
                    return _c(
                      "option",
                      { key: annotation, domProps: { value: annotation } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `component.report.create_annotation.predefined.${annotation}`
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          : _c("p", { staticClass: "has-text-danger" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("component.logger.service.modal.annotation_alert")
                )
              ),
            ]),
        _c(
          "b-field",
          {
            attrs: {
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.report.create_annotation.text")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.report.create_annotation.text")
              ),
            },
          },
          [
            _vm.predefinedAnnotationText === _vm.PredefinedText.OWN
              ? _c("b-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:350",
                      expression: "'required|max:350'",
                    },
                  ],
                  attrs: {
                    type: "textarea",
                    name: _vm.$t("component.report.create_annotation.text"),
                    expanded: "",
                    maxlength: "350",
                  },
                  model: {
                    value: _vm.customAnnotationText,
                    callback: function ($$v) {
                      _vm.customAnnotationText = $$v
                    },
                    expression: "customAnnotationText",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("unsaved_changes.cancel")))]
        ),
        _vm.startImmediately === true
          ? _c(
              "b-button",
              {
                attrs: { type: "is-success" },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("component.logger.service.modal.start_btn_label")
                  )
                ),
              ]
            )
          : _c(
              "b-button",
              {
                attrs: { type: "is-success" },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.logger.service.modal.schedule_service_btn_label"
                    )
                  )
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }