var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "is-flex is-justify-content-space-between mb-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
            },
            [
              _c("h2", { staticClass: "subtitle is-4" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("component.group.detail.title")) + " "
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "columns columns-info-form group-info-form" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.group.detail.table.name"),
                  type: {
                    "is-danger":
                      _vm.errors.has(_vm.$t("component.group.create.name")) ||
                      _vm.nameExists,
                  },
                  message: _vm.nameExists
                    ? _vm.$t("component.group.create.msg.unique_name")
                    : _vm.errors.first(_vm.$t("component.group.create.name")),
                  "custom-class": "ml-0-mobile",
                },
              },
              [
                !_vm.hasEditPermissions
                  ? _c("p", { staticClass: "ml-3-mobile" }, [
                      _vm._v(
                        " " + _vm._s(_vm.groupName || _vm.group.name) + " "
                      ),
                    ])
                  : _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: _vm.$t("component.group.detail.table.name"),
                        expanded: "",
                      },
                      on: { input: _vm.checkIfNameExists },
                      model: {
                        value: _vm.groupName,
                        callback: function ($$v) {
                          _vm.groupName = $$v
                        },
                        expression: "groupName",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: { horizontal: "", label: _vm.$t("loggers.visibility") },
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      placeholder: _vm.$t("loggers.visibility"),
                      expanded: "",
                      name: _vm.$t("loggers.visibility"),
                      icon: "eye",
                    },
                    model: {
                      value: _vm.groupVisibility,
                      callback: function ($$v) {
                        _vm.groupVisibility = $$v
                      },
                      expression: "groupVisibility",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("loggers.visibility_state.visible")) +
                          " "
                      ),
                    ]),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("loggers.visibility_state.hidden")) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.group.detail.table.boundaries"),
                  "custom-class": "ml-0-mobile",
                },
              },
              [
                _c(
                  "div",
                  [
                    !_vm.canHaveBoundaries
                      ? _c("p", { staticClass: "ml-3-mobile" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.group.detail.msg.cant_create_boundaries"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("BoundaryInfoWithEdit", {
                          ref: "boundariesComponent",
                          staticClass: "ml-3-mobile",
                          attrs: {
                            boundaries: _vm.getCopyOfBoundaries(),
                            hasEditPermissions: _vm.canHaveBoundaries
                              ? _vm.hasEditPermissions
                              : false,
                            eventTypes: _vm.eventTypes,
                          },
                          on: {
                            boundariesChanged: _vm.boundariesChanged,
                            validation: _vm.onBoundaryValidation,
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.group.detail.table.loggers"),
                  "custom-class": "ml-0-mobile",
                },
              },
              [
                [
                  _c("EntitySelectionTable", {
                    ref: "entitySelectionTable",
                    attrs: {
                      tableData: _vm.tableData,
                      assignedEntities: _vm.group.sources || [],
                      filters: _vm.filters,
                      choosedEntityColumn: _vm.choosedEntityColumn,
                      hasEditPermissions: _vm.hasEditPermissions,
                      isLinkable: true,
                    },
                    on: {
                      entitySelected: _vm.sourceSelected,
                      getLink: _vm.getLink,
                    },
                  }),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm.hasEditPermissions
        ? _c(
            "div",
            { staticClass: "has-text-right" },
            [
              _vm.resetFormDefaultsEnabled
                ? _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        type: "is-link",
                        outlined: "",
                        disabled: !_vm.hasChanges,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetDefaultsModal()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("generals.cancel_edit")) + " ")]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary",
                    disabled:
                      !_vm.hasChanges ||
                      _vm.emptyGroupName ||
                      _vm.emptyBoundaryField,
                  },
                  on: {
                    "~click": function ($event) {
                      return _vm.saveChanges()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.group.detail.saveChanges_btn"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.createRuleWarningVisible
        ? _c("CreateRuleWarning", {
            attrs: { sourceIds: _vm.unasignedSourcesIds },
            on: { warningClosed: _vm.warningClosed },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }