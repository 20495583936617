import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { Source } from '@/entities/models/Source';
import { ApiQueries } from '@/entities/ApiQueries';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiStatus from '@/entities/enums/apiStatuses';
import ApiResponse from '@/entities/ApiResponse';
import { vxm } from '@/store/store.vuex';
import { SourceChanges } from '@/entities/models/SourceChanges';
import Quantity from '@/entities/Quantity';
import { SourceService } from '@/entities/models/SourceService';
var vxDepartmentStore = vxm.departmentStore;
export default class SourceRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getVisibleSources(pagination = null, sort = null, departmentId = this.getSelectedDepartmentId(), filter = []) {
        let apiResponse = new ApiResponse();
        // if (!this.ctx.$ability.can(Actions.READ, Subjects.SOURCE)) {
        //   return apiResponse;
        // }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return apiResponse;
        else {
            //Create response with pagination data
            apiResponse.setPagination(response.data);
            apiResponse.setData(Source.sortByName(Source.fromApi(response.data.data)));
            return apiResponse;
        }
    }
    async getAllSources(pagination = null, sort = null, departmentId = this.getSelectedDepartmentId()) {
        let apiResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            return apiResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        queries.all = true;
        if (sort)
            queries.addSorts(sort);
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return apiResponse;
        else {
            //Create response with pagination data
            apiResponse.setPagination(response.data);
            apiResponse.setData(Source.sortByName(Source.fromApi(response.data.data)));
            return apiResponse;
        }
    }
    async checkIfNameExists(name, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action checkIfNameExists Source';
        }
        if (!name || name.length <= 0)
            return { Exist: false };
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}${ApiLinks.Sources.Keys.Exists}`;
        let queries = new ApiQueries();
        queries.name = name;
        url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return response.data.data;
    }
    async createSource(source, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            return new Source();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}`;
        let response = await AxiosService.postDataToServer(url, source, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async getSource(sourceId, departmentId = this.getSelectedDepartmentId(), processError = true) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.SOURCE)) {
            return new Source();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, processError);
        if (response.status != ApiStatus.SUCCESS)
            new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async assignLogger(sourceId, loggerId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action assignLogger';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Assign}`;
        let data = new FormData();
        data.set('companyLoggerId', loggerId);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async unassignLogger(sourceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action assignLogger';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Assign}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async getLoggerChanges(sourceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.SOURCE)) {
            return new Array();
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Assign}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            new SourceChanges();
        else
            return SourceChanges.fromApi(response.data.data);
    }
    async deleteLogger(sourceId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async updateSource(sourceId, sourceDTO, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            return new Source();
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}`;
        let response = await AxiosService.putDataToServer(url, sourceDTO, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async assignToGroup(sourceId, groupId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGESOURCES, Subjects.SOURCE_GROUP)) {
            throw 'Forbidden action assignToGroup';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}/${groupId}${ApiLinks.Sources.Keys.Add}`;
        let data = new FormData();
        data.set('sourceId', sourceId);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async unassignFromGroup(sourceId, groupId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGESOURCES, Subjects.SOURCE_GROUP)) {
            throw 'Forbidden action unassignFromGroup';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Groups}/${groupId}${ApiLinks.Sources.Keys.Remove}`;
        let data = new FormData();
        data.set('sourceId', sourceId);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Source();
        else
            return Source.fromApi(response.data.data);
    }
    async getQuantities(sourceId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            return [];
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Quantities}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            new Quantity();
        else
            return Quantity.fromApi(response.data.data);
    }
    async enableQuantities(sourceId, quantityKey, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action enableQuantities';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Quantities}`;
        let data = new FormData();
        data.set('key', quantityKey);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return true;
        else
            return true;
    }
    async disableQuantities(sourceId, quantityKey, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE)) {
            throw 'Forbidden action disableQuantities';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Quantities}`;
        let data = new FormData();
        data.set('key', quantityKey);
        let response = await AxiosService.deleteDataFromServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return true;
        else
            return true;
    }
    async setServiceForSource(sourceId, serviceDto, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_SERVICE)) {
            throw 'Forbidden action setServiceForLogger';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Service}`;
        let response = await AxiosService.postDataToServer(url, serviceDto, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return false;
        else
            return SourceService.fromApi(response.data.data);
    }
    async updateServiceForSource(sourceId, serviceId, serviceDto, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.SOURCE_SERVICE)) {
            throw 'Forbidden action updateServiceForSource';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Service}/${serviceId}`;
        let response = await AxiosService.putDataToServer(url, serviceDto, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return SourceService.fromApi(response.data.data);
    }
    async adminGetAllSources(departmentId, pagination = null, sort = null) {
        let finalResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_SOURCES)) {
            return finalResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Sources.Keys.Sources}${ApiLinks.Sources.Keys.All}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return finalResponse;
        else {
            //Create response with pagination data
            finalResponse.setPagination(response.data);
            finalResponse.setData(Source.sortByName(Source.fromApi(response.data.data)));
            return finalResponse;
        }
    }
    async adminGetDeletedSources(departmentId, pagination = null, sort = null) {
        let finalResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_SOURCES)) {
            return finalResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        // if (sort)
        //     queries.addSorts(sort);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Sources.Keys.Sources}${ApiLinks.Sources.Keys.Deleted}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return finalResponse;
        else {
            //Create response with pagination data
            finalResponse.setPagination(response.data);
            if (!sort)
                finalResponse.setData(Source.sortByName(Source.fromApi(response.data.data)));
            else {
                finalResponse.setData(Source.fromApi(response.data.data));
            }
            return finalResponse;
        }
    }
    async reviveDeletedSource(departmentId, sourceId) {
        if (!this.ctx.$ability.can(Actions.REVIVE, Subjects.ADMIN_DEPARTMENTS_SOURCES)) {
            throw 'Forbidden action reviveDeletedSource';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Sources.Keys.Sources}/${sourceId}${ApiLinks.Sources.Keys.Revive}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        return true;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
