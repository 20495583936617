var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "section-header-level" },
        [
          _c("div", { staticClass: "title-with-edit" }, [
            _c("h2", { staticClass: "subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.company.detail.companyDevices.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-dropdown",
            {
              attrs: { position: "is-bottom-left", "aria-role": "list" },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.$t(
                              "admin.component.company.list.actions.columns"
                            ),
                            position: "is-left",
                            type: "is-dark",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            { staticStyle: { "font-size": "1rem" } },
                            [
                              _c("b-icon", {
                                attrs: { type: "is-primary", icon: "list" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.tableColumns, function (column) {
              return _c(
                "b-dropdown-item",
                {
                  key: column.field,
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    focusable: false,
                    custom: "",
                    "aria-role": "listitem",
                  },
                },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: {
                        disabled:
                          column.field == _vm.tableColumns.deviceName.field,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.chooseTableColumns(column.field, $event)
                        },
                      },
                      model: {
                        value: column.visible,
                        callback: function ($$v) {
                          _vm.$set(column, "visible", $$v)
                        },
                        expression: "column.visible",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `admin.component.company.detail.companyDevices.list.${column.field}`
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: {
            data: _vm.devices,
            "row-class": (row) =>
              row.instanceState === _vm.DeviceInstanceState.RETURNED
                ? "is-disabled has-text-grey"
                : "",
            paginated: "",
            "backend-pagination": "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.totalDeviceListSize,
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            "page-change": _vm.onPageChange,
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u([
            {
              key: "bottom-left",
              fn: function () {
                return [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "component.notifications_list.table.row_per_page"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "b-field",
                    { staticClass: "pl-2 mb-4-mobile" },
                    [
                      _c(
                        "b-select",
                        {
                          on: { input: _vm.rowsPerPageSelected },
                          model: {
                            value: _vm.pageSize,
                            callback: function ($$v) {
                              _vm.pageSize = $$v
                            },
                            expression: "pageSize",
                          },
                        },
                        _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [_vm._v(" " + _vm._s(option) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "SerialName",
              visible: _vm.tableColumns.deviceName.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.deviceName.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.instanceState !==
                    _vm.DeviceInstanceState.RETURNED
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminDeviceDetail",
                                params: {
                                  deviceId: devices.row.deviceId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(devices.row.deviceName))]
                        )
                      : _c(
                          "p",
                          [
                            _vm._v(" " + _vm._s(devices.row.deviceName) + " "),
                            _c(
                              "b-tag",
                              { attrs: { type: "is-primary", rounded: "" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.devices.deviceList.returned"
                                      )
                                    )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Id",
              visible: _vm.tableColumns.deviceId.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.deviceId.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.instanceState !==
                    _vm.DeviceInstanceState.RETURNED
                      ? _c(
                          "router-link",
                          {
                            staticClass: "is-family-monospace",
                            attrs: {
                              to: {
                                name: "adminDeviceDetail",
                                params: {
                                  deviceId: devices.row.deviceId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(devices.row.deviceId))]
                        )
                      : _c("p", { staticClass: "is-family-monospace" }, [
                          _vm._v(_vm._s(devices.row.deviceId)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "MacAddress",
              visible: _vm.tableColumns.macAddress.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.macAddress.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.instanceState !==
                    _vm.DeviceInstanceState.RETURNED
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminDeviceDetail",
                                params: {
                                  deviceId: devices.row.deviceId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(devices.row.deviceMacAddress))]
                        )
                      : _c("p", [_vm._v(_vm._s(devices.row.deviceMacAddress))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Interval",
              visible: _vm.tableColumns.interval.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.interval.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.currentConfig
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(devices.row.currentConfig.interval) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "Description",
              visible: _vm.tableColumns.description.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.description.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.description
                      ? _c("p", { staticClass: "description" }, [
                          _vm._v(" " + _vm._s(devices.row.description) + " "),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "IsOffline",
              visible: _vm.tableColumns.isOnline.visible,
              label: _vm.$t(
                `admin.component.company.detail.companyDevices.list.${_vm.tableColumns.isOnline.field}`
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (devices) {
                  return [
                    devices.row.isOnline == false
                      ? _c(
                          "b-tag",
                          { attrs: { type: "is-primary", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.device.list.table.disconnected"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "b-tag",
                          { attrs: { type: "is-success", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.device.list.table.connected")
                              )
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.company.detail.companyDevices.list.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }