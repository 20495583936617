import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import FirmwareRepository from '@/services/repository/FirmwareRepository';
var firmwareRepository;
let AdminFirmwareModal = class AdminFirmwareModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.version = '';
        this.fileName = '';
        this.uploadedFile = null;
        this.files = [];
        this.fwKeyValueArray = [];
        this.file = {};
        this.updatedFwKeyValueObj = null;
        this.description = '';
    }
    created() {
        firmwareRepository = new FirmwareRepository(this);
    }
    async createFirmware() {
        if (this.isLoading === true)
            return;
        this.updatedFwKeyValueObj = Object.fromEntries(this.fwKeyValueArray);
        this.file.File = this.uploadedFile;
        this.file.Name = this.fileName;
        this.files.push(this.file);
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let firmwareDto = {
                    Version: this.version,
                    Description: this.description,
                    Files: this.files
                };
                let createResult = await firmwareRepository.createFirmware(firmwareDto);
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.createFirmwareModel.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.createFirmwareModel.failure'));
                }
                this.isLoading = false;
            }
        });
    }
    checkFileName(event) {
        this.fileName = event.name.substr(0, event.name.lastIndexOf('.'));
    }
    addKeyValue() {
        this.fwKeyValueArray.push({ 0: '', 1: '' });
    }
    removeKeyValue(index) {
        this.fwKeyValueArray = this.fwKeyValueArray.filter((x) => x != index);
    }
    deleteDropFile() {
        this.uploadedFile = null;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
    errorMessageKey(id) {
        var message = this.errors.first(this.$t('admin.component.devices.firmwareList.detail.firmwareInfo.properties.key') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
    errorMessageValue(id) {
        var message = this.errors.first(this.$t('admin.component.devices.firmwareList.detail.firmwareInfo.properties.value') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
};
AdminFirmwareModal = __decorate([
    Component({})
], AdminFirmwareModal);
export default AdminFirmwareModal;
