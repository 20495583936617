"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxisPosition = void 0;
var AxisPosition;
(function (AxisPosition) {
    AxisPosition[AxisPosition["LEFT_OUTSIDE"] = 0] = "LEFT_OUTSIDE";
    AxisPosition[AxisPosition["LEFT_INSIDE"] = 1] = "LEFT_INSIDE";
    AxisPosition[AxisPosition["RIGHT_OUTSIDE"] = 2] = "RIGHT_OUTSIDE";
    AxisPosition[AxisPosition["RIGHT_INSIDE"] = 3] = "RIGHT_INSIDE";
})(AxisPosition || (exports.AxisPosition = AxisPosition = {}));
