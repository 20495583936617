import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceRepository from '@/services/repository/SourceRepository';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import { BoundaryDTO, Boundary } from '@/entities/models/Boundary';
import BoundaryInfo from '@/views/components/boundary/BoundaryInfo.vue';
import BoundaryInfoWithEdit from '@/views/components/boundary/BoundaryInfoWithEdit.vue';
import BoundaryRepository from '@/services/repository/BoundaryRepository';
import VueUtilities from '@/services/VueUtilities';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import ChangeLoggerModal from '@/views/components/logger/ChangeLoggerModal.vue';
import CreateRuleWarning from '@/views/components/notification/CreateRuleWarning.vue';
import DecimalConversion from '@/services/DecimalConversion';
import UserRepository from '@/services/repository/UserRepository';
import AppConfig from '@/configLoader';
import { ServiceState } from '@/entities/enums/ServiceState';
import CheckboxSelect from '../generals/CheckboxSelect.vue';
var sourceRepository;
var boundaryRepository;
var companyLoggerRepository;
var userRepository;
var pdf = require('@/assets/other/loghub_sample_calibration_protocol.pdf');
let LoggerInfo = class LoggerInfo extends Vue {
    constructor() {
        super(...arguments);
        this.demo = AppConfig.getConfig().demo.name;
        this.selectedQuantities = [];
        this.CalibrationStatus = CalibrationStatus;
        this.ServiceState = ServiceState;
        this.nameExists = false;
        this.sourceName = null;
        this.sourceDescription = null;
        this.selectedGroup = null;
        this.isChangeLoggerModalActive = false;
        this.boundaries = null;
        this.originBoundaries = null;
        this.availableCompanyLoggers = [];
        this.isLoading = false;
        this.createRuleWarningVisible = false;
        this.unasignedSourcesIds = [];
        this.selectedVisibility = null;
        this.isEditing = false;
        this.pdf = pdf;
        this.currentUserName = null;
        this.modalIsReassigning = false;
    }
    get hasCalibrationProtocolToDownload() {
        if (this.source.logger == null ||
            this.source.logger.calibration == null ||
            this.source.logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    get selectedGroupName() {
        return !this.selectedGroup || !this.selectedGroup.name ? this.$t('generals.unassigned') : this.selectedGroup.name;
    }
    get nameChanged() {
        return this.sourceName && this.sourceName != this.source.name;
    }
    get descriptionChanged() {
        return this.sourceDescription != this.source.description;
    }
    get visibilityChanged() {
        return this.selectedVisibility != this.source.visible;
    }
    get vqsChanged() {
        return !(this.selectedQuantities.every((x) => this.source.enabledVirtualQuantities.includes(x)) &&
            this.source.enabledVirtualQuantities.every((x) => this.selectedQuantities.includes(x)));
    }
    get sourceGroupChanged() {
        if (!this.source.sourceGroup && this.selectedGroup.id == null)
            return false;
        else if (!this.source.sourceGroup && this.selectedGroup.id != null)
            return true;
        else {
            return !(this.source.sourceGroup.id == this.selectedGroup.id);
        }
    }
    get hasBoundariesChanged() {
        return !BoundaryDTO.compareBoundaries(this.originBoundaries, this.boundaries);
    }
    //This is called from parent component
    get hasChanges() {
        return (this.nameChanged ||
            this.descriptionChanged ||
            this.sourceGroupChanged ||
            this.hasBoundariesChanged ||
            this.visibilityChanged ||
            this.vqsChanged);
    }
    get canHaveBoundaries() {
        return this.selectedGroup.id == null;
    }
    quantityArrChanged(quantityArr) {
        this.selectedQuantities = quantityArr;
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        boundaryRepository = new BoundaryRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        userRepository = new UserRepository(this);
        this.loadUser();
        this.assignDefaultValues();
    }
    async loadUser() {
        let currentUser = await userRepository.getCurrentUser();
        this.currentUserName = currentUser.username;
    }
    get isDemo() {
        return this.currentUserName == this.demo;
    }
    assignDefaultValues() {
        this.sourceName = this.source.name;
        this.sourceDescription = this.source.description;
        this.selectedVisibility = this.source.visible;
        this.boundaries = Boundary.convertBoundariesToDTO(this.source.boundaries);
        this.originBoundaries = Boundary.convertBoundariesToDTO(this.source.boundaries);
        this.selectedQuantities = this.source.enabledVirtualQuantities;
        this.assignSourceGroupToForm();
    }
    async downloadCalibration() {
        this.isLoading = true;
        await companyLoggerRepository.downloadCalibrationProtocol(this.source.logger.companyLoggerId);
        this.isLoading = false;
    }
    getCopyOfBoundaries() {
        if (!this.boundaries)
            return [];
        else
            return JSON.parse(JSON.stringify(this.boundaries));
    }
    //This is called from parent component
    validate() {
        if (this.$refs.boundariesComponent)
            this.$refs.boundariesComponent.validate();
        else {
            this.onBoundaryValidation(true);
        }
    }
    onBoundaryValidation(result) {
        if (result == true) {
            this.saveChanges();
        }
    }
    async saveChanges() {
        await this.checkIfNameExists(this.sourceName);
        if (this.nameExists) {
            VueUtilities.openErrorToast(this, this.$t('component.location.detail.msg.unique_name').toString());
        }
        this.$validator.validateAll().then(async (result) => {
            if (result && !this.nameExists) {
                let allPromises = [];
                if (this.hasChanges) {
                    let sourceDTO = {
                        Name: this.sourceName,
                        Description: this.sourceDescription,
                        Visible: this.selectedVisibility
                    };
                    allPromises.push(sourceRepository.updateSource(this.source.id, sourceDTO));
                }
                let showWarning = false;
                if (this.sourceGroupChanged) {
                    if (this.selectedGroup.id == null) {
                        allPromises.push(sourceRepository.unassignFromGroup(this.source.id, this.source.sourceGroup.id));
                        showWarning = true;
                    }
                    else {
                        allPromises.push(sourceRepository.assignToGroup(this.source.id, this.selectedGroup.id));
                    }
                }
                if (this.canHaveBoundaries && this.hasBoundariesChanged) {
                    this.boundaries.forEach((boundary) => {
                        boundary.lowerAlarmBoundary = DecimalConversion.toFloat(boundary.lowerAlarmBoundary);
                        boundary.upperAlarmBoundary = DecimalConversion.toFloat(boundary.upperAlarmBoundary);
                        boundary.lowerWarningBoundary = DecimalConversion.toFloat(boundary.lowerWarningBoundary);
                        boundary.upperWarningBoundary = DecimalConversion.toFloat(boundary.upperWarningBoundary);
                    });
                    allPromises.push(this.assignBoundaries(this.boundaries, this.source.id));
                }
                if (this.vqsChanged) {
                    let added = this.selectedQuantities.filter((x) => !this.source.enabledVirtualQuantities.includes(x));
                    let deleted = this.source.enabledVirtualQuantities.filter((x) => !this.selectedQuantities.includes(x));
                    if (added && added.length > 0) {
                        added.forEach((x) => {
                            allPromises.push(sourceRepository.enableQuantities(this.source.id, x.key));
                        });
                    }
                    if (deleted && deleted.length > 0) {
                        added.forEach((x) => {
                            allPromises.push(sourceRepository.disableQuantities(this.source.id, x.key));
                        });
                    }
                }
                Promise.all(allPromises).then((x) => {
                    VueUtilities.openSuccessToast(this, this.$t('component.location.detail.msg.edit_success').toString());
                    this.$validator.pause();
                    this.assignDefaultValues();
                    this.isEditing = false;
                    if (showWarning) {
                        this.unasignedSourcesIds = [this.source.id];
                        this.createRuleWarningVisible = true;
                    }
                    else {
                        this.$emit('reloadSource');
                    }
                });
            }
        });
    }
    warningClosed() {
        this.$emit('reloadSource');
    }
    async assignBoundaries(boundaries, sourceId) {
        return boundaryRepository.createBoundary(boundaries, sourceId);
    }
    async checkIfNameExists(name) {
        if (!name || name == this.source.name) {
            this.nameExists = false;
        }
        else {
            let checkResponse = await sourceRepository.checkIfNameExists(name);
            this.nameExists = checkResponse.Exist;
        }
    }
    groupSelected(group) {
        this.selectedGroup = group;
    }
    boundariesChanged(boundaries) {
        this.boundaries = boundaries;
    }
    closeChangeLoggerModal(data) {
        this.isChangeLoggerModalActive = false;
        if (data == true)
            this.$emit('reloadSource');
    }
    assignSourceGroupToForm() {
        if (!this.source.sourceGroup)
            this.selectedGroup = this.sourceGroups.find((x) => !x.id);
        else
            this.selectedGroup = this.sourceGroups.find((x) => x.id == this.source.sourceGroup.id);
    }
    //This is called from parent component
    async changeLogger(reassigning) {
        this.isLoading = true;
        this.modalIsReassigning = reassigning;
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        allPromises.push(sourceRepository.getVisibleSources());
        Promise.all(allPromises).then((response) => {
            this.processChangeLoggerData(response[0], response[1]);
        });
    }
    processChangeLoggerData(companyLoggerList, sourceList) {
        CompanyLogger.addSources(companyLoggerList.getData(), sourceList.getData());
        if (this.source.logger)
            this.availableCompanyLoggers = companyLoggerList
                .getData()
                .filter((x) => x.companyLoggerId != this.source.logger.companyLoggerId);
        else
            this.availableCompanyLoggers = companyLoggerList.getData();
        this.isLoading = false;
        this.isChangeLoggerModalActive = true;
    }
    editLogger() {
        this.isEditing = !this.isEditing;
        if (!this.isEditing)
            this.assignDefaultValues();
        this.$emit('editingLogger');
    }
    async changePausedMeasurementFlag() {
        let sourceDTO = {
            Name: this.source.name,
            Description: this.source.description,
            Visible: this.source.visible,
            PausedMeasurement: !this.source.pausedMeasurement
        };
        let updateResult = await sourceRepository.updateSource(this.source.id, sourceDTO);
        if (updateResult) {
            VueUtilities.openSuccessToast(this, this.$t('component.location.detail.msg.edit_success').toString());
            this.$emit('reloadSource');
        }
    }
    editService() {
        this.$emit('editService');
    }
};
__decorate([
    Prop({ type: Object })
], LoggerInfo.prototype, "source", void 0);
__decorate([
    Prop({ type: Array })
], LoggerInfo.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], LoggerInfo.prototype, "quantities", void 0);
__decorate([
    Prop({ type: Array })
], LoggerInfo.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], LoggerInfo.prototype, "dateTimeManager", void 0);
LoggerInfo = __decorate([
    Component({
        components: {
            BoundaryInfo,
            ChangeLoggerModal,
            CreateRuleWarning,
            BoundaryInfoWithEdit,
            CheckboxSelect
        }
    })
], LoggerInfo);
export default LoggerInfo;
