var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.devices.ScheduleUpdateModal.title", {
                  firmwareId: _vm.firmware.id,
                })
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.devices.ScheduleUpdateModal.devices"
                ),
              },
            },
            [
              _c(
                "b-table",
                {
                  staticClass: "select-location-table border",
                  attrs: {
                    data: _vm.deviceList,
                    paginated: true,
                    "per-page": 5,
                    narrowed: "",
                    "checked-rows": _vm.selectedDevices,
                    checkable: "",
                    "mobile-cards": false,
                  },
                  on: {
                    "update:checkedRows": function ($event) {
                      _vm.selectedDevices = $event
                    },
                    "update:checked-rows": function ($event) {
                      _vm.selectedDevices = $event
                    },
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "deviceName",
                      label: _vm.$t(
                        "admin.component.devices.ScheduleUpdateModal.table.deviceName"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceList) {
                          return [
                            _c("p", { staticStyle: { "max-width": "150px" } }, [
                              _vm._v(_vm._s(deviceList.row.deviceName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "deviceId",
                      label: _vm.$t(
                        "admin.component.devices.ScheduleUpdateModal.table.deviceId"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceList) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(deviceList.row.deviceId)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "deviceMacAddress",
                      label: _vm.$t(
                        "admin.component.devices.ScheduleUpdateModal.table.deviceMacAddress"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceList) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(deviceList.row.deviceMacAddress)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("b-table-column", {
                    attrs: {
                      field: "company.companyName",
                      label: _vm.$t(
                        "admin.component.devices.ScheduleUpdateModal.table.company"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (deviceList) {
                          return [
                            deviceList.row.company
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(deviceList.row.company.companyName)
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("section", { staticClass: "section" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "content has-text-grey has-text-centered",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.devices.ScheduleUpdateModal.table.empty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "bottom-left" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notification.create_rule.entity_table.selected_count"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" : " + _vm._s(_vm.selectedDevices.length) + " "),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.scheduleUpdate()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.devices.ScheduleUpdateModal.confirm")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }