import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ReportChartDataConverter from '@/services/chart-data-conversion/ReportChartDataConverter';
import { Chart, ChartBuilder, LabelPosition, LegendOrientation } from 'd3Chart';
import { ChartServices } from '@/entities/enums/ChartServices';
import UserPreferences from '@/services/UserPreferences';
import { ChartHeight } from '@/entities/enums/ChartHeight';
import { ChartComponents } from '@/entities/enums/ChartComponents';
var pdf = require('@/assets/other/loghub_report_sample.pdf');
let ReportsOverallChart = class ReportsOverallChart extends Vue {
    constructor() {
        super(...arguments);
        this.Chart = Chart;
        this.ChartServices = ChartServices;
        this.selectedChartService = ChartServices.TEMPERATURE;
        this.pdf = pdf;
        this.chartId = 'reportOverallChart';
        this.hasDataForTimerange = false;
        this.isHasBoundaries = false;
        this.isScaleByData = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsScaleByData);
        this.availableChartLogger = [];
        this.isLoggersFilterApplied = false;
        this.ChartHeight = ChartHeight;
        this.chartHeightPixel = 0;
        this.selectedChartHeight = ChartHeight.SMALL;
        this.chartServices = [];
    }
    mounted() {
        this.selectedChartHeight = this.chartHeight;
        this.chartHeightPixel = this.getChartHeight();
        this.loadChartServices();
        this.createChart();
    }
    getChartHeight() {
        if (this.selectedChartHeight == ChartHeight.SMALL) {
            return 300;
        }
        else if (this.selectedChartHeight == ChartHeight.LARGE) {
            return 500;
        }
        else {
            return Math.floor(70 * (screen.height / 100));
        }
    }
    changeChartHeight() {
        this.destroyChart();
        this.chartHeightPixel = this.getChartHeight();
        this.loadChartServices();
        this.createChart();
        this.$emit('changeUserSetting', this.selectedChartHeight);
    }
    reloadChart() {
        this.destroyChart();
        this.createChart();
    }
    beforeDestroy() {
        this.destroyChart();
    }
    destroyChart() {
        if (this.chartEntity)
            this.chartEntity.destroyChart();
    }
    changeChartServices(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsScaleByData, event);
    }
    applyLoggersFilter() {
        this.isLoggersFilterApplied = true;
    }
    changeApplyButton() {
        this.isLoggersFilterApplied = false;
    }
    loadChartServices() {
        this.chartServices = [];
        this.eventTypes.forEach((event) => {
            if (event.name.toLowerCase() == ChartServices.HUMIDITY ||
                event.name.toLowerCase() == ChartServices.PRESSURE ||
                event.name.toLowerCase() == ChartServices.TEMPERATURE) {
                this.chartServices.push(event.name.toLowerCase());
            }
        });
        if (this.chartServices.length > 1)
            this.chartServices.unshift(ChartServices.ALL);
    }
    selectChartServices(item) {
        let tempServices = document.getElementById(ChartServices.TEMPERATURE);
        let humidityServices = document.getElementById(ChartServices.HUMIDITY);
        let pressureServices = document.getElementById(ChartServices.PRESSURE);
        let allServices = document.getElementById(ChartServices.ALL);
        //Changing style for sevices selection tab
        if (tempServices)
            tempServices.classList.remove('is-active');
        if (humidityServices)
            humidityServices.classList.remove('is-active');
        if (pressureServices)
            pressureServices.classList.remove('is-active');
        if (allServices)
            allServices.classList.remove('is-active');
        switch (item) {
            case ChartServices.TEMPERATURE:
                tempServices.classList.add('is-active');
                break;
            case ChartServices.PRESSURE:
                pressureServices.classList.add('is-active');
                break;
            case ChartServices.HUMIDITY:
                humidityServices.classList.add('is-active');
                break;
            default:
                allServices.classList.add('is-active');
                break;
        }
        this.selectedChartService = item;
    }
    async createChart() {
        this.isHasBoundaries = false;
        let reportChartDataConverter = new ReportChartDataConverter(this.reportRecords, this.eventTypes, this.sourceGroupSources, this.annotationsForSources);
        if (reportChartDataConverter.boundaries.length > 0) {
            this.isHasBoundaries = true;
        }
        let rightMargin = reportChartDataConverter.services.length > 1 ? 30 : 15;
        let settings = {
            fullHeight: this.chartHeightPixel,
            previewChartHeight: 70,
            gapBetweenCharts: 20,
            margin: {
                left: 30,
                right: rightMargin
            },
            marginPreview: {
                left: 30,
                right: rightMargin
            }
        };
        let dateRange = {
            startTS: this.dateFilter.startTS * 1000,
            stopTS: this.dateFilter.stopTS * 1000
        };
        if (reportChartDataConverter.hasData) {
            this.availableChartLogger = reportChartDataConverter.data;
            let availableChartLogger = this.availableChartLogger.filter((x, i, a) => a.findIndex((x2) => x.name === x2.name) === i);
            this.availableChartLogger = availableChartLogger;
            this.hasDataForTimerange = true;
            this.chartEntity = new ChartBuilder(this.chartId, settings, reportChartDataConverter.services, reportChartDataConverter.data)
                .addBoundaries(reportChartDataConverter.boundaries, false)
                .makeInteractive()
                .setDateFormatter(await reportChartDataConverter.getTimeFormatter(this, this.onPublicPage))
                .setChartDateFormatter(await reportChartDataConverter.getChartTimeFormatter(this, this.onPublicPage))
                .setOnlyTimeFormatter(await reportChartDataConverter.getOnlyTimeFormatter(this, this.onPublicPage))
                .setOnlyDateFormatter(await reportChartDataConverter.getOnlyDateFormatter(this, this.onPublicPage))
                .addPreviewChart()
                .addGridlines(true, true)
                .setDateRange(dateRange)
                .setLabelPosition(LabelPosition.UP)
                .addLegend(LegendOrientation.ROW)
                .addZoomButtons()
                .addServiceButtonsHandling()
                .addScaleButton(this.isScaleByData)
                .addLoggersFilter()
                .addLegendsFilter()
                .setFontSize('12px')
                .build();
        }
        else {
            this.hasDataForTimerange = false;
        }
        this.$emit('axisRangeSelected', this.chartEntity ? this.chartEntity.axisScales : []);
        this.$emit('eventHandler', this.hasDataForTimerange, this.chartEntity, ChartComponents.REPORTSOVERALLCHART);
    }
    downloadCSV() {
        this.$emit('downloadCSV');
    }
    downloadPDF() {
        this.$emit('downloadPDF');
    }
    downloadSample() {
        this.$emit('downloadSample', pdf);
    }
};
__decorate([
    Prop({ type: Object })
], ReportsOverallChart.prototype, "reportRecords", void 0);
__decorate([
    Prop({ type: Array })
], ReportsOverallChart.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportsOverallChart.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Object })
], ReportsOverallChart.prototype, "dateFilter", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ReportsOverallChart.prototype, "onPublicPage", void 0);
__decorate([
    Prop({ type: String })
], ReportsOverallChart.prototype, "chartHeight", void 0);
__decorate([
    Prop({ type: Array })
], ReportsOverallChart.prototype, "annotationsForSources", void 0);
ReportsOverallChart = __decorate([
    Component({
        components: {}
    })
], ReportsOverallChart);
export default ReportsOverallChart;
