var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-collapse",
        {
          staticClass: "panel outdated",
          attrs: { animation: "slide" },
          on: {
            open: function ($event) {
              return _vm.stateDataCollapseChanged(true)
            },
            close: function ($event) {
              return _vm.stateDataCollapseChanged(false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "panel-heading m-0 p-0",
                      attrs: { role: "button" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "has-text-weight-medium is-flex is-justify-content-space-between",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "is-flex" },
                            [
                              _c("h2", { staticClass: "subtitle is-4 m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.device.devicesStates.title"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.deviceStatesVisible
                                ? [
                                    _vm.offlineCount > 0
                                      ? _c(
                                          "b-tag",
                                          {
                                            staticClass: "ml-3 mt-1",
                                            attrs: {
                                              type: "is-primary",
                                              rounded: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "is-flex is-align-items-center",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "offline_svg mr-1 white",
                                                  staticStyle: {
                                                    height: "13px",
                                                    width: "13px",
                                                  },
                                                }),
                                                _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "component.device.devicesStates.offline",
                                                          {
                                                            count:
                                                              _vm.offlineCount,
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.onlineCount > 0
                                      ? _c(
                                          "b-tag",
                                          {
                                            staticClass: "ml-3 mt-1",
                                            attrs: {
                                              type: "is-success",
                                              rounded: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "is-flex is-align-items-center",
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass: "mr-1",
                                                  attrs: { icon: "wifi" },
                                                }),
                                                _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "component.device.devicesStates.online",
                                                          {
                                                            count:
                                                              _vm.onlineCount,
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.deviceStatesVisible
                            ? _c("b-icon", {
                                attrs: { icon: "chevron-down mt-1" },
                              })
                            : _c("b-icon", {
                                attrs: { icon: "chevron-right mt-1" },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.deviceStatesVisible,
            callback: function ($$v) {
              _vm.deviceStatesVisible = $$v
            },
            expression: "deviceStatesVisible",
          },
        },
        [
          _c(
            "b-table",
            {
              ref: "table",
              staticClass: "mt-3",
              attrs: {
                data: _vm.deviceList,
                paginated: "",
                "backend-pagination": "",
                "per-page": "6",
                total: _vm.deviceList.length,
                striped: "",
                "default-sort-direction": "asc",
                "default-sort": "isOnline",
              },
            },
            [
              _c("b-table-column", {
                attrs: {
                  label: _vm.$t("component.device.list.table.isOnline"),
                  field: "isOnline",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (deviceList) {
                      return [
                        _c(
                          "div",
                          { staticClass: "is-flex is-align-items-center" },
                          [
                            _c("b-icon", {
                              staticClass: "is-size-7",
                              attrs: {
                                type:
                                  deviceList.row.isOnline == true
                                    ? "is-success"
                                    : "is-danger",
                                icon: "circle",
                              },
                            }),
                            deviceList.row.isOnline == true
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("generals.online"))),
                                ])
                              : _c(
                                  "span",
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("generals.offline")) + " "
                                    ),
                                    deviceList.row.lastSeen
                                      ? _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              type: "is-dark",
                                              label: _vm.getOfflineTime(
                                                new Date(
                                                  deviceList.row.lastSeen * 1000
                                                )
                                              ),
                                              position: "is-left",
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                size: "is-small",
                                                icon: "question-circle",
                                                type: "is-grey",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "deviceName",
                  label: _vm.$t("component.device.list.table.deviceName"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (deviceList) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-wrap-anywhere",
                            attrs: {
                              to: {
                                name: "deviceConf",
                                params: {
                                  deviceId: deviceList.row.deviceId,
                                  departmentId: _vm.$route.params.departmentId,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(deviceList.row.deviceName) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "lastSeen",
                  label: _vm.$t("component.device.list.table.lastSeen"),
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (deviceList) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              deviceList.row.lastSeen
                                ? _vm.getDayTime(
                                    new Date(deviceList.row.lastSeen * 1000)
                                  )
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "admin.component.devices.deviceList.table.empty"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }