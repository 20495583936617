var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("section", [
      _c("div", { staticClass: "columns columns-info-form" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("tiers.name") } },
              [_c("span", [_vm._v(_vm._s(_vm.activeTier.name))])]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("tiers.id") } },
              [_c("span", [_vm._v(_vm._s(_vm.activeTier.id))])]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("tiers.tier_id") } },
              [_c("span", [_vm._v(_vm._s(_vm.activeTier.tierId))])]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("tiers.active") } },
              [
                _c("span", [
                  _vm.activeTier.active
                    ? _c("i", { staticClass: "fas fa-check" })
                    : _c("i", { staticClass: "fas fa-times" }),
                ]),
              ]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("tiers.created_at") } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.activeTier.createdAt * 1000),
                        null,
                        null,
                        null
                      )
                    )
                  ),
                ]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: { horizontal: "", label: _vm.$t("tiers.activated_at") },
              },
              [
                _vm.activeTier.activatedAt
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(_vm.activeTier.activatedAt * 1000),
                            null,
                            null,
                            null
                          )
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("tiers.deactivated_at"),
                },
              },
              [
                _vm.activeTier.deactivatedAt
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(_vm.activeTier.deactivatedAt * 1000),
                            null,
                            null,
                            null
                          )
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("-")]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "column" }, [
          _c("p", { staticClass: "has-text-weight-bold is-size-5" }, [
            _vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " "),
          ]),
          _c("div", [
            _c(
              "ul",
              {
                staticClass: "has-text-dark",
                staticStyle: { "list-style-type": "disc" },
              },
              _vm._l(_vm.activeTier.features, function (feature, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class:
                      _vm.activeTier.enabledFeatures.find(
                        (x) => x.Name == feature.Name
                      ) == null
                        ? "has-text-weight-light has-text-grey has-text-grey-dark-mode"
                        : "has-text-weight-semibold",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("tiers.features." + feature.Name)) + " "
                      ),
                    ]),
                    feature.Value.Quota
                      ? _c("span", [
                          _vm._v("(" + _vm._s(feature.Value.Quota)),
                          feature.Value.AllowOverflow
                            ? _c("span", [_vm._v("+")])
                            : _vm._e(),
                          _vm._v(")"),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }