import { render, staticRenderFns } from "./NotificationsStep.vue?vue&type=template&id=48df3b17"
import script from "./NotificationsStep.vue?vue&type=script&lang=ts"
export * from "./NotificationsStep.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48df3b17')) {
      api.createRecord('48df3b17', component.options)
    } else {
      api.reload('48df3b17', component.options)
    }
    module.hot.accept("./NotificationsStep.vue?vue&type=template&id=48df3b17", function () {
      api.rerender('48df3b17', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/wizard/steps/NotificationsStep.vue"
export default component.exports