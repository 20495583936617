import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import AppConfig from '@/configLoader';
import ApiLinks from '@/entities/ApiLinks';
import AxiosService from '@/services/api/AxiosService';
import cbor from 'cbor';
let DeviceMonitor = class DeviceMonitor extends Vue {
    constructor() {
        super(...arguments);
        this.logs = [];
        this.LogType = LogType;
        this.parseCbor = true;
    }
    created() {
        this.listenForLogs();
    }
    async beforeDestroy() {
        await this.connection.stop();
    }
    async resultRetrieved(event) {
        await this.connection.stop();
        this.$emit('modalClosed', event);
    }
    async cborHexToJson(hexString) {
        // Convert the hex string to a Buffer/Uint8Array
        const cborBytes = Uint8Array.from(Buffer.from(hexString, 'hex'));
        // Decode the CBOR data to a JavaScript object
        const decodedData = await cbor.decodeFirst(cborBytes);
        // Convert the decoded data to JSON
        return JSON.stringify(decodedData, null, 2); // Pretty-print JSON
    }
    async getBodyMessage(bodyMessage) {
        let parsedBody = bodyMessage;
        try {
            parsedBody = JSON.parse(bodyMessage);
        }
        catch (error) {
            if (typeof parsedBody != 'object') {
                try {
                    await this.cborHexToJson(bodyMessage).then((result) => {
                        if (result) {
                            parsedBody = result;
                        }
                    });
                }
                catch (error) {
                    parsedBody = bodyMessage;
                }
            }
        }
        return parsedBody;
    }
    async listenForLogs() {
        let options = {
            accessTokenFactory: () => {
                return new Promise((resolve, reject) => {
                    let token = AxiosService.GetWebSocketOptions(this);
                    if (token == null || token.length === 0)
                        reject();
                    resolve(token);
                });
            }
        };
        this.connection = new HubConnectionBuilder()
            .withUrl(`${AppConfig.getConfig().backend.address}${ApiLinks.DeviceDebug.Home}`, options)
            .configureLogging(LogLevel.Information)
            .build();
        this.connection
            .start()
            .then(() => {
            this.connection.invoke('joinGroup', this.device ? this.device.deviceId : 'all');
        })
            .catch((error) => console.log(error));
        this.connection.on('DeviceRequest', async (data) => {
            let parsedData = await this.getBodyMessage(data.RequestBody);
            let rawMsg = data;
            let parsedMessage = {
                type: LogType.REQUEST,
                deviceId: data.DeviceId,
                messageId: data.RequestId,
                path: data.Path,
                body: data.RequestBody,
                parsedBody: parsedData,
                headers: data.RequestHeaders,
                claims: data.Claims,
                time: new Date().getTime(),
                rawMsg: rawMsg
            };
            this.logs.push(parsedMessage);
        });
        this.connection.on('DeviceResponse', async (data) => {
            let parsedData = await this.getBodyMessage(data.ResponseBody);
            let rawMsg = data;
            let parsedMessage = {
                type: LogType.RESPONSE,
                deviceId: data.DeviceId,
                messageId: data.RequestId,
                path: data.Path,
                body: data.ResponseBody,
                parsedBody: parsedData,
                headers: data.ResponseHeaders,
                responseStatusCode: data.ResponseStatusCode,
                time: new Date().getTime(),
                rawMsg: rawMsg
            };
            this.logs.push(parsedMessage);
        });
    }
};
__decorate([
    Prop({ type: Object })
], DeviceMonitor.prototype, "device", void 0);
__decorate([
    Prop({ type: Object })
], DeviceMonitor.prototype, "dateTimeManager", void 0);
DeviceMonitor = __decorate([
    Component({})
], DeviceMonitor);
export default DeviceMonitor;
var LogType;
(function (LogType) {
    LogType["REQUEST"] = "request";
    LogType["RESPONSE"] = "response";
})(LogType || (LogType = {}));
