var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "small" }, [
    _c(
      "div",
      [
        _c("h2", { staticClass: "title is-2" }, [
          _vm._v(_vm._s(_vm.$t("component.login.title"))),
        ]),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("component.login.loginName"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.login.loginName")
                ),
              },
              message: _vm.errors.first(_vm.$t("component.login.loginName")),
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              ref: "email",
              attrs: {
                name: _vm.$t("component.login.loginName"),
                type: "text",
                placeholder: _vm.$t("component.login.loginName"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitCredentialsAsync.apply(null, arguments)
                },
              },
              model: {
                value: _vm.username,
                callback: function ($$v) {
                  _vm.username = $$v
                },
                expression: "username",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("generals.password"),
              type: {
                "is-danger": _vm.errors.has(_vm.$t("generals.password")),
              },
              message: _vm.errors.first(_vm.$t("generals.password")),
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                name: _vm.$t("generals.password"),
                type: "password",
                "password-reveal": "",
                placeholder: _vm.$t("generals.password"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitCredentialsAsync.apply(null, arguments)
                },
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
          ],
          1
        ),
        _c(
          "b-button",
          {
            attrs: {
              type: "is-primary",
              disabled: _vm.loginButtonDisabled,
              expanded: "",
            },
            on: {
              click: function ($event) {
                return _vm.submitCredentialsAsync()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("component.login.login")))]
        ),
        _c("div", { staticClass: "level mt-2 mb-4" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "forgotPassword",
                      params: {
                        lang: _vm.$route.params.lang,
                      },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("component.login.forgot")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "register",
                      params: {
                        lang: _vm.$route.params.lang,
                      },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("component.login.register")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }