var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "columns",
        class: { "is-desktop is-multiline": !_vm.isBasic },
      },
      [
        _c(
          "div",
          {
            staticClass: "column is-one-quarter is-one-quarter-widescreen",
            class: { "is-two-thirds-desktop": !_vm.isBasic },
          },
          [
            _c("h2", { staticClass: "subtitle mb-1" }, [
              _vm._v(" " + _vm._s(_vm.titleText) + " "),
              _c(
                "span",
                [
                  _c("b-switch", {
                    attrs: { size: "is-small", disabled: !_vm.editIsAllowed },
                    on: { input: _vm.save },
                    model: {
                      value: _vm.currentEnabled,
                      callback: function ($$v) {
                        _vm.currentEnabled = $$v
                      },
                      expression: "currentEnabled",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "p",
              {
                staticClass:
                  "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
              },
              [_vm._v(" " + _vm._s(_vm.descriptionText) + " ")]
            ),
          ]
        ),
        !_vm.currentEnabled
          ? _c(
              "div",
              { staticClass: "column is-narrow" },
              [
                _c(
                  "b-notification",
                  {
                    attrs: {
                      type: "is-danger is-light",
                      role: "alert",
                      closable: false,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.turnedOffText) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentEnabled
          ? _c(
              "div",
              {
                staticClass: "column is-three-quarters-widescreen",
                class: { "is-full-desktop": !_vm.isBasic },
              },
              [
                _c("div", { staticClass: "b-table" }, [
                  _c("div", { staticClass: "table-wrapper has-mobile-cards" }, [
                    _c(
                      "table",
                      { staticClass: "table custom-notifications-table" },
                      [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              !_vm.isBasic
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "notifications.configuration.settings.head"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.type.channel, function (channel) {
                                return _c(
                                  "th",
                                  { key: channel.type + "_head" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "notification_channels." +
                                              channel.type
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm.advancedAllowed
                          ? _c("tfoot", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: _vm.numberOfColumns } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "has-text-right" },
                                      [
                                        _vm.isBasic
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "is-size-7 has-text-primary clickable-text-small",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeDisplayMode(
                                                      _vm
                                                        .NotificationRuleDisplayMode
                                                        .ADVANCED
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.show_advanced"
                                                    )
                                                  )
                                                ),
                                                _c("b-icon", {
                                                  attrs: {
                                                    icon: "chevron-down",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "is-size-7 has-text-primary clickable-text-small",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeDisplayMode(
                                                      _vm
                                                        .NotificationRuleDisplayMode
                                                        .BASIC
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notifications.configuration.hide_advanced"
                                                    )
                                                  )
                                                ),
                                                _c("b-icon", {
                                                  attrs: { icon: "chevron-up" },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("tbody", [
                          _c(
                            "tr",
                            [
                              !_vm.isBasic
                                ? _c("td", [
                                    _c("div", [
                                      _c(
                                        "h3",
                                        { staticClass: "has-text-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.channel_title"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.channel_desc"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.type.channel, function (channel) {
                                return _c(
                                  "td",
                                  {
                                    key: channel.type + "_switch",
                                    attrs: {
                                      "data-label": _vm.$t(
                                        "notification_channels." + channel.type
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-switch", {
                                      attrs: {
                                        type: "is-primary",
                                        disabled: !_vm.editIsAllowed,
                                      },
                                      on: { input: _vm.save },
                                      model: {
                                        value:
                                          _vm.currentChannels[channel.type]
                                            .enabled,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentChannels[channel.type],
                                            "enabled",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentChannels[channel.type].enabled",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          !_vm.isBasic && _vm.typeHasDelay(_vm.type.name)
                            ? _c(
                                "tr",
                                [
                                  _c("td", [
                                    _c("div", [
                                      _c(
                                        "h3",
                                        { staticClass: "has-text-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "notifications.configuration.settings.delay_title"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.delay_desc"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._l(_vm.type.channel, function (channel) {
                                    return _c(
                                      "td",
                                      {
                                        key: channel.type + "_delay",
                                        attrs: {
                                          "data-label": _vm.$t(
                                            "notification_channels." +
                                              channel.type
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-field",
                                          [
                                            _c(
                                              "b-select",
                                              {
                                                staticClass: "fullwidth-select",
                                                attrs: {
                                                  name: `${_vm.$t(
                                                    "notifications.configuration.settings.delay_title"
                                                  )} (${_vm.$t(
                                                    "notification_channels." +
                                                      channel.type
                                                  )})`,
                                                  placeholder: _vm.$t(
                                                    "notifications.configuration.settings.delay_title"
                                                  ),
                                                  disabled:
                                                    !_vm.currentChannels[
                                                      channel.type
                                                    ].enabled ||
                                                    !_vm.editIsAllowed,
                                                },
                                                on: { input: _vm.save },
                                                model: {
                                                  value:
                                                    _vm.currentChannels[
                                                      channel.type
                                                    ].delay,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.currentChannels[
                                                        channel.type
                                                      ],
                                                      "delay",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentChannels[channel.type].delay",
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: 0 } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "notifications.configuration.values.delay_off"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.delayOptions,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: option,
                                                        domProps: {
                                                          value: option,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.values." +
                                                                  option
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          !_vm.isBasic && _vm.typeHasRepeat(_vm.type.name)
                            ? _c(
                                "tr",
                                [
                                  _c("td", [
                                    _c("div", [
                                      _c(
                                        "h3",
                                        { staticClass: "has-text-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.repeat_title"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.repeat_desc"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._l(_vm.type.channel, function (channel) {
                                    return _c(
                                      "td",
                                      {
                                        key: channel.type + "_repeat",
                                        attrs: {
                                          "data-label": _vm.$t(
                                            "notification_channels." +
                                              channel.type
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-field",
                                          [
                                            _c(
                                              "b-select",
                                              {
                                                staticClass: "fullwidth-select",
                                                attrs: {
                                                  name: `${_vm.$t(
                                                    "notifications.configuration.settings.repeat_title"
                                                  )} (${_vm.$t(
                                                    "notification_channels." +
                                                      channel.type
                                                  )})`,
                                                  placeholder: _vm.$t(
                                                    "notifications.configuration.settings.repeat_title"
                                                  ),
                                                  disabled:
                                                    !_vm.currentChannels[
                                                      channel.type
                                                    ].enabled ||
                                                    !_vm.editIsAllowed,
                                                },
                                                on: { input: _vm.save },
                                                model: {
                                                  value:
                                                    _vm.currentChannels[
                                                      channel.type
                                                    ].repeat,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.currentChannels[
                                                        channel.type
                                                      ],
                                                      "repeat",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentChannels[channel.type].repeat",
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: -1 } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "notifications.configuration.values.repeat_off"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.RepeatOptions.getValues(),
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: option,
                                                        domProps: {
                                                          value: option,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notifications.configuration.values." +
                                                                  option
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          !_vm.isBasic && _vm.typeHasOnResolve(_vm.type.name)
                            ? _c(
                                "tr",
                                [
                                  _c("td", [
                                    _c("div", [
                                      _c(
                                        "h3",
                                        { staticClass: "has-text-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "notifications.configuration.settings.resolve_title"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "is-size-7 is-italic has-text-grey has-text-grey-dark-mode",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.onResolveDesc) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._l(_vm.type.channel, function (channel) {
                                    return _c(
                                      "td",
                                      {
                                        key: channel.type + "_resolve",
                                        attrs: {
                                          "data-label": _vm.$t(
                                            "notification_channels." +
                                              channel.type
                                          ),
                                        },
                                      },
                                      [
                                        _vm.channelForTypeHasOnResolve(
                                          channel.type
                                        )
                                          ? _c("b-switch", {
                                              attrs: {
                                                type: "is-primary",
                                                disabled:
                                                  !_vm.currentChannels[
                                                    channel.type
                                                  ].enabled ||
                                                  !_vm.editIsAllowed,
                                              },
                                              on: { input: _vm.save },
                                              model: {
                                                value:
                                                  _vm.currentChannels[
                                                    channel.type
                                                  ].onResolve,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentChannels[
                                                      channel.type
                                                    ],
                                                    "onResolve",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentChannels[channel.type].onResolve",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }