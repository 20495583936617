import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminFirmwareDetail from '@/views/components/admin/devices/AdminFirmwareDetail.vue';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import { AdminFirmware } from '@/entities/models/AdminFirmware';
import FirmwareRepository from '@/services/repository/FirmwareRepository';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
var firmwareRepository;
var deviceRepository;
let AdminFirmwareDetailManagement = class AdminFirmwareDetailManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.deviceList = new Array();
        this.firmware = new AdminFirmware();
    }
    async created() {
        deviceRepository = new DeviceRepository(this);
        firmwareRepository = new FirmwareRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        let deviceResult;
        deviceResult = await deviceRepository.getAdminDevices();
        this.deviceList = deviceResult.getData();
        this.firmware = await firmwareRepository.getFirmware(this.$route.params.firmwareId);
        this.isLoading = false;
    }
    async deleteFirmware() {
        this.isLoading = true;
        let result = await firmwareRepository.deleteFirmware(this.firmware.id);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.deleteFirmwareModel.success'));
            this.$router
                .push({
                name: 'adminDevices',
                params: {
                    lang: this.$route.params.lang
                }
            })
                .catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.devices.deleteFirmwareModel.failure'));
        }
        this.isLoading = false;
    }
    async firmwareChanges(firmware) {
        if (this.isLoading === true)
            return;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let data = {
                    Id: firmware.id,
                    Version: firmware.version,
                    CreatedAt: firmware.createdAt,
                    CreatedBy: firmware.createdBy,
                    Data: firmware.data,
                    Assets: firmware.assets
                };
                let result = await firmwareRepository.editFirmware(this.firmware.id, data);
                if (result) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.updateFirmware.success'));
                    this.loadData();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.updateFirmware.failure'));
                }
            }
        });
        this.isLoading = false;
    }
};
AdminFirmwareDetailManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS) &&
                    vm.$ability.can(Actions.READ, Subjects.ADMIN_GATEWAYS_FIRMWARES)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { AdminFirmwareDetail }
    })
], AdminFirmwareDetailManagement);
export default AdminFirmwareDetailManagement;
