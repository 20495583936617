var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading &&
      _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.ADMIN_GATEWAYS)
        ? _c(
            "div",
            [
              _c("AdminDeviceDetail", {
                attrs: {
                  deviceUpdates: _vm.deviceUpdates,
                  device: _vm.device,
                  deviceFirmwareList: _vm.deviceFirmwareList.getData() || [],
                  commands: _vm.commands.getData() || [],
                  deviceInstances: _vm.deviceInstances,
                  currentCredentials: _vm.currentCredentials,
                  historyCredentials: _vm.historyCredentials,
                  dateTimeManager: _vm.dateTimeManager,
                },
                on: {
                  loadAll: _vm.loadAll,
                  loadCredentials: _vm.loadCredentials,
                  loadCommandsAndUpdates: _vm.loadCommandsAndUpdates,
                  loadHistory: _vm.loadHistory,
                  reload: function ($event) {
                    return _vm.loadData()
                  },
                  revertDevice: _vm.revertDevice,
                  revokeCredential: _vm.revokeCredential,
                  newCredential: _vm.newCredential,
                  loadNewCredential: _vm.loadNewCredential,
                  returnDevice: _vm.returnDevice,
                  disableCommand: _vm.disableCommand,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }