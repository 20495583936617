import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Notification } from '@/entities/Notification';
import NotificationsTable from '@/views/components/notification/NotificationsTable.vue';
import NotificationRepository from '@/services/repository/NotificationRepository';
import DateTimeManager from '@/services/DateTimeManager';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import PauseNotificationsMessage from '@/views/components/notification/PauseNotificationsMessage.vue';
import ApiResponse from '@/entities/ApiResponse';
import { vxm } from '@/store/store.vuex';
import UserPreferences from '@/services/UserPreferences';
import IssuesTable from '../components/issues/IssuesTable.vue';
import IssueRepository from '@/services/repository/IssueRepository';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
var issueRepository;
var vxDepartmentStore = vxm.departmentStore;
var notificationRepository;
var userSettingsRepository;
var deviceRepository;
var sourceRepository;
var sourceGroupRepository;
let NotificationsBoard = class NotificationsBoard extends Vue {
    constructor() {
        super(...arguments);
        this.dateTimeManager = null;
        this.currentFilter = [];
        this.dateRange = [];
        this.notificationsResult = new ApiResponse();
        this.pauseDismissedAt = 0;
        this.pauseNotificationsUntil = 0;
        this.isLoading = true;
        this.preselectedFilterCategories = [];
        this.activeTab = UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
        this.issuesLoaded = false;
        this.issuesFilters = [];
        this.devices = [];
        this.sources = [];
        this.sourceGroups = [];
        this.departmentIssues = null;
        this.SELECTED_DEPARTMENT_ID = null;
    }
    get pauseComponentVisible() {
        if (this.notificationsResult)
            return Notification.pauseComponentIsVisible(this.pauseDismissedAt, this.pauseNotificationsUntil, this.notificationsResult.getData());
    }
    get notificationsPaused() {
        let now = Math.floor(new Date().getTime() / 1000);
        return this.pauseNotificationsUntil > now;
    }
    async created() {
        issueRepository = new IssueRepository(this);
        notificationRepository = new NotificationRepository(this);
        deviceRepository = new DeviceRepository(this);
        sourceRepository = new SourceRepository(this);
        userSettingsRepository = await UserSettingsRepository.getInstance(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        let settings = await userSettingsRepository.loadUserSettings();
        this.pauseDismissedAt = parseInt(settings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(settings.notifications.pause.until);
        this.activeTab = parseInt(this.$route.query.activeTab)
            ? parseInt(this.$route.query.activeTab)
            : UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
        this.changedActiveTab(this.activeTab);
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartment().id;
    }
    preload() {
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartment().id;
        this.loadDevices();
        this.loadLoggers();
        this.loadSourceGroups();
        this.$refs.notificationsTable.loadNotifications();
        this.$refs.issuesTable.initLoadIssues();
    }
    async loadDevices() {
        this.devices = (await deviceRepository.getDevices(null, null)).getData();
    }
    async loadLoggers() {
        this.sources = (await sourceRepository.getVisibleSources(null, null)).getData();
    }
    async loadSourceGroups() {
        this.sourceGroups = (await sourceGroupRepository.getSourceGroups()).getData();
    }
    async onIssuesTableOptionsChange(options, filters = []) {
        this.issuesFilters = filters;
        this.isLoading = true;
        this.departmentIssues = await issueRepository.getDepartmentIssues(options.pagination, options.sort, filters);
        this.isLoading = false;
    }
    convertDateRange() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let dateRange = [yesterday, today];
        return dateRange.map((x) => this.dateTimeManager.getTimeStringForInputDate(x));
    }
    filterExists(filter) {
        return this.issuesFilters.findIndex((element) => element.includes(filter));
    }
    replaceFilter(filter, value) {
        let index = this.filterExists(filter);
        if (index !== -1) {
            if (!value) {
                this.issuesFilters.splice(index, 1);
            }
            else {
                this.issuesFilters[index] = filter + value;
            }
        }
        else if (value)
            this.issuesFilters.push(filter + value);
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    acknowledgeIssueDialog(issueId) {
        this.$buefy.dialog.confirm({
            title: this.$t('component.issues.acknowledgeModal.title'),
            message: this.$t('component.issues.acknowledgeModal.body'),
            confirmText: this.$t('component.issues.acknowledgeModal.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.acknowledgeIssue(issueId);
            }
        });
    }
    async acknowledgeIssue(issueId) {
        this.isLoading = true;
        let result = await issueRepository.acknowledgeIssue(issueId);
        if (result == true) {
            VueUtilities.openSuccessToast(this, this.$t('component.issues.acknowledgeModal.success'));
            this.onIssuesTableOptionsChange(null, this.issuesFilters);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.issues.acknowledgeModal.failure'));
        }
        this.isLoading = false;
    }
    async changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab, event);
        switch (this.activeTab) {
            case SelectedTab.ISSUES:
                if (!this.issuesLoaded) {
                    this.loadDevices();
                    this.loadLoggers();
                    this.loadSourceGroups();
                    this.issuesLoaded = true;
                }
                break;
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    async loadNotifications(pagination, sort, filters = [], dateRange = [], departmentId = '') {
        this.isLoading = true;
        this.currentFilter = filters;
        this.dateRange = dateRange;
        if (this.SELECTED_DEPARTMENT_ID !== this.getSelectedDepartment().id)
            this.notificationsResult = null;
        let convertedDateRange = [];
        if (this.dateRange?.length > 0) {
            convertedDateRange = [
                this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                this.dateTimeManager.formatEndDateForReports(this.dateRange[1], true).text
            ];
        }
        this.notificationsResult = await notificationRepository.getNotifications(pagination, sort, filters, convertedDateRange, departmentId ? departmentId + '|null' : departmentId);
        this.isLoading = false;
    }
    async onOptionsChange(options, filters = [], dateRange = [], departmentId = '') {
        this.callOptions = options;
        this.currentFilter = filters;
        this.dateRange = dateRange;
        this.loadNotifications(options.pagination, options.sort, filters, dateRange, departmentId);
    }
    async notificationRemoved(notifIds, pagination, sort, filters = [], dateRange = []) {
        this.isLoading = true;
        await notificationRepository.markNotificationsAsRead(notifIds);
        await this.$root.$emit('reloadNotifications');
        this.isLoading = false;
    }
    async removeAllNotifications() {
        this.isLoading = true;
        await notificationRepository.markAllNotificationsAsRead();
        await this.$root.$emit('reloadNotifications');
        this.isLoading = false;
    }
    async reloadNotificationPause() {
        this.isLoading = true;
        let settings = await userSettingsRepository.loadUserSettings();
        this.pauseDismissedAt = parseInt(settings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(settings.notifications.pause.until);
        this.isLoading = false;
    }
};
NotificationsBoard = __decorate([
    Component({
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartment().id) {
                this.preload();
            }
            next(); // to resolve hook
        },
        components: { NotificationsTable, PauseNotificationsMessage, IssuesTable }
    })
], NotificationsBoard);
export default NotificationsBoard;
var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["NOTIFICATIONS"] = 0] = "NOTIFICATIONS";
    SelectedTab[SelectedTab["ISSUES"] = 1] = "ISSUES";
})(SelectedTab || (SelectedTab = {}));
