var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h1", { staticClass: "title is-3 is-spaced has-text-centered-touch" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("component.settings.device.device_settings")) + " "
      ),
    ]),
    _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
      _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.DEVICE) &&
      _vm.dateTimeManager
        ? _c(
            "div",
            { staticClass: "tile is-parent" },
            [
              _c("DeviceList", {
                ref: "deviceList",
                staticClass: "box tile is-child",
                attrs: {
                  dateTimeManager: _vm.dateTimeManager,
                  pageSize: _vm.PAGE_SIZE,
                  deviceList: _vm.deviceList,
                  allDevicesCount: _vm.allDevicesCount,
                },
                on: { pageChanged: _vm.pageChanged },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }