var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h1", { staticClass: "subtitle is-4" }, [
      _vm._v(" " + _vm._s(_vm.$t("contact_form.feedback")) + " "),
    ]),
    _c("div", [
      _c("p", { staticClass: "has-text-weight-bold" }, [
        _vm._v(_vm._s(_vm.feedback.subject)),
      ]),
      _c("p", [_vm._v(_vm._s(_vm.feedback.text))]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("contact_form.feedback_table.Id"),
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.feedback.id))])]
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("contact_form.feedback_table.Category"),
              },
            },
            [
              _vm.feedback.category
                ? _c(
                    "b-tag",
                    {
                      attrs: { type: _vm.feedback.category.type, rounded: "" },
                    },
                    [_vm._v(_vm._s(_vm.feedback.category.name))]
                  )
                : _c(
                    "b-tag",
                    {
                      attrs: {
                        type: _vm.FeedbackCategories.UNKNOWN.type,
                        rounded: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FeedbackCategories.UNKNOWN.name))]
                  ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("contact_form.feedback_table.CreatedAt"),
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.dateTimeManager.formatTime(
                      new Date(_vm.feedback.createdAt * 1000),
                      null,
                      null,
                      null
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("contact_form.feedback_table.SentById"),
              },
            },
            [
              _vm.feedback.sentBy.email !== null
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "adminUserDetail",
                          params: {
                            userId: _vm.feedback.sentBy.apiUserId,
                            title: _vm.$t("contact_form.feedback"),
                            lang: _vm.$route.params.lang,
                          },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.feedback.sentBy.fullName))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.feedback.sentBy.fullName))]),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { horizontal: "", label: _vm.$t("generals.email") } },
            [
              _vm.feedback.sentBy.email !== null
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "adminUserDetail",
                          params: {
                            userId: _vm.feedback.sentBy.apiUserId,
                            title: _vm.$t("contact_form.feedback"),
                            lang: _vm.$route.params.lang,
                          },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.feedback.sentBy.email))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.feedback.sentBy.email))]),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("contact_form.feedback_table.Status"),
              },
            },
            [
              _vm.feedback.status
                ? _c(
                    "b-tag",
                    { attrs: { type: _vm.feedback.status.type, rounded: "" } },
                    [_vm._v(_vm._s(_vm.feedback.status.name))]
                  )
                : _c(
                    "b-tag",
                    {
                      attrs: {
                        type: _vm.FeedbackStatus.UNKNOWN.type,
                        rounded: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FeedbackStatus.UNKNOWN.name))]
                  ),
              _vm.feedback.status.name != _vm.FeedbackStatus.CLOSED.name &&
              _vm.$ability.can(
                _vm.$permActions.UPDATE,
                _vm.$permSubjects.ADMIN_FEEDBACK
              )
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", size: "is-small" },
                      on: {
                        click: function ($event) {
                          return _vm.closeFeedback()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("contact_form.close")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }