var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("LoggerConfigurationList", {
        staticClass: "box",
        attrs: {
          companyLoggerList: _vm.companyLoggerList,
          sourceList: _vm.sourceList,
          inWizard: true,
          openAddLoggers: _vm.openLoggerScan,
          dateTimeManager: _vm.dateTimeManager,
          showHiddenLoggers: false,
          deviceId: _vm.deviceId,
        },
        on: {
          "reload-loggers": _vm.reloadData,
          "logger-modal-visible": _vm.onLoggerModalEvent,
        },
      }),
      _c("IdleLoggersList", {
        staticClass: "box",
        attrs: { companyLoggerList: _vm.companyLoggerList, inWizard: true },
        on: { "reload-loggers": _vm.reloadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }