var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("admin.component.loggers.allowedTypesModal.title")) +
            " (" +
            _vm._s(_vm.companyLogger.serialNumber) +
            ") "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c("b-message", { attrs: { type: "is-warning", "has-icon": "" } }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.loggers.allowedTypesModal.note")) +
              " "
          ),
        ]),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t("admin.component.loggers.create_logger.services"),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.create_logger.services")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.create_logger.services")
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "block" },
              _vm._l(_vm.companyLogger.availableTypes, function (type) {
                return _c(
                  "b-checkbox",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    key: type.id,
                    attrs: {
                      value: type.slug,
                      "native-value": type.slug,
                      name: _vm.$t(
                        "admin.component.loggers.create_logger.services"
                      ),
                    },
                    model: {
                      value: _vm.selectedEventTypes,
                      callback: function ($$v) {
                        _vm.selectedEventTypes = $$v
                      },
                      expression: "selectedEventTypes",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("services." + type.slug)) + " ")]
                )
              }),
              1
            ),
          ]
        ),
        _vm.selectedEventTypes.length > 0
          ? _c("p", [
              _c("span", { staticClass: "has-text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.loggers.create_logger.selected_services"
                    )
                  ) + ": "
                ),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.selectedEventTypes
                      .map((x) => _vm.$t("services." + x))
                      .join(", ")
                  )
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.selected()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t("admin.component.loggers.allowedTypesModal.confirm_btn")
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }