var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      { staticClass: "level-item is-flex-mobile" },
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.devices.selectDevice.selectDevice"
              ),
            },
          },
          [
            _c(
              "b-select",
              {
                attrs: {
                  name: _vm.$t(
                    "admin.component.devices.selectDevice.selectDevice"
                  ),
                  placeholder: _vm.$t(
                    "admin.component.devices.selectDevice.selectDevicePlaceholder"
                  ),
                },
                model: {
                  value: _vm.selectedDevice,
                  callback: function ($$v) {
                    _vm.selectedDevice = $$v
                  },
                  expression: "selectedDevice",
                },
              },
              _vm._l(_vm.deviceList, function (option) {
                return _c(
                  "option",
                  { key: option.deviceId, domProps: { value: option } },
                  [_vm._v(" " + _vm._s(option.deviceName) + " ")]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "level-item is-flex-mobile" },
      [
        _c(
          "b-button",
          {
            staticClass: "level-item",
            attrs: { type: "is-primary", disabled: _vm.selectedDevice == null },
            on: {
              click: function ($event) {
                return _vm.connectToDevice()
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.$t("admin.component.devices.selectDevice.connect_btn"))
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }