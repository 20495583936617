import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
var authenticationCalls;
let VerifyAccount = class VerifyAccount extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.verified = false;
        this.rawToken = null;
        this.registered = true;
        this.jwtToken = null;
        this.errorVerified = false;
        this.password = null;
        this.passwordConfirm = null;
    }
    created() {
        this.$validator.localize(this.$route.params.lang);
        authenticationCalls = new AuthenticationCalls(this);
        let queryData = this.$route.query;
        if (queryData.hasOwnProperty('token')) {
            this.rawToken = queryData.token;
            if (this.rawToken.split('.').length !== 3) {
                this.verify(this.rawToken);
            }
            else {
                try {
                    let parsedToken = JSON.parse(atob(this.rawToken.split('.')[1].replace('-', '+').replace('_', '/')));
                    this.registered = parsedToken.registered !== 'False';
                    this.jwtToken = parsedToken.token;
                    this.isLoading = false;
                }
                catch (error) { }
            }
        }
        else {
            this.isLoading = false;
        }
    }
    async finishPreregistration() {
        let passwordValid = await this.$refs.passwordFields.validate();
        this.$validator.validateAll().then(async (result) => {
            if (result && passwordValid) {
                this.isLoading = true;
                let response = await authenticationCalls.verifyAccount(null, this.rawToken, this.password);
                if (response === true) {
                    this.verified = true;
                    this.registered = true;
                }
                else {
                    this.errorVerified = true;
                }
                this.isLoading = false;
            }
        });
    }
    async verify(token) {
        let success = await authenticationCalls.verifyAccount(token, null, null);
        if (success === true) {
            this.verified = true;
            this.isLoading = false;
        }
        else {
            this.verified = false;
            this.isLoading = false;
        }
    }
};
VerifyAccount = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider
        }
    })
], VerifyAccount);
export default VerifyAccount;
