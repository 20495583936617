var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("admin.component.devices.detail.actions.keyUpdate")) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c(
        "p",
        {
          staticClass:
            "has-text-centered has-text-weight-bold has-text-primary",
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.devices.publickey_update.title")) +
              " "
          ),
        ]
      ),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.resultRetrieved(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-success" },
            on: {
              click: function ($event) {
                return _vm.updateKey()
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.$t("admin.component.devices.publickey_update.confirm"))
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }