var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "source-group-picker" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "columns is-multiline is-mobile" },
        [
          _vm._l(_vm.sourceGroups, function (group) {
            return _c(
              "div",
              {
                key: group.id,
                staticClass:
                  "group column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile",
                on: {
                  click: function ($event) {
                    return _vm.sourceGroupSelected(group.id)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "box has-text-centered",
                    class:
                      _vm.selectedSourceGroupId == group.id
                        ? "selected"
                        : "not-selected",
                  },
                  [
                    _vm.showFavoriteStarIcon
                      ? _c("b-icon", {
                          staticClass: "is-pulled-right",
                          attrs: {
                            "custom-class": "favorite-star",
                            type:
                              group.id == _vm.favoriteGroupId
                                ? "is-warning"
                                : "is-light",
                            size: "is-small",
                            icon: "star",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.favoriteSelected($event, group.id)
                            },
                          },
                        })
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        staticClass:
                          "is-size-5 has-text-primary has-text-weight-bold",
                      },
                      [_vm._v(" " + _vm._s(group.name) + " ")]
                    ),
                    _c("p", { staticClass: "has-text-weight-semibold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc("generals.source", group.sources.length)
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm.unassignedSources.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "group column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile",
                  on: {
                    click: function ($event) {
                      return _vm.sourceGroupSelected(
                        _vm.UserSettingsValues.UNASSIGNED_GROUP_ID
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box has-text-centered",
                      class:
                        _vm.selectedSourceGroupId ==
                        _vm.UserSettingsValues.UNASSIGNED_GROUP_ID
                          ? "selected"
                          : "not-selected",
                    },
                    [
                      _vm.showFavoriteStarIcon
                        ? _c("b-icon", {
                            staticClass: "is-pulled-right",
                            attrs: {
                              "custom-class": "favorite-star",
                              type:
                                _vm.favoriteGroupId ==
                                _vm.UserSettingsValues.UNASSIGNED_GROUP_ID
                                  ? "is-warning"
                                  : "is-light",
                              size: "is-small",
                              icon: "star",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.favoriteSelected(
                                  $event,
                                  _vm.UserSettingsValues.UNASSIGNED_GROUP_ID
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-size-5 has-text-primary has-text-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.source_group_picker.default_group_name"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "generals.source",
                                _vm.unassignedSources.length
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.allSources.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "group column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile",
                  on: {
                    click: function ($event) {
                      return _vm.sourceGroupSelected(
                        _vm.UserSettingsValues.ALL_GROUP_ID
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box has-text-centered",
                      class:
                        _vm.selectedSourceGroupId ==
                        _vm.UserSettingsValues.ALL_GROUP_ID
                          ? "selected"
                          : "not-selected",
                    },
                    [
                      _vm.showFavoriteStarIcon
                        ? _c("b-icon", {
                            staticClass: "is-pulled-right",
                            attrs: {
                              "custom-class": "favorite-star",
                              type:
                                _vm.favoriteGroupId ==
                                _vm.UserSettingsValues.ALL_GROUP_ID
                                  ? "is-warning"
                                  : "is-light",
                              size: "is-small",
                              icon: "star",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.favoriteSelected(
                                  $event,
                                  _vm.UserSettingsValues.ALL_GROUP_ID
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-size-5 has-text-primary has-text-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("generals.all")) + " ")]
                      ),
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc("generals.source", _vm.allSources.length)
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }