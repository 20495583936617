var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level mx-3 mx-0-desktop" }, [
        _c("h1", { staticClass: "title is-3" }, [
          _vm._v(" " + _vm._s(_vm.$t("tiers.management_title")) + " "),
        ]),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_TIERS_REQUESTS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("ChangeRequestsList", {
                      staticClass: "tile is-child box",
                      attrs: {
                        changeRequest: _vm.changeRequest,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_TIERS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("TiersList", {
                      staticClass: "tile is-child box",
                      attrs: { tiers: _vm.tiers },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.READ,
              _vm.$permSubjects.ADMIN_TIERS
            )
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("FeaturesList", {
                      staticClass: "tile is-child box",
                      attrs: { features: _vm.features },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }