var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-notification",
        {
          staticClass: "mx-3-mobile mx-3-touch mx-0-tablet",
          attrs: {
            type: "is-warning",
            "has-icon": "",
            "aria-close-label": "Close notification",
            role: "alert",
            closable: false,
          },
        },
        [
          _c("div", { staticClass: "multi-header" }, [
            _c("p", { staticClass: "pb-2 has-text-weight-semibold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "reports.report_settings.addNotificationRule.message"
                    )
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary" },
                    on: { click: _vm.redirectToNotification },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "reports.report_settings.addNotificationRule.addRule_Btn"
                        )
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }