import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LoggedUserManager from '@/services/LoggedUserManager';
import DepartmentIssueTypes from '@/entities/enums/DepartmentIssueTypes';
import IssuesFilterDateRangeOptions from '@/entities/enums/IssuesFilterDateRangeOptions';
let IssueTableFilter = class IssueTableFilter extends Vue {
    constructor() {
        super(...arguments);
        this.DepartmentIssueTypes = DepartmentIssueTypes;
        this.IssuesFilterDateRangeOptions = IssuesFilterDateRangeOptions;
        this.isLoggedInCompany = LoggedUserManager.isLoggedToCompany(this);
        this.isIncludeResolved = false;
        this.selectedType = null;
        this.selectedDateRange = [];
        this.today = new Date();
        this.dateRangeValue = null;
        this.dateRangeLabel = '';
        this.selectedDevice = null;
        this.selectedSource = null;
        this.selectedAdminDevice = null;
        this.dateRangeMessage = '';
    }
    selectDeviceUrl() {
        if (this.selectedTypeUrl)
            this.setDeviceOrSource();
    }
    selectSourceUrl() {
        if (this.selectedTypeUrl)
            this.setDeviceOrSource();
    }
    created() {
        this.setDateRangeLabel();
        this.setFilters();
    }
    setFilters() {
        if (this.isIncludeResolvedUrl)
            this.isIncludeResolved = this.isIncludeResolvedUrl;
        if (this.selectedTypeUrl)
            this.selectedType = this.selectedTypeUrl;
        this.setDeviceOrSource();
        if (this.dateRangeValueUrl) {
            this.dateRangeValue = this.dateRangeValueUrl;
            this.dateRangeLabel = this.$t('component.issues.issuesFilter.dateRangeOptions.' + this.dateRangeValue);
        }
        else if (this.selectedDateRangeUrl && this.selectedDateRangeUrl.length == 2) {
            this.selectedDateRange = this.selectedDateRangeUrl;
            this.dateRangeLabel = this.selectedDateRange
                .map((x) => this.dateTimeManager.formatTime(x, null, null, ''))
                .toString();
        }
    }
    setDeviceOrSource() {
        if (this.selectedType == DepartmentIssueTypes.DEVICE_OFFLINE) {
            this.selectedDevice = this.devices.find((x) => x.deviceId == this.entityIdUrl);
        }
        else if (this.selectedType == DepartmentIssueTypes.LOW_BATTERY ||
            this.selectedType == DepartmentIssueTypes.MEASURED_DATA_ALARM ||
            this.selectedType == DepartmentIssueTypes.SOURCE_OFFLINE) {
            this.selectedSource = this.sources.find((x) => x.id == this.entityIdUrl);
        }
    }
    setDateRangeLabel() {
        this.dateRangeLabel = this.$t('component.issues.issuesFilter.created');
    }
    issueTableCheckbox(value) {
        this.$emit('issueTableCheckbox', value);
    }
    handleDateRangeValue(value) {
        this.selectedDateRange = [];
        this.dateRangeLabel = this.$t('component.issues.issuesFilter.dateRangeOptions.' + value);
        let dateRange = this.getDateRange(value);
        dateRange = this.dateTimeManager.formatDateRange(dateRange);
        this.$emit('dateRangeSelected', dateRange, value);
        this.dateRangeMessage = this.getDateRangeMessage(value);
    }
    getDateRangeMessage(value) {
        let dateRange = this.getDateRange(value);
        let dateRangeMessage = '';
        if (this.dateTimeManager) {
            dateRange = this.dateTimeManager.formatDateRange(dateRange);
            if (IssuesFilterDateRangeOptions.TODAY == value || IssuesFilterDateRangeOptions.YESTERDAY == value) {
                dateRangeMessage = this.dateTimeManager.formatTime(dateRange[0], null, null, '');
            }
            else {
                dateRangeMessage =
                    (this.dateTimeManager.formatTime(dateRange[0], null, null, '') +
                        this.$t('component.issues.issuesFilter.to')) +
                        this.dateTimeManager.formatTime(dateRange[1], null, null, '');
            }
        }
        return dateRangeMessage ? dateRangeMessage : '';
    }
    getDateRange(value) {
        let date = new Date();
        let from = new Date();
        let to = new Date();
        switch (value) {
            case IssuesFilterDateRangeOptions.TODAY:
                return [date, date];
                break;
            case IssuesFilterDateRangeOptions.YESTERDAY:
                date.setDate(date.getDate() - 1);
                return [date, date];
                break;
            case IssuesFilterDateRangeOptions.THISWEEK:
                //this week start date
                from.setDate(from.getDate() - from.getDay() + (from.getDay() === 0 ? -6 : 1));
                return [from, to];
                break;
            case IssuesFilterDateRangeOptions.LASTWEEK:
                from.setDate(from.getDate() - 7 - from.getDay() + (from.getDay() === 0 ? -6 : 1));
                to.setDate(to.getDate() - 7 - to.getDay() + (to.getDay() === 0 ? 6 : 1 + 6));
                return [from, to];
                break;
            case IssuesFilterDateRangeOptions.THISMONTH:
                from = new Date(from.getFullYear(), from.getMonth(), 1);
                return [from, to];
                break;
            case IssuesFilterDateRangeOptions.LASTMONTH:
                from = new Date(from.getFullYear(), from.getMonth() - 1, 1);
                to = new Date(to.getFullYear(), to.getMonth(), 0);
                return [from, to];
                break;
        }
    }
    typeSelected(type) {
        this.$emit('issueTypeSelected', type);
    }
    clearFilter() {
        this.selectedDateRange = [];
        this.isIncludeResolved = null;
        this.selectedType = null;
        this.selectedDevice = null;
        this.dateRangeValue = null;
        this.setDateRangeLabel();
        this.$emit('clearFilter');
    }
    selectDevice(device) {
        this.$emit('entitySelected', device.deviceId);
    }
    selectAdminDevice(device) {
        this.$emit('entitySelected', device.deviceId);
    }
    selectSource(source) {
        this.$emit('entitySelected', source.id);
    }
    selectDateRange(dateRange) {
        // this.$emit('dateRangeChanged', dateRange);
        this.dateRangeValue = null;
        this.selectedDateRange = this.dateTimeManager.formatDateRange(dateRange);
        this.dateRangeLabel = this.selectedDateRange
            .map((x) => this.dateTimeManager.formatTime(x, null, null, ''))
            .toString();
        this.$refs.dropdown._data.isActive = false;
        this.$emit('dateRangeSelected', this.selectedDateRange);
    }
    chooseTableColumns(field, event) {
        this.$emit('chooseTableColumns', field, event);
    }
    closeDropdown() {
        this.dateRangeValue = null;
        this.$refs.dropdown._data.isActive = false;
    }
};
__decorate([
    Prop({ type: Array })
], IssueTableFilter.prototype, "currentFilters", void 0);
__decorate([
    Prop({ type: Object })
], IssueTableFilter.prototype, "tableColumns", void 0);
__decorate([
    Prop({ type: Object })
], IssueTableFilter.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], IssueTableFilter.prototype, "devices", void 0);
__decorate([
    Prop({ type: Array })
], IssueTableFilter.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], IssueTableFilter.prototype, "companyLoggers", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IssueTableFilter.prototype, "fromAdmin", void 0);
__decorate([
    Prop({ type: Boolean })
], IssueTableFilter.prototype, "isIncludeResolvedUrl", void 0);
__decorate([
    Prop({ type: String })
], IssueTableFilter.prototype, "selectedTypeUrl", void 0);
__decorate([
    Prop({ type: String })
], IssueTableFilter.prototype, "entityIdUrl", void 0);
__decorate([
    Prop({ type: Array })
], IssueTableFilter.prototype, "selectedDateRangeUrl", void 0);
__decorate([
    Prop({ type: String })
], IssueTableFilter.prototype, "dateRangeValueUrl", void 0);
__decorate([
    Watch('devices')
], IssueTableFilter.prototype, "selectDeviceUrl", null);
__decorate([
    Watch('sources')
], IssueTableFilter.prototype, "selectSourceUrl", null);
IssueTableFilter = __decorate([
    Component({ components: {} })
], IssueTableFilter);
export default IssueTableFilter;
