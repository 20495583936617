var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "section-header-level mx-3 mx-0-desktop" }, [
        _c("h1", { staticClass: "title is-3" }, [
          _vm._v(" " + _vm._s(_vm.$t("contact_form.feedback_list")) + " "),
        ]),
      ]),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        _c(
          "div",
          { staticClass: "tile is-parent" },
          [
            _c("FeedbackList", {
              staticClass: "box tile is-child",
              attrs: {
                feedbackList: _vm.feedbackList.getData() || [],
                totalSize: _vm.feedbackList.getData().length,
                dateTimeManager: _vm.dateTimeManager,
              },
              on: {
                onOptionsChange: _vm.onOptionsChange,
                changeRouterUrlFromFeedback: _vm.changeRouterUrlFromFeedback,
                loadFeedback: _vm.loadFeedback,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }