import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import bb, { zoom, areaSplineRange } from 'billboard.js';
import DaysBeforeOptions from '@/entities/enums/DaysBeforeOptions';
let LoggerRssiChart = class LoggerRssiChart extends Vue {
    constructor() {
        super(...arguments);
        this.DaysBeforeOptions = DaysBeforeOptions;
        this.selectedDayOption = DaysBeforeOptions.ONE;
        this.isLoading = false;
        this.chart = null;
    }
    get hasData() {
        return this.rssi && this.rssi.length > 0;
    }
    rssiUpdated() {
        this.$nextTick(() => {
            const data = this.processData(JSON.parse(JSON.stringify(this.rssi)));
            this.buildChart(data);
            const chartElement = document.getElementById('signalChart');
            chartElement.scrollIntoView({ block: 'start' });
        });
    }
    created() {
        this.$nextTick(() => {
            const data = this.processData(JSON.parse(JSON.stringify(this.rssi)));
            this.buildChart(data);
        });
    }
    processData(rssiData) {
        const signalMax = Math.max(...this.rssi.map((x) => x[3]).filter((x) => x != null));
        const signalMin = Math.min(...this.rssi.map((x) => x[1]).filter((x) => x != null));
        let times = [];
        rssiData.forEach((rssi) => {
            let val = rssi.shift();
            times.push(new Date(val * 1000));
        });
        let values = ['signalData'];
        let xData = ['x'].concat(times);
        let yData = values.concat(rssiData);
        return { x: xData, y: yData, max: signalMax, min: signalMin };
    }
    buildChart(data) {
        let self = this;
        this.chart = bb.generate({
            data: {
                x: 'x',
                columns: [data.x, data.y],
                types: {
                    signalData: areaSplineRange()
                },
                names: {
                    signalData: this.$t('loggers.signal.title')
                }
            },
            axis: {
                x: {
                    tick: {
                        fit: false,
                        count: 5,
                        format: (x) => {
                            return this.dateTimeManager.formatTime(new Date(x), null, null, null);
                        }
                    },
                    type: 'timeseries'
                },
                y: {
                    label: this.$t('loggers.signal.title'),
                    max: data.max,
                    min: data.min,
                    tick: {
                        format: function (x) {
                            return x + ' dBm';
                        }
                    }
                }
            },
            zoom: {
                enabled: zoom()
            },
            point: {
                focus: {
                    only: true
                }
            },
            tooltip: {
                contents: function (d) {
                    let index = d[0].index; // Get the index of the data point
                    let val = self.rssi[index]; // Get min, avg, and max values
                    return `
              <div class="custom-tooltip">
                <div class="tooltip-content">
                  <span> ${self.dateTimeManager.formatTime(new Date(val[0] * 1000), null, null, null)}</span>
                  <div><span class="tooltip-label">${self.$t('loggers.signal.max')}:</span> <span class="tooltip-value">${val[3]} dBm</span></div>
                  <div><span class="tooltip-label has-text-weight-bold">${self.$t('loggers.signal.avg')}:</span> <span class="tooltip-value has-text-weight-bold">${val[2]} dBm</span></div>
                   <div><span class="tooltip-label">${self.$t('loggers.signal.min')}:</span> <span class="tooltip-value">${val[1]} dBm</span></div>
                </div>
              </div>
            `;
                }
            },
            bindto: '#signalStrengthChart'
        });
    }
    dayOptionChanged() {
        let loadFrom = new Date();
        loadFrom.setDate(loadFrom.getDate() - this.selectedDayOption);
        this.chart.destroy();
        this.$emit('loadSignal', loadFrom);
    }
};
__decorate([
    Prop({ type: Object })
], LoggerRssiChart.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], LoggerRssiChart.prototype, "rssi", void 0);
__decorate([
    Prop({ type: Boolean })
], LoggerRssiChart.prototype, "isLoadingSignal", void 0);
__decorate([
    Watch('rssi')
], LoggerRssiChart.prototype, "rssiUpdated", null);
LoggerRssiChart = __decorate([
    Component({ components: {} })
], LoggerRssiChart);
export default LoggerRssiChart;
