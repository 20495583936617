var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("Header"),
      _c(
        "div",
        {
          staticClass: "site-content",
          class:
            _vm.isExpanded === true ? "main-column-expanded" : "main-column",
        },
        [
          _c("organization-side-bar", {
            attrs: { isExpanded: _vm.isExpanded },
            on: { SideMenuExpanded: _vm.menuExpanded },
          }),
          _vm._t("default"),
        ],
        2
      ),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }