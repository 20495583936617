"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartServices = void 0;
var ChartServices;
(function (ChartServices) {
    ChartServices["ALL"] = "allServices";
    ChartServices["TEMPERATURE"] = "temperature";
    ChartServices["HUMIDITY"] = "humidity";
    ChartServices["PRESSURE"] = "pressure";
})(ChartServices || (exports.ChartServices = ChartServices = {}));
