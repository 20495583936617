var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("p", { staticClass: "has-text-weight-semibold pt-2" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t("component.device.register.modal.device_not_active.subtitle")
          ) +
          " "
      ),
    ]),
    _c("p", { staticClass: "has-text-weight-semibold pt-3" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "component.device.register.modal.device_not_active.steps_title"
            )
          ) +
          " "
      ),
    ]),
    _c("section", { staticClass: "content mx-6 mt-3" }, [
      _c("dl", [
        _c("dt", { staticClass: "has-text-weight-semibold" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step1_title"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step1_alt1"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step1_alt2"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step1_alt3"
                )
              ) +
              " "
          ),
        ]),
        _c("dt", { staticClass: "has-text-weight-semibold" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step2_title"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step2_alt1"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step2_alt2"
                )
              ) +
              " "
          ),
        ]),
        _c("dt", { staticClass: "has-text-weight-semibold" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step3_title"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step3_substep1"
                )
              ) +
              " "
          ),
        ]),
        _c("dd", { staticClass: "is-list-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.device.register.modal.device_not_active.step3_substep2"
                )
              ) +
              " "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }