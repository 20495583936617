import { __decorate } from "tslib";
import LoggedUserManager from '@/services/LoggedUserManager';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AcknowledgeIssueModal from '@/views/components/organization/AcknowledgeIssueModal.vue';
import { IssueType } from '@/entities/enums/IssueType';
import DateTimeManager from '@/services/DateTimeManager';
let OrganizationItem = class OrganizationItem extends Vue {
    constructor() {
        super(...arguments);
        this.IssueType = IssueType;
        this.isAdmin = false;
        this.isAcknowledgeModalActive = false;
        this.dateTimeManager = null;
        this.states = {
            OK: { id: 0, name: 'fine', class: 'is-success' },
            ACKNOWLEDGED: { id: 0, name: 'acknowledged', class: 'is-success' },
            MAINTENANCE: { id: 1, name: IssueType.MAINTENANCE, class: 'is-info' },
            OFF_SOURCE: { id: 2, name: IssueType.SOURCE_OFFLINE, class: 'is-grey' },
            OFF_DEVICE: { id: 2, name: IssueType.DEVICE_OFFLINE, class: 'is-grey' },
            LOW: { id: 3, name: IssueType.LOW_BATTERY, class: 'is-warning' },
            WARN: { id: 4, name: IssueType.ALARM, alarmLevel: 'Warning', class: 'is-warning' },
            ALARM: { id: 5, name: IssueType.ALARM, alarmLevel: 'Alarm', class: 'is-danger' }
        };
    }
    get isFavorite() {
        return this.favoriteDepartmentId === this.department.id;
    }
    get isAcknowledged() {
        let departmentSummary = this.issuesSummary.find((summary) => summary.id === this.department.id);
        if (departmentSummary) {
            return departmentSummary.issues.every((issue) => issue.notAcknowledged === 0);
        }
    }
    get fineState() {
        let issueTypes = [
            { type: IssueType.ALARM, alarmLevel: 'Alarm' },
            { type: IssueType.ALARM, alarmLevel: 'Warning' },
            { type: IssueType.LOW_BATTERY, alarmLevel: null },
            { type: IssueType.DEVICE_OFFLINE, alarmLevel: null },
            { type: IssueType.SOURCE_OFFLINE, alarmLevel: null },
            { type: IssueType.MAINTENANCE, alarmLevel: null }
        ];
        for (let issue of issueTypes) {
            if (this.getIssueByType(issue.type, issue.alarmLevel)) {
                return true;
            }
        }
        return false;
    }
    get isMobileView() {
        return window.innerWidth <= 768;
    }
    async created() {
        this.isAdmin = LoggedUserManager.isAdmin(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
    }
    getIssueByType(type, alarmLevel = null) {
        let totalNotAcknowledged = 0;
        let totalNotResolved = 0;
        let departmentSummary = this.issuesSummary.find((summary) => summary.id === this.department.id);
        if (departmentSummary) {
            departmentSummary.issues.forEach((issue) => {
                if (issue.type === type && issue.alarmLevel === alarmLevel) {
                    totalNotAcknowledged += issue.notAcknowledged;
                    totalNotResolved += issue.notResolved;
                }
            });
        }
        else
            return false;
        return totalNotResolved >= 1;
    }
    getClass() {
        let selectedStatus = this.states.OK;
        if (this.isAcknowledged)
            return selectedStatus.class;
        let departmentSummary = this.issuesSummary.find((summary) => summary.id === this.department.id);
        if (departmentSummary) {
            departmentSummary.issues.forEach((issue) => {
                for (const key in this.states) {
                    let state = this.states[key];
                    let issueMatchesStatus = Array.isArray(state.name)
                        ? state.name.includes(issue.type)
                        : issue.type === state.name;
                    let alarmLevelMatches = state.alarmLevel ? issue.alarmLevel === state.alarmLevel : true;
                    if (issueMatchesStatus && alarmLevelMatches && state.id > selectedStatus.id) {
                        selectedStatus = state;
                    }
                }
            });
        }
        return selectedStatus.class;
    }
    favoriteSelected() {
        if (!this.department.member.Enabled)
            return;
        let id = !this.isFavorite ? this.department.id : '';
        this.$emit('favoriteSelected', id);
    }
    reload() {
        this.$emit('reload');
    }
    openModal() {
        this.isAcknowledgeModalActive = true;
    }
    closeAcknowledgeModal() {
        this.isAcknowledgeModalActive = false;
    }
    abbreviateName(name) {
        let parts = name.split(' ');
        if (parts.length === 1 || name.length <= 25)
            return name;
        return parts
            .map((part, index) => {
            return index < parts.length - 1 ? part.slice(0, 4) + '.' : part;
        })
            .join(' ');
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationItem.prototype, "department", void 0);
__decorate([
    Prop({ type: Array })
], OrganizationItem.prototype, "issuesSummary", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationItem.prototype, "favoriteDepartmentId", void 0);
OrganizationItem = __decorate([
    Component({ components: { AcknowledgeIssueModal } })
], OrganizationItem);
export default OrganizationItem;
