var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.componentIsLoading
      ? _c(
          "div",
          [_c("b-skeleton", { attrs: { height: "90px", animated: true } })],
          1
        )
      : _c("div", [
          _vm.isLayoutList
            ? _c("div", { staticClass: "multi-header mb-4" }, [
                _c("h2", { staticClass: "subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("admin.component.loggers.lastData.title")) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.hasData
            ? _c("div", { staticClass: "columns info-fields" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "section",
                    _vm._l(_vm.firstHalf, function (subkey, index) {
                      return _c(
                        "b-field",
                        {
                          key: subkey,
                          attrs: { horizontal: "", label: subkey },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.values[index].Value) +
                                " " +
                                _vm._s(_vm.showDate(_vm.values[index].Time))
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ]),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "section",
                    _vm._l(_vm.secondHalf, function (subkey, index) {
                      return _c(
                        "b-field",
                        {
                          key: subkey,
                          attrs: { horizontal: "", label: subkey },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.values[index + _vm.firstHalf.length].Value
                                ) +
                                " " +
                                _vm._s(
                                  _vm.showDate(
                                    _vm.values[index + _vm.firstHalf.length]
                                      .Time
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ]),
              ])
            : _c("div", { staticClass: "empty-calibration-data" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("admin.component.loggers.lastData.empty"))
                  ),
                ]),
              ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }