var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "aside",
    {
      staticClass: "is-narrow-mobile is-fullheight is-left-mobile side-menu",
      class: [
        _vm.isExpanded ? "column expanded-side-menu" : "bar-side-menu",
        _vm.isSideMenuVisible ? "checked" : null,
      ],
      attrs: { id: "side-menu" },
    },
    [
      _c("ul", { staticClass: "menu-list" }, [
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["organizations"]),
                },
                attrs: {
                  to: {
                    name: "organizations",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "home" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.main_org"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.MAIN_ORG.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.MAIN_ORG.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.MAIN_ORG
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.MAIN_ORG
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["notificationsOrg"]),
                },
                attrs: {
                  to: {
                    name: "notificationsOrg",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "bell" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.notification_board"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.NOTIFICATIONS.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.NOTIFICATIONS.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.NOTIFICATIONS
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.NOTIFICATIONS
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["profile"]),
                },
                attrs: {
                  to: {
                    name: "profile",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", {
                  attrs: { icon: _vm.SettingOptions.PROFILE.icon },
                }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.profile"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.PROFILE.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.PROFILE.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.PROFILE
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.PROFILE
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("b-icon", {
        staticClass: "width-button is-hidden-mobile",
        attrs: {
          icon: _vm.isExpanded ? "angle-double-left" : "angle-double-right",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.expandButtonClicked(!_vm.isExpanded)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }