import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import UserRepository from '@/services/repository/UserRepository';
var userRepository;
let CreateUserModal = class CreateUserModal extends Vue {
    constructor() {
        super(...arguments);
        this.email = null;
        this.username = null;
        this.password = null;
        this.firstName = null;
        this.lastName = null;
        this.phoneNumber = null;
        this.isLoading = false;
        this.keyValueArray = [];
        this.updatedKeyValueObj = null;
        this.errorResponse = {};
    }
    created() {
        userRepository = new UserRepository(this);
    }
    async createUser() {
        if (this.isLoading === true)
            return;
        this.updatedKeyValueObj = Object.fromEntries(this.keyValueArray);
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let userDto = {
                    Email: this.email,
                    Username: this.username,
                    Password: this.password,
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    PhoneNumber: this.phoneNumber,
                    CustomerData: this.updatedKeyValueObj
                };
                let createResult = await userRepository.adminCreateUser(userDto);
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.user.createUserModal.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.user.createUserModal.failure'));
                }
                this.isLoading = false;
            }
        });
    }
    setvalue(value) {
        this.username = value;
    }
    addKeyValue() {
        this.keyValueArray.push({ 0: '', 1: '' });
    }
    removeKeyValue(index) {
        this.keyValueArray = this.keyValueArray.filter((x) => x != index);
    }
    errorMessageKey(id) {
        var message = this.errors.first(this.$t('admin.component.user.createUserModal.properties.key') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
    phoneNumberChanged() {
        delete this.errorResponse['PhoneNumber'];
    }
    hasErrorMsg(fieldName) {
        if (this.errorResponse[fieldName])
            return true;
        else
            return false;
    }
    getErrorMsg(fieldName) {
        if (this.errorResponse[fieldName])
            return this.errorResponse[fieldName];
        else
            return null;
    }
    errorMessageValue(id) {
        var message = this.errors.first(this.$t('admin.component.user.createUserModal.properties.value') + id.toString());
        if (message != undefined) {
            message = message.replace(id.toString(), '');
        }
        return message;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
CreateUserModal = __decorate([
    Component({})
], CreateUserModal);
export default CreateUserModal;
