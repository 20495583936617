var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "wide" }, [
    _c("div", [
      _c(
        "span",
        { staticClass: "text-with-icon" },
        [
          _c("b-icon", {
            attrs: {
              icon: "check-circle",
              size: "is-large",
              type: "is-success",
            },
          }),
          _c("h2", { staticClass: "title is-3" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.registration_successful.title")) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c("p", { staticClass: "has-text-centered" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.registration_successful.msg")) +
                " "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }