var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("admin.component.loggers.createVerificationKeyModal.title")
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(
                "admin.component.loggers.createVerificationKeyModal.verificationKey"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t(
                    "admin.component.loggers.createVerificationKeyModal.verificationKey"
                  )
                ),
              },
              message: _vm.errors.first(
                _vm.$t(
                  "admin.component.loggers.createVerificationKeyModal.verificationKey"
                )
              ),
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:32|min:32",
                  expression: "'required|max:32|min:32'",
                },
              ],
              attrs: {
                placeholder: _vm.$t(
                  "admin.component.loggers.createVerificationKeyModal.verificationKeyPlaceholder"
                ),
                type: "text",
                minlength: "32",
                maxlength: "32",
                name: _vm.$t(
                  "admin.component.loggers.createVerificationKeyModal.verificationKey"
                ),
              },
              model: {
                value: _vm.verificationKey,
                callback: function ($$v) {
                  _vm.verificationKey = $$v
                },
                expression: "verificationKey",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }