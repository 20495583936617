import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import { CompanyLogger, CompanyLoggerFullCalibration } from '@/entities/models/CompanyLogger';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import Subjects from '../permissions/Subjects';
import Actions from '../permissions/Actions';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiStatus from '@/entities/enums/apiStatuses';
import FileDownloader from '../FileDownloader';
import ApiResponse from '@/entities/ApiResponse';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
const DEFAULT_PROTOCOL_FILENAME = 'calibration.pdf';
export default class CompanyLoggerRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async downloadCalibrationProtocol(companyLoggerId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.LOGGER)) {
            return false;
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.CompanyLogger.Keys.Loggers}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Protocol}`;
        let response = await AxiosService.downloadFile_POST(url, null, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_PROTOCOL_FILENAME);
            return true;
        }
        else {
            return false;
        }
    }
    async adminDownloadCalibrationProtocol(companyLoggerId) {
        if (!this.ctx.$ability.can(Actions.PROTOCOL, Subjects.ADMIN_LOGGERS_CALIBRATION)) {
            return false;
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Protocol}`;
        let response = await AxiosService.downloadFile_POST(url, null, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS) {
            FileDownloader.downloadFile(response, DEFAULT_PROTOCOL_FILENAME);
            return true;
        }
        else {
            return false;
        }
    }
    async adminCopyCalibrationProtocol(sourceCompanyLoggerId, targetCompanyLoggerId) {
        if (!this.ctx.$ability.can(Actions.PROTOCOL, Subjects.ADMIN_LOGGERS_CALIBRATION)) {
            return false;
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${targetCompanyLoggerId}${ApiLinks.CompanyLogger.Keys.ProtocolCopy}`;
        let body = {
            SourceCompanyLoggerId: sourceCompanyLoggerId
        };
        let response = await AxiosService.postDataToServer(url, body, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return Promise.reject({ statusCode: response.status, sn: targetCompanyLoggerId });
        else
            return true;
    }
    async getCompanyLoggers(pagination = null, sort = null, departmentId = this.getSelectedDepartmentId()) {
        let apiResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.LOGGER)) {
            return apiResponse;
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.CompanyLogger.Keys.Loggers}`;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return apiResponse;
        else {
            //Create response with pagination data
            apiResponse.setPagination(response.data);
            apiResponse.setData(CompanyLogger.sortBySerialNumber(CompanyLogger.fromApi(response.data.data)));
            return apiResponse;
        }
    }
    async activateLoggers(loggerList, departmentId = null) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.LOGGER)) {
            return false;
        }
        if (!departmentId)
            departmentId = this.getSelectedDepartmentId();
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.CompanyLogger.Keys.Loggers}`;
        let data = new FormData();
        loggerList.forEach((loggerId) => {
            data.append('loggers[]', loggerId);
        });
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        return response.status == ApiStatus.SUCCESS && response.data.Code == ApiResponseCodes.LOGGER_ACTIVATED;
    }
    async verifyLogger(sn, key, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.LOGGER)) {
            throw 'Forbidden action verifyLogger Logger';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.CompanyLogger.Keys.Loggers}${ApiLinks.CompanyLogger.Keys.Verify}`;
        let body = {
            SerialNumber: sn,
            Key: key
        };
        let response = await AxiosService.postDataToServer(url, body, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return Promise.reject({ statusCode: response.status, sn: sn });
        else
            return CompanyLogger.fromApi(response.data.data);
    }
    async adminGetCompanyLoggers(departmentId = null, pagination = null, sort = null) {
        let finalResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_COMPANYLOGGERS)) {
            return finalResponse;
        }
        let url = ApiLinks.CompanyLogger.AdminHome;
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort) {
            queries.addSorts(sort);
        }
        if (departmentId) {
            queries.departmentId = departmentId;
        }
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return finalResponse;
        else {
            //Create response with pagination data
            finalResponse.setPagination(response.data);
            finalResponse.setData(CompanyLogger.sortBySerialNumber(CompanyLogger.fromApi(response.data.data)));
            return finalResponse;
        }
    }
    async adminUnpublishLogger(companyLoggerId) {
        if (!this.ctx.$ability.can(Actions.UNPUBLISH, Subjects.ADMIN_LOGGERS)) {
            throw 'Forbidden action adminUnpublishLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Unpublish}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async adminActivateLogger(companyLoggerId, departmentId) {
        if (!this.ctx.$ability.can(Actions.ACTIVATE, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminActivateLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Activate}`;
        let queries = new ApiQueries();
        queries.departmentId = departmentId;
        if (queries.hasQueries()) {
            url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == 200 && response.data.Code == ApiResponseCodes.LOGGER_ACTIVATED) {
            return true;
        }
        else
            return false;
    }
    async adminDeactivateLogger(companyLoggerId) {
        if (!this.ctx.$ability.can(Actions.DEACTIVATE, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminDeactivateLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Deactivate}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == 200 && response.data.Code == ApiResponseCodes.LOGGER_DEACTIVATED) {
            return true;
        }
        else
            return false;
    }
    async adminValidateSerialNumber(serialNumber) {
        if (!this.ctx.$ability.can(Actions.PUBLISH, Subjects.ADMIN_LOGGERS)) {
            throw 'Forbidden action adminValidateSerialNumber CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.Validate}/${serialNumber}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        return response.data.data;
    }
    async adminCheckIfSerialNumberExists(serialNumber) {
        if (!this.ctx.$ability.can(Actions.PUBLISH, Subjects.ADMIN_LOGGERS)) {
            throw 'Forbidden action checkIfSerialNumberExists CompanyLogger';
        }
        let url = ApiLinks.CompanyLogger.Exists;
        let queries = new ApiQueries();
        queries.serialNumber = serialNumber;
        url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            throw this.ctx.$t('error_messages.fatal_error');
        else
            return response.data.data;
    }
    async adminDeleteCalibration(companyLoggerId) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_LOGGERS_CALIBRATION)) {
            throw 'Forbidden action adminDeleteCalibration CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Calibration}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async adminCreateCalibration(companyLoggerId, calibration) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_LOGGERS_CALIBRATION)) {
            throw 'Forbidden action adminCreateCalibration CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Calibration}`;
        let formData = new FormData();
        formData.append('CalibratedAt', new Date(calibration.CalibratedAt).toISOString());
        formData.append('VerifiedAt', new Date(calibration.VerifiedAt).toISOString());
        formData.append('ExpiresAt', new Date(calibration.ExpiresAt).toISOString());
        calibration.Files.forEach((file, index) => {
            formData.append('Files[' + index + '].File', file.Protocol);
            formData.append('Files[' + index + '].Locale', file.Locale);
        });
        let response = await AxiosService.postDataToServer(url, formData, this.ctx);
        return response.status == ApiStatus.CREATED;
    }
    async adminUpdateCalibration(companyLoggerId, calibration) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_LOGGERS_CALIBRATION)) {
            throw 'Forbidden action adminCreateCalibration CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.Calibration}`;
        let response = await AxiosService.putDataToServer(url, calibration, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async adminGetCompanyLogger(companyloggerId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_COMPANYLOGGERS)) {
            return new CompanyLoggerFullCalibration();
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyloggerId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyLoggerFullCalibration();
        else
            return CompanyLoggerFullCalibration.fromApi(response.data.data);
    }
    async adminRevertCompanyLogger(companyloggerId) {
        if (!this.ctx.$ability.can(Actions.REVERT, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminRevertCompanyLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyloggerId}${ApiLinks.CompanyLogger.Keys.Revert}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyLogger();
        else
            return CompanyLogger.fromApi(response.data.data);
    }
    async adminReturnLoggerFromDepartment(companyloggerId) {
        if (!this.ctx.$ability.can(Actions.RETURN, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminReturnLoggerFromDepartment CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyloggerId}${ApiLinks.CompanyLogger.Keys.Return}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new CompanyLogger();
        else
            return CompanyLogger.fromApi(response.data.data);
    }
    async adminReadLoggerData(companyLoggerId, params) {
        if (!this.ctx.$ability.can(Actions.READDATA, Subjects.ADMIN_LOGGERS_COMMANDS)) {
            throw 'Forbidden action adminRevertCompanyLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.ReadData}`;
        let response = await AxiosService.postDataToServer(url, params, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    async adminVerifyLogger(sn, key) {
        if (!this.ctx.$ability.can(Actions.ACTIVATE, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminActivateCompanyLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}${ApiLinks.CompanyLogger.Keys.Verify}`;
        let body = {
            SerialNumber: sn,
            Key: key
        };
        let response = await AxiosService.postDataToServer(url, body, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return Promise.reject({ statusCode: response.status, sn: sn });
        else
            return CompanyLogger.fromApi(response.data.data);
    }
    async adminChangeAllowedTypesForLogger(companyLoggerId, selectedTypes) {
        if (!this.ctx.$ability.can(Actions.ACTIVATE, Subjects.ADMIN_COMPANYLOGGERS)) {
            throw 'Forbidden action adminChangeAllowedTypesForLogger CompanyLogger';
        }
        let url = `${ApiLinks.CompanyLogger.AdminHome}/${companyLoggerId}${ApiLinks.CompanyLogger.Keys.AllowedTypes}`;
        let body = {
            Types: selectedTypes
        };
        let response = await AxiosService.postDataToServer(url, body, this.ctx);
        return response.status == ApiStatus.SUCCESS;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
