var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "section",
      [
        _c("div", { staticClass: "columns columns-info-form" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                {
                  staticClass: "required",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.name"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("tiers.name")),
                    },
                    message: _vm.errors.first(_vm.$t("tiers.name")),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: { name: _vm.$t("tiers.name") },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "required",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.display_name"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("tiers.display_name")),
                    },
                    message: _vm.errors.first(_vm.$t("tiers.display_name")),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: { name: _vm.$t("tiers.display_name") },
                    model: {
                      value: _vm.displayName,
                      callback: function ($$v) {
                        _vm.displayName = $$v
                      },
                      expression: "displayName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("tiers.active"),
                    type: {
                      "is-danger": _vm.errors.has(_vm.$t("tiers.active")),
                    },
                    message: _vm.errors.first(_vm.$t("tiers.active")),
                  },
                },
                [
                  _c("b-switch", {
                    model: {
                      value: _vm.active,
                      callback: function ($$v) {
                        _vm.active = $$v
                      },
                      expression: "active",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "column" }, [
            _c(
              "p",
              {
                staticClass:
                  "has-text-weight-bold is-size-5 required only-asterisk",
              },
              [_vm._v(" " + _vm._s(_vm.$t("tiers.features_list")) + " ")]
            ),
            _c(
              "div",
              { staticClass: "mt-1" },
              _vm._l(_vm.featuresInTable, function (feature, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-1",
                  },
                  [
                    _c("b-field", [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("tiers.features." + feature.name))
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-flex-direction-row is-align-items-center",
                      },
                      [
                        feature.type === _vm.FeaturesTypes.BOOL
                          ? _c(
                              "b-field",
                              [
                                _c(
                                  "b-switch",
                                  {
                                    model: {
                                      value: feature.value.Allowed,
                                      callback: function ($$v) {
                                        _vm.$set(feature.value, "Allowed", $$v)
                                      },
                                      expression: "feature.value.Allowed",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("tiers.features.allowed")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.type === _vm.FeaturesTypes.QUOTA
                          ? _c(
                              "b-field",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  "label-position": "on-border",
                                  label: _vm.$t("tiers.features.quota"),
                                },
                              },
                              [
                                _c("b-numberinput", {
                                  attrs: { controls: false },
                                  model: {
                                    value: feature.value.Quota,
                                    callback: function ($$v) {
                                      _vm.$set(feature.value, "Quota", $$v)
                                    },
                                    expression: "feature.value.Quota",
                                  },
                                }),
                                _c(
                                  "p",
                                  { staticClass: "control" },
                                  [
                                    _c("b-button", {
                                      attrs: {
                                        type: "is-primary",
                                        label: _vm.$t(
                                          "tiers.features.unlimited"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setUnlimited(feature)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.type === _vm.FeaturesTypes.MONTHLY
                          ? _c(
                              "b-field",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  "label-position": "on-border",
                                  label: _vm.$t("tiers.features.quota"),
                                },
                              },
                              [
                                _c("b-numberinput", {
                                  attrs: { controls: false },
                                  model: {
                                    value: feature.value.Quota,
                                    callback: function ($$v) {
                                      _vm.$set(feature.value, "Quota", $$v)
                                    },
                                    expression: "feature.value.Quota",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        feature.type === _vm.FeaturesTypes.MONTHLY
                          ? _c(
                              "b-field",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-switch",
                                  {
                                    model: {
                                      value: feature.value.AllowOverflow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          feature.value,
                                          "AllowOverflow",
                                          $$v
                                        )
                                      },
                                      expression: "feature.value.AllowOverflow",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("tiers.features.allowOverflow")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.createTier()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("tiers.create_tier")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }