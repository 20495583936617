var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.logger.service.edit_modal.title", {
                name: _vm.source.name,
              })
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "p",
          {
            staticClass:
              "has-text-centered has-text-weight-bold has-text-primary mb-3",
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.logger.service.edit_modal.msg")) +
                " "
            ),
          ]
        ),
        _c("hr"),
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.$t(`component.logger.service.edit_modal.date_label`),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.logger.service.edit_modal.date_label")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.logger.service.edit_modal.date_label")
              ),
            },
          },
          [
            _c("b-datetimepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                inline: "",
                name: _vm.$t("component.logger.service.edit_modal.date_label"),
                placeholder: _vm.$t(
                  "component.logger.service.edit_modal.date_label"
                ),
                "first-day-of-week": 1,
                "mobile-native": false,
                "min-datetime": _vm.minEndTime,
                timepicker: { hourFormat: _vm.hourFormat },
                "datetime-formatter": (date) =>
                  _vm.dateTimeManager.formatDateTimeInInput(date),
              },
              model: {
                value: _vm.newEndTime,
                callback: function ($$v) {
                  _vm.newEndTime = $$v
                },
                expression: "newEndTime",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("component.report.create_annotation.text")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("component.report.create_annotation.text")
              ),
              label: _vm.$t("component.report.create_annotation.text"),
            },
          },
          [
            _c("b-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:350",
                  expression: "'required|max:350'",
                },
              ],
              attrs: {
                type: "textarea",
                name: _vm.$t("component.report.create_annotation.text"),
                placeholder: _vm.$t(
                  "component.report.create_annotation.placeholder"
                ),
                expanded: "",
                maxlength: "350",
              },
              model: {
                value: _vm.customAnnotationText,
                callback: function ($$v) {
                  _vm.customAnnotationText = $$v
                },
                expression: "customAnnotationText",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("unsaved_changes.cancel")))]
        ),
        _c(
          "div",
          {
            staticClass:
              "is-flex is-flex-wrap-wrap is-justify-content-flex-end flex-row-gap-5",
          },
          [
            _c(
              "b-button",
              {
                staticClass: "ml-1 mr-0",
                attrs: { type: "is-success" },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.logger.service.edit_modal.save"))
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-1",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.endNow()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("component.logger.service.edit_modal.end_now"))
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }