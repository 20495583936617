import { __decorate } from "tslib";
import PublicDashboardComponents from '@/entities/enums/PublicDashboardComponents';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import PublicDashboardOwnerRepository from '@/services/repository/PublicDashboardOwnerRepository';
import AppConfig from '@/configLoader';
import LoggedUserManager from '@/services/LoggedUserManager';
var publicDashboardOwnerRepository;
let CreateDashboardModal = class CreateDashboardModal extends Vue {
    constructor() {
        super(...arguments);
        this.PublicDashboardComponents = PublicDashboardComponents;
        this.isPublic = true;
        this.title = null;
        this.selectedSources = [];
        this.selectedSourceGroups = [];
        this.selectedDevices = [];
        this.selectedUsers = [];
        this.allowedComponents = [];
        this.isLoading = false;
        this.showAnnotations = false;
        this.dashboardKey = null;
        this.homeAddress = null;
        this.origin = window.location.origin;
        this.copyResult = null;
        this.departmentId = null;
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get isLoggedInCompany() {
        return LoggedUserManager.isLoggedToCompany(this);
    }
    created() {
        this.homeAddress = AppConfig.getConfig().backend.address;
        publicDashboardOwnerRepository = new PublicDashboardOwnerRepository(this);
        if (this.isLoggedInCompany)
            this.departmentId = this.$route.params.departmentId;
        else if (this.isLoggedFromAdmin)
            this.departmentId = this.$route.params.companyId;
        else {
            this.departmentId = this.$route.params.departmentId;
        }
    }
    resultRetrieved(result) {
        this.$emit('modalClosed', result);
    }
    createPublicDashboard() {
        let usersIds = this.selectedUsers.map((x) => x.apiUserId);
        let sourcesIds = this.selectedSources.map((x) => x.id);
        let sourceGroupsIds = this.selectedSourceGroups.map((x) => x.id);
        let deviceIds = this.selectedDevices.map((x) => x.deviceId);
        let sourceName;
        let groupName;
        let hasDuplicate = false;
        this.selectedSources.forEach((source) => this.selectedSourceGroups.forEach((group) => {
            if (!hasDuplicate && group.sources.find((s) => s.id == source.id)) {
                sourceName = source.name;
                groupName = group.name;
                hasDuplicate = true;
            }
        }));
        if (hasDuplicate) {
            VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.create.errors.duplicateSource', {
                sourceName: sourceName,
                groupName: groupName
            }));
            return;
        }
        if (usersIds.length <= 0 && !this.isPublic) {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('component.public_dashboard.create.errors.select_user'),
                position: 'is-bottom',
                type: 'is-danger'
            });
            return;
        }
        if (sourcesIds.length <= 0 && sourceGroupsIds.length <= 0) {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('component.public_dashboard.create.errors.select_entity'),
                position: 'is-bottom',
                type: 'is-danger'
            });
            return;
        }
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.showAnnotations && !this.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS)) {
                    this.allowedComponents.push(PublicDashboardComponents.ANNOTATIONS);
                }
                else if (!this.showAnnotations && this.allowedComponents.includes(PublicDashboardComponents.ANNOTATIONS)) {
                    this.allowedComponents = this.allowedComponents.filter((x) => x != PublicDashboardComponents.ANNOTATIONS);
                }
                let dto = {
                    Name: this.title,
                    IsPublic: this.isPublic,
                    UserList: this.isPublic ? [] : usersIds,
                    SourceGroupList: sourceGroupsIds,
                    SourceList: sourcesIds,
                    GatewayList: deviceIds,
                    Settings: null,
                    AllowedComponents: this.allowedComponents
                };
                this.isLoading = true;
                let createResult = await publicDashboardOwnerRepository.createPublicDashboard(dto, this.departmentId);
                this.isLoading = false;
                if (createResult !== null) {
                    let createdDashboard = await publicDashboardOwnerRepository.getPublicDashboardDetail(createResult, this.departmentId);
                    this.dashboardKey = createdDashboard.dashboardKey;
                    VueUtilities.openSuccessToast(this, this.$t('component.public_dashboard.create.success'));
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.public_dashboard.create.failure'));
                }
            }
        });
    }
    copyLinkToClipboard() {
        let linkToDashboard = document.querySelector('#linkToDashboard').getAttribute('href');
        let fullLink = this.origin + linkToDashboard;
        let self = this;
        navigator.clipboard.writeText(fullLink).then(function () {
            self.copyResult = true;
        }, function (err) {
            self.copyResult = false;
        });
    }
};
__decorate([
    Prop({ type: Array })
], CreateDashboardModal.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], CreateDashboardModal.prototype, "availableDevices", void 0);
__decorate([
    Prop({ type: Array })
], CreateDashboardModal.prototype, "availableSourceGroups", void 0);
__decorate([
    Prop({ type: Array })
], CreateDashboardModal.prototype, "availableUsers", void 0);
CreateDashboardModal = __decorate([
    Component({ components: {} })
], CreateDashboardModal);
export default CreateDashboardModal;
