var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }),
    _c("section", { staticClass: "modal-card-body content mb-0 mt-4" }, [
      _c("h1", { staticClass: "has-text-primary" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("component.wizard.detailedInstructions.title")) +
            " "
        ),
      ]),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.text")
          ),
        },
      }),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.cable.description")
          ),
        },
      }),
      _c("h2", [
        _vm._v(
          _vm._s(_vm.$t("component.wizard.detailedInstructions.cable.title"))
        ),
      ]),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.cable.text")
          ),
        },
      }),
      _c(
        "div",
        { staticClass: "img-40 has-text-centered imageWithCaption" },
        [
          _c("b-image", {
            attrs: {
              src: require("@/assets/img/instructions/cable.png"),
              alt: "lan_cable",
              responsive: false,
            },
          }),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t("component.wizard.detailedInstructions.figures.fig1_1")
              )
            ),
          ]),
        ],
        1
      ),
      _c("h4", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.wizard.detailedInstructions.cable.steps.title")
            ) +
            " "
        ),
      ]),
      _c("ol", [
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.cable.steps.1")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-40 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/observerConnected.png"),
                  alt: "observerConnected",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig1_2"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.cable.steps.2")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-40 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/routerConnected.png"),
                  alt: "routerConnected",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig1_3"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.cable.steps.3")
            ),
          },
        }),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.cable.steps.4")
            ),
          },
        }),
      ]),
      _c("p", {
        staticClass: "is-italic",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.cable.steps.warning")
          ),
        },
      }),
      _c("h2", [
        _vm._v(
          _vm._s(_vm.$t("component.wizard.detailedInstructions.wifi.title"))
        ),
      ]),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "component.wizard.detailedInstructions.wifi.requirements.title"
            )
          ),
        },
      }),
      _c("ul", [
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "component.wizard.detailedInstructions.wifi.requirements.req1"
              )
            ),
          },
        }),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "component.wizard.detailedInstructions.wifi.requirements.req2"
              )
            ),
          },
        }),
      ]),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "component.wizard.detailedInstructions.wifi.requirements.description"
            )
          ),
        },
      }),
      _c("h4", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.wizard.detailedInstructions.wifi.steps.title")
            ) +
            " "
        ),
      ]),
      _c("ol", [
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.wifi.steps.1")
            ),
          },
        }),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.wifi.steps.2")
            ),
          },
        }),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.3")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-40 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/wifiOn.png"),
                  alt: "turn_on_wifi",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_1"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.4")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-20 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/wifiList.png"),
                  alt: "list_wifi",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_2"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.5")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-20 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/connectWifi.png"),
                  alt: "connect_wifi",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_3"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
          _c("p", {
            staticClass: "is-italic",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "component.wizard.detailedInstructions.wifi.steps.5_note"
                )
              ),
            },
          }),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.6")
              ),
            },
          }),
          _c("div", { staticClass: "is-flex is-justify-content-center" }, [
            _c(
              "div",
              { staticClass: "img-40" },
              [
                _c("b-image", {
                  attrs: {
                    src: require("@/assets/img/instructions/stickerPassword.png"),
                    alt: "type_in_password",
                    responsive: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "img-40 has-text-centered imageWithCaption" },
              [
                _c("b-image", {
                  attrs: {
                    src: require("@/assets/img/instructions/wifiPassword.png"),
                    alt: "type_in_password",
                    responsive: false,
                  },
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "component.wizard.detailedInstructions.figures.fig2_4"
                      )
                    )
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.7")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-20 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/wifiConnecting.png"),
                  alt: "connecting_wifi",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_5"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.8")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-60 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/adhocForm.png"),
                  alt: "adhocForm",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_6"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.wifi.steps.9")
            ),
          },
        }),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.10")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-60 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/adhocFillForm.png"),
                  alt: "adhocFormFilled",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_7"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
          _c("p", {
            staticClass: "is-italic",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "component.wizard.detailedInstructions.wifi.steps.10_note"
                )
              ),
            },
          }),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.11")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-60 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/adhocConnecting.png"),
                  alt: "adhocConnecting",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_8"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.detailedInstructions.wifi.steps.12")
              ),
            },
          }),
          _c(
            "div",
            { staticClass: "img-60 has-text-centered imageWithCaption" },
            [
              _c("b-image", {
                attrs: {
                  src: require("@/assets/img/instructions/adhocConnected.png"),
                  alt: "adhocConnected",
                  responsive: false,
                },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "component.wizard.detailedInstructions.figures.fig2_9"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("li", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.wizard.detailedInstructions.wifi.steps.13")
            ),
          },
        }),
      ]),
      _c("p", {
        staticClass: "is-italic",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.wifi.error")
          ),
        },
      }),
      _c("p", {
        staticClass: "is-italic",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.wifi.warning")
          ),
        },
      }),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("component.wizard.detailedInstructions.wifi.finalNote")
          ),
        },
      }),
    ]),
    _c(
      "footer",
      { staticClass: "modal-card-foot is-justify-content-center" },
      [
        _c(
          "b-button",
          {
            attrs: { type: "is-primary", "icon-left": "check" },
            on: {
              click: function ($event) {
                return _vm.closeModal(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }