import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import SalesRepository from '@/services/repository/SalesRepository';
import FeedbackDetail from '@/views/components/admin/feedback/FeedbackDetail.vue';
import DateTimeManager from '@/services/DateTimeManager';
import AdminFeedback from '@/entities/models/AdminFeedback';
import FeedbackStatus from '@/entities/enums/FeedbackStatus';
import VueUtilities from '@/services/VueUtilities';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
var salesRepository;
let AdminFeedbackDetail = class AdminFeedbackDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.dateTimeManager = null;
        this.feedback = new AdminFeedback();
    }
    async created() {
        salesRepository = new SalesRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let promises = [];
        promises.push(salesRepository.getFeedbackById(this.$route.params.feedbackId));
        await Promise.all(promises).then((response) => {
            this.feedback = response[0];
            this.isLoading = false;
        });
    }
    async closeFeedback(id) {
        if (this.isLoading === true)
            return;
        this.isLoading = true;
        let data = { Status: FeedbackStatus.CLOSED.name };
        let result = await salesRepository.updateFeedback(id, data);
        if (result === true) {
            VueUtilities.openSuccessToast(this, this.$t('contact_form.close_dialog.success'));
            this.loadData();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('contact_form.close_dialog.failure'));
            this.isLoading = false;
        }
    }
};
AdminFeedbackDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.ADMIN_FEEDBACK)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem(LocalStorageKeys.ADMIN_PREV_ROUTE, JSON.stringify({ name: this.$route.name, params: this.$route.params }));
            next();
        },
        components: { FeedbackDetail }
    })
], AdminFeedbackDetail);
export default AdminFeedbackDetail;
