import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var deviceRepository;
let ActivateDeviceModal = class ActivateDeviceModal extends Vue {
    constructor() {
        super(...arguments);
        this.publicKey = null;
        this.keySignature = null;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    createLogger() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.isLoading === true)
                    return;
                this.isLoading = true;
                let deviceDto = {
                    PublicKey: this.publicKey,
                    KeySignature: this.keySignature
                };
                let createResult = await deviceRepository.activateAdminDevice(this.device.deviceId, deviceDto);
                this.isLoading = false;
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.activate_device.success'));
                    this.resultRetrieved(true);
                }
                else if (createResult.code == ApiResponseCodes.VALIDATION_ERROR) {
                    let fields = Object.keys(createResult.errors).join(',');
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.activate_device.validation_error', {
                        fields: fields
                    }));
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.activate_device.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], ActivateDeviceModal.prototype, "device", void 0);
ActivateDeviceModal = __decorate([
    Component({})
], ActivateDeviceModal);
export default ActivateDeviceModal;
