var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dateTimeManager
    ? _c("div", [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.settings.about.versions")) + " "
          ),
        ]),
        _c(
          "section",
          _vm._l(_vm.releaseNotes, function (rnItem, rnIndex) {
            return _c(
              "div",
              {
                key: "rn" + rnIndex,
                staticClass: "has-text-justified about-section",
                class: { "is-italic": rnIndex > 0 },
              },
              [
                !rnItem.version
                  ? _c("div", [
                      _c("h2", { staticClass: "title mb-1 mt-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.settings.about.version")) +
                            " "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.gitVersion))]),
                      _c("h3", { staticClass: "title mb-1 mt-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.settings.about.commit")) +
                            " "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.gitCommit))]),
                      _c("h3", { staticClass: "title mb-1 mt-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("component.settings.about.released")
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(_vm.buildDate),
                                null,
                                null,
                                ""
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      [
                        rnIndex == 1
                          ? _c("section", [
                              _vm._m(0, true),
                              _c("h2", { staticClass: "title mb-1 mt-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.settings.about.previous_version"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _c("hr"),
                        _c("h2", { staticClass: "subtitle mb-1 mt-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.settings.about.version")
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", [_vm._v(_vm._s(rnItem.version))]),
                        rnItem.releasedAt
                          ? [
                              _c("h2", { staticClass: "subtitle mb-1 mt-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.settings.about.released"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dateTimeManager.formatTime(
                                      new Date(rnItem.releasedAt),
                                      null,
                                      null,
                                      ""
                                    )
                                  )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                rnItem.features.length > 0
                  ? _c(
                      "h4",
                      {
                        staticClass: "mb-1 mt-4",
                        class: rnIndex > 0 ? "subtitle" : "title",
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus-square pr-2" }),
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.settings.about.new_functions")
                          ) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(rnItem.features, function (note, index) {
                  return _c("section", { key: "f" + index }, [
                    _c(
                      "h4",
                      {
                        staticClass: "has-text-weight-semibold is-size-5 mt-3",
                      },
                      [_vm._v(" " + _vm._s(note.title) + " ")]
                    ),
                    _c("p", [_vm._v(_vm._s(note.desc))]),
                  ])
                }),
                rnItem.bugfixes.length > 0
                  ? _c(
                      "h4",
                      {
                        staticClass: "mb-1 mt-4",
                        class: rnIndex > 0 ? "subtitle" : "title",
                      },
                      [
                        _c("i", { staticClass: "fas fa-bug pr-2" }),
                        _vm._v(
                          _vm._s(_vm.$t("component.settings.about.bug_fixed")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(rnItem.bugfixes, function (note, index) {
                  return _c("section", { key: "b" + index }, [
                    _c(
                      "h4",
                      {
                        staticClass: "has-text-weight-semibold is-size-5 mt-3",
                      },
                      [_vm._v(" " + _vm._s(note.title) + " ")]
                    ),
                    _c("p", [_vm._v(_vm._s(note.desc))]),
                  ])
                }),
                rnIndex == 0
                  ? [
                      _vm._m(1, true),
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          " Monitorovací systém je validovaný. Zmeny v aktuálnej verzii boli preskúmané, zmeny nemajú vplyv na validitu systému validovaného k verzii v2.31.0. "
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "divider" }, [_c("hr"), _c("hr"), _c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h2", { staticClass: "title mb-1 mt-4" }, [
      _c("i", { staticClass: "fas fa-check pr-2" }),
      _vm._v("Validácia"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }