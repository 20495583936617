var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "tile is-parent" }, [
      _c("div", { staticClass: "tile is-child box" }, [
        _c(
          "div",
          { staticClass: "is-flex is-justify-content-flex-end mx-0 mb-5" },
          [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("b-button", {
                  staticClass: "mr-0-mobile",
                  attrs: { "icon-left": "plus", type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadThermalMap()
                    },
                  },
                }),
                !_vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: {
                          label: _vm.$t("admin.component.layout.listView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          attrs: { "icon-left": "stream" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLayoutList
                  ? _c(
                      "b-tooltip",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: {
                          label: _vm.$t("admin.component.layout.gridView"),
                          position: "is-left",
                          type: "is-dark",
                        },
                      },
                      [
                        _c("b-button", {
                          attrs: { "icon-left": "th-large" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLayout()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm.thermoMaps.length > 0
          ? _c("div", { staticClass: "columns is-multiline pb-4" }, [
              _c(
                "div",
                {
                  staticClass: "column",
                  class: _vm.isLayoutList ? "is-full" : "is-8",
                },
                [
                  _c("div", { staticClass: "columns mx-0" }, [
                    _vm.thermoMaps.length > 1
                      ? _c(
                          "div",
                          { staticClass: "is-2 column m-0 px-2 py-2" },
                          _vm._l(_vm.thermoImages, function (thermoImage, i) {
                            return _c(
                              "div",
                              {
                                key: thermoImage.thermoMapId,
                                staticClass:
                                  "is-clickable is-word-break-all mb-2 is-hidden-mobile",
                                style:
                                  thermoImage.thermoMapId === _vm.selectedMap.id
                                    ? ""
                                    : "opacity: 0.2;",
                                on: {
                                  click: function ($event) {
                                    return _vm.setSelectedThermoMap(
                                      thermoImage.thermoMapId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { id: "svgContainer_" + i } },
                                  [
                                    _c("svg", {
                                      attrs: { id: "svgElement_" + i },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "column mx-1",
                        class: _vm.isLayoutList
                          ? _vm.thermoMaps.length > 1
                            ? "is-10"
                            : "is-full"
                          : _vm.thermoMaps.length > 1
                          ? "is-10"
                          : "is-full",
                      },
                      [
                        _c("div", { attrs: { id: "svgContainer" } }, [
                          _c("svg", { attrs: { id: "svgElement" } }),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "column",
                  class: _vm.isLayoutList ? "is-full" : "is-4",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-flex is-justify-content-space-between is-align-items-center is-word-break-all",
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "subtitle has-text-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("component.thermal_map.settings"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            position: "is-bottom-left",
                            "append-to-body": "",
                            "aria-role": "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "admin.component.devices.detail.actions.title"
                                          ),
                                          position: "is-left",
                                          type: "is-dark",
                                        },
                                      },
                                      [
                                        _c("b-button", {
                                          staticClass: "is-dark-grey ml-1",
                                          attrs: { "icon-left": "ellipsis-v" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2780924240
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateThermoMap()
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "pencil-alt" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.edit")) + " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "custom-dropdown-item",
                              attrs: { "aria-role": "listitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteThermoMap()
                                },
                              },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "trash" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("generals.delete")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.thermoMaps.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "is-hidden-tablet is-flex is-justify-content-center my-2",
                        },
                        _vm._l(_vm.thermoMaps, function (thermo) {
                          return _c("b-icon", {
                            key: thermo.id,
                            staticClass: "mx-1 is-clickable",
                            attrs: {
                              size: "is-small",
                              pack:
                                thermo.id === _vm.selectedMap.id
                                  ? "fas"
                                  : "far",
                              icon: "circle",
                              type: "is-primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.setSelectedThermoMap(thermo.id)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.name"),
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.selectedMap.name) + " ")]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.from"
                            ),
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dateTimeManager.formatDateTimeInInput(
                                  new Date(_vm.selectedMap.from * 1000)
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t(
                              "component.report.configuration.modal.to"
                            ),
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatDateTimeInInput(
                                    new Date(_vm.selectedMap.to * 1000)
                                  )
                                )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "subtitle mt-5 mb-2 has-text-weight-bold",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.loggers"))
                              ),
                            ]
                          ),
                          _vm.layoutDataList[0] != null
                            ? _vm._l(
                                _vm.layoutDataList[0].sensors,
                                function (sensor) {
                                  return _c(
                                    "div",
                                    { key: sensor.id },
                                    [
                                      _vm.selectedNames[sensor.index]
                                        ? _c(
                                            "b-field",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "component.thermal_map.logger_label",
                                                  { x: sensor.index + 1 }
                                                ),
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedNames[
                                                        sensor.index
                                                      ]
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "subtitle mt-5 mb-2 has-text-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.thermal_map.display_settings")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("h3", { staticClass: "subtitle mb-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("component.thermal_map.color_range"))
                        ),
                      ]),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.min_value"),
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.selectedMap.minValue))])]
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("component.thermal_map.max_value"),
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.selectedMap.maxValue))])]
                      ),
                      _c(
                        "div",
                        { staticClass: "is-flex is-flex-direction-column" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                type: "is-primary",
                                tag: "a",
                                href: _vm.link,
                                "icon-pack": "fa",
                                "icon-left": "file",
                                download: "Map",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("component.thermal_map.download"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }