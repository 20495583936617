import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdminUserList from '@/views/components/admin/user/AdminUserList.vue';
import UserRepository from '@/services/repository/UserRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import CreateUserModal from '@/views/components/admin/user/CreateUserModal.vue';
import SalesRepository from '@/services/repository/SalesRepository';
import ApiResponse from '@/entities/ApiResponse';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import PreRegisterUserModal from '@/views/components/admin/user/PreRegisterUserModal.vue';
var userRepository;
var salesRepository;
let AdminUserManagement = class AdminUserManagement extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.userList = new ApiResponse();
        this.dateTimeManager = null;
        this.isCreateUserModalActive = false;
        this.isPreRegisterUserModalActive = false;
        this.totalSize = null;
        this.currentFilter = [];
        this.objStringAdminUser = null;
        this.tableAdminUserQueries = 'adminUser';
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        localStorage.removeItem(LocalStorageKeys.ADMIN_PREV_ROUTE);
        salesRepository = new SalesRepository(this);
    }
    async loadUsers(pagination, sort, currentFilter) {
        this.isLoading = true;
        userRepository = new UserRepository(this);
        this.userList = await userRepository.getUsersAdmin(null, pagination, sort, currentFilter);
        this.isLoading = false;
    }
    async onOptionsChange(options, filters = []) {
        this.callOptions = options;
        this.currentFilter = filters;
        this.loadUsers(options.pagination, options.sort, filters);
    }
    changeRouterUrlFromUser(objStringAdminUser) {
        this.objStringAdminUser = objStringAdminUser;
        this.changeRouterUrl(this.objStringAdminUser);
    }
    changeRouterUrl(objStringAdminUser) {
        this.$router
            .push({
            query: {
                [this.tableAdminUserQueries]: objStringAdminUser
            }
        })
            .catch((err) => err);
    }
    preRegisterUser() {
        this.isPreRegisterUserModalActive = true;
    }
    closePreRegisterUserModal(result) {
        this.isPreRegisterUserModalActive = false;
        if (result == true)
            this.loadUsers();
    }
    createNewUser() {
        this.isCreateUserModalActive = true;
    }
    closeCreateUserModal(result) {
        this.isCreateUserModalActive = false;
        if (result == true)
            this.loadUsers(this.callOptions.pagination, this.callOptions.sort, this.currentFilter);
    }
    async exportMailingList() {
        this.isLoading = true;
        await salesRepository.getMailingList(this.callOptions?.pagination, this.callOptions?.sort, this.currentFilter);
        this.isLoading = false;
    }
};
AdminUserManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.LIST, Subjects.ADMIN_USERS)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            AdminUserList,
            PreRegisterUserModal,
            CreateUserModal
        }
    })
], AdminUserManagement);
export default AdminUserManagement;
