var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _vm.isLayoutList
        ? _c("div", { staticClass: "level section-header-level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("h2", { staticClass: "level-item subtitle is-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.credentialCurrent.title"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("table", { staticClass: "info-table mobile-filter card-table" }, [
          _c("tr", [
            _c(
              "td",
              { staticClass: "row-label" },
              [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.credentialCurrent.table.publicKey"
                      )
                    )
                  ),
                ]),
                _c("b-icon", { attrs: { icon: "key" } }),
              ],
              1
            ),
            _c("td", [
              _vm.currentCredentials
                ? _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.currentCredentials.publicKey) + " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.credentialCurrent.table.keyId"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.currentCredentials
                ? _c("p", [
                    _vm._v(" " + _vm._s(_vm.currentCredentials.keyId) + " "),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.credentialCurrent.table.lastUsed"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.currentCredentials
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentCredentials.lastUsedAt
                            ? _vm.dateTimeManager.formatTime(
                                new Date(
                                  _vm.currentCredentials.lastUsedAt * 1000
                                )
                              )
                            : "-"
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
        ]),
        _c("table", { staticClass: "info-table mobile-filter card-table" }, [
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.credentialCurrent.table.keyType"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.currentCredentials
                ? _c("p", [
                    _vm._v(" " + _vm._s(_vm.currentCredentials.keyType) + " "),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "row-label" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "admin.component.devices.detail.credentialCurrent.table.created"
                    )
                  )
                ),
              ]),
            ]),
            _c("td", [
              _vm.currentCredentials
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentCredentials.createdAt
                            ? _vm.dateTimeManager.formatTime(
                                new Date(
                                  _vm.currentCredentials.createdAt * 1000
                                )
                              )
                            : "-"
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
          ]),
        ]),
      ]),
      _vm.$can(
        _vm.$permActions.EDIT,
        _vm.$permSubjects.ADMIN_GATEWAYS_CREDENTIALS
      )
        ? _c(
            "div",
            { staticClass: "buttons is-flex-mobile" },
            [
              _vm.currentCredentials != null
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item",
                      on: {
                        click: function ($event) {
                          return _vm.revokeCredential()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "admin.component.devices.detail.credentialCurrent.actions.revoke"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "level-item",
                  attrs: {
                    type: "is-primary",
                    disabled:
                      _vm.device.state === _vm.DeviceState.REGISTERED ||
                      _vm.device.state === _vm.DeviceState.ACTIVATED
                        ? false
                        : true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.newCredential()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.devices.detail.credentialCurrent.actions.newkey"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.devices.detail.credentialHistory.title"
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item is-hidden-mobile",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.clearFilter()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
            ),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: { position: "is-bottom-left", "aria-role": "list" },
                    scopedSlots: _vm._u([
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "admin.component.company.list.actions.columns"
                                  ),
                                  position: "is-left",
                                  type: "is-dark",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  { staticStyle: { "font-size": "1rem" } },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        type: "is-primary",
                                        icon: "list",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  _vm._l(_vm.tableColumns, function (column) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: column.field,
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          focusable: false,
                          custom: "",
                          "aria-role": "listitem",
                        },
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: {
                              disabled:
                                column.field ==
                                _vm.tableColumns.publicKey.field,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.chooseTableColumns(
                                  column.field,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: column.visible,
                              callback: function ($$v) {
                                _vm.$set(column, "visible", $$v)
                              },
                              expression: "column.visible",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `admin.component.devices.detail.credentialHistory.table.${column.field}`
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter table-wrap",
          attrs: {
            data: _vm.historyCredentials,
            paginated: "",
            "current-page": _vm.page,
            "per-page": _vm.pageSize,
            total: _vm.total,
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            striped: "",
            "page-input": true,
            "pagination-order": "is-centered",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.historyCredentials.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.total,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.publicKey.field,
              label: _vm.$t(
                `admin.component.devices.detail.credentialHistory.table.${_vm.tableColumns.publicKey.field}`
              ),
              visible: _vm.tableColumns.publicKey.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function (credentials) {
                  return [
                    _c(
                      "b-field",
                      {
                        staticClass:
                          "mt-1-mobile is-hidden-tablet is-full-width",
                        attrs: {
                          grouped: "",
                          label: _vm.$t(
                            "admin.component.devices.detail.credentialHistory.table.publicKey"
                          ),
                          "label-position": "on-border",
                        },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          model: {
                            value:
                              credentials.filters[credentials.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                credentials.filters,
                                credentials.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "credentials.filters[credentials.column.field]",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "level-item",
                                attrs: { type: "is-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearFilter()
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "times-circle" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      { staticClass: "is-hidden-mobile" },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          model: {
                            value:
                              credentials.filters[credentials.column.field],
                            callback: function ($$v) {
                              _vm.$set(
                                credentials.filters,
                                credentials.column.field,
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "credentials.filters[credentials.column.field]",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (credentials) {
                  return [
                    _c(
                      "p",
                      {
                        staticClass: "mr-1",
                        staticStyle: { "max-width": "400px" },
                      },
                      [_vm._v(" " + _vm._s(credentials.row.publicKey) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.keyId.field,
              label: _vm.$t(
                `admin.component.devices.detail.credentialHistory.table.${_vm.tableColumns.keyId.field}`
              ),
              visible: _vm.tableColumns.keyId.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (credentials) {
                  return [
                    _c("span", { staticClass: "is-family-monospace" }, [
                      _vm._v(_vm._s(credentials.row.keyId)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.keyType.field,
              label: _vm.$t(
                `admin.component.devices.detail.credentialHistory.table.${_vm.tableColumns.keyType.field}`
              ),
              visible: _vm.tableColumns.keyType.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (credentials) {
                  return [_vm._v(_vm._s(credentials.row.keyType))]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.createdAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.credentialHistory.table.${_vm.tableColumns.createdAt.field}`
              ),
              visible: _vm.tableColumns.createdAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (credentials) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(credentials.row.createdAt * 1000)
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.revokedAt.field,
              label: _vm.$t(
                `admin.component.devices.detail.credentialHistory.table.${_vm.tableColumns.revokedAt.field}`
              ),
              visible: _vm.tableColumns.revokedAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (credentials) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(credentials.row.revokedAt * 1000)
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.devices.detail.credentialHistory.table.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }