var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.notifications.sendNotification.title")
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c(
        "b-field",
        {
          attrs: {
            horizontal: "",
            label: _vm.$t(
              "admin.component.notifications.sendNotification.usersScope"
            ),
            type: {
              "is-danger": _vm.errors.has(
                _vm.$t(
                  "admin.component.notifications.sendNotification.usersScope"
                )
              ),
            },
            message: _vm.errors.first(
              _vm.$t(
                "admin.component.notifications.sendNotification.usersScope"
              )
            ),
          },
        },
        [
          _c(
            "b-select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                name: _vm.$t(
                  "admin.component.notifications.sendNotification.usersScope"
                ),
              },
              model: {
                value: _vm.selectedUserScope,
                callback: function ($$v) {
                  _vm.selectedUserScope = $$v
                },
                expression: "selectedUserScope",
              },
            },
            _vm._l(_vm.userScopes, function (option) {
              return _c(
                "option",
                { key: option, domProps: { value: option } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `admin.component.notifications.sendNotification.userScopes.${option}`
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "ValidationObserver",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showUsersTable,
              expression: "showUsersTable",
            },
          ],
          ref: "usersTable",
        },
        [
          _c(
            "b-field",
            {
              staticClass: "mb-3",
              attrs: {
                name: _vm.$t(
                  "admin.component.notifications.sendNotification.users"
                ),
                horizontal: "",
                label: _vm.$t(
                  "admin.component.notifications.sendNotification.users"
                ),
              },
            },
            [
              _c("ValidationProvider", {
                attrs: { rules: "min_value:1" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "b-field",
                          {
                            class: { "component-is-danger": errors[0] },
                            model: {
                              value: _vm.selectedUsers.length,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedUsers, "length", $$v)
                              },
                              expression: "selectedUsers.length",
                            },
                          },
                          [
                            _vm.showUsersTable
                              ? _c("UserListComponent", {
                                  attrs: {
                                    users: _vm.users,
                                    totalSize: _vm.totalSize,
                                  },
                                  on: {
                                    loadUsers: _vm.loadUsers,
                                    selectedUsersChanged:
                                      _vm.selectedUsersChanged,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        errors[0]
                          ? _c(
                              "span",
                              { staticClass: "has-text-danger is-size-7" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.notifications.sendNotification.usersTable.errorMsg"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        {
          attrs: {
            horizontal: "",
            label: _vm.$t(
              "admin.component.notifications.sendNotification.priority"
            ),
            type: {
              "is-danger": _vm.errors.has(
                _vm.$t(
                  "admin.component.notifications.sendNotification.priority"
                )
              ),
            },
            message: _vm.errors.first(
              _vm.$t("admin.component.notifications.sendNotification.priority")
            ),
          },
        },
        [
          _c(
            "b-select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                placeholder: _vm.$t(
                  "admin.component.notifications.sendNotification.priorityPlaceholder"
                ),
                name: _vm.$t(
                  "admin.component.notifications.sendNotification.priority"
                ),
              },
              model: {
                value: _vm.selectedPriority,
                callback: function ($$v) {
                  _vm.selectedPriority = $$v
                },
                expression: "selectedPriority",
              },
            },
            _vm._l(_vm.priorities, function (option) {
              return _c(
                "option",
                { key: option.text, domProps: { value: option } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `admin.component.notifications.sendNotification.priorities.${option.text}`
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "b-field",
        {
          attrs: {
            horizontal: "",
            label: _vm.$t("component.notification.create_rule.channel"),
            type: {
              "is-danger": _vm.errors.has(
                _vm.$t("component.notification.create_rule.channel")
              ),
            },
            message: _vm.errors.first(
              _vm.$t("component.notification.create_rule.channel")
            ),
          },
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.channels, function (channel) {
                return [
                  channel !== _vm.NotificationChannelType.BUZZER &&
                  channel !== _vm.NotificationChannelType.CALL
                    ? _c(
                        "b-checkbox",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          key: channel,
                          attrs: {
                            "native-value": channel,
                            name: _vm.$t(
                              "component.notification.create_rule.channel"
                            ),
                          },
                          model: {
                            value: _vm.selectedChannels,
                            callback: function ($$v) {
                              _vm.selectedChannels = $$v
                            },
                            expression: "selectedChannels",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("notification_channels." + channel)
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "b-field",
        {
          attrs: {
            horizontal: "",
            label: _vm.$t(
              "admin.component.notifications.sendNotification.notificationTitle"
            ),
            type: {
              "is-danger": _vm.errors.has(
                _vm.$t(
                  "admin.component.notifications.sendNotification.notificationTitle"
                )
              ),
            },
            message: _vm.errors.first(
              _vm.$t(
                "admin.component.notifications.sendNotification.notificationTitle"
              )
            ),
          },
        },
        [
          _c(
            "div",
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "pb-0",
                attrs: {
                  maxlength: _vm.getTitleLength(),
                  name: _vm.$t(
                    "admin.component.notifications.sendNotification.notificationTitle"
                  ),
                },
                model: {
                  value: _vm.notificationTitle,
                  callback: function ($$v) {
                    _vm.notificationTitle = $$v
                  },
                  expression: "notificationTitle",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-field",
        {
          attrs: {
            horizontal: "",
            label: _vm.$t(
              "admin.component.notifications.sendNotification.text"
            ),
            type: {
              "is-danger": _vm.errors.has(
                _vm.$t("admin.component.notifications.sendNotification.text")
              ),
            },
            message: _vm.errors.first(
              _vm.$t("admin.component.notifications.sendNotification.text")
            ),
          },
        },
        [
          _c("b-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            attrs: {
              type: "textarea",
              maxlength: _vm.getContentLength(),
              name: _vm.$t(
                "admin.component.notifications.sendNotification.text"
              ),
            },
            model: {
              value: _vm.notificationText,
              callback: function ($$v) {
                _vm.notificationText = $$v
              },
              expression: "notificationText",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "level-right" },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "admin.component.notifications.sendNotification.send_btn"
                  )
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }