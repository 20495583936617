import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportRecord } from '@/entities/models/ReportRecord';
import { ChartBuilder, LabelPosition } from 'd3Chart';
import ReportChartDataConverter from '@/services/chart-data-conversion/ReportChartDataConverter';
import StatisticsTable from '@/views/components/reports/StatisticsTable.vue';
import RecordsTable from '@/views/components/reports/RecordsTable.vue';
import { Statistics } from '@/entities/Statistics';
import { ChartComponents } from '@/entities/enums/ChartComponents';
let SourceTabs = class SourceTabs extends Vue {
    constructor() {
        super(...arguments);
        this.ReportRecord = ReportRecord;
        this.chartId = 'reportChart';
        this.hasDataForTimerange = false;
        this.activeTab = 0;
        this.activeSource = null;
        this.activeStatistics = [];
        this.activeSourceServices = [];
        this.activeRecords = [];
        this.filterRecordsValue = false;
    }
    created() {
        if (this.sourceGroupSources.length > 0) {
            this.activeTab = this.selectSourceTabIndex(this.$route.query.sourceId);
            this.updateRouteQuery(this.sourceGroupSources[this.activeTab].id);
            this.$emit('tabChanged', this.sourceGroupSources[this.activeTab].id);
            this.activeSource = this.sourceGroupSources[this.activeTab];
            this.activeStatistics = this.sourceGroupStatistics[this.activeSource.id];
            this.activeSourceServices = Statistics.getServices(this.sourceGroupStatistics[this.activeSource.id]);
            this.activeRecords = this.reportRecords[this.activeSource.id];
        }
        this.filterRecordsValue = this.filterRecords;
    }
    selectSourceTabIndex(id) {
        if (!id || this.sourceGroupSources.findIndex((x) => x.id == id) < 0) {
            /*  if (id)
              VueUtilities.openErrorToast(
                this,
                this.$t("error_messages.invalid_source_id").toString()
              );*/
            return 0;
        }
        else {
            return this.sourceGroupSources.findIndex((x) => x.id == id);
        }
    }
    updateRouteQuery(sourceId) {
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, { sourceId: sourceId }),
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    mounted() {
        if (this.activeSource)
            this.createChart();
    }
    beforeDestroy() {
        this.destroyChart();
    }
    reloadRecords() {
        this.$emit('reloadRecords');
    }
    tabChanged(tabIndex) {
        this.destroyChart();
        this.updateRouteQuery(this.sourceGroupSources[tabIndex].id);
        this.$emit('tabChanged', this.sourceGroupSources[tabIndex].id);
        this.activeSource = this.sourceGroupSources[tabIndex];
        this.activeSourceServices = Statistics.getServices(this.sourceGroupStatistics[this.activeSource.id]);
        this.activeRecords = this.reportRecords[this.activeSource.id];
        this.activeStatistics = this.sourceGroupStatistics[this.activeSource.id];
        this.createChart();
    }
    async createChart() {
        let pickedRecordsForSource = ReportRecord.pickSource(this.allReportRecords, this.activeSource.id);
        let reportChartDataConverter = new ReportChartDataConverter(pickedRecordsForSource, this.eventTypes, this.sourceGroupSources);
        let settings = {
            fullHeight: 300,
            previewChartHeight: 70,
            gapBetweenCharts: 20,
            margin: {
                left: 30,
                right: 15
            },
            marginPreview: {
                left: 30,
                right: 15
            }
        };
        let dateRange = {
            startTS: this.dateFilter.startTS * 1000,
            stopTS: this.dateFilter.stopTS * 1000
        };
        if (reportChartDataConverter.hasData) {
            this.hasDataForTimerange = true;
            this.chartEntity = new ChartBuilder(this.chartId, settings, reportChartDataConverter.services, reportChartDataConverter.data)
                .addBoundaries(reportChartDataConverter.boundaries, false)
                .makeInteractive()
                .setDateFormatter(await reportChartDataConverter.getTimeFormatter(this, this.onPublicPage))
                .setChartDateFormatter(await reportChartDataConverter.getChartTimeFormatter(this, this.onPublicPage))
                .setOnlyTimeFormatter(await reportChartDataConverter.getOnlyTimeFormatter(this, this.onPublicPage))
                .setOnlyDateFormatter(await reportChartDataConverter.getOnlyDateFormatter(this, this.onPublicPage))
                .addPreviewChart()
                .setLabelPosition(LabelPosition.UP)
                .addGridlines(true, true)
                .setDateRange(dateRange)
                .setAxisScaleRange(this.axisRanges)
                .addZoomButtons()
                .setFontSize('12px')
                .build();
        }
        else {
            this.hasDataForTimerange = false;
        }
        this.$emit('eventHandler', this.hasDataForTimerange, this.chartEntity, ChartComponents.SOURCETABS);
    }
    destroyChart() {
        if (this.chartEntity)
            this.chartEntity.destroyChart();
    }
    filterRecordsChanged(value) {
        this.$emit('changeFilterRecords', value);
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], SourceTabs.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Object })
], SourceTabs.prototype, "sourceGroupStatistics", void 0);
__decorate([
    Prop({ type: Object })
], SourceTabs.prototype, "reportRecords", void 0);
__decorate([
    Prop({ type: Object })
], SourceTabs.prototype, "allReportRecords", void 0);
__decorate([
    Prop({ type: Array })
], SourceTabs.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], SourceTabs.prototype, "dateFilter", void 0);
__decorate([
    Prop({ type: Object })
], SourceTabs.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], SourceTabs.prototype, "axisRanges", void 0);
__decorate([
    Prop({ type: Boolean })
], SourceTabs.prototype, "filterRecords", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], SourceTabs.prototype, "onPublicPage", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], SourceTabs.prototype, "annotationsForSource", void 0);
SourceTabs = __decorate([
    Component({
        components: {
            StatisticsTable,
            RecordsTable
        }
    })
], SourceTabs);
export default SourceTabs;
