var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    { staticClass: "footer is-bold mt-5" },
    [
      _c(
        "div",
        {
          staticClass: "has-text-centered footer-text",
          style: { "padding-left": _vm.sideBarWidth + "px" },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("generals.footer_text", {
                    range: "2018-" + new Date().getFullYear(),
                  })
                ) +
                " "
            ),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.branchInfo))]),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "mx-2",
                on: {
                  click: function ($event) {
                    return _vm.openAboutModal()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("about.aboutUs")))]
            ),
            _vm.hasCookiesAllowed
              ? _c(
                  "a",
                  {
                    staticClass: "mx-2",
                    on: {
                      click: function ($event) {
                        return _vm.openCookiesSettings()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("cookies.open_settings")))]
                )
              : _vm._e(),
            _vm.isPageAfterLogin
              ? _c(
                  "a",
                  {
                    staticClass: "mx-2",
                    on: {
                      click: function ($event) {
                        return _vm.logout()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("navbar.logout")))]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.hasCookiesAllowed
        ? _c(
            "b-modal",
            {
              staticClass: "cookies-modal",
              attrs: {
                active: _vm.isCustomSettingsVisible,
                "has-modal-card": "",
                onCancel: _vm.closeCustomSettingsModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isCustomSettingsVisible = $event
                },
                "close-modal": _vm.closeCustomSettingsModal,
              },
            },
            [
              _c("CustomSettingsModal", {
                attrs: { cookiesAllowed: _vm.cookiesAllowed },
                on: { modalClosed: _vm.closeCustomSettingsModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isAboutVisible,
            "has-modal-card": "",
            onCancel: _vm.closeAboutModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isAboutVisible = $event
            },
            "close-modal": _vm.closeAboutModal,
          },
        },
        [
          _c("AboutModal", {
            attrs: { cookiesAllowed: _vm.cookiesAllowed },
            on: { modalClosed: _vm.closeAboutModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }