import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import { DeviceState } from '@/entities/enums/DeviceState';
let DeviceActionsDropdown = class DeviceActionsDropdown extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceInstanceState = DeviceInstanceState;
        this.DeviceState = DeviceState;
    }
    reuseDevice() {
        this.$emit('reuseDevice', this.adminDevice);
    }
    returnDeviceFromCompany() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.devices.detail.deviceInfo.return.title'),
            message: this.$t('admin.component.devices.detail.deviceInfo.return.message', {
                name: this.adminDevice.deviceName
            }),
            confirmText: this.$t('admin.component.devices.detail.deviceInfo.return.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.$emit('returnDeviceFromCompany', this.adminDevice);
            }
        });
    }
    registerToCompany() {
        this.$emit('registerToCompany', this.adminDevice);
    }
    openDeviceMonitor() {
        this.$emit('openDeviceMonitor', this.adminDevice);
    }
    configureDevice() {
        this.$emit('configureDevice', this.adminDevice);
    }
    otaUpdateDevice() {
        this.$emit('otaUpdateDevice', this.adminDevice);
    }
    keyUpdate() {
        this.$emit('keyUpdate', this.adminDevice);
    }
    revertDevice() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.devices.detail.deviceInfo.revert.title'),
            message: this.$t('admin.component.devices.detail.deviceInfo.revert.message', {
                name: this.adminDevice.deviceName
            }),
            confirmText: this.$t('admin.component.devices.detail.deviceInfo.revert.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.$emit('revertDevice', this.adminDevice);
            }
        });
    }
    activateDevice() {
        this.$emit('activateDevice', this.adminDevice);
    }
    publishDevice() {
        this.$emit('publishDevice', this.adminDevice);
    }
};
__decorate([
    Prop({ type: Object })
], DeviceActionsDropdown.prototype, "adminDevice", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DeviceActionsDropdown.prototype, "hasButton", void 0);
DeviceActionsDropdown = __decorate([
    Component({})
], DeviceActionsDropdown);
export default DeviceActionsDropdown;
