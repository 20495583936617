import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
import ReportingInterval, { getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import UserPreferences from '@/services/UserPreferences';
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
let ReportSettings = class ReportSettings extends Vue {
    constructor() {
        super(...arguments);
        this.PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;
        this.isEditing = false;
        this.loggerIds = [];
        this.selectedSourceGroups = [];
        this.sortDirection = UserPreferences.LocalStorageKeys.ReportSettingsSortDirection.defaultValue;
        this.sortField = UserPreferences.LocalStorageKeys.ReportSettingsSortField.defaultValue;
        this.page = UserPreferences.LocalStorageKeys.ReportSettingsPage.defaultValue;
        this.pageSize = UserPreferences.LocalStorageKeys.ReportSettingsRowsPerPage.defaultValue;
        this.total = 0;
        this.allMeasuredTimesList = [];
        this.isLoading = true;
        this.tableColumns = {
            periodicReportPdfType: {
                field: 'periodicReportPdfType',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportSettingsPeriodicReportPdfType)
            },
            interval: {
                field: 'interval',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportSettingsInterval)
            },
            measuredAt: {
                field: 'measuredAt',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportSettingsMeasuredAt)
            },
            eventTypes: {
                field: 'eventTypes',
                visible: UserPreferences.getPreference(UserPreferences.LocalStorageKeys.ReportSettingsEventTypes)
            }
        };
    }
    get fromTableData() {
        let from = this.page == 1 ? this.page : (this.page - 1) * this.pageSize + 1;
        return from;
    }
    get toTableData() {
        return this.page * this.pageSize > this.total ? this.total : this.page * this.pageSize;
    }
    created() {
        this.total = this.periodicReports.length;
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.periodicReports.forEach(async (periodicReport) => {
            let allMeasuredTimes = await getAllAvailableMeasureTimes(this, periodicReport.interval);
            this.allMeasuredTimesList.push({ id: periodicReport.id, allMeasuredTimes: allMeasuredTimes });
        });
        this.isLoading = false;
    }
    currentTypes(periodicReport) {
        if (periodicReport) {
            return periodicReport.eventTypes.map((x) => this.$t(`services.${x}`)).join(', ');
        }
        else {
            return null;
        }
    }
    deleteConfigModal(id) {
        this.$buefy.dialog.confirm({
            title: `${this.$t('reports.report_settings.deleteReportConfigModal.title')}`,
            message: this.$t('reports.report_settings.deleteReportConfigModal.message'),
            confirmText: `${this.$t('reports.report_settings.deleteReportConfigModal.confirm')}`,
            cancelText: `${this.$t('reports.report_settings.deleteReportConfigModal.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('deletePeriodicReport', id);
            },
            onCancel: () => { }
        });
    }
    editConfigModal(periodicReport) {
        this.openReportSettingModal(periodicReport, true);
    }
    openReportSettingModal(periodicReport = null, isEdit = false) {
        this.$emit('openReportSettingModal', periodicReport, isEdit);
    }
    editSettings() {
        this.isEditing = !this.isEditing;
    }
    save() {
        this.$validator.validateAll().then((result) => {
            if (result) {
            }
        });
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    formatStatModel(periodicReport) {
        if (periodicReport && periodicReport.statModels.findIndex((x) => x.includes('daily')) !== -1)
            return 'daily';
        else
            return 'average';
    }
    formatMeasureTime(periodicReport) {
        if (periodicReport) {
            if (periodicReport.interval == ReportingInterval.INTERVAL_24_00) {
                let date = new Date(0, 0, 0, 0);
                date.setMinutes(periodicReport?.offset);
                return date.toTimeString().slice(0, 5);
            }
            else {
                let measurementOffsetIndex = periodicReport.offset / 60;
                return this.allMeasuredTimesList.find((x) => x.id == periodicReport.id)?.allMeasuredTimes[measurementOffsetIndex];
            }
        }
        else {
            return null;
        }
    }
};
__decorate([
    Prop({ type: Object })
], ReportSettings.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], ReportSettings.prototype, "periodicReports", void 0);
ReportSettings = __decorate([
    Component({
        components: {}
    })
], ReportSettings);
export default ReportSettings;
