var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "small" }, [
    _c(
      "div",
      [
        _c("h2", { staticClass: "title is-2 has-text-centered" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.password_recover.change_password")) +
              " "
          ),
        ]),
        _vm.showForm
          ? _c(
              "div",
              [
                _c(
                  "b-field",
                  { attrs: { label: _vm.$t("component.register.email") } },
                  [_c("span", [_vm._v(_vm._s(_vm.username))])]
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t("component.register.password"),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("component.register.password")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("component.register.password")
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|password",
                          expression: "'required|password'",
                        },
                      ],
                      ref: "pwd",
                      attrs: {
                        type: "password",
                        name: _vm.$t("component.register.password"),
                        "password-reveal": "",
                        placeholder: _vm.$t("component.register.password"),
                      },
                      model: {
                        value: _vm.pwd,
                        callback: function ($$v) {
                          _vm.pwd = $$v
                        },
                        expression: "pwd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: _vm.$t("component.register.confirm_pswd"),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("component.register.confirm_pswd")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("component.register.confirm_pswd")
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|password|confirmed:pwd",
                          expression: "'required|password|confirmed:pwd'",
                        },
                      ],
                      ref: "pwd_check",
                      attrs: {
                        type: "password",
                        name: _vm.$t("component.register.confirm_pswd"),
                        "password-reveal": "",
                        placeholder: _vm.$t("component.register.confirm_pswd"),
                      },
                      model: {
                        value: _vm.pwd_check,
                        callback: function ($$v) {
                          _vm.pwd_check = $$v
                        },
                        expression: "pwd_check",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-info" },
                    on: {
                      click: function ($event) {
                        return _vm.changePassword()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("component.password_recover.submit")))]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.wrongToken == true
          ? _c(
              "div",
              {
                staticClass:
                  "has-text-centered has-text-danger has-text-weight-semibold mt-5",
              },
              [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("component.password_recover.wrong_token"))
                  ),
                ]),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "forgotPassword",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticStyle: { "margin-top": "1rem" },
                        attrs: { type: "is-primary", outlined: "" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("component.password_recover.forgot"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.sent
          ? _c(
              "p",
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.password_recover.change_successful_part1"
                      )
                    ) +
                    " "
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "login",
                        params: {
                          lang: _vm.$route.params.lang,
                        },
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.password_recover.change_successful_login"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.password_recover.change_successful_part2"
                      )
                    ) +
                    " "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.error
          ? _c("p", { staticClass: "error" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("component.password_recover.error_occurred")) +
                  " "
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("component.password_recover.try_again")) +
                  " "
              ),
            ])
          : _vm._e(),
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "login",
                params: {
                  lang: _vm.$route.params.lang,
                },
              },
            },
          },
          [
            _vm.sent
              ? _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "1rem" },
                    attrs: { type: "is-primary", outlined: "" },
                  },
                  [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }