var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "content" }, [
        _c("h1", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.wizard.info_modal.body.tutorial.instructions.title"
                )
              ) +
              " "
          ),
        ]),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "component.wizard.info_modal.body.tutorial.instructions.text"
              )
            ),
          },
        }),
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.title")
              ) +
              " "
          ),
        ]),
        _c("ol", [
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.loggers")
              ),
            },
          }),
          _c("li", [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("component.wizard.instructions.systemParts.observer")
                ),
              },
            }),
            _c(
              "div",
              { staticClass: "img-70" },
              [
                _c("b-image", {
                  attrs: {
                    src: require("@/assets/img/loggersWithObserver.png"),
                    alt: "loggersWithObserver",
                    responsive: false,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.cloud")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.app")
              ),
            },
          }),
        ]),
        _c("div", { staticClass: "multi-header mt-5" }, [
          _c("h2", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "component.wizard.instructions.installationRequirements.title"
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("ol", [
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "component.wizard.instructions.installationRequirements.req1"
                )
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "component.wizard.instructions.installationRequirements.req2"
                )
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "component.wizard.instructions.installationRequirements.req3"
                )
              ),
            },
          }),
          !_vm.isFlutter
            ? _c("li", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "component.wizard.instructions.installationRequirements.req4"
                    )
                  ),
                },
              })
            : _c("li", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "component.wizard.instructions.installationRequirements.req5"
                    )
                  ),
                },
              }),
        ]),
        !_vm.isFlutter
          ? _c(
              "div",
              {
                staticClass:
                  "is-flex is-justify-content-space-around is-align-items-center",
              },
              [
                _c(
                  "figure",
                  { staticClass: "img-70 my-0" },
                  [
                    _c("b-image", {
                      staticClass: "mb-4",
                      attrs: {
                        src: require("@/assets/img/qr_google_store.jpeg"),
                        alt: "Google Play QR code",
                        responsive: false,
                      },
                    }),
                    _c("figcaption", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.wizard.instructions.installationRequirements.qrGooglePlayStore"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "figure",
                  { staticClass: "img-70 my-0" },
                  [
                    _c("b-image", {
                      staticClass: "mb-4",
                      attrs: {
                        src: require("@/assets/img/qr_apple_store.jpeg"),
                        alt: "Apple App Store QR code",
                        responsive: false,
                      },
                    }),
                    _c("figcaption", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.wizard.instructions.installationRequirements.qrAppleStore"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.wizard.instructions.connection.title")) +
              " "
          ),
        ]),
        _c("ol", [
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.1")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.2")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.3")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.4")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.5")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.6")
              ),
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }