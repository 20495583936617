var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("admin.component.user.detail.add_phone.title", {
                  prefix: _vm.phoneNumber
                    ? _vm.$t("admin.component.user.detail.add_phone.update")
                    : _vm.$t("admin.component.user.detail.add_phone.add"),
                })
              ) +
              " "
          ),
        ]),
      ]),
      _c("section", { staticClass: "modal-card-body has-text-centered" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "has-text-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "admin.component.user.detail.add_phone.phone_number_format"
                    )
                  ) +
                  " "
              ),
            ]),
            _c(
              "b-field",
              {
                staticClass: "is-half-width h-center-div",
                attrs: {
                  type: {
                    "is-danger":
                      _vm.errors.has(
                        _vm.$t(
                          "admin.component.user.detail.add_phone.phone_label"
                        )
                      ) || _vm.invalidNumber,
                  },
                  message:
                    _vm.errors.first(
                      _vm.$t(
                        "admin.component.user.detail.add_phone.phone_label"
                      )
                    ) || _vm.phoneNumberAdditionalError,
                },
              },
              [
                _c("b-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|phoneE164",
                      expression: "'required|phoneE164'",
                    },
                  ],
                  attrs: {
                    name: _vm.$t(
                      "admin.component.user.detail.add_phone.phone_label"
                    ),
                    type: "text",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.numberChanged()
                    },
                  },
                  model: {
                    value: _vm.editedPhoneNumber,
                    callback: function ($$v) {
                      _vm.editedPhoneNumber = $$v
                    },
                    expression: "editedPhoneNumber",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "is-italic" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("admin.component.user.detail.add_phone.note")) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.phoneNumber === _vm.editedPhoneNumber,
                type: "is-primary",
              },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.phoneNumber
                    ? _vm.$t("admin.component.user.detail.add_phone.update")
                    : _vm.$t("admin.component.user.detail.add_phone.add")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }