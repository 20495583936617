import { Statistics } from '../Statistics';
import User from '../User';
export class Annotation {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => Annotation.define(et));
        }
        else if (data != null) {
            return Annotation.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let annotation = new Annotation();
        annotation.id = data.Id;
        annotation.createdAt = data.CreatedAt;
        annotation.sourceId = data.SourceId;
        annotation.text = data.Text;
        annotation.startDate = data.StartDate;
        annotation.endDate = data.EndDate;
        if (data.Statistics) {
            let processedStatistics = Statistics.fromApi({
                [annotation.sourceId]: data.Statistics
            });
            annotation.statistics = processedStatistics[annotation.sourceId];
        }
        if (data.CreatedBy)
            annotation.createdBy = User.fromApi(data.CreatedBy);
        return annotation;
    }
}
export var PredefinedText;
(function (PredefinedText) {
    PredefinedText["SANITATION"] = "sanitation";
    PredefinedText["DEFROSTING"] = "defrosting";
    PredefinedText["MAINTENANCE"] = "maintenance";
    PredefinedText["MALFUNCTION"] = "malfunction";
    PredefinedText["BLACKOUT"] = "blackout";
    PredefinedText["TAKEN_OUT"] = "taken_out";
    PredefinedText["STOCKING"] = "stocking";
    PredefinedText["POSITION"] = "position";
    PredefinedText["OWN"] = "own";
})(PredefinedText || (PredefinedText = {}));
