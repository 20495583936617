var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
      _vm.hasCookiesAllowed && _vm.hasAnalyticsAllowedOnFlutter
        ? _c("CookiesBar")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }