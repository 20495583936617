import DaysBeforeOptions from '@/entities/enums/DaysBeforeOptions';
import { EventDetailGridSize } from '@/entities/enums/EventDetailGridSize';
var PreferenceType;
(function (PreferenceType) {
    PreferenceType["BOOLEAN"] = "boolean";
    PreferenceType["STRING"] = "string";
    PreferenceType["NUMBER"] = "number";
})(PreferenceType || (PreferenceType = {}));
class UserPreferences {
    constructor() {
        this.LocalStorageKeys = {
            AdminDeviceUpdatesSortField: {
                key: 'adminDeviceCommandsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'id'
            },
            AdminDeviceUpdatesSortDirection: {
                key: 'adminDeviceCommandsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminDeviceUpdatesPage: {
                key: 'adminDeviceCommandsPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeviceUpdatesRowsPerPage: {
                key: 'adminDeviceUpdatesRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminDeviceCommandsSortField: {
                key: 'adminDeviceCommandsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'id'
            },
            AdminDeviceCommandsSortDirection: {
                key: 'adminDeviceCommandsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminDeviceCommandsPage: {
                key: 'adminDeviceCommandsPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeviceCommandsRowsPerPage: {
                key: 'adminDeviceCommandsRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            SentInvitationsManagementSortField: {
                key: 'sentInvitationsManagementSortField',
                type: PreferenceType.STRING,
                defaultValue: 'latestInvitation.createdAt'
            },
            SentInvitationsManagementSortDirection: {
                key: 'sentInvitationsManagementSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            SentInvitationsManagementPage: {
                key: 'sentInvitationsManagementPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            SentInvitationsManagementRowsPerPage: {
                key: 'sentInvitationsManagementRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            ReportSettingsSortField: {
                key: 'reportSettingsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'periodicReportPdfType'
            },
            ReportSettingsSortDirection: {
                key: 'reportSettingsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            ReportSettingsPage: {
                key: 'reportSettingsPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            ReportSettingsRowsPerPage: {
                key: 'reportSettingsRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminDeviceInstanceSortField: {
                key: 'adminDeviceInstanceSortField',
                type: PreferenceType.STRING,
                defaultValue: 'id'
            },
            AdminDeviceInstanceSortDirection: {
                key: 'adminDeviceInstanceSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            IssueNotificationsSortDirection: {
                key: 'issueNotificationsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            IssueNotificationsSortField: {
                key: 'issueNotificationsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'createdAt'
            },
            IssueNotificationsPage: {
                key: 'issueNotificationsPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            IssueNotificationsRowsPerPage: {
                key: 'issueNotificationsRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            DashboardsSortField: {
                key: 'dashboardsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'name'
            },
            DashboardsSortDirection: {
                key: 'dashboardsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            DashboardsPage: { key: 'dashboardsPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            DashboardsRowsPerPage: {
                key: 'dashboardsRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            PublicDashboardSortField: {
                key: 'publicDashboardSortField',
                type: PreferenceType.STRING,
                defaultValue: 'lastSeen'
            },
            PublicDashboardSortDirection: {
                key: 'publicDashboardSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            PublicDashboardPage: {
                key: 'publicDashboardPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            PublicDashboardRowsPerPage: {
                key: 'publicDashboardRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 5
            },
            StatesVisible: { key: 'statesVisible', type: PreferenceType.BOOLEAN, defaultValue: false },
            SelectedLoggersState: {
                key: 'selectedLoggersState',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            SelectedDevicesState: {
                key: 'selectedDevicesState',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            LoggerChangesSortField: {
                key: 'loggerChangesSortField',
                type: PreferenceType.STRING,
                defaultValue: 'assigned.changedAt'
            },
            LoggerChangesSortDirection: {
                key: 'loggerChangesSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminCompanyDevicesSortField: {
                key: 'adminCompanyDevicesSortField',
                type: PreferenceType.STRING,
                defaultValue: 'deviceName'
            },
            AdminCompanyDevicesSortDirection: {
                key: 'adminCompanyDevicesSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminResellerUsersSortField: {
                key: 'adminResellerUsersSortField',
                type: PreferenceType.STRING,
                defaultValue: 'fullName'
            },
            AdminResellerUsersSortDirection: {
                key: 'adminResellerUsersSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminCompanyUsersSortDirection: {
                key: 'adminCompanyUsersSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminCompanyUsersSortField: {
                key: 'adminCompanyUsersSortField',
                type: PreferenceType.STRING,
                defaultValue: 'fullName'
            },
            AdminAddCompanyUsersPage: {
                key: 'adminAddCompanyUsers',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminAddCompanyUsersRowsPerPage: {
                key: 'adminAddCompanyUsersRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 5
            },
            UserListComponentPage: {
                key: 'userListComponentPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            UserListComponentRowsPerPage: {
                key: 'userListComponentRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 20
            },
            BackgroundJobsSortField: {
                key: 'backgroundJobsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'Name'
            },
            BackgroundJobsSortDirection: {
                key: 'backgroundJobsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            BackgroundJobsPage: { key: 'backgroundJobsPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            AdminCompanyLoggersSortField: {
                key: 'adminCompanyLoggersSortField',
                type: PreferenceType.STRING,
                defaultValue: 'serialNumber'
            },
            AdminCompanyLoggersSortDirection: {
                key: 'adminCompanyLoggersSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            ChangeRequestsSortField: {
                key: 'changeRequestsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'id'
            },
            ChangeRequestsSortDirection: {
                key: 'changeRequestsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            TierUsageSortField: {
                key: 'tierUsageSortField',
                type: PreferenceType.STRING,
                defaultValue: 'Name'
            },
            TierUsageSortDirection: {
                key: 'tierUsageSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            FeaturesSortField: {
                key: 'featuresSortField',
                type: PreferenceType.STRING,
                defaultValue: 'name'
            },
            FeaturesSortDirection: {
                key: 'featuresSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            cardViewActive: { key: 'cardViewActive', type: PreferenceType.BOOLEAN, defaultValue: true },
            SideMenuExpanded: { key: 'sideMenuExpanded', type: PreferenceType.BOOLEAN, defaultValue: true },
            DeviceListSortField: {
                key: 'deviceListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'deviceName'
            },
            DeviceListSortDirection: {
                key: 'deviceListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            DeviceListPage: { key: 'deviceListPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            DeviceListRowsPerPage: {
                key: 'deviceListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            SourceListSortField: {
                key: 'sourceListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'name'
            },
            SourceListSortDirection: {
                key: 'sourceListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            SourceListPage: { key: 'sourceListPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            SourceListRowsPerPage: {
                key: 'sourceListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            IssuesTableSortField: {
                key: 'issuesTableSortField',
                type: PreferenceType.STRING,
                defaultValue: 'createdAt'
            },
            IssuesTableSortDirection: {
                key: 'issuesTableSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            IssuesTablePage: { key: 'issuesTablePage', type: PreferenceType.NUMBER, defaultValue: 1 },
            IssuesTableRowsPerPage: {
                key: 'issuesTableRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            SourceGroupListSortField: {
                key: 'sourceGroupListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'name'
            },
            SourceGroupListSortDirection: {
                key: 'sourceGroupListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            SourceGroupListPage: {
                key: 'sourceGroupListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            EventDetailGridSize: {
                key: 'eventDetailGridSize',
                type: PreferenceType.NUMBER,
                defaultValue: EventDetailGridSize.SMALL
            },
            NotificationsListSortField: {
                key: 'notificationsListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'CreatedAt'
            },
            NotificationsListSortDirection: {
                key: 'notificationsListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            NotificationsListPage: {
                key: 'notificationsListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            NotificationsListRowsPerPage: {
                key: 'notificationsListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            NotificationRulesListSortField: {
                key: 'notificationRulesListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'NotificationId'
            },
            NotificationRulesListSortDirection: {
                key: 'notificationRulesListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            NotificationRulesListPage: {
                key: 'notificationRulesListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            ReportsServiceTab: { key: 'reportsServiceTab', type: PreferenceType.NUMBER, defaultValue: 0 },
            ReportsSelectedLoggers: {
                key: 'reportsSelectedLoggers',
                type: PreferenceType.STRING,
                defaultValue: null
            },
            DateFilterOption: { key: 'dateFilterOption', type: PreferenceType.STRING, defaultValue: null },
            DateFilterStart: { key: 'dateFilterStart', type: PreferenceType.STRING, defaultValue: null },
            DateFilterStop: { key: 'dateFilterStop', type: PreferenceType.STRING, defaultValue: null },
            UserAdministrationSortField: {
                key: 'userAdministrationSortField',
                type: PreferenceType.STRING,
                defaultValue: 'fullName'
            },
            UserAdministrationSortDirection: {
                key: 'userAdministrationSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            UserAdministrationPage: {
                key: 'userAdministrationPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            UserAdministrationRowsPerPage: {
                key: 'userAdministrationRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            OutdatedLastEventsVisible: {
                key: 'outdatedLastEventsVisible',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceStatesVisible: {
                key: 'deviceStatesVisible',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            FloorPlanVisible: {
                key: 'floorPlanVisible',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            IsScaleByData: { key: 'isScaleByData', type: PreferenceType.BOOLEAN, defaultValue: false },
            OutdatedGroupEventsVisible: {
                key: 'outdatedGroupEventsVisible',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AnnotationTablePage: {
                key: 'annotationTablePage',
                type: PreferenceType.NUMBER,
                defaultValue: null
            },
            AdminCompanySortField: {
                key: 'adminCompanySortField',
                type: PreferenceType.STRING,
                defaultValue: 'companyName'
            },
            AdminCompanySortDirection: {
                key: 'adminCompanySortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminCompanyPage: { key: 'adminCompanyPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            AdminCompanyRowsPerPage: {
                key: 'adminCompanyRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 20
            },
            AdminResellerSortField: {
                key: 'adminResellerSortField',
                type: PreferenceType.STRING,
                defaultValue: 'name'
            },
            AdminResellerSortDirection: {
                key: 'adminResellerSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminResellerPage: { key: 'adminResellerPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            AdminResellerRowsPerPage: {
                key: 'adminResellerRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            ReportsListSortField: {
                key: 'reportsListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'generatedAt'
            },
            ReportsListSortDirection: {
                key: 'reportsListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            ReportsListPage: { key: 'reportsListPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            ReportsListRowsPerPage: {
                key: 'reportsListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            FeedbackListSortField: {
                key: 'feedbackListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'id'
            },
            FeedbackListSortDirection: {
                key: 'feedbackListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            FeedbackListPage: { key: 'feedbackListPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            FeedbackListRowsPerPage: {
                key: 'feedbackListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminUserSortField: {
                key: 'adminUserSortField',
                type: PreferenceType.STRING,
                defaultValue: 'FullName'
            },
            AdminUserSortDirection: {
                key: 'adminUserSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminUserRowsPerPage: {
                key: 'adminCompanyRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 20
            },
            BasicNotificationRulesSortField: {
                key: 'notificationRulesSortField',
                type: PreferenceType.STRING,
                defaultValue: 'notificationType'
            },
            BasicNotificationRulesSortDirection: {
                key: 'notificationRulesSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdvanceNotificationRulesSortField: {
                key: 'advanceNotificationRulesSortField',
                type: PreferenceType.STRING,
                defaultValue: 'subscription.scopeType'
            },
            AdvanceNotificationRulesSortDirection: {
                key: 'advanceNotificationRulesSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminUserPage: { key: 'adminUserPage', type: PreferenceType.NUMBER, defaultValue: 1 },
            AdminDeviceListSortField: {
                key: 'adminDeviceListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'SerialName'
            },
            AdminDeviceListSortDirection: {
                key: 'adminDeviceListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminDeviceListPage: {
                key: 'adminDeviceListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeviceListRowsPerPage: {
                key: 'adminDeviceListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminCompanyDevicesListSortField: {
                key: 'adminCompanyDevicesListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'deviceName'
            },
            AdminCompanyDevicesListSortDirection: {
                key: 'adminCompanyDevicesListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminCompanyDevicesListPage: {
                key: 'adminCompanyDevicesListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminCompanyDevicesListRowsPerPage: {
                key: 'adminCompanyDevicesListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 20
            },
            AdminResellerDeviceListSortField: {
                key: 'adminResellerDeviceListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'SerialName'
            },
            AdminResellerDeviceListSortDirection: {
                key: 'adminResellerDeviceListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminResellerDeviceListPage: {
                key: 'adminResellerDeviceListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminResellerDeviceListRowsPerPage: {
                key: 'adminResellerDeviceListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminDeviceCredentialsSortField: {
                key: 'AdminDeviceCredentialsSortField',
                type: PreferenceType.STRING,
                defaultValue: 'publicKey'
            },
            AdminDeviceCredentialsSortDirection: {
                key: 'AdminDeviceCredentialsSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminDeviceCredentialsPage: {
                key: 'AdminDeviceCredentialsPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeviceCredentialsRowsPerPage: {
                key: 'adminDeviceCredentialsRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminDeviceConfigurationListSortField: {
                key: 'AdminDeviceConfigurationListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'configId'
            },
            AdminDeviceConfigurationListSortDirection: {
                key: 'AdminDeviceConfigurationListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminDeviceFirmwareListPage: {
                key: 'adminDeviceFirmwareListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeviceFirmwarePerPage: {
                key: 'adminDeviceFirmwarePerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminDeviceFirmwareListSortField: {
                key: 'AdminDeviceFirmwareListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'CreatedAt'
            },
            AdminDeviceFirmwareListSortDirection: {
                key: 'AdminDeviceFirmwareListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminLoggerListSortField: {
                key: 'AdminLoggerListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'MacAddress'
            },
            AdminLoggerListSortDirection: {
                key: 'AdminLoggerListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            AdminLoggerListRowsPerPage: {
                key: 'adminLoggerRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminLoggerListPage: {
                key: 'AdminLoggerListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            CalibrationExpiresSortField: {
                key: 'calibrationExpiresSortField',
                type: PreferenceType.STRING,
                defaultValue: 'CalibrationExpiresAt'
            },
            CalibrationExpiresSortDirection: {
                key: 'calibrationExpiresSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            CalibrationExpiresRowsPerPage: {
                key: 'calibrationExpiresRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            CalibrationExpiresPage: {
                key: 'calibrationExpiresPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminDeletedLoggerListSortField: {
                key: 'AdminDeletedLoggerListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'SourceName'
            },
            AdminDeletedLoggerListSortDirection: {
                key: 'AdminDeletedLoggerListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminDeletedLoggerListRowsPerPage: {
                key: 'AdminDeletedLoggerListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 5
            },
            AdminNotificationsListSortField: {
                key: 'adminNotificationsListSortField',
                type: PreferenceType.STRING,
                defaultValue: 'CreatedAt'
            },
            AdminNotificationsListSortDirection: {
                key: 'adminNotificationsListSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            AdminNotificationsListPage: {
                key: 'adminNotificationsListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminNotificationsListRowsPerPage: {
                key: 'adminNotificationsListRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            ChartDayOption: {
                key: 'ChartDayOption',
                type: PreferenceType.NUMBER,
                defaultValue: DaysBeforeOptions.ONE
            },
            SettingsCategoryExpanded: {
                key: 'SettingsCategoryExpanded',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            SelectedDepartmentId: {
                key: 'SelectedDepartmentId',
                type: PreferenceType.STRING,
                defaultValue: null
            },
            SelectedDepartmentSettings: {
                key: 'SelectedDepartmentSettings',
                type: PreferenceType.STRING,
                defaultValue: null
            },
            CookiesAccepted: { key: 'CookiesAccepted', type: PreferenceType.STRING, defaultValue: null },
            IsAdminDetailPagesLayoutList: {
                key: 'isAdminDetailPagesLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            IsAdminCompanyDetailLayoutList: {
                key: 'isAdminCompanyDetailLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            FloorPlanUploadLayoutList: {
                key: 'floorPlanUploadLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminDeviceDetailLayoutActiveTab: {
                key: 'adminDetailPagesLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            AdminCompanyDetailLayoutActiveTab: {
                key: 'adminCompanyDetailLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            IssueDetailLayoutActiveTab: {
                key: 'issueDetailLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            IsAdminDetailPagesLayoutActiveTab: {
                key: 'IsAdminDetailPagesLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            IsAdminResellerPageLayoutList: {
                key: 'IsAdminResellerPageLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            IsAdminResellerPageLayoutActiveTab: {
                key: 'IsAdminResellerPageLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            AdminCreateNotificationRule: {
                key: 'AdminCreateNotificationRule',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            AdminLoggerDetailLayoutList: {
                key: 'adminLoggerDetailLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminLoggerDetailLayoutActiveTab: {
                key: 'adminLoggerDetailLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            AdminUserDetailLayoutList: {
                key: 'adminUserDetailLayoutList',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminUserDetailLayoutActiveTab: {
                key: 'adminUserDetailLayoutActiveTab',
                type: PreferenceType.NUMBER,
                defaultValue: 0
            },
            AdminFeedbackListPage: {
                key: 'adminFeedbackListPage',
                type: PreferenceType.NUMBER,
                defaultValue: 1
            },
            AdminFeedbackRowsPerPage: {
                key: 'adminLoggerRowsPerPage',
                type: PreferenceType.NUMBER,
                defaultValue: 10
            },
            AdminFeedbackSortField: {
                key: 'adminFeedbackSortField',
                type: PreferenceType.STRING,
                defaultValue: 'Id'
            },
            AdminFeedbackSortDirection: {
                key: 'adminFeedbackSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'asc'
            },
            DeviceUpdateModalSortDirection: {
                key: 'deviceUpdateModalSortDirection',
                type: PreferenceType.STRING,
                defaultValue: 'desc'
            },
            DeviceUpdateModalSortField: {
                key: 'deviceUpdateModalSortField',
                type: PreferenceType.STRING,
                defaultValue: 'createdAt'
            },
            AdminCompanyListCompanyId: {
                key: 'adminCompanyListCompanyId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListCompanyName: {
                key: 'adminCompanyListCompanyName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListIco: {
                key: 'adminCompanyListIco',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListNote: {
                key: 'adminCompanyListNote',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListTierName: {
                key: 'adminCompanyListTierName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListUserCount: {
                key: 'adminCompanyListUserCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListDeviceCount: {
                key: 'adminCompanyListDeviceCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListLoggerCount: {
                key: 'adminCompanyListLoggerCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListCreatedAt: {
                key: 'adminCompanyListCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyListResponsiblePerson: {
                key: 'adminCompanyListResponsiblePerson',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListApiUserId: {
                key: 'adminUserListApiUserId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListEmail: {
                key: 'adminUserListEmail',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListUsername: {
                key: 'adminUserListUsername',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListFullName: {
                key: 'adminUserListFullName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListPhoneNumber: {
                key: 'adminUserListPhoneNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListEnabled: {
                key: 'adminUserListEnabled',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListVerified: {
                key: 'adminUserListVerified',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListNewsletter: {
                key: 'adminUserListNewsletter',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListRegisteredAt: {
                key: 'adminUserListRegisteredAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminUserListLastActivity: {
                key: 'adminUserListLastActivity',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListSerialName: {
                key: 'adminDeviceListSerialName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListMacAddress: {
                key: 'adminDeviceListMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListDeviceId: {
                key: 'adminDeviceListDeviceId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListCompany: {
                key: 'adminDeviceListCompany',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListInterval: {
                key: 'adminDeviceListInterval',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListVersion: {
                key: 'adminDeviceListVersion',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListLastSeen: {
                key: 'adminDeviceListLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListDescription: {
                key: 'adminDeviceListDescription',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceListIsOffline: {
                key: 'AdminDeviceListIsOffline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceFirmwareListId: {
                key: 'adminDeviceFirmwareListId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceFirmwareListCreatedBy: {
                key: 'adminDeviceFirmwareListCreatedBy',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceFirmwareListVersion: {
                key: 'adminDeviceFirmwareListVersion',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceFirmwareListCreatedAt: {
                key: 'adminDeviceFirmwareListCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListLoggerId: {
                key: 'adminLoggerListLoggerId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListMacAddress: {
                key: 'adminLoggerListMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListSerialNumber: {
                key: 'adminLoggerListSerialNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListCompanyName: {
                key: 'adminLoggerListCompanyName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListLastSeen: {
                key: 'adminLoggerListLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListLastStateChange: {
                key: 'adminLoggerListLastStateChange',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListIsOnline: {
                key: 'adminLoggerListIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListState: {
                key: 'adminLoggerListState',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListBattery: {
                key: 'adminLoggerListBattery',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListCalibrated: {
                key: 'adminLoggerListCalibrated',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerListNote: {
                key: 'adminLoggerListNote',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminLoggersCalibrationListLoggerId: {
                key: 'adminLoggersCalibrationListLoggerId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListMacAddress: {
                key: 'adminLoggersCalibrationListMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListSerialNumber: {
                key: 'adminLoggersCalibrationListSerialNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListCompanyName: {
                key: 'adminLoggersCalibrationListCompanyName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListLastSeen: {
                key: 'adminLoggersCalibrationListLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListLastStateChange: {
                key: 'adminLoggersCalibrationListLastStateChange',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListIsOnline: {
                key: 'adminLoggersCalibrationListIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListState: {
                key: 'adminLoggersCalibrationListState',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListBattery: {
                key: 'adminLoggersCalibrationListBattery',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListCalibrated: {
                key: 'adminLoggersCalibrationListCalibrated',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggersCalibrationListCalibrationExpiresAt: {
                key: 'adminLoggersCalibrationListCalibrationExpiresAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListId: {
                key: 'adminResellerListId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListName: {
                key: 'adminResellerListName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListUserCount: {
                key: 'adminResellerListUserCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListDepartmentCount: {
                key: 'adminResellerListDepartmentCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListGatewayCount: {
                key: 'adminResellerListGatewayCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminResellerListLoggerCount: {
                key: 'adminResellerListLoggerCount',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            NotificationsTableCreatedAt: {
                key: 'notificationsTableCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            NotificationsTableCategory: {
                key: 'notificationsTableCategory',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            NotificationsTableTitle: {
                key: 'notificationsTableTitle',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            NotificationsTablePriority: {
                key: 'notificationsTablePriority',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListId: {
                key: 'feedbackListId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListSubject: {
                key: 'feedbackListSubject',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListCategory: {
                key: 'feedbackListCategory',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListCreatedAt: {
                key: 'feedbackListCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListStatus: {
                key: 'feedbackListStatus',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            FeedbackListSentById: {
                key: 'feedbackListSentById',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListName: {
                key: 'dashboardsListName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListCreatedBy: {
                key: 'dashboardsListCreatedBy',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListSourceList: {
                key: 'dashboardsListSourceList',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListGatewayList: {
                key: 'dashboardsListGatewayList',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListSourceGroupList: {
                key: 'dashboardsListSourceGroupList',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DashboardsListIsPublic: {
                key: 'dashboardsListIsPublic',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCredentialsPublicKey: {
                key: 'adminDeviceCredentialsPublicKey',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCredentialsKeyId: {
                key: 'adminDeviceCredentialsKeyId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCredentialsKeyType: {
                key: 'adminDeviceCredentialsKeyType',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCredentialsCreatedAt: {
                key: 'adminDeviceCredentialsCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCredentialsRevokedAt: {
                key: 'adminDeviceCredentialsRevokedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentEmail: {
                key: 'adminCompanyUsersComponentEmail',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentUsername: {
                key: 'adminCompanyUsersComponentUsername',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentFullName: {
                key: 'adminCompanyUsersComponentFullName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentRoleKey: {
                key: 'adminCompanyUsersComponentRoleKey',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentEnabled: {
                key: 'adminCompanyUsersComponentEnabled',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyUsersComponentAssignedAt: {
                key: 'adminCompanyUsersComponentAssignedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesDeviceName: {
                key: 'adminCompanyDevicesDeviceName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesDeviceId: {
                key: 'adminCompanyDevicesDeviceId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesMacAddress: {
                key: 'adminCompanyDevicesMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesInterval: {
                key: 'adminCompanyDevicesInterval',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesDescription: {
                key: 'adminCompanyDevicesDescription',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyDevicesIsOnline: {
                key: 'adminCompanyDevicesIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersLoggerId: {
                key: 'adminCompanyLoggersLoggerId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersMacAddress: {
                key: 'adminCompanyLoggersMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersSerialNumber: {
                key: 'adminCompanyLoggersSerialNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersSourceName: {
                key: 'adminCompanyLoggersSourceName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersLastSeen: {
                key: 'adminCompanyLoggersLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersExpiration: {
                key: 'adminCompanyLoggersExpiration',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersCalibration: {
                key: 'adminCompanyLoggersCalibration',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersIsOnline: {
                key: 'adminCompanyLoggersIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersActive: {
                key: 'adminCompanyLoggersActive',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminCompanyLoggersBattery: {
                key: 'adminCompanyLoggersBattery',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsId: {
                key: 'adminDeviceCommandsId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsType: {
                key: 'adminDeviceCommandsType',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsProgress: {
                key: 'adminDeviceCommandsProgress',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminDeviceCommandsAllowMultiple: {
                key: 'adminDeviceCommandsAllowMultiple',
                type: PreferenceType.BOOLEAN,
                defaultValue: false
            },
            AdminDeviceCommandsCreatedAt: {
                key: 'adminDeviceCommandsCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsScheduleAt: {
                key: 'adminDeviceCommandsScheduleAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsConfirmedAt: {
                key: 'adminDeviceCommandsConfirmedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsDisabledAt: {
                key: 'adminDeviceCommandsDisabledAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceCommandsUpdatedAt: {
                key: 'adminDeviceCommandsUpdatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesId: {
                key: 'adminDeviceUpdatesId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesFirmwareId: {
                key: 'adminDeviceUpdatesFirmwareId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesFwVersion: {
                key: 'adminDeviceUpdatesFwVersion',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesDescription: {
                key: 'adminDeviceUpdatesDescription',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesScheduleAt: {
                key: 'adminDeviceUpdatesScheduleAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesConfirmedAt: {
                key: 'adminDeviceUpdatesConfirmedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesUpdatedAt: {
                key: 'adminDeviceUpdatesUpdatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceUpdatesDisabledAt: {
                key: 'adminDeviceUpdatesDisabledAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceConfigHistoryConfigId: {
                key: 'adminDeviceConfigHistoryConfigId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceConfigHistoryCreatedAt: {
                key: 'adminDeviceConfigHistoryCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceConfigHistoryConfiguredAt: {
                key: 'adminDeviceConfigHistoryConfiguredAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceConfigHistoryInterval: {
                key: 'adminDeviceConfigHistoryInterval',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceConfigHistoryCurrent: {
                key: 'adminDeviceConfigHistoryCurrent',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceInstanceHistoryId: {
                key: 'adminDeviceInstanceHistoryId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceInstanceHistoryCreatedAt: {
                key: 'adminDeviceInstanceHistoryCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceInstanceHistoryReturnedAt: {
                key: 'adminDeviceInstanceHistoryReturnedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceInstanceHistoryCompany: {
                key: 'adminDeviceInstanceHistoryCompany',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminDeviceInstanceHistoryState: {
                key: 'adminDeviceInstanceHistoryState',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistoryCompanyLoggerId: {
                key: 'adminLoggerHistoryCompanyLoggerId',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistorySerialNumber: {
                key: 'adminLoggerHistorySerialNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistoryLastSeen: {
                key: 'adminLoggerHistoryLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistoryCompany: {
                key: 'adminLoggerHistoryCompany',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistoryActive: {
                key: 'adminLoggerHistoryActive',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            AdminLoggerHistoryCalibration: {
                key: 'adminLoggerHistoryCalibration',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListFrom: {
                key: 'reportsListFrom',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListTo: {
                key: 'reportsListTo',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListFileName: {
                key: 'reportsListFileName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListReportType: {
                key: 'reportsListReportType',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListState: {
                key: 'reportsListState',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListGeneratedAt: {
                key: 'reportsListGeneratedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportsListGeneratedBy: {
                key: 'reportsListGeneratedBy',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceListField: {
                key: 'deviceListField',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceListDescription: {
                key: 'deviceListDescription',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceListRegisteredAt: {
                key: 'deviceListRegisteredAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceListLastSeen: {
                key: 'deviceListLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            DeviceListIsOnline: {
                key: 'deviceListIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListName: {
                key: 'loggerConfigurationListName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListSerialNumber: {
                key: 'loggerConfigurationListSerialNumber',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListDescription: {
                key: 'loggerConfigurationListDescription',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListLastSeen: {
                key: 'loggerConfigurationListLastSeen',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListBattery: {
                key: 'loggerConfigurationListBattery',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListIsOnline: {
                key: 'loggerConfigurationListIsOnline',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListCalibration: {
                key: 'loggerConfigurationListCalibration',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListMacAddress: {
                key: 'loggerConfigurationListMacAddress',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            LoggerConfigurationListVisible: {
                key: 'loggerConfigurationListVisible',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            UserAdministrationUsername: {
                key: 'userAdministrationUsername',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            UserAdministrationRole: {
                key: 'userAdministrationRole',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            UserAdministrationEmail: {
                key: 'userAdministrationEmail',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            UserAdministrationFullname: {
                key: 'userAdministrationFullname',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            UserAdministrationEnabled: {
                key: 'userAdministrationEnabled',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            SentInvitationsManagementInvitedEmail: {
                key: 'sentInvitationsManagementInvitedEmail',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            SentInvitationsManagementState: {
                key: 'sentInvitationsManagementState',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            SentInvitationsManagementRole: {
                key: 'sentInvitationsManagementRole',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            SentInvitationsManagementCreatedAt: {
                key: 'sentInvitationsManagementCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportSettingsPeriodicReportPdfType: {
                key: 'reportSettingsPeriodicReportPdfType',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportSettingsInterval: {
                key: 'reportSettingsInterval',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportSettingsMeasuredAt: {
                key: 'reportSettingsMeasuredAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            ReportSettingsEventTypes: {
                key: 'reportSettingsEventTypes',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssuesTableType: {
                key: 'issuesTableType',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssuesTableName: {
                key: 'issuesTableName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssuesTableCreatedAt: {
                key: 'issuesTableCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssuesTableResolvedAt: {
                key: 'issuesTableResolvedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssuesTableAcknowledgedBy: {
                key: 'issuesTableAcknowledgedBy',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssueNotificationsFullName: {
                key: 'issueNotificationsFullName',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssueNotificationsEmail: {
                key: 'issueNotificationsEmail',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssueNotificationsCreatedAt: {
                key: 'issueNotificationsCreatedAt',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            IssueNotificationsChannel: {
                key: 'issueNotificationsChannel',
                type: PreferenceType.BOOLEAN,
                defaultValue: true
            },
            Theme: {
                key: 'theme',
                type: PreferenceType.STRING,
                defaultValue: 'light'
            }
        };
    }
    getAll() {
        return localStorage;
    }
    getPreference(localStorageKey) {
        let value = localStorage.getItem(localStorageKey.key);
        if (value == null || value == undefined || value == 'null')
            return localStorageKey.defaultValue;
        if (localStorageKey.type == PreferenceType.STRING)
            return value;
        else if (localStorageKey.type == PreferenceType.BOOLEAN)
            return value == 'true';
        else if (localStorageKey.type == PreferenceType.NUMBER)
            return parseInt(value);
    }
    setPreference(localStorageKey, value) {
        let valueToStore = value == null ? null : value.toString();
        localStorage.setItem(localStorageKey.key, valueToStore);
    }
    deletePreference(localStorageKey) {
        localStorage.removeItem(localStorageKey.key);
    }
    clearLocalStorage() {
        localStorage.clear();
    }
}
export default new UserPreferences();
