var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "nav",
      { staticClass: "breadcrumb", attrs: { "aria-label": "breadcrumb" } },
      [
        _c("h1", { staticClass: "title has-text-centered-touch" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-chevron-left is-size-5",
                    staticStyle: { "padding-right": "10px" },
                  }),
                ]
              ),
              _c("h1", [
                _vm._v(
                  "Pravidlá ochrany osobných údajov a používania súborov cookies"
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "pl-3" }, [
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [
        _vm._v("Identifikácia prevádzkovateľa informačného systému."),
      ]),
      _c("p", [
        _c("strong", [_vm._v("R-DAS, s. r. o.,")]),
        _vm._v(
          " IČO: 44984251, so sídlom Rybárska 408/28, 962 31 Sliač, zapísaná v OR OS Banská Bystrica, Oddiel Sro, Vložka č.: 37125/S "
        ),
      ]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [
        _vm._v("Spracovávané osobné údaje a účely ich spracovania"),
      ]),
      _c("ul", { staticClass: "disc-style pl-3" }, [
        _c("li", [
          _vm._v(
            " na právnom základe plnenia zmluvy (čl. 6 ods. 1 písm. b) Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27.apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe týchto údajov) spracúva prevádzkovateľ osobné údaje na účely plnenia kúpnej zmluvy a dodania objednaného tovaru, a to konkrétne: "
          ),
          _c("ul", { staticClass: "circle-style pl-3" }, [
            _c("li", [_vm._v("Titul, Meno, Priezvisko,")]),
            _c("li", [_vm._v("Trvalý pobyt,")]),
            _c("li", [_vm._v("Adresa doručenia,")]),
            _c("li", [_vm._v("Telefónne číslo,")]),
            _c("li", [_vm._v("E-mailový kontakt.")]),
          ]),
          _vm._v(
            " Tieto osobné údaje prevádzkovateľ uchováva po dobu 10 rokov od Vášho nákupu. "
          ),
        ]),
        _c("br"),
        _c("li", [
          _vm._v(
            " na základe oprávneného záujmu prevádzkovateľa podľa čl. 6 ods. 1 písm. f) Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27.apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe týchto údajov, z dôvodu zabezpečenia funkcie e-shopu a individuálne personalizovaných obchodných ponúk spracúva prevádzkovateľ nasledovné údaje: "
          ),
          _c("ul", { staticClass: "circle-style pl-3" }, [
            _c("li", [_vm._v("Cookies")]),
          ]),
          _vm._v(
            " Tieto osobné údaje prevádzkovateľ uchováva po dobu najviac 2 roky od použitia webstránky. "
          ),
        ]),
      ]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [_vm._v("Príjemcovia")]),
      _c("p", [
        _vm._v(
          "Vaše osobné údaje budeme sprístupňovať iba zmluvným dopravcom za účelom doručenia Vami objednaného tovaru."
        ),
      ]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [
        _vm._v("Práva dotknutej osoby"),
      ]),
      _c("p", [_vm._v("Zákazník e-shopu ako dotknutá osoba má právo:")]),
      _c("ul", { staticClass: "disc-style pl-3" }, [
        _c("li", [_vm._v("požadovať prístup k svojim osobným údajom")]),
        _c("li", [_vm._v("právo na opravu osobných údajov")]),
        _c("li", [_vm._v("právo na vymazanie osobných údajov")]),
        _c("li", [_vm._v("právo na obmedzenie spracúvania osobných údajov")]),
        _c("li", [_vm._v("právo namietať spracúvanie osobných údajov")]),
        _c("li", [_vm._v("právo na prenosnosť osobných údajov")]),
        _c("li", [_vm._v("právo podať sťažnosť dozornému orgánu")]),
      ]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [
        _vm._v("Zodpovedná osoba"),
      ]),
      _c("p", [_vm._v("E-mailový kontakt na zodpovednú osobu: dpo@r-das.sk")]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [
        _vm._v("Dozorný orgán"),
      ]),
      _c("p", [
        _vm._v(
          " Dozorný orgánom, na ktorý sa dotknutá osoba môže obrátiť so sťažnosťou je: "
        ),
        _c("br"),
        _c("strong", [
          _vm._v(
            " Úrad na ochranu osobných údajov, Hraničná 12, 820 07 Bratislava 27"
          ),
        ]),
      ]),
      _c("h2", { staticClass: "subtitle pt-5 mb-2" }, [_vm._v("Cookies")]),
      _c("p", [
        _vm._v(
          " Táto webstránka používa súbory cookies na zaistenie správnej funkcionality nákupného košíka a platobného procesu a na anonymnú analýzu návštevnosti. Niektoré informácie o využití našej webstránky poskytujeme aj našim partnerom ako napríklad Google Analytics, ktorí môžu príslušné informácie skombinovať s ďalšími údajmi, ktoré ste im poskytli alebo, ktoré od vás získali, keď ste používali ich služby. "
        ),
      ]),
      _c("br"),
      _c("h3", { staticClass: "has-text-weight-bold" }, [
        _vm._v("O súboroch cookies"),
      ]),
      _c("p", [
        _vm._v(
          " Súbory Cookies sú malé textové súbory, ktoré sa používaju na zjednodušenie používania webstránok a na zabezpečenie spokojnosti návštevníkov webstránok. "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " Súbory cookies môžu byť uložené na vašom zariadení iba ak sú potrebné pre správny beh webstránok. Na všetky ostatné typy súborov cookies potrebujeme od vás súhlas. Používaním týchto stránok nám dávate súhlas s využitím súborov cookies. "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " Cookies môžete kontrolovať a/alebo zmazať podľa Vášho uváženia viacerými spôsobmi. Môžete vymazať niektoré z nich alebo aj všetky cookies uložené vo Vašom zariadení a väčšinu prehliadačov môžete nastaviť tak, aby ste znemožnili ukladanie súborov cookies. V prípade zablokovania použitia súborov cookies nevyhnutných na beh našich stránok si vás dovoľujeme upozorniť, že to môže narušiť ich funkčnosť, prípadne úplne zablokovať možnosť nákupu. Vymazaním súborov cookies môžete prísť o niektoré vaše nastavenia, ktoré boli v nich uložené. Viac informácií o súboroch cookies, ako ich akceptovať, odmietnuť, selektovať, prípadne zmazať nájdete na "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.allaboutcookies.org",
              target: "_blank",
            },
          },
          [_vm._v("www.allaboutcookies.org")]
        ),
        _vm._v(" . "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }