var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      [
        _c("div", { staticClass: "level section-header-level" }, [
          _c("div", { staticClass: "level-left" }, [
            _vm.isLayoutList
              ? _c("h2", { staticClass: "level-item subtitle is-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.reseller.detail.resellerDevices.title"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "level-right" }, [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "level-item is-hidden-mobile",
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "admin.component.company.list.actions.columns"
                                      ),
                                      position: "is-left",
                                      type: "is-dark",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      { staticStyle: { "font-size": "1rem" } },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            type: "is-primary",
                                            icon: "list",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.tableColumns, function (column) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: column.field,
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              focusable: false,
                              custom: "",
                              "aria-role": "listitem",
                            },
                          },
                          [
                            _c(
                              "b-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    column.field ==
                                    _vm.tableColumns.serialName.field,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.chooseTableColumns(
                                      column.field,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: column.visible,
                                  callback: function ($$v) {
                                    _vm.$set(column, "visible", $$v)
                                  },
                                  expression: "column.visible",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `admin.component.devices.deviceList.table.${column.field}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "b-table",
          {
            ref: "table",
            staticClass: "mobile-filter res-table",
            attrs: {
              data: _vm.devices,
              paginated: "",
              "backend-pagination": "",
              "current-page": _vm.page,
              "per-page": _vm.pageSize,
              total: _vm.totalSize,
              "backend-sorting": "",
              "default-sort-direction": _vm.sortDirection,
              "default-sort": _vm.sortField,
              "backend-filtering": "",
              striped: "",
              "page-input": true,
              "pagination-order": "is-centered",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
              "page-change": _vm.onPageChange,
              sort: _vm.sorted,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "bottom-left",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "component.notifications_list.table.row_per_page"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-field",
                        { staticClass: "pl-2 mb-4-mobile" },
                        [
                          _c(
                            "b-select",
                            {
                              on: { input: _vm.rowsPerPageSelected },
                              model: {
                                value: _vm.pageSize,
                                callback: function ($$v) {
                                  _vm.pageSize = $$v
                                },
                                expression: "pageSize",
                              },
                            },
                            _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                              return _c(
                                "option",
                                { key: option, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.devices.length > 0
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-justify-content-flex-end",
                            },
                            [
                              _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.company.list.table.results",
                                        {
                                          from: _vm.fromTableData,
                                          to: _vm.toTableData,
                                          total: _vm.totalSize,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          [
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.serialName.field,
                visible: _vm.tableColumns.serialName.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.serialName.field}`
                ),
                sortable: "",
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isNameSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  expanded: "",
                                },
                                on: { input: _vm.handleFilterNameDebounce },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.filterDevices(
                                      _vm.tableColumns.serialName.field,
                                      _vm.nameSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.nameSearch,
                                  callback: function ($$v) {
                                    _vm.nameSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "nameSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterNameDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.serialName.field,
                                  _vm.nameSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.nameSearch,
                              callback: function ($$v) {
                                _vm.nameSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "nameSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminDeviceDetail",
                              params: {
                                deviceId: deviceList.row.deviceId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(deviceList.row.deviceName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              staticClass: "wrap-if-long",
              attrs: {
                field: _vm.tableColumns.deviceId.field,
                visible: _vm.tableColumns.deviceId.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.deviceId.field}`
                ),
                sortable: "",
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isIdSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "min:36",
                                        expression: "'min:36'",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: { input: _vm.handleFilterIdDebounce },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.filterDevices(
                                          _vm.tableColumns.deviceId.field,
                                          _vm.idSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.idSearch,
                                      callback: function ($$v) {
                                        _vm.idSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "idSearch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile is-full-width",
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                expanded: "",
                                type: {
                                  "is-danger": _vm.errors.has(
                                    _vm.$t(
                                      "admin.component.devices.deviceList.table.Id"
                                    )
                                  ),
                                },
                                message: _vm.errors.first(
                                  _vm.$t(
                                    "admin.component.devices.deviceList.table.Id"
                                  )
                                ),
                              },
                            },
                            [
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "min:36",
                                    expression: "'min:36'",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  name: _vm.$t(
                                    "admin.component.devices.deviceList.table.Id"
                                  ),
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: { input: _vm.handleFilterIdDebounce },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.filterDevices(
                                      _vm.tableColumns.deviceId.field,
                                      _vm.idSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.idSearch,
                                  callback: function ($$v) {
                                    _vm.idSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "idSearch",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "is-family-monospace",
                          attrs: {
                            to: {
                              name: "adminDeviceDetail",
                              params: {
                                deviceId: deviceList.row.deviceId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                          nativeOn: {
                            contextmenu: function ($event) {
                              return _vm.copyLinkToClipboard(
                                deviceList.row.deviceId
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(deviceList.row.deviceId))]
                      ),
                      _vm.hasCopyIconAllowed
                        ? _c("b-icon", {
                            staticClass: "clickable is-size-7 is-hidden-touch",
                            attrs: { icon: "clipboard" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.copyLinkToClipboard(
                                  deviceList.row.deviceId
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.macAddress.field,
                visible: _vm.tableColumns.macAddress.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.macAddress.field}`
                ),
                sortable: "",
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isMacAddressSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("b-input", {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  expanded: "",
                                },
                                on: { input: _vm.handleFilterMacDebounce },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.filterDevices(
                                      _vm.tableColumns.macAddress.field,
                                      _vm.macSearch
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.macSearch,
                                  callback: function ($$v) {
                                    _vm.macSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "macSearch",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "level-item",
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        { staticClass: "is-hidden-mobile" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: _vm.$t("generals.search"),
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterMacDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.macAddress.field,
                                  _vm.macSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.macSearch,
                              callback: function ($$v) {
                                _vm.macSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "macSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "adminDeviceDetail",
                              params: {
                                deviceId: deviceList.row.deviceId,
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(deviceList.row.deviceMacAddress))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.company.field,
                visible: _vm.tableColumns.company.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.company.field}`
                ),
                searchable: "",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isCompanyNameSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input:
                                        _vm.handleFilterCompanyNameDebounce,
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.filterDevices(
                                          _vm.tableColumns.company.field,
                                          _vm.companyNameSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.companyNameSearch,
                                      callback: function ($$v) {
                                        _vm.companyNameSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "companyNameSearch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterCompanyNameDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.company.field,
                                  _vm.companyNameSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.companyNameSearch,
                              callback: function ($$v) {
                                _vm.companyNameSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "companyNameSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      deviceList.row.company &&
                      deviceList.row.instanceState !==
                        _vm.DeviceInstanceState.RETURNED &&
                      deviceList.row.company.isDeleted == true
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(deviceList.row.company.companyName) +
                                  " "
                              ),
                              _c(
                                "b-tag",
                                { attrs: { type: "is-primary", rounded: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "admin.component.devices.deviceList.table.deleted"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      deviceList.row.company &&
                      deviceList.row.instanceState !==
                        _vm.DeviceInstanceState.RETURNED
                        ? [
                            _vm.hasPermissionToViewDepartment(
                              deviceList.row.company.companyId
                            )
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "adminCompanyDetail",
                                        params: {
                                          companyId:
                                            deviceList.row.company.companyId,
                                          title: _vm.$t(
                                            "admin.component.devices.deviceList.title"
                                          ),
                                          lang: _vm.$route.params.lang,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(deviceList.row.company.companyName)
                                    ),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(deviceList.row.company.companyName)
                                  ),
                                ]),
                          ]
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.currentInterval.field,
                visible: _vm.tableColumns.currentInterval.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.currentInterval.field}`
                ),
                numeric: "",
                searchable: "",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isConfigIntervalSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: _vm.handleFilterIntervalDebounce,
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.filterDevices(
                                          _vm.tableColumns.currentInterval
                                            .field,
                                          _vm.configIntervalSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.configIntervalSearch,
                                      callback: function ($$v) {
                                        _vm.configIntervalSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "configIntervalSearch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterIntervalDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.currentInterval.field,
                                  _vm.configIntervalSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.configIntervalSearch,
                              callback: function ($$v) {
                                _vm.configIntervalSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "configIntervalSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      deviceList.row.currentConfig
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(deviceList.row.currentConfig.interval) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.fwVersion.field,
                visible: _vm.tableColumns.fwVersion.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.fwVersion.field}`
                ),
                searchable: "",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isFirmwareVersionSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input:
                                        _vm.handleFilterFirmwareVersionDebounce,
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.filterDevices(
                                          _vm.tableColumns.fwVersion.field,
                                          _vm.firmwareVersionSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.firmwareVersionSearch,
                                      callback: function ($$v) {
                                        _vm.firmwareVersionSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "firmwareVersionSearch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: {
                              input: _vm.handleFilterFirmwareVersionDebounce,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.fwVersion.field,
                                  _vm.firmwareVersionSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.firmwareVersionSearch,
                              callback: function ($$v) {
                                _vm.firmwareVersionSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "firmwareVersionSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      deviceList.row.firmware && deviceList.row.firmware.current
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  deviceList.row.firmware.current.version
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", [_vm._v("-")]),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.lastSeen.field,
                visible: _vm.tableColumns.lastSeen.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.lastSeen.field}`
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      _vm.dateTimeManager
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dateTimeManager.formatTime(
                                    new Date(deviceList.row.lastSeen * 1000)
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.description.field,
                visible: _vm.tableColumns.description.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.description.field}`
                ),
                searchable: "",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isDescriptionSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input:
                                        _vm.handleFilterDescriptionDebounce,
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.filterDevices(
                                          _vm.tableColumns.description.field,
                                          _vm.descriptionSearch
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.descriptionSearch,
                                      callback: function ($$v) {
                                        _vm.descriptionSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "descriptionSearch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("generals.search"),
                              "icon-pack": "fas",
                              icon: "search",
                              expanded: "",
                            },
                            on: { input: _vm.handleFilterDescriptionDebounce },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.filterDevices(
                                  _vm.tableColumns.description.field,
                                  _vm.descriptionSearch
                                )
                              },
                            },
                            model: {
                              value: _vm.descriptionSearch,
                              callback: function ($$v) {
                                _vm.descriptionSearch =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "descriptionSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      _vm.dateTimeManager
                        ? _c("p", { staticClass: "description" }, [
                            _vm._v(
                              " " + _vm._s(deviceList.row.description) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: _vm.tableColumns.isOffline.field,
                visible: _vm.tableColumns.isOffline.visible,
                label: _vm.$t(
                  `admin.component.devices.deviceList.table.${_vm.tableColumns.isOffline.field}`
                ),
                sortable: "",
                searchable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "searchable",
                  fn: function () {
                    return [
                      _vm.isOfflineSearch
                        ? _c(
                            "b-field",
                            {
                              staticClass:
                                "mt-1-mobile is-hidden-tablet is-full-width",
                              attrs: { grouped: "" },
                            },
                            [
                              _c(
                                "b-field",
                                [
                                  _c(
                                    "b-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      on: { input: _vm.changeSearchField },
                                      model: {
                                        value: _vm.selectedValue,
                                        callback: function ($$v) {
                                          _vm.selectedValue = $$v
                                        },
                                        expression: "selectedValue",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.tableColumns,
                                        function (option) {
                                          return [
                                            option.visible && option.hasSearch
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: option.field,
                                                    domProps: {
                                                      value: option.field,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `admin.component.devices.deviceList.table.${option.field}`
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-field",
                                { attrs: { expanded: "" } },
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t("generals.search"),
                                        "icon-pack": "fas",
                                        icon: "search",
                                        expanded: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.filterDevices(
                                            _vm.tableColumns.isOffline.field,
                                            _vm.offlineSearch.toString()
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.offlineSearch,
                                        callback: function ($$v) {
                                          _vm.offlineSearch = $$v
                                        },
                                        expression: "offlineSearch",
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { domProps: { value: false } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.last_events.table.has_signal"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { domProps: { value: true } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.last_events.table.no_signal"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("b-field", [
                                _c(
                                  "div",
                                  { staticClass: "buttons is-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "times-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: { grouped: "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.filterDevices(
                                        _vm.tableColumns.isOffline.field,
                                        _vm.offlineSearch.toString()
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.offlineSearch,
                                    callback: function ($$v) {
                                      _vm.offlineSearch = $$v
                                    },
                                    expression: "offlineSearch",
                                  },
                                },
                                [
                                  _c("option", { domProps: { value: false } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.last_events.table.has_signal"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("option", { domProps: { value: true } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.last_events.table.no_signal"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (deviceList) {
                    return [
                      deviceList.row.additionalProperties.isOnline == "false"
                        ? _c(
                            "b-tag",
                            { attrs: { type: "is-primary", rounded: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.device.list.table.disconnected"
                                  )
                                )
                              ),
                            ]
                          )
                        : deviceList.row.additionalProperties.isOnline == "true"
                        ? _c(
                            "b-tag",
                            { attrs: { type: "is-success", rounded: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "component.device.list.table.connected"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "action",
                label: _vm.$t(
                  "admin.component.reseller.detail.resellerDevices.list.action"
                ),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (devices) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "is-flex is-flex-direction-row is-align-items-center",
                        },
                        [
                          _c("b-icon", {
                            staticClass: "control-icon clickable",
                            attrs: { icon: "trash" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.actionSelect(devices.row)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "admin.component.devices.deviceList.table.empty"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }