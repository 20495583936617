var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { "border-radius": "7px" } },
    [
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c("p", { staticClass: "has-text-black" }, [
            _vm._v(" " + _vm._s(_vm.$t("cookies.message")) + " "),
            _c(
              "a",
              {
                staticClass: "is-italic has-text-primary",
                on: {
                  click: function ($event) {
                    return _vm.openPolicy()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("cookies.link")))]
            ),
          ]),
          _c("hr"),
          _c("p", { staticClass: "has-text-black" }, [
            _vm._v(_vm._s(_vm.$t("cookies.custom_modal.title"))),
          ]),
          _c(
            "div",
            { staticClass: "pt-5" },
            [
              _c(
                "div",
                { staticClass: "is-flex is-justify-content-space-between" },
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("cookies.custom_modal.essential.name"))
                    ),
                  ]),
                  _c(
                    "b-field",
                    [
                      _c("b-switch", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.essentialCookiesSwitch,
                          callback: function ($$v) {
                            _vm.essentialCookiesSwitch = $$v
                          },
                          expression: "essentialCookiesSwitch",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "has-text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("cookies.custom_modal.essential.desc")) +
                    " "
                ),
              ]),
              _c(
                "b-collapse",
                {
                  attrs: { open: false, "aria-id": "contentEssential" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "has-text-primary",
                              attrs: {
                                "aria-expanded": props.open,
                                "aria-controls": "contentEssential",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("cookies.custom_modal.more")) +
                                  " "
                              ),
                              props.open
                                ? _c("b-icon", {
                                    attrs: {
                                      size: "is-small",
                                      icon: "chevron-down",
                                    },
                                  })
                                : _c("b-icon", {
                                    attrs: {
                                      icon: "chevron-right",
                                      size: "is-small",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            data: _vm.essentialCookiesList,
                            striped: "",
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.name"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.name) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.provider"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.provider) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.description"
                              ),
                              width: "40%",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(cookies.row.description) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.lifetime"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.lifetime) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.type"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.type) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-5" },
            [
              _c(
                "div",
                { staticClass: "is-flex is-justify-content-space-between" },
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("cookies.custom_modal.analytics.name"))
                    ),
                  ]),
                  _c(
                    "b-field",
                    [
                      _c("b-switch", {
                        model: {
                          value: _vm.analyticsCookiesSwitch,
                          callback: function ($$v) {
                            _vm.analyticsCookiesSwitch = $$v
                          },
                          expression: "analyticsCookiesSwitch",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "has-text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("cookies.custom_modal.analytics.desc")) +
                    " "
                ),
              ]),
              _c(
                "b-collapse",
                {
                  attrs: { open: false, "aria-id": "contentEssential" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "has-text-primary",
                              attrs: {
                                "aria-expanded": props.open,
                                "aria-controls": "contentEssential",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("cookies.custom_modal.more")) +
                                  " "
                              ),
                              props.open
                                ? _c("b-icon", {
                                    attrs: {
                                      size: "is-small",
                                      icon: "chevron-down",
                                    },
                                  })
                                : _c("b-icon", {
                                    attrs: {
                                      icon: "chevron-right",
                                      size: "is-small",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            data: _vm.analyticsCookiesList,
                            striped: "",
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.name"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.name) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.provider"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.provider) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.description"
                              ),
                              width: "40%",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(cookies.row.description) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.lifetime"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.lifetime) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("b-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cookies.custom_modal.columns.type"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (cookies) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(cookies.row.type) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-5 is-primary",
              attrs: { expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cookies.done")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }