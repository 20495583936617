import User from '../User';
import { Source } from './Source';
export class PeriodicReport {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => PeriodicReport.define(et));
        }
        else if (data != null) {
            return PeriodicReport.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let report = new PeriodicReport();
        report.id = data.Id;
        report.periodicReportPdfType = data.PeriodicReportPdfType;
        report.interval = data.Interval;
        report.offset = data.Offset;
        report.timezone = data.Timezone;
        if (data.Sources)
            report.sources = Source.fromApi(data.Sources);
        report.eventTypes = data.EventTypes;
        report.statModels = data.StatModels;
        report.toggles = data.Toggles;
        if (data.Author)
            report.author = User.fromApi(data.Author);
        if (data.CreatedBy)
            report.createdBy = User.fromApi(data.CreatedBy);
        report.createdAt = data.CreatedAt;
        if (data.UpdatedBy)
            report.updatedBy = User.fromApi(data.UpdatedBy);
        report.updatedAt = data.UpdatedAt;
        return report;
    }
}
