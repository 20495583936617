var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(_vm.$t("component.verify_phone.title"))),
        ]),
      ]),
      _c("section", { staticClass: "modal-card-body has-text-centered" }, [
        !_vm.verificationSent
          ? _c(
              "div",
              [
                _c("p", { staticClass: "has-text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("component.verify_phone.phone_number_format")
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "b-field",
                  {
                    staticClass: "is-half-width h-center-div",
                    attrs: {
                      type: {
                        "is-danger":
                          _vm.errors.has(
                            _vm.$t("component.verify_phone.phone_label")
                          ) || _vm.invalidNumber,
                      },
                      message:
                        _vm.errors.first(
                          _vm.$t("component.verify_phone.phone_label")
                        ) || _vm.phoneNumberAdditionalError,
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|phoneE164",
                          expression: "'required|phoneE164'",
                        },
                      ],
                      attrs: {
                        name: _vm.$t("component.verify_phone.phone_label"),
                        type: "text",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.numberChanged()
                        },
                      },
                      model: {
                        value: _vm.editedPhoneNumber,
                        callback: function ($$v) {
                          _vm.editedPhoneNumber = $$v
                        },
                        expression: "editedPhoneNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", { staticClass: "is-italic" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("component.verify_phone.note")) + " "
                  ),
                ]),
              ],
              1
            )
          : !_vm.isFinished
          ? _c(
              "div",
              [
                _c("p", { staticClass: "is-italic pb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.verify_phone.msg.verify_sent")) +
                      " "
                  ),
                ]),
                _c(
                  "b-field",
                  {
                    attrs: {
                      position: "is-centered",
                      label: _vm.$t("component.verify_phone.code_label"),
                      type: {
                        "is-danger": _vm.errors.has(
                          _vm.$t("component.verify_phone.code_label")
                        ),
                      },
                      message: _vm.errors.first(
                        _vm.$t("component.verify_phone.code_label")
                      ),
                    },
                  },
                  [
                    _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        "custom-class": "is-half-width h-center-div",
                        name: _vm.$t("component.verify_phone.code_label"),
                        type: "text",
                      },
                      model: {
                        value: _vm.verificationCode,
                        callback: function ($$v) {
                          _vm.verificationCode = $$v
                        },
                        expression: "verificationCode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", [
              _c(
                "p",
                { staticClass: "has-text-centered" },
                [
                  _c("b-icon", {
                    attrs: {
                      pack: "fas",
                      icon: "check-circle",
                      size: "is-large",
                      type: "is-success",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "p",
                { staticClass: "has-text-centered has-text-weight-medium" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.verify_phone.msg.success")) +
                      " "
                  ),
                ]
              ),
            ]),
      ]),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.phoneNumber === _vm.editedPhoneNumber,
                type: "is-primary",
              },
              on: {
                click: function ($event) {
                  return _vm.update()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("component.verify_phone.update_btn")))]
          ),
          !_vm.verificationSent
            ? _c(
                "b-button",
                {
                  attrs: {
                    disabled:
                      _vm.phoneNumber === _vm.editedPhoneNumber &&
                      _vm.isVerified,
                    type: "is-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.verify()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("component.verify_phone.verify_btn")))]
              )
            : !_vm.isFinished
            ? _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.verificationCode.length <= 0,
                    type: "is-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkVerification()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("component.verify_phone.check_btn")))]
              )
            : _c(
                "b-button",
                {
                  attrs: { type: "is-success" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal(true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("component.verify_phone.finish_btn")))]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }