var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _vm.isLoading && !_vm.isSwappingRulesMode
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _vm.isSwappingRulesMode
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isSwappingRulesMode,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isSwappingRulesMode = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.notification.rules_list.mode_change.waiting"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "tile is-ancestor" }, [
        _c(
          "div",
          { staticClass: "tile is-vertical" },
          [
            _c("ProgressBar", {
              attrs: {
                id: "progressBar",
                actualStep: _vm.actualProcessStep,
                canSkip: _vm.canSkipStep,
                alreadyVisitedSteps: _vm.alreadyVisitedSteps,
              },
              on: { setStep: _vm.setStep },
            }),
            !_vm.isChangingStep &&
            _vm.actualProcessStep != _vm.WizardSteps.INSTRUCTIONS
              ? _c("SkipStep", {
                  attrs: {
                    actualStep: _vm.actualProcessStep,
                    canSkip: _vm.canSkipStep(_vm.actualProcessStep),
                    hasMsg: true,
                  },
                  on: { skipStep: _vm.skipStep, backStep: _vm.backStep },
                })
              : _vm._e(),
            _vm.actualProcessStep != _vm.WizardSteps.INSTRUCTIONS
              ? _c("div", { staticClass: "tile is-parent" }, [
                  _c("div", { staticClass: "modal-card wizard-card" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "modal-card-title has-text-centered is-size-3 wizard-title has-text-primary",
                      },
                      [_vm._v(" " + _vm._s(_vm.wizzardTitle) + " ")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.INSTRUCTIONS
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("InstructionsStep", {
                      staticClass: "box",
                      class: [
                        _vm.fullHelpVisible
                          ? "tile is-8 is-child"
                          : "centered-card",
                      ],
                      on: { showDetailedInstructions: _vm.showDetailedInfo },
                    }),
                    _vm.fullHelpVisible && !_vm.isDetailedInfoModalActive
                      ? _c(
                          "div",
                          { staticClass: "tile is-4 is-child" },
                          [
                            _c("WizardHelp", {
                              attrs: { text: _vm.wizzardHelp },
                              on: {
                                notificationClosed: _vm.notificationClosed,
                                showDetailedInstructions: _vm.showDetailedInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.DEVICE &&
            _vm.$featuresManager.isAvailable(_vm.$features.OBSERVER_IN_COMPANY)
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _c("DeviceRegistrationStep", {
                      staticClass: "box",
                      class: [
                        _vm.fullHelpVisible
                          ? "tile is-8 is-child"
                          : "centered-card",
                      ],
                      on: {
                        closeRegistrationModal: function ($event) {
                          return _vm.finishDeviceStep($event)
                        },
                      },
                    }),
                    _vm.fullHelpVisible && !_vm.isDetailedInfoModalActive
                      ? _c(
                          "div",
                          { staticClass: "tile is-4 is-child" },
                          [
                            _c("WizardHelp", {
                              attrs: { text: _vm.wizzardHelp },
                              on: {
                                notificationClosed: _vm.notificationClosed,
                                showDetailedInstructions: _vm.showDetailedInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.LOGGERS
              ? _c("div", { staticClass: "tile" }, [
                  _c(
                    "div",
                    { staticClass: "tile is-parent is-vertical" },
                    [
                      !_vm.isLoading
                        ? _c("LoggerRegistrationStep", {
                            class: [
                              _vm.fullHelpVisible
                                ? "tile is-child"
                                : "centered-card",
                            ],
                            attrs: {
                              companyLoggerList: _vm.companyLoggerList,
                              sourceList: _vm.sources,
                              dateTimeManager: _vm.dateTimeManager,
                              deviceId: _vm.deviceId,
                              openLoggerScan: _vm.openLoggerScanFlag,
                            },
                            on: {
                              reloadLoggers: _vm.loadLoggerData,
                              "logger-modal-visible": _vm.onLoggerModalEvent,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.fullHelpVisible && !_vm.isDetailedInfoModalActive
                    ? _c(
                        "div",
                        { staticClass: "tile is-4 is-child" },
                        [
                          _c("WizardHelp", {
                            attrs: { text: _vm.wizzardHelp },
                            on: {
                              notificationClosed: _vm.notificationClosed,
                              showDetailedInstructions: _vm.showDetailedInfo,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.BOUNDARIES
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    !_vm.isLoading
                      ? _c("BoundariesStep", {
                          class: [
                            _vm.fullHelpVisible
                              ? "tile is-8 is-child"
                              : "centered-card",
                          ],
                          attrs: {
                            sources: _vm.sources,
                            sourceGroups: _vm.sourceGroups,
                            eventTypes: _vm.eventTypes,
                          },
                          on: { reload: _vm.reloadBoundaries },
                        })
                      : _vm._e(),
                    _vm.fullHelpVisible && !_vm.isDetailedInfoModalActive
                      ? _c(
                          "div",
                          { staticClass: "tile is-4 is-child" },
                          [
                            _c("WizardHelp", {
                              attrs: { text: _vm.wizzardHelp },
                              on: {
                                notificationClosed: _vm.notificationClosed,
                                showDetailedInstructions: _vm.showDetailedInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.NOTIFICATIONS
              ? _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    _vm.timetableEnabled === true
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-message",
                              { attrs: { type: "is-warning", "has-icon": "" } },
                              [
                                _vm._v(
                                  " V nastaveniach notifikácií máte zapnuté používanie časového plánu. Tieto nastavenia nie sú súčasťou sprievodcu. Pre ich zmenu prejdite na stránku Nastavenia -> Notifikácie "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : !_vm.isLoading
                      ? _c("NotificationsStep", {
                          class: [
                            _vm.fullHelpVisible
                              ? "tile is-8 is-child"
                              : "centered-card",
                          ],
                          attrs: {
                            departmentUserSettings: _vm.departmentUserSettings,
                            currentUser: _vm.currentUser,
                            availableTypes: _vm.availableTypes,
                            notificationSubscriptions:
                              _vm.notificationSubscriptions,
                            sourceGroups: _vm.sourceGroups,
                            sources: _vm.sources,
                            departmentSettings: _vm.departmentSettings,
                            presets: _vm.presets,
                            tier: _vm.tier,
                          },
                          on: {
                            reloadAll: _vm.loadNotificationsData,
                            applyPreset: _vm.applyPreset,
                          },
                        })
                      : _vm._e(),
                    _vm.fullHelpVisible && !_vm.isDetailedInfoModalActive
                      ? _c(
                          "div",
                          { staticClass: "tile is-4 is-child" },
                          [
                            _c("WizardHelp", {
                              attrs: { text: _vm.wizzardHelp },
                              on: {
                                notificationClosed: _vm.notificationClosed,
                                showDetailedInstructions: _vm.showDetailedInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.actualProcessStep == _vm.WizardSteps.DONE
              ? _c("div", { staticClass: "tile is-parent" }, [
                  _c(
                    "article",
                    {
                      staticClass: "modal-card wizard-card message is-success",
                    },
                    [
                      _c("div", { staticClass: "message-body" }, [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("component.wizard.close.final_text")
                              )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "tile is-parent is-flex is-flex-direction-column",
              },
              [
                _vm.actualProcessStep == _vm.WizardSteps.DONE
                  ? _c(
                      "b-button",
                      {
                        staticClass: "modal-card wizard-card mb-2",
                        staticStyle: {
                          "flex-direction": "row",
                          "background-color": "#6aa84f",
                          color: "white",
                        },
                        attrs: { "icon-pack": "fa", "icon-right": "check" },
                        on: {
                          click: function ($event) {
                            return _vm.finishWizard()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("component.wizard.close.finish")))]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "has-text-centered" },
                  [
                    !_vm.isLoading
                      ? _c("SkipStep", {
                          attrs: {
                            actualStep: _vm.actualProcessStep,
                            canSkip: _vm.canSkipStep(_vm.actualProcessStep),
                          },
                          on: {
                            skipStep: _vm.skipStep,
                            backStep: _vm.backStep,
                          },
                        })
                      : _vm._e(),
                    _c("hr"),
                    _vm.isSetupRequired === false &&
                    _vm.actualProcessStep != _vm.WizardSteps.DONE
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "is-primary",
                              "icon-pack": "fa",
                              outlined: "",
                              "icon-right": "times",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.closeWizard()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("component.wizard.close.close"))
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isDetailedInfoModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
            "full-screen": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.isDetailedInfoModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("DetailedInstructionsModal", {
            on: { modalClosed: _vm.closeModal },
          }),
        ],
        1
      ),
      !_vm.fullHelpVisible &&
      !_vm.isDetailedInfoModalActive &&
      !_vm.helpButtonHidden
        ? _c(
            "b-button",
            {
              attrs: {
                id: "hiddenHelp",
                type: "is-info",
                "icon-left": "question-circle",
              },
              on: { click: _vm.showHelp },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("component.wizard.info_modal.body.tutorial.showHelp")
                )
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }