import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import BoundaryRepository from '@/services/repository/BoundaryRepository';
import SourceRepository from '@/services/repository/SourceRepository';
import BoundaryModal from '@/views/components/boundary/BoundaryModal.vue';
import BoundaryDtoInfo from '@/views/components/boundary/BoundaryDtoInfo.vue';
import CreateRuleWarning from '@/views/components/notification/CreateRuleWarning.vue';
import EntitySelectionTable from '../generals/EntitySelectionTable.vue';
import VueUtilities from '@/services/VueUtilities';
import { Boundary } from '@/entities/models/Boundary';
var sourceGroupRepository;
var sourceRepository;
var boundaryRepository;
let CreateForm = class CreateForm extends Vue {
    constructor() {
        super(...arguments);
        //TODO: Zistit ako pridat viac error msg k jednemu fieldu (veeValidate)
        this.SOURCE_PER_PAGE = 10;
        this.groupName = null;
        this.nameExists = false;
        this.isNameEmpty = false;
        this.selectedSources = [];
        this.isBoundaryModalActive = false;
        this.boundaries = null;
        this.createRuleWarningVisible = false;
        this.createdGroupId = null;
        this.choosedEntityColumn = 'name';
        this.isLoading = false;
        this.filters = {
            name: '',
            'sourceGroup.name': ''
        };
    }
    created() {
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        boundaryRepository = new BoundaryRepository(this);
        this.boundaries = Boundary.convertBoundariesToDTO([]);
    }
    getCopyOfBoundaries() {
        return JSON.parse(JSON.stringify(this.boundaries));
    }
    async validateBeforeSubmit() {
        this.isLoading = true;
        await this.checkName(this.groupName);
        this.$validator
            .validateAll()
            .then(async (result) => {
            if (result && !this.nameExists) {
                let newSourceGroup = await this.createSourceGroup();
                if (this.selectedSources.length > 0) {
                    await this.asignToGroup(this.selectedSources[0].id, newSourceGroup.id);
                    await this.assignBoundaries(this.getCopyOfBoundaries(), this.selectedSources[0].id);
                }
                let additionalPromises = new Array();
                for (let i = 1; i < this.selectedSources.length; i++) {
                    additionalPromises.push(this.asignToGroup(this.selectedSources[i].id, newSourceGroup.id));
                }
                await Promise.all(additionalPromises)
                    .then((response) => {
                    if (newSourceGroup) {
                        VueUtilities.openSuccessToast(this, this.$t('component.group.create.msg.group_created', {
                            name: newSourceGroup.name
                        }).toString());
                        this.createdGroupId = newSourceGroup.id;
                        this.createRuleWarningVisible = true;
                    }
                })
                    .catch((error) => {
                    VueUtilities.openErrorToast(this, `${this.$t('component.group.create.msg.failure').toString()} ${error.response.data.message}`);
                });
            }
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
    warningClosed() {
        this.$router
            .push({
            name: 'configuration_groups',
            params: {
                lang: this.$route.params.lang,
                departmentId: this.$route.params.departmentId
            }
        })
            .catch((err) => err);
    }
    async asignToGroup(sourceId, groupId) {
        return sourceRepository.assignToGroup(sourceId, groupId);
    }
    async assignBoundaries(boundaries, sourceId) {
        return boundaryRepository.createBoundary(boundaries, sourceId);
    }
    async createSourceGroup() {
        let groupDTO = {
            Name: this.groupName
        };
        return await sourceGroupRepository.createGroup(groupDTO);
    }
    async checkName(name) {
        let checkResponse = await sourceGroupRepository.checkIfNameExists(name);
        this.nameExists = checkResponse.Exist;
        this.isNameEmpty = !(name && name.length > 0);
    }
    sourceSelected(allChecked) {
        this.selectedSources = allChecked;
    }
    addBoundary() {
        this.isBoundaryModalActive = true;
    }
    closeModal(data) {
        this.isBoundaryModalActive = false;
    }
    saveBoundariesCommand(boundaries) {
        this.boundaries = boundaries;
    }
};
__decorate([
    Prop({ type: Array })
], CreateForm.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], CreateForm.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], CreateForm.prototype, "currenUser", void 0);
CreateForm = __decorate([
    Component({
        components: {
            BoundaryModal,
            BoundaryDtoInfo,
            EntitySelectionTable,
            CreateRuleWarning
        }
    })
], CreateForm);
export default CreateForm;
