var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notification-card" },
    [
      _c(
        "div",
        { staticClass: "section-header-level" },
        [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("component.notifications_list.card_title")) +
                " "
            ),
          ]),
          _vm.buttonEnabled
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "notifications",
                      params: {
                        lang: _vm.$route.params.lang,
                        departmentId: _vm.$route.params.departmentId,
                      },
                    },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-2-mobile",
                      attrs: { type: "is-primary", outlined: "" },
                    },
                    [_vm._v(_vm._s(_vm.$t("generals.view_all")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.paused
        ? _c("b-message", { attrs: { type: "is-warning" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.notification.pause.paused_msg", {
                    time: _vm.dateTimeManager.formatTime(
                      new Date(_vm.pausedTill * 1000),
                      null,
                      "",
                      null
                    ),
                  })
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "notifications-column" },
        [
          _vm.notifications.length <= 0
            ? _c(
                "div",
                [
                  _c(
                    "b-notification",
                    { attrs: { closable: false, type: "is-light" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "has-text-centered has-text-weight-semibold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.notifications_list.table.empty"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._l(_vm.notifications, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("NotificationItem", {
                      attrs: {
                        notificationItem: item,
                        dateTimeManager: _vm.dateTimeManager,
                      },
                      on: { notificationRemoved: _vm.notificationRemoved },
                    }),
                    index < _vm.notifications.length - 1 ? _c("hr") : _vm._e(),
                  ],
                  1
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }