import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import VueUtilities from '@/services/VueUtilities';
import ApiStatus from '@/entities/enums/apiStatuses';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var userRepository;
let AdminAddPhoneNumberModal = class AdminAddPhoneNumberModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.editedPhoneNumber = '';
        this.verificationSent = false;
        this.isFinished = false;
        this.verificationCode = '';
        this.invalidNumber = false;
    }
    get phoneNumberAdditionalError() {
        if (this.invalidNumber)
            return this.$t('admin.component.user.detail.add_phone.msg.phone_number_invalid');
        else
            return null;
    }
    created() {
        userRepository = new UserRepository(this);
        this.phoneNumber = this.phoneNumberDto ? this.phoneNumberDto.Number : '';
        this.editedPhoneNumber = this.phoneNumber;
    }
    beforeDestroy() {
        if (this.isFinished == true)
            this.$emit('verificationFinished', true);
    }
    submit() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                if (this.phoneNumberDto) {
                    let deleteResult = await userRepository.adminDeletePhoneNumber(this.currentUserId);
                    if (!deleteResult) {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.user.detail.add_phone.msg.edit_failed'));
                        this.isLoading = false;
                        return;
                    }
                }
                let createResult = await userRepository.adminAddPhoneNumber(this.currentUserId, this.editedPhoneNumber);
                if (createResult != true) {
                    if (createResult.status == ApiStatus.BAD_REQUEST &&
                        createResult.code == ApiResponseCodes.INVALID_PHONE_NUMBER) {
                        this.invalidNumber = true;
                    }
                    else {
                        VueUtilities.openErrorToast(this, this.$t('admin.component.user.detail.add_phone.msg.edit_failed'));
                    }
                    this.isLoading = false;
                    return;
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.user.detail.add_phone.msg.edit_success'));
                    this.closeModal(true);
                    this.$emit('reloadUser');
                    this.isLoading = false;
                }
            }
        });
    }
    closeModal(event) {
        this.$emit('modalClosed', event);
    }
    numberChanged() {
        this.invalidNumber = false;
    }
};
__decorate([
    Prop({ type: Object })
], AdminAddPhoneNumberModal.prototype, "phoneNumberDto", void 0);
__decorate([
    Prop({ type: String })
], AdminAddPhoneNumberModal.prototype, "currentUserId", void 0);
AdminAddPhoneNumberModal = __decorate([
    Component({})
], AdminAddPhoneNumberModal);
export default AdminAddPhoneNumberModal;
