var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "admin.component.loggers.calibration.create_calibration.title"
              )
            ) +
            " (" +
            _vm._s(_vm.logger.serialNumber) +
            ") "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body modal-form" },
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.calibration.calibration_date"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.calibration.calibration_date")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.calibration.calibration_date")
              ),
            },
          },
          [
            _c("b-datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                name: _vm.$t(
                  "admin.component.loggers.calibration.calibration_date"
                ),
                "mobile-native": false,
                "first-day-of-week": 1,
                "date-formatter": (date) =>
                  _vm.dateTimeManager.formatTimeInInput(date),
                "month-names": _vm.dateTimeManager.getMonthNames(),
              },
              model: {
                value: _vm.calibratedAt,
                callback: function ($$v) {
                  _vm.calibratedAt = $$v
                },
                expression: "calibratedAt",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.calibration.verification_date"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t(
                    "admin.component.loggers.calibration.verification_date"
                  )
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.calibration.verification_date")
              ),
            },
          },
          [
            _c("b-datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                name: _vm.$t(
                  "admin.component.loggers.calibration.verification_date"
                ),
                "mobile-native": false,
                "first-day-of-week": 1,
                "date-formatter": (date) =>
                  _vm.dateTimeManager.formatTimeInInput(date),
                "month-names": _vm.dateTimeManager.getMonthNames(),
              },
              on: {
                input: function ($event) {
                  return _vm.changeDate()
                },
              },
              model: {
                value: _vm.verifiedAt,
                callback: function ($$v) {
                  _vm.verifiedAt = $$v
                },
                expression: "verifiedAt",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "admin.component.loggers.calibration.expiration_date"
              ),
              type: {
                "is-danger": _vm.errors.has(
                  _vm.$t("admin.component.loggers.calibration.expiration_date")
                ),
              },
              message: _vm.errors.first(
                _vm.$t("admin.component.loggers.calibration.expiration_date")
              ),
            },
          },
          [
            _c("b-datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              attrs: {
                name: _vm.$t(
                  "admin.component.loggers.calibration.expiration_date"
                ),
                "mobile-native": false,
                "first-day-of-week": 1,
                "date-formatter": (date) =>
                  _vm.dateTimeManager.formatTimeInInput(date),
                "month-names": _vm.dateTimeManager.getMonthNames(),
              },
              model: {
                value: _vm.expireAt,
                callback: function ($$v) {
                  _vm.expireAt = $$v
                },
                expression: "expireAt",
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "b-field",
          [
            _c(
              "b-upload",
              {
                attrs: {
                  "drag-drop": "",
                  accept: ".pdf",
                  expanded: "",
                  multiple: "",
                },
                on: { input: _vm.filesUploaded },
                model: {
                  value: _vm.uploadedFiles,
                  callback: function ($$v) {
                    _vm.uploadedFiles = $$v
                  },
                  expression: "uploadedFiles",
                },
              },
              [
                _c("section", { staticClass: "section" }, [
                  _c("div", { staticClass: "content has-text-centered" }, [
                    _c(
                      "p",
                      [
                        _c("b-icon", {
                          attrs: { icon: "upload", size: "is-large" },
                        }),
                      ],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("admin.component.devices.importDevices.upload")
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "table",
          _vm._l(_vm.uploadedFiles, function (file, index) {
            return _c("tr", { key: file.name }, [
              _c("td", { staticClass: "pb-2" }, [
                file
                  ? _c(
                      "span",
                      { key: file.name, staticClass: "tag is-primary mr-1" },
                      [
                        _vm._v(" " + _vm._s(file.name) + " "),
                        _c("button", {
                          staticClass: "delete is-small",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteDropFile(file.name)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "td",
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        type: {
                          "is-danger": _vm.errors.has(
                            _vm.$t("navbar.language") + index
                          ),
                        },
                        message: _vm.errors.has(
                          _vm.$t("navbar.language") + index
                        )
                          ? _vm.$t("error_messages.required_field")
                          : "",
                      },
                    },
                    [
                      _c(
                        "b-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            size: "is-small",
                            placeholder: _vm.$t("navbar.language"),
                            name: _vm.$t("navbar.language") + index,
                          },
                          model: {
                            value: file.locale,
                            callback: function ($$v) {
                              _vm.$set(file, "locale", $$v)
                            },
                            expression: "file.locale",
                          },
                        },
                        _vm._l(_vm.locales, function (locale) {
                          return _c(
                            "option",
                            { key: locale, domProps: { value: locale } },
                            [
                              locale === "sk"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                                  ])
                                : locale === "cz"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("navbar.czech"))),
                                  ])
                                : locale === "en"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("navbar.english"))),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c("hr"),
      ],
      1
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }