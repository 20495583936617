import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import VueUtilities from '@/services/VueUtilities';
var userRepository;
let PreRegisterUserModal = class PreRegisterUserModal extends Vue {
    constructor() {
        super(...arguments);
        this.email = null;
        this.username = null;
        this.firstName = null;
        this.lastName = null;
        this.sendVerification = true;
        this.isLoading = false;
    }
    created() {
        userRepository = new UserRepository(this);
    }
    setUsername(value) {
        this.username = value;
    }
    preRegisterUser() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let preregisterUserDto = {
                    Email: this.email,
                    Username: this.username,
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    SendVerification: this.sendVerification
                };
                let createResult = await userRepository.adminPreregisterUser(preregisterUserDto);
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.user.preregisterUserModal.success'));
                    this.resultRetrieved(true);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.user.preregisterUserModal.failure'));
                }
                this.isLoading = false;
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
PreRegisterUserModal = __decorate([
    Component({})
], PreRegisterUserModal);
export default PreRegisterUserModal;
