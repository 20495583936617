import { vxm } from '@/store/store.vuex';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import ApiLinks from '@/entities/ApiLinks';
import AxiosService from '../api/AxiosService';
import ApiStatus from '@/entities/enums/apiStatuses';
import { PeriodicReport } from '@/entities/models/PeriodicReport';
var vxDepartmentStore = vxm.departmentStore;
export default class ReportRecordRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getPeriodicReports(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.REPORTS_CONFIGURATION)) {
            return null;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Configurations}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return PeriodicReport.fromApi(response.data.data);
    }
    async postPeriodicReport(data, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.REPORTS_CONFIGURATION)) {
            return null;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Configurations}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return null;
        else
            return true;
    }
    async putPeriodicReport(reportConfigurationId, data, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGE, Subjects.REPORTS_CONFIGURATION)) {
            return null;
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Configurations}/${reportConfigurationId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async deletePeriodicReport(reportConfigurationId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.REPORTS_CONFIGURATION)) {
            throw 'Forbidden action deletePeriodicReport';
        }
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Reports.Configurations}/${reportConfigurationId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
