var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("component.invitation.resendInvitationModal.title")
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.invite_language"
                ),
              },
            },
            [
              _c(
                "b-select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { placeholder: "Select a character", icon: "globe" },
                  on: { input: _vm.selectLang },
                  model: {
                    value: _vm.selectedLang,
                    callback: function ($$v) {
                      _vm.selectedLang = $$v
                    },
                    expression: "selectedLang",
                  },
                },
                [
                  _c("option", { domProps: { value: _vm.Languages.ENGLISH } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.english"))),
                  ]),
                  _c("option", { domProps: { value: _vm.Languages.SLOVAK } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                  ]),
                  _c("option", { domProps: { value: _vm.Languages.CZECH } }, [
                    _vm._v(_vm._s(_vm.$t("navbar.czech"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.table.invite_emails"
                ),
                grouped: "",
              },
            },
            [
              _c(
                "b-field",
                {
                  staticClass: "mt-2",
                  attrs: {
                    expanded: "",
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "component.userAdministration.list.invite.form.table.email"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t(
                        "component.userAdministration.list.invite.form.table.email"
                      )
                    ),
                    label: _vm.$t("generals.email"),
                    "label-position": "on-border",
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    attrs: {
                      disabled: "",
                      name: _vm.$t(
                        "component.userAdministration.list.invite.form.table.email"
                      ),
                      type: "text",
                      placeholder: _vm.$t(
                        "component.userAdministration.list.invite.form.table.emailPlaceholder"
                      ),
                      expanded: "",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "mt-2 ml-2",
                  attrs: {
                    expanded: "",
                    label: _vm.$t("component.invitation.role"),
                    "label-position": "on-border",
                  },
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { expanded: "" },
                      model: {
                        value: _vm.role,
                        callback: function ($$v) {
                          _vm.role = $$v
                        },
                        expression: "role",
                      },
                    },
                    _vm._l(_vm.availableRoles, function (option) {
                      return _c(
                        "option",
                        { key: option.Key, domProps: { value: option } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t(`users.roles.${option.Key}`)) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "mt-2 ml-2",
                  attrs: {
                    expanded: "",
                    label: _vm.$t(
                      "component.userAdministration.list.invite.form.table.notif_preset"
                    ),
                    "label-position": "on-border",
                  },
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { expanded: "" },
                      model: {
                        value: _vm.preset,
                        callback: function ($$v) {
                          _vm.preset = $$v
                        },
                        expression: "preset",
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { selected: "", disabled: "", hidden: "" },
                          domProps: { value: null },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.userAdministration.list.invite.form.table.presetPlaceholder`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm._l(_vm.availablePresets, function (option) {
                        return _c(
                          "option",
                          { key: option.id, domProps: { value: option } },
                          [_vm._v(" " + _vm._s(option.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "component.userAdministration.list.invite.form.message"
                ),
              },
            },
            [
              _c("b-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "component.userAdministration.list.invite.form.write"
                  ),
                  maxlength: "1200",
                  type: "textarea",
                },
                on: {
                  "~focus": function ($event) {
                    return _vm.prefillMessage()
                  },
                },
                model: {
                  value: _vm.inviteMessage,
                  callback: function ($$v) {
                    _vm.inviteMessage = $$v
                  },
                  expression: "inviteMessage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { "icon-left": "share", type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("component.invitation.actions.resend")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }