import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import DeviceRepository from '@/services/repository/DeviceRepository';
var companyLoggerRepository;
var deviceRepository;
let ReadDataFromLoggerModal = class ReadDataFromLoggerModal extends Vue {
    constructor() {
        super(...arguments);
        this.devices = [];
        this.selectedDevice = null;
        this.isLoading = true;
    }
    async created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        deviceRepository = new DeviceRepository(this);
        this.loadData();
    }
    read() {
        this.$emit('readData', this.selectedDevice);
    }
    async loadData() {
        let allPromises = [];
        allPromises.push(deviceRepository.getAdminDevicesForDepartment(this.logger.currentlyPublishedAs.company.companyId));
        Promise.all(allPromises).then((response) => {
            this.processLoadedData(response[0]);
        });
    }
    processLoadedData(devices) {
        this.devices = devices.getData();
        this.isLoading = false;
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
__decorate([
    Prop({ type: Object })
], ReadDataFromLoggerModal.prototype, "logger", void 0);
ReadDataFromLoggerModal = __decorate([
    Component({})
], ReadDataFromLoggerModal);
export default ReadDataFromLoggerModal;
