import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AdminDeviceInfoComponent from '@/views/components/admin/devices/AdminDeviceInfoComponent.vue';
import AdminDeviceConfigHistoryComponent from '@/views/components/admin/devices/AdminDeviceConfigHistoryComponent.vue';
import AdminDeviceHistoryCredentials from '@/views/components/admin/devices/AdminDeviceHistoryCredentials.vue';
import AdminDeviceInstanceHistory from '@/views/components/admin/devices/AdminDeviceInstanceHistory.vue';
import AdminDeviceCommands from '@/views/components/admin/devices/AdminDeviceCommands.vue';
import AdminDeviceUpdates from '@/views/components/admin/devices/AdminDeviceUpdates.vue';
import AdminDeviceFirmwareHistory from '@/views/components/admin/devices/AdminDeviceFirmwareHistory.vue';
import AdminConfigureDeviceModal from '@/views/components/admin/devices/AdminConfigureDeviceModal.vue';
import ActivateDeviceModal from '@/views/components/admin/devices/ActivateDeviceModal.vue';
import DeviceUpdateModal from '@/views/components/admin/devices/DeviceUpdateModal.vue';
import CredentialModal from '@/views/components/admin/devices/CredentialModal.vue';
import PublishDeviceModal from '@/views/components/admin/devices/PublishDeviceModal.vue';
import RegisterToCompany from '@/views/components/admin/devices/RegisterToCompany.vue';
import DeviceMonitor from '@/views/components/admin/devices/DeviceMonitor.vue';
import WarningModal from '@/views/components/administration/WarningModal.vue';
import KeyUpdateModal from '@/views/components/admin/devices/KeyUpdateModal.vue';
import { DeviceState } from '@/entities/enums/DeviceState';
import { DeviceInstanceState } from '@/entities/enums/DeviceInstanceState';
import UserPreferences from '@/services/UserPreferences';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import DeviceActionsDropdown from './DeviceActionsDropdown.vue';
let AdminDeviceDetail = class AdminDeviceDetail extends Vue {
    constructor() {
        super(...arguments);
        this.DeviceState = DeviceState;
        this.DeviceInstanceState = DeviceInstanceState;
        this.selectedDevices = new Array();
        this.isReuse = false;
        this.isConfigModalActive = false;
        this.isActivateDeviceModalActive = false;
        this.isCredentialModalActive = false;
        this.isDeviceUpdateModalActive = false;
        this.isPublishModalActive = false;
        this.isRegisterModalActive = false;
        this.isMonitorModalActive = false;
        this.isKeyUpdateModalActive = false;
        this.diableCommandId = '';
        this.credentialsLoaded = false;
        this.commandsLoaded = false;
        this.historyLoaded = false;
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsAdminDetailPagesLayoutList);
        this.activeTab = UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab.defaultValue;
        this.prevRoute = null;
    }
    created() {
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.IsAdminDetailPagesLayoutList);
        if (this.isLayoutList) {
            this.$emit('loadAll');
            this.credentialsLoaded = true;
            this.commandsLoaded = true;
            this.historyLoaded = true;
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
        this.prevRoute = JSON.parse(localStorage.getItem(LocalStorageKeys.ADMIN_PREV_ROUTE));
    }
    configureDevice() {
        this.selectedDevices = [this.device];
        this.isConfigModalActive = true;
    }
    closeConfigureModal() {
        this.isConfigModalActive = false;
    }
    async configured(interval) {
        this.isConfigModalActive = false;
        this.selectedDevices = new Array();
        this.$emit('reload');
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.IsAdminDetailPagesLayoutList, this.isLayoutList);
        if (this.isLayoutList) {
            if (!this.credentialsLoaded || !this.commandsLoaded || !this.historyLoaded)
                this.$emit('loadAll');
        }
        else {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminCompanyDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminDeviceDetailLayoutActiveTab, event);
        switch (this.activeTab) {
            case SelectedTab.CREDENTIALS:
                if (!this.credentialsLoaded) {
                    this.$emit('loadCredentials');
                    this.credentialsLoaded = true;
                }
                break;
            case SelectedTab.COMMANDS:
                if (!this.commandsLoaded) {
                    this.$emit('loadCommandsAndUpdates');
                    this.commandsLoaded = true;
                }
                break;
            case SelectedTab.HISTORY:
                if (!this.historyLoaded) {
                    this.$emit('loadHistory');
                    this.historyLoaded = true;
                }
                break;
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    activateDevice() {
        this.isActivateDeviceModalActive = true;
    }
    closeActivateDeviceModal(result) {
        this.isActivateDeviceModalActive = false;
        if (result === true)
            this.$emit('reload');
    }
    otaUpdateDevice() {
        this.isDeviceUpdateModalActive = true;
    }
    closeDeviceUpdateModal() {
        this.isDeviceUpdateModalActive = false;
    }
    publishDevice() {
        this.isPublishModalActive = true;
    }
    reload() {
        this.$emit('reload');
    }
    closePublishModal(result) {
        this.isReuse = false;
        this.isPublishModalActive = false;
        if (result === true)
            this.$emit('reload');
    }
    registerToCompany() {
        this.isRegisterModalActive = true;
    }
    closeRegisterModal(result) {
        this.isRegisterModalActive = false;
        if (result === true)
            this.$emit('reload');
    }
    reuseDevice() {
        this.isReuse = true;
        this.isPublishModalActive = true;
    }
    returnDeviceFromCompany() {
        this.$emit('returnDevice');
    }
    revertDevice() {
        this.$emit('revertDevice');
    }
    revokeCredential() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('admin.component.devices.revokeModel.title')}`,
            message: this.$t('admin.component.devices.revokeModel.message', {
                publicKey: this.currentCredentials.publicKey
            }),
            confirmText: `${this.$t('admin.component.devices.revokeModel.confirm')}`,
            cancelText: `${this.$t('admin.component.devices.revokeModel.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('revokeCredential');
            },
            onCancel: () => { }
        });
    }
    openDisableModal(commandId) {
        this.diableCommandId = commandId;
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.devices.detail.deviceCommands.disable.title'),
            message: this.$t('admin.component.devices.detail.deviceCommands.disable.body'),
            confirmText: this.$t('admin.component.devices.detail.deviceCommands.disable.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
                this.$emit('disableCommand', this.diableCommandId);
            }
        });
    }
    newCredential() {
        this.isCredentialModalActive = true;
    }
    closeNewCredentialModal() {
        this.isCredentialModalActive = false;
    }
    loadNewCredential() {
        this.$emit('loadNewCredential');
    }
    openDeviceMonitor() {
        this.isMonitorModalActive = true;
    }
    closeMonitorModal() {
        this.isMonitorModalActive = false;
    }
    keyUpdate() {
        this.isKeyUpdateModalActive = true;
    }
    closeKeyUpdate() {
        this.isKeyUpdateModalActive = false;
    }
};
__decorate([
    Prop({ type: Array })
], AdminDeviceDetail.prototype, "deviceUpdates", void 0);
__decorate([
    Prop({ type: Array })
], AdminDeviceDetail.prototype, "commands", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceDetail.prototype, "device", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminDeviceDetail.prototype, "deviceInstances", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], AdminDeviceDetail.prototype, "currentCredentials", void 0);
__decorate([
    Prop({ type: Array })
], AdminDeviceDetail.prototype, "deviceFirmwareList", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], AdminDeviceDetail.prototype, "historyCredentials", void 0);
AdminDeviceDetail = __decorate([
    Component({
        components: {
            KeyUpdateModal,
            AdminDeviceInfoComponent,
            AdminDeviceConfigHistoryComponent,
            AdminDeviceHistoryCredentials,
            AdminDeviceFirmwareHistory,
            AdminConfigureDeviceModal,
            ActivateDeviceModal,
            DeviceUpdateModal,
            CredentialModal,
            PublishDeviceModal,
            RegisterToCompany,
            AdminDeviceInstanceHistory,
            DeviceMonitor,
            AdminDeviceCommands,
            AdminDeviceUpdates,
            WarningModal,
            DeviceActionsDropdown
        }
    })
], AdminDeviceDetail);
export default AdminDeviceDetail;
var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["DETAIL"] = 0] = "DETAIL";
    SelectedTab[SelectedTab["CREDENTIALS"] = 1] = "CREDENTIALS";
    SelectedTab[SelectedTab["COMMANDS"] = 2] = "COMMANDS";
    SelectedTab[SelectedTab["HISTORY"] = 3] = "HISTORY";
})(SelectedTab || (SelectedTab = {}));
