var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.settings.report.report_settings")) +
              " "
          ),
        ]
      ),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            [
              !_vm.isAdmin && !_vm.isUserHasAutoPdfGenerated
                ? _c("AddNotificationRule", { staticClass: "mb-5" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("ReportSettings", {
                  staticClass: "box tile is-child",
                  attrs: {
                    dateTimeManager: _vm.dateTimeManager,
                    periodicReports: _vm.periodicReports,
                  },
                  on: {
                    deletePeriodicReport: _vm.deletePeriodicReport,
                    openReportSettingModal: _vm.openReportSettingModal,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isReportConfigModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePdfModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isReportConfigModalActive = $event
            },
            "close-modal": _vm.closePdfModal,
          },
        },
        [
          _vm.userList
            ? _c("ReportSettingsModal", {
                attrs: {
                  isPeriodicReportEdit: _vm.isPeriodicReportEdit,
                  dateTimeManager: _vm.dateTimeManager,
                  sourceGroups: _vm.sourceGroups,
                  showEmpty: _vm.showEmpty,
                  sources: _vm.sources,
                  userList: _vm.userList,
                  isAdmin: _vm.isAdmin,
                  currentUser: _vm.currentUser,
                  eventTypes: _vm.eventTypes,
                  configuration: _vm.configuration,
                  selectedPeriodicReport: _vm.selectedPeriodicReport,
                },
                on: { modalClosed: _vm.closePdfModal, reload: _vm.reload },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }