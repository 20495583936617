var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "multi-header" }, [
        _c(
          "h1",
          { staticClass: "title is-3 is-spaced has-text-centered-touch" },
          [_vm._v(" " + _vm._s(_vm.$t("component.thermal_map.title")) + " ")]
        ),
      ]),
      !_vm.isLoading
        ? [
            _vm.isUploadingThermoMap || _vm.isUpdatingThermoMap
              ? _c("ThermalMapUpload", {
                  ref: "thermalMapUploadComp",
                  attrs: {
                    sources: _vm.sources,
                    selectedMap: _vm.selectedMap,
                    selectedMapImage: _vm.selectedMapImage,
                    thermoMaps: _vm.thermoMaps,
                    dateTimeManager: _vm.dateTimeManager,
                    statistics: _vm.selectedMap
                      ? _vm.statisticsList.find(
                          (x) => x.thermoMapId == _vm.selectedMap.id
                        ).statistics
                      : null,
                    isUploading: _vm.isUploadingThermoMap,
                    isUpdating: _vm.isUpdatingThermoMap,
                  },
                  on: {
                    createThermoMap: _vm.uploadThermalMap,
                    updateThermoMap: _vm.updateThermoMap,
                    cancel: _vm.cancelUpdating,
                  },
                })
              : _c("ThermalMapOverview", {
                  ref: "thermalMapOverviewComp",
                  attrs: {
                    sources: _vm.sources,
                    selectedMap: _vm.selectedMap,
                    thermoImages: _vm.thermoImages,
                    selectedMapImage: _vm.selectedMapImage,
                    thermoMaps: _vm.thermoMaps,
                    dateTimeManager: _vm.dateTimeManager,
                    statisticsList: _vm.statisticsList,
                  },
                  on: {
                    updateThermoMap: _vm.updateThermoMap,
                    uploadThermalMap: _vm.uploadThermalMap,
                    deleteThermoMap: _vm.openDeleteDialog,
                    setSelectedThermoMap: _vm.setSelectedThermoMap,
                  },
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }