var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("admin.component.loggers.selectCompanyModal.title")) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _vm._v(" " + _vm._s(_vm.backgroundJob) + " "),
    ]),
    _c(
      "footer",
      { staticClass: "modal-card-foot" },
      [
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }