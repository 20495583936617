import { render, staticRenderFns } from "./PauseNotificationsMessage.vue?vue&type=template&id=4993a380"
import script from "./PauseNotificationsMessage.vue?vue&type=script&lang=ts"
export * from "./PauseNotificationsMessage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4993a380')) {
      api.createRecord('4993a380', component.options)
    } else {
      api.reload('4993a380', component.options)
    }
    module.hot.accept("./PauseNotificationsMessage.vue?vue&type=template&id=4993a380", function () {
      api.rerender('4993a380', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/notification/PauseNotificationsMessage.vue"
export default component.exports