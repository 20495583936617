var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "section-header-level" },
        [
          _c("h2", { staticClass: "subtitle is-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("component.logger.list.idle_title")) + " "
            ),
          ]),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.LOGGER) &&
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE)
            ? _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      _vm.isAddLoggersModalActive = true
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.logger.list.idle_button_label"))
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-table",
        {
          staticClass: "idle-loggers-table",
          attrs: {
            data: _vm.sortedLoggers,
            "row-class": (row, index) =>
              row.active == false && "disabled-logger",
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              label: _vm.$t("component.logger.list.table.serialNumber"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_c("p", [_vm._v(_vm._s(props.row.serialNumber))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("component.logger.list.table.macAddress") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_c("p", [_vm._v(_vm._s(props.row.macAddress))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              centered: "",
              label: _vm.$t("component.logger.list.table.active"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("b-icon", {
                      attrs: { icon: props.row.active ? "check" : "times" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.logger.list.table.empty")) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.LOGGER) &&
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddLoggersModalActive,
                "has-modal-card": "",
                onCancel: _vm.onModalClose,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddLoggersModalActive = $event
                },
              },
            },
            [
              _c("CreateSourcesModal", {
                attrs: {
                  assignedLoggers: _vm.activeLoggers,
                  inWizard: _vm.inWizard,
                },
                on: {
                  "close-modal": _vm.onModalClose,
                  "reload-loggers": _vm.reloadLoggers,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }