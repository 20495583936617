var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "columns columns-info-form group-info-form" },
    [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("component.group.detail.table.name"),
                type: {
                  "is-danger":
                    _vm.errors.has(_vm.$t("component.group.create.name")) ||
                    _vm.nameExists ||
                    _vm.isNameEmpty,
                },
                message: _vm.isNameEmpty
                  ? _vm.$t("component.group.create.msg.empty_name")
                  : _vm.nameExists
                  ? _vm.$t("component.group.create.msg.unique_name")
                  : _vm.errors.first(_vm.$t("component.group.create.name")),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: _vm.$t("component.group.create.name"),
                  expanded: "",
                },
                on: { input: _vm.checkName },
                model: {
                  value: _vm.groupName,
                  callback: function ($$v) {
                    _vm.groupName = $$v
                  },
                  expression: "groupName",
                },
              }),
            ],
            1
          ),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
            ? _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("component.group.create.loggers"),
                  },
                },
                [
                  _c("EntitySelectionTable", {
                    attrs: {
                      tableData: _vm.sources,
                      assignedEntities: [],
                      filters: _vm.filters,
                      choosedEntityColumn: _vm.choosedEntityColumn,
                      isLinkable: true,
                    },
                    on: { entitySelected: _vm.sourceSelected },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
            ? _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t("component.location.create.boundaries"),
                  },
                },
                [
                  _vm.selectedSources <= 0
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "has-text-centered is-italic has-text-grey-light",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.group.create.msg.select_logger_first"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        [
                          _c("BoundaryDtoInfo", {
                            attrs: { boundaries: _vm.boundaries },
                          }),
                          _c(
                            "b-button",
                            {
                              attrs: { type: "is-primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addBoundary()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.boundaries.length == 0
                                    ? _vm.$t(
                                        "component.location.create.add_boundary"
                                      )
                                    : _vm.$t(
                                        "component.location.create.edit_boundary"
                                      )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", disabled: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.validateBeforeSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.create")))]
              ),
            ],
            1
          ),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
            ? _c(
                "b-modal",
                {
                  attrs: {
                    active: _vm.isBoundaryModalActive,
                    "has-modal-card": "",
                    onCancel: _vm.closeModal,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.isBoundaryModalActive = $event
                    },
                    "close-modal": _vm.closeModal,
                  },
                },
                [
                  _c("BoundaryModal", {
                    attrs: {
                      boundaries: _vm.getCopyOfBoundaries(),
                      eventTypes: _vm.eventTypes,
                    },
                    on: {
                      modalClosed: function ($event) {
                        return _vm.closeModal()
                      },
                      saveBoundaries: _vm.saveBoundariesCommand,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.createRuleWarningVisible
            ? _c("CreateRuleWarning", {
                attrs: {
                  sourceGroupId: _vm.createdGroupId,
                  currenUser: _vm.currenUser,
                },
                on: { warningClosed: _vm.warningClosed },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }