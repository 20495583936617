var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "small" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _vm.registered === true
        ? _c("div", [
            _c("h2", { staticClass: "title is-2 has-text-centered" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("component.verify_account.title")) + " "
              ),
            ]),
            _vm.verified === true
              ? _c(
                  "div",
                  { staticClass: "has-text-centered" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "has-text-success has-text-centered has-text-weight-medium",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("component.verify_account.success")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "login",
                            params: {
                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: { type: "is-primary", outlined: "" },
                          },
                          [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "h3",
                    { staticClass: "has-text-danger has-text-weight-medium" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.verify_account.failure")) +
                          " "
                      ),
                    ]
                  ),
                  _c("p", { staticClass: "has-text-weight-medium" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("component.verify_account.causes_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("ul", { staticClass: "account-verify-causes-list" }, [
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.wrong_url")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.verify_account.causes.account_missing"
                          )
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.verified")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.disabled")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.expired")
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "resend-box has-text-centered" }, [
                    _c("p", { staticClass: "has-text-weight-medium" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.verify_account.retry_request")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "multi-header" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "login",
                                params: {
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { type: "is-primary", outlined: "" } },
                              [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "verifyResend",
                                query: { user: _vm.$route.query.user },
                                params: {
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { type: "is-primary", outlined: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("component.verify_account.resend")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
          ])
        : _c("div", [
            _c("h2", { staticClass: "title is-2 has-text-centered" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("component.finish_registration.title")) +
                  " "
              ),
            ]),
            _vm.errorVerified === false
              ? _c(
                  "div",
                  { staticClass: "has-text-centered" },
                  [
                    _c("ValidationObserver", { ref: "passwordFields" }, [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$t(
                                "component.finish_registration.password"
                              ),
                              rules: "required|password|min:8",
                              vid: "confirmation",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-field",
                                        {
                                          staticClass: "required",
                                          attrs: {
                                            label: _vm.$t(
                                              "component.finish_registration.password"
                                            ),
                                            type: {
                                              "is-danger": errors.length > 0,
                                            },
                                            message: errors[0],
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "password",
                                              placeholder: _vm.$t(
                                                "component.finish_registration.password"
                                              ),
                                              "password-reveal": "",
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function ($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2276680886
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$t(
                                "component.finish_registration.confirm_pswd"
                              ),
                              rules: "required|confirmed:confirmation",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-field",
                                        {
                                          staticClass: "required",
                                          attrs: {
                                            label: _vm.$t(
                                              "component.finish_registration.confirm_pswd"
                                            ),
                                            type: {
                                              "is-danger": errors.length > 0,
                                            },
                                            message: errors[0],
                                          },
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "password",
                                              placeholder: _vm.$t(
                                                "component.finish_registration.confirm_pswd"
                                              ),
                                              "password-reveal": "",
                                            },
                                            model: {
                                              value: _vm.passwordConfirm,
                                              callback: function ($$v) {
                                                _vm.passwordConfirm = $$v
                                              },
                                              expression: "passwordConfirm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3797497316
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "is-primary",
                              disabled:
                                _vm.password === null &&
                                _vm.passwordConfirm === null,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.finishPreregistration()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "component.finish_registration.finish_button"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "h3",
                    { staticClass: "has-text-danger has-text-weight-medium" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.finish_registration.failure")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("p", { staticClass: "has-text-weight-medium" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("component.verify_account.causes_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("ul", { staticClass: "account-verify-causes-list" }, [
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.wrong_url")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.verify_account.causes.account_missing"
                          )
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.verified")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.disabled")
                        )
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.verify_account.causes.expired")
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "resend-box has-text-centered" }, [
                    _c("p", { staticClass: "has-text-weight-medium" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.verify_account.retry_request")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "login",
                                params: {
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { type: "is-primary", outlined: "" } },
                              [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }