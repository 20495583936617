var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoadingOverlay
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoadingOverlay,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoadingOverlay = $event
              },
            },
          })
        : _vm._e(),
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.report.create_annotation.title")) +
              " "
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _vm.diffIsMoreThanOneDay
            ? _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-message",
                    { attrs: { type: "is-warning", "has-icon": "" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.report.create_annotation.range_warning"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("component.report.create_annotation.range"),
              },
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.annotationRange) + " ")])]
          ),
          _c(
            "b-field",
            {
              attrs: {
                horizontal: "",
                label: _vm.$t("component.report.create_annotation.text"),
              },
            },
            [
              _c(
                "b-select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(
                      "component.report.create_annotation.placeholder"
                    ),
                  },
                  model: {
                    value: _vm.predefined,
                    callback: function ($$v) {
                      _vm.predefined = $$v
                    },
                    expression: "predefined",
                  },
                },
                _vm._l(_vm.PredefinedText, function (annotation) {
                  return _c(
                    "option",
                    { key: annotation, domProps: { value: annotation } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `component.report.create_annotation.predefined.${annotation}`
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t("component.report.create_annotation.text")
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t("component.report.create_annotation.text")
                ),
              },
            },
            [
              _vm.predefined === _vm.PredefinedText.OWN
                ? _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      type: "textarea",
                      name: _vm.$t("component.report.create_annotation.text"),
                      expanded: "",
                    },
                    model: {
                      value: _vm.annotationText,
                      callback: function ($$v) {
                        _vm.annotationText = $$v
                      },
                      expression: "annotationText",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.validateBeforeSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.create")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }