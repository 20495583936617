var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "multi-header mx-3 mx-0-desktop" },
        [
          _c(
            "h1",
            { staticClass: "title is-3 is-spaced has-text-centered-touch" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("component.public_dashboard.dashboard_list.title")
                  ) +
                  " "
              ),
            ]
          ),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
            ? _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createPublicDashboard()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.add")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("DashboardsList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    dashboards: _vm.dashboards,
                    totalSize: _vm.dashboards.length,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { deleteDashboard: _vm.deleteDashboard },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DASHBOARD)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddDashboardModalActive,
                "has-modal-card": "",
                onCancel: _vm.onCreateModalClose,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddDashboardModalActive = $event
                },
              },
            },
            [
              _c("CreateDashboardModal", {
                attrs: {
                  sources: _vm.sources,
                  availableSourceGroups: _vm.sourceGroups,
                  availableDevices: _vm.devices,
                  availableUsers: _vm.users,
                },
                on: { modalClosed: _vm.onCreateModalClose },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }