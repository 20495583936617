var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-card" },
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "header",
        { staticClass: "modal-card-head" },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: { icon: "building", size: "is-medium" },
          }),
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.company.createCompanyModal.title")
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.createCompanyModal.form.company"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.company.createCompanyModal.form.company"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.company.createCompanyModal.form.company"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.company.createCompanyModal.form.companyName"
                  ),
                  type: "text",
                  maxlength: "50",
                  name: _vm.$t(
                    "admin.component.company.createCompanyModal.form.company"
                  ),
                },
                model: {
                  value: _vm.companyName,
                  callback: function ($$v) {
                    _vm.companyName = $$v
                  },
                  expression: "companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.companyIco"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.company.detail.companyInfo.companyIco"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.company.detail.companyInfo.companyIco"
                  )
                ),
              },
            },
            [
              _c("b-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "admin.component.company.createCompanyModal.form.companyIco"
                  ),
                  type: "text",
                  name: _vm.$t(
                    "admin.component.company.detail.companyInfo.companyIco"
                  ),
                },
                model: {
                  value: _vm.companyIco,
                  callback: function ($$v) {
                    _vm.companyIco = $$v
                  },
                  expression: "companyIco",
                },
              }),
            ],
            1
          ),
          _vm.usersAllowed
            ? _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t(
                      "admin.component.company.createCompanyModal.addUsers"
                    ),
                    message: _vm.$t(
                      "admin.component.company.createCompanyModal.errorOwnerCount"
                    ),
                  },
                },
                [
                  _c(
                    "b-table",
                    {
                      staticClass:
                        "select-location-table border select-user-table",
                      attrs: {
                        data: _vm.users,
                        "checked-rows": _vm.selectedUsers,
                        paginated: true,
                        "backend-pagination": "",
                        "current-page": _vm.page,
                        "per-page": _vm.pageSize,
                        total: _vm.totalSize,
                        "backend-filtering": "",
                        narrowed: "",
                        checkable: "",
                        "mobile-cards": false,
                        "custom-is-checked": (a, b) => {
                          return a.apiUserId === b.apiUserId
                        },
                      },
                      on: {
                        "update:checkedRows": function ($event) {
                          _vm.selectedUsers = $event
                        },
                        "update:checked-rows": function ($event) {
                          _vm.selectedUsers = $event
                        },
                        "update:currentPage": function ($event) {
                          _vm.page = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.page = $event
                        },
                        "page-change": _vm.onPageChange,
                        check: _vm.deleteUnChecked,
                      },
                    },
                    [
                      _c("b-table-column", {
                        attrs: {
                          field: "email",
                          label: _vm.$t(
                            "admin.component.company.createCompanyModal.form.selectAll"
                          ),
                          searchable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "searchable",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "buttons" },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            _vm.$t("generals.search"),
                                          size: "is-small",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.filterUsers(
                                              _vm.emailSearch
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.emailSearch,
                                          callback: function ($$v) {
                                            _vm.emailSearch =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "emailSearch",
                                        },
                                      }),
                                      _c("b-icon", {
                                        staticClass: "level-item clickable",
                                        attrs: { icon: "search" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.filterUsers(
                                              _vm.emailSearch
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (users) {
                                return [
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field flex-space-between",
                                      },
                                      [
                                        _c("div", {}, [
                                          _vm._v(
                                            _vm._s(users.row.fullName) +
                                              " (" +
                                              _vm._s(users.row.email) +
                                              ")"
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _vm.selectedUsers.find(
                                              (data) => users.row.id == data.id
                                            )
                                              ? _c(
                                                  "b-select",
                                                  {
                                                    attrs: {
                                                      size: "is-small",
                                                      placeholder: _vm.$t(
                                                        "admin.component.company.createCompanyModal.form.selectRole"
                                                      ),
                                                      name: _vm.$t(
                                                        "admin.component.company.createCompanyModal.form.selectRole"
                                                      ),
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.selectRole(
                                                          users.row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: (
                                                        _vm.assignUsers.find(
                                                          (x) =>
                                                            x.userId ===
                                                            users.row.id
                                                        ) || {}
                                                      ).userRole,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.assignUsers.find(
                                                            (x) =>
                                                              x.userId ===
                                                              users.row.id
                                                          ) || {},
                                                          "userRole",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "(assignUsers.find((x) => x.userId === users.row.id) || {}).userRole",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.availableUserRoles,
                                                    function (option) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: option,
                                                          domProps: {
                                                            value: option,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `users.roles.${option}`
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1123222447
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.usersAllowed
            ? _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t(
                      "admin.component.company.createCompanyModal.list.selectedUsers"
                    ),
                  },
                },
                [
                  _c(
                    "b-table",
                    {
                      staticClass:
                        "select-location-table border select-user-table-list",
                      attrs: { data: _vm.assignUsers, striped: "" },
                    },
                    [
                      _c("b-table-column", {
                        attrs: {
                          field: "name",
                          label: _vm.$t(
                            "admin.component.company.createCompanyModal.list.userName"
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (assignUsers) {
                                return [
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field flex-space-between",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(assignUsers.row.fullName) +
                                            " ( " +
                                            _vm._s(assignUsers.row.email) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4277130337
                        ),
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "Role",
                          label: _vm.$t(
                            "admin.component.company.createCompanyModal.list.role"
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (assignUsers) {
                                return [
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field flex-space-between",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `users.roles.${assignUsers.row.userRole}`
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2497866756
                        ),
                      }),
                      _c("template", { slot: "empty" }, [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.createCompanyModal.list.empty"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "modal-card-foot",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resultRetrieved(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("generals.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", disabled: _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.newCompany()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("admin.component.company.createCompanyModal.confirm")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }