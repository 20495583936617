import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateTimeManager from '@/services/DateTimeManager';
import ReportingInterval, { defaultReportingIntervals, getAllAvailableMeasureTimes } from '@/entities/enums/ReportingInterval';
import StatisticsType from '@/entities/enums/statisticsType';
import AppConfig from '@/configLoader';
import VueUtilities from '@/services/VueUtilities';
import { PdfReportTypes } from '@/entities/enums/ReportTypes';
let ReportConfigurationComponent = class ReportConfigurationComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DateTimeManager = DateTimeManager;
        this.StatisticsType = StatisticsType;
        this.interval = null;
        this.selectedType = StatisticsType.AVERAGE;
        this.measurementOffsetIndex = 0;
        this.allMeasuredTimes = [];
        this.offset = 0;
        this.timeOfMeasurement = new Date();
        this.hourFormatInClockPicker = null;
        this.currentShowEmpty = null;
        this.selectedEntity = PdfScope.GROUP;
        this.selectedSourceGroups = [];
        this.selectedSources = [];
        this.selectedEventTypes = [];
        this.temperatureCrop = null;
        this.humidityCrop = null;
        this.rawMinDate = new Date();
        this.rawMaxDate = new Date();
        this.dateTimeFrom = new Date();
        this.dateTimeTo = new Date();
        this.hourFormat = '24';
    }
    get allReportingIntervals() {
        return defaultReportingIntervals;
    }
    get hasOneDayIntervalSelected() {
        return this.interval === ReportingInterval.INTERVAL_24_00;
    }
    get canHaveOffset() {
        return this.selectedType === StatisticsType.DAILY;
    }
    get reportRange() {
        return AppConfig.getConfig().attributes?.max_report_range_days || 30;
    }
    created() {
        if (this.reportExportSetting)
            this.fillFromStored();
        else {
            this.interval = this.configuration.interval;
            this.selectedType = this.configuration.getModel().model;
            if (this.selectedType == StatisticsType.DAILY) {
                this.offset = this.configuration.getModel().modelParameters.Offset;
            }
            if (this.hasOneDayIntervalSelected == true) {
                let date = new Date();
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMinutes(this.configuration.getModel().modelParameters.Offset);
                this.timeOfMeasurement = date;
            }
            if (this.selectedSourceGroupId === null) {
                this.selectedEntity = PdfScope.LOGGER;
                this.selectedSources = this.sources.filter((x) => this.sourceGroupSources.find((y) => y.id == x.id) != null);
            }
            else {
                this.selectedSourceGroups = this.sourceGroups.filter((x) => x.id == this.selectedSourceGroupId);
            }
            this.selectedEventTypes = this.reportsEventTypes.map((x) => x.slug);
            this.temperatureCrop = {
                serviceId: this.temperatureService?.id,
                min: null,
                max: null
            };
            this.humidityCrop = {
                serviceId: this.humidityService?.id,
                min: null,
                max: null
            };
            this.rawMinDate.setHours(0, 0, 0, 0);
        }
        this.dateTimeFrom = new Date(this.selectedDateRange[0]);
        this.dateTimeTo = new Date(this.selectedDateRange[1]);
        this.currentShowEmpty = this.showEmpty;
        this.hourFormatInClockPicker = this.dateTimeManager.getSelectedClockFormat().slice(0, 2);
        this.assignMeasureTimes(this.interval).then(() => {
            this.measurementOffsetIndex = this.offset / 60;
        });
    }
    fillFromStored() {
        this.interval = this.reportExportSetting.interval;
        this.selectedType = this.reportExportSetting.statisticsType;
        if (this.selectedType == StatisticsType.DAILY) {
            this.offset = this.reportExportSetting.measurementOffsetIndex * 60;
        }
        if (this.hasOneDayIntervalSelected == true) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMinutes(this.reportExportSetting.measurementOffsetIndex * 60);
            this.timeOfMeasurement = date;
        }
    }
    /**
     * Listener for reporting interval change
     */
    async reportingIntervalChanged(step) {
        if (this.hasOneDayIntervalSelected == true) {
            this.clockChanged(this.timeOfMeasurement);
        }
        else {
            this.measurementOffsetIndex = 0;
            this.measuredOffsetChanged();
            this.assignMeasureTimes(step);
        }
    }
    /**
     * Create array with all allowed measure times for selected interval
     */
    async assignMeasureTimes(step) {
        let allTimes = await getAllAvailableMeasureTimes(this, step, this.isPublic);
        this.allMeasuredTimes = allTimes;
    }
    /**
     * change listener for offset
     */
    measuredOffsetChanged() {
        if (this.hasOneDayIntervalSelected == true)
            return;
        this.offset = this.measurementOffsetIndex * 60;
    }
    clockChanged(value) {
        if (this.hasOneDayIntervalSelected == false)
            return;
        this.offset = value.getHours() * 60 + value.getMinutes();
    }
    typeChanged() {
        this.measurementOffsetIndex = 0;
        this.measuredOffsetChanged();
        this.assignMeasureTimes(this.interval);
    }
    /**
     * Create configuration DTO and send it with selected month to parent component
     */
    applyConfiguration() {
        if (this.dateTimeFrom.getTime() >= this.dateTimeTo.getTime()) {
            VueUtilities.openErrorToast(this, this.$t('component.thermal_map.range_error'));
            return;
        }
        let reportExportSetting = {
            mode: this.reportExportSetting ? this.reportExportSetting.mode : PdfReportTypes.FULL,
            isDay: false,
            isTwoDay: false,
            isWeek: false,
            isTwoWeek: false,
            isLastMonth: false,
            isMonth: false,
            from: this.dateTimeManager.formatStartDateForReports(this.dateTimeFrom).text,
            to: this.dateTimeManager.formatEndDateForReports(this.dateTimeTo, false).text,
            entity: this.reportExportSetting ? this.reportExportSetting.entity : this.selectedEntity,
            sources: this.reportExportSetting ? this.reportExportSetting.sources : this.selectedSources?.map((x) => x.id),
            sourceGroups: this.reportExportSetting
                ? this.reportExportSetting.sourceGroups
                : this.selectedSourceGroups?.map((x) => x.id),
            recordsTableType: this.reportExportSetting ? this.reportExportSetting.recordsTableType : RecordsTableTypes.OUT_OF,
            interval: this.interval,
            statisticsType: this.selectedType,
            measurementOffsetIndex: this.measurementOffsetIndex,
            timeOfMeasurement: this.timeOfMeasurement,
            eventTypes: this.reportExportSetting ? this.reportExportSetting.eventTypes : this.selectedEventTypes,
            showChart: this.reportExportSetting ? this.reportExportSetting.showChart : false,
            temperature: {
                isCrop: this.reportExportSetting ? this.reportExportSetting.temperature.isCrop : false,
                min: this.reportExportSetting
                    ? this.reportExportSetting.temperature.min
                    : this.temperatureCrop
                        ? this.temperatureCrop.min
                        : null,
                max: this.reportExportSetting
                    ? this.reportExportSetting.temperature.max
                    : this.temperatureCrop
                        ? this.temperatureCrop.max
                        : null
            },
            humidity: {
                isCrop: this.reportExportSetting ? this.reportExportSetting.humidity.isCrop : false,
                min: this.reportExportSetting
                    ? this.reportExportSetting.humidity.min
                    : this.humidityCrop
                        ? this.humidityCrop.min
                        : null,
                max: this.reportExportSetting
                    ? this.reportExportSetting.humidity.max
                    : this.humidityCrop
                        ? this.humidityCrop.max
                        : null
            },
            showAnnotations: this.reportExportSetting ? this.reportExportSetting.showAnnotations : true,
            currentShowEmpty: this.currentShowEmpty,
            rawMinDate: this.reportExportSetting ? this.reportExportSetting.rawMinDate : this.rawMinDate,
            rawMaxDate: this.reportExportSetting ? this.reportExportSetting.rawMaxDate : this.rawMaxDate,
            wholeDayReport: this.reportExportSetting ? this.reportExportSetting.wholeDayReport : false
        };
        this.$emit('applyConfiguration', reportExportSetting);
    }
    get temperatureService() {
        return this.eventTypes.find((x) => x.slug == 'temperature');
    }
    get humidityService() {
        return this.eventTypes.find((x) => x.slug == 'humidity');
    }
    get reportsEventTypes() {
        return this.eventTypes.filter((x) => x.slug == 'temperature' || x.slug == 'humidity');
    }
};
__decorate([
    Prop({ type: Object })
], ReportConfigurationComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ReportConfigurationComponent.prototype, "sourceGroups", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ReportConfigurationComponent.prototype, "sources", void 0);
__decorate([
    Prop({ type: String })
], ReportConfigurationComponent.prototype, "selectedSourceGroupId", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ReportConfigurationComponent.prototype, "sourceGroupSources", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ReportConfigurationComponent.prototype, "eventTypes", void 0);
__decorate([
    Prop({ type: Object })
], ReportConfigurationComponent.prototype, "configuration", void 0);
__decorate([
    Prop({ type: Object })
], ReportConfigurationComponent.prototype, "reportExportSetting", void 0);
__decorate([
    Prop({ type: Array })
], ReportConfigurationComponent.prototype, "selectedDateRange", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ReportConfigurationComponent.prototype, "isPublic", void 0);
__decorate([
    Prop({ type: Boolean })
], ReportConfigurationComponent.prototype, "showEmpty", void 0);
ReportConfigurationComponent = __decorate([
    Component({})
    /**
     * Component to select report configuration.
     */
], ReportConfigurationComponent);
export default ReportConfigurationComponent;
var PdfScope;
(function (PdfScope) {
    PdfScope["LOGGER"] = "logger";
    PdfScope["GROUP"] = "group";
})(PdfScope || (PdfScope = {}));
var RecordsTableTypes;
(function (RecordsTableTypes) {
    RecordsTableTypes["ALL"] = "all";
    RecordsTableTypes["OUT_OF"] = "only_out_of";
    RecordsTableTypes["NONE"] = "none";
})(RecordsTableTypes || (RecordsTableTypes = {}));
