var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "level section-header-level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h2", { staticClass: "level-item subtitle is-4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("admin.component.user.detail.userSettings.title")) +
              " "
          ),
        ]),
      ]),
    ]),
    _vm.componentIsLoading
      ? _c(
          "div",
          [_c("b-skeleton", { attrs: { height: "90px", animated: true } })],
          1
        )
      : _c("div", { staticClass: "columns columns-info-form label-grow-4" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.user.detail.userSettings.dateFormat"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(),
                            _vm.userSettings.datetime.timezone,
                            _vm.userSettings.datetime.dateFormat,
                            ""
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.user.detail.userSettings.timeFormat"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.userSettings.datetime.clockFormat) + " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.user.detail.userSettings.timezone"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.userSettings.datetime.timezone) + " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.user.detail.userSettings.tzInfo"
                    ),
                  },
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.userSettings.datetime.tzInFormat
                        ? "check"
                        : "times",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.user.detail.userSettings.language"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.userSettings.language) + " "),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.settings.profile.user_settings.measurements_sort"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "sort." +
                              _vm.userSettings.preferences.measurementSort
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.settings.profile.user_settings.events_refresh_interval"
                    ),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.departmentUserSettings.preferences.eventsRefresh
                            .interval
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "component.settings.profile.user_settings.filterRecords"
                    ),
                  },
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon:
                        _vm.departmentUserSettings.reports.records
                          .filterByBoundary == "true"
                          ? "check"
                          : "times",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column" },
            _vm._l(_vm.convertedUnits, function (unit) {
              return _c(
                "b-field",
                {
                  key: unit.key,
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(`services.${unit.name}`),
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`units.${unit.key}`)) +
                        " " +
                        _vm._s(`(${unit.unit})`) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }