var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.currentClaims == null
    ? _c("div", [
        _vm.isCustomSettingsVisible == false
          ? _c(
              "div",
              { staticClass: "cookies-bar is-flex is-justify-content-center" },
              [
                _c(
                  "div",
                  { staticClass: "content has-text-justified mx-6 my-5" },
                  [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("cookies.message")) + " "),
                      _c(
                        "a",
                        {
                          staticClass: "is-italic has-text-primary",
                          on: {
                            click: function ($event) {
                              return _vm.openPolicy()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cookies.link")))]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary is-uppercase" },
                            on: {
                              click: function ($event) {
                                return _vm.acceptCookies()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cookies.accept")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary is-uppercase" },
                            on: {
                              click: function ($event) {
                                return _vm.rejectCookies()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cookies.reject")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-ghost is-uppercase" },
                            on: {
                              click: function ($event) {
                                return _vm.openCustomSettings()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cookies.custom_btn")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _c(
              "div",
              [
                _c(
                  "b-modal",
                  {
                    staticClass: "cookies-modal",
                    attrs: {
                      active: _vm.isCustomSettingsVisible,
                      "has-modal-card": "",
                      onCancel: _vm.closeCustomSettingsModal,
                    },
                    on: {
                      "update:active": function ($event) {
                        _vm.isCustomSettingsVisible = $event
                      },
                      "close-modal": _vm.closeCustomSettingsModal,
                    },
                  },
                  [
                    _c("CustomSettingsModal", {
                      on: { modalClosed: _vm.closeCustomSettingsModal },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }