var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "section-header-level" }, [
      _c(
        "div",
        { staticClass: "title-with-edit" },
        [
          _c("h3", { staticClass: "subtitle is-4 mr-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.user.detail.userCompanySettings.general"
                  )
                ) +
                " "
            ),
          ]),
          !_vm.fillingValues &&
          _vm.$ability.can(
            _vm.$permActions.UPDATE,
            _vm.$permSubjects.ADMIN_USERS_SETTINGS
          )
            ? [
                !_vm.isEditing
                  ? _c("b-button", {
                      attrs: { "icon-right": "pencil-alt", type: "is-white" },
                      on: {
                        click: function ($event) {
                          return _vm.edit()
                        },
                      },
                    })
                  : _c(
                      "b-button",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          "icon-left": "times-circle",
                          type: "is-white",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.edit()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")) + " ")]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm.fillingValues
      ? _c(
          "div",
          [
            _vm.departments.length === 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.user.detail.userSettings.no_department"
                        )
                      ) +
                      " "
                  ),
                ])
              : _c("b-skeleton", { attrs: { height: "90px", animated: true } }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass:
              "columns columns-info-form label-grow-5 text-wrap-anywhere",
          },
          [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  { attrs: { label: "notifications.pause.msgDismissedAt" } },
                  [
                    _vm.isEditing
                      ? _c("b-input", {
                          on: {
                            input: function ($event) {
                              return _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.NOTIFICATIONS,
                                  _vm.userSettingsKeys.PAUSE,
                                  _vm.userSettingsKeys.DISMISSED,
                                ],
                                _vm.selectedNotificationsPauseMsgDissmisedAt,
                                _vm.isNotificationsPauseMsgDissmisedAtChanged
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedNotificationsPauseMsgDissmisedAt,
                            callback: function ($$v) {
                              _vm.selectedNotificationsPauseMsgDissmisedAt = $$v
                            },
                            expression:
                              "selectedNotificationsPauseMsgDissmisedAt",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.notifications &&
                        _vm.departmentUserSettings.rawSettings.notifications
                          .pause &&
                        _vm.departmentUserSettings.rawSettings.notifications
                          .pause.msgDismissedAt
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .notifications.pause.msgDismissedAt
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "notifications.pause.until" } },
                  [
                    _vm.isEditing
                      ? _c("b-input", {
                          on: {
                            input: function ($event) {
                              return _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.NOTIFICATIONS,
                                  _vm.userSettingsKeys.PAUSE,
                                  _vm.userSettingsKeys.UNTIL,
                                ],
                                _vm.selectedNotificationsPauseUntil,
                                _vm.isNotificationsPauseUntilChanged
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedNotificationsPauseUntil,
                            callback: function ($$v) {
                              _vm.selectedNotificationsPauseUntil = $$v
                            },
                            expression: "selectedNotificationsPauseUntil",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.notifications &&
                        _vm.departmentUserSettings.rawSettings.notifications
                          .pause &&
                        _vm.departmentUserSettings.rawSettings.notifications
                          .pause.until
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .notifications.pause.until
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "notifications.rules.mode" } },
                  [
                    _vm.departmentUserSettings &&
                    _vm.departmentUserSettings.rawSettings &&
                    _vm.departmentUserSettings.rawSettings.notifications &&
                    _vm.departmentUserSettings.rawSettings.notifications.rules
                      .mode
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .notifications.rules.mode
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ]
                ),
                _c(
                  "b-field",
                  { attrs: { label: "notifications.rules.scheduleMode" } },
                  [
                    _vm.departmentUserSettings &&
                    _vm.departmentUserSettings.rawSettings &&
                    _vm.departmentUserSettings.rawSettings.notifications &&
                    _vm.departmentUserSettings.rawSettings.notifications
                      .rules &&
                    _vm.departmentUserSettings.rawSettings.notifications.rules
                      .scheduleMode
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .notifications.rules.scheduleMode
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  { attrs: { label: "notifications.schedule.mode" } },
                  [
                    _vm.departmentUserSettings &&
                    _vm.departmentUserSettings.rawSettings &&
                    _vm.departmentUserSettings.rawSettings.notifications &&
                    _vm.departmentUserSettings.rawSettings.notifications
                      .schedule &&
                    _vm.departmentUserSettings.rawSettings.notifications
                      .schedule.mode
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .notifications.schedule.mode
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ]
                ),
                _c(
                  "b-field",
                  { attrs: { label: "preferences.eventsRefresh.enabled" } },
                  [
                    _vm.isEditing
                      ? _c("b-checkbox", {
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PREFERENCES,
                                  _vm.userSettingsKeys.EVENTS_REFRESH,
                                  _vm.userSettingsKeys.ENABLED,
                                ],
                                _vm.selectedEventsRefreshEnabled[
                                  _vm.selectedDepartment.Department.Id
                                ],
                                _vm.isEventsRefreshEnabledChanged(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedEventsRefreshEnabled[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedEventsRefreshEnabled,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedEventsRefreshEnabled[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.preferences &&
                        _vm.departmentUserSettings.rawSettings.preferences
                          .eventsRefresh &&
                        _vm.departmentUserSettings.rawSettings.preferences
                          .eventsRefresh.enabled
                      ? _c("b-icon", {
                          attrs: {
                            icon: _vm.getBooleanValue(
                              _vm.departmentUserSettings.rawSettings.preferences
                                .eventsRefresh.enabled
                            )
                              ? "check"
                              : "times",
                          },
                        })
                      : _c("b-icon", {
                          staticClass: "has-text-grey-light is-italic",
                          attrs: { icon: "times" },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "preferences.eventsRefresh.interval" } },
                  [
                    _vm.isEditing
                      ? _c("b-numberinput", {
                          attrs: {
                            disabled:
                              !_vm.selectedEventsRefreshEnabled[
                                _vm.selectedDepartment.Department.Id
                              ],
                            min: "1",
                          },
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PREFERENCES,
                                  _vm.userSettingsKeys.EVENTS_REFRESH,
                                  _vm.userSettingsKeys.INTERVAL,
                                ],
                                _vm.selectedRefreshInterval[
                                  _vm.selectedDepartment.Department.Id
                                ].toString(),
                                _vm.isRefreshIntervalChanged(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedRefreshInterval[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedRefreshInterval,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedRefreshInterval[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.preferences &&
                        _vm.departmentUserSettings.rawSettings.preferences
                          .eventsRefresh &&
                        _vm.departmentUserSettings.rawSettings.preferences
                          .eventsRefresh.interval &&
                        _vm.selectedEventsRefreshEnabled[
                          _vm.selectedDepartment.Department.Id
                        ]
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .preferences.eventsRefresh.interval
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "preferences.favoriteGroupId" } },
                  [
                    _vm.isEditing
                      ? _c("b-input", {
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.PREFERENCES,
                                  _vm.userSettingsKeys.FAVORITE_GROUP_ID,
                                ],
                                _vm.selectedFavoriteGroupId[
                                  _vm.selectedDepartment.Department.Id
                                ],
                                _vm.isFavoriteGroupIdChanged(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedFavoriteGroupId[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedFavoriteGroupId,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedFavoriteGroupId[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.preferences &&
                        _vm.departmentUserSettings.rawSettings.preferences
                          .favoriteGroupId
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings
                                  .preferences.favoriteGroupId
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "b-field",
                  { attrs: { label: "reports.configuration" } },
                  [
                    _vm.isEditing
                      ? _c("b-input", {
                          attrs: {
                            expanded: "",
                            maxlength: "200",
                            type: "textarea",
                          },
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.REPORTS,
                                  _vm.userSettingsKeys.CONFIGURATION,
                                ],
                                _vm.selectedConfiguration[
                                  _vm.selectedDepartment.Department.Id
                                ],
                                _vm.isConfigurationChangedForDepartment(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedConfiguration[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedConfiguration,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedConfiguration[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.reports &&
                        _vm.departmentUserSettings.rawSettings.reports
                          .configuration
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings.reports
                                  .configuration
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "reports.customSettings" } },
                  [
                    _vm.isEditing
                      ? _c("b-input", {
                          attrs: {
                            expanded: "",
                            maxlength: "1500",
                            type: "textarea",
                          },
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.REPORTS,
                                  _vm.userSettingsKeys.CUSTOM,
                                ],
                                _vm.customReportExportSetting,
                                _vm.iscustomReportExportSettingChanged()
                              )
                            },
                          },
                          model: {
                            value: _vm.customReportExportSetting,
                            callback: function ($$v) {
                              _vm.customReportExportSetting = $$v
                            },
                            expression: "customReportExportSetting",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.reports &&
                        _vm.departmentUserSettings.rawSettings.reports
                          .customSettings
                      ? _c("p", { staticClass: "overflow-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.departmentUserSettings.rawSettings.reports
                                  .customSettings
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          { staticClass: "has-text-grey-light is-italic" },
                          [_vm._v("-")]
                        ),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "reports.receiveByEmail" } },
                  [
                    _vm.isEditing
                      ? _c("b-checkbox", {
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.REPORTS,
                                  _vm.userSettingsKeys.RECEIVE_REPORTS,
                                ],
                                _vm.selectedReceiveReports[
                                  _vm.selectedDepartment.Department.Id
                                ],
                                _vm.isReceiveReportsChangedForDepartment(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedReceiveReports[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedReceiveReports,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedReceiveReports[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.reports &&
                        _vm.departmentUserSettings.rawSettings.reports
                          .receiveByEmail
                      ? _c("b-icon", {
                          attrs: {
                            icon: _vm.getBooleanValue(
                              _vm.departmentUserSettings.rawSettings.reports
                                .receiveByEmail
                            )
                              ? "check"
                              : "times",
                          },
                        })
                      : _c("b-icon", {
                          staticClass: "has-text-grey-light is-italic",
                          attrs: { icon: "times" },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "reports.records.filterByBoundary" } },
                  [
                    _vm.isEditing
                      ? _c("b-checkbox", {
                          on: {
                            input: function ($event) {
                              _vm.addToChanges(
                                [
                                  _vm.userSettingsKeys.REPORTS,
                                  _vm.userSettingsKeys.RECORDS,
                                  _vm.userSettingsKeys.FILTER_BY_BOUNDARY,
                                ],
                                _vm.selectedFilterByBoundary[
                                  _vm.selectedDepartment.Department.Id
                                ],
                                _vm.isFilterByBoundaryChangedForDepartment(
                                  _vm.selectedDepartment.Department.Id
                                )
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.selectedFilterByBoundary[
                                _vm.selectedDepartment.Department.Id
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedFilterByBoundary,
                                _vm.selectedDepartment.Department.Id,
                                $$v
                              )
                            },
                            expression:
                              "selectedFilterByBoundary[selectedDepartment.Department.Id]",
                          },
                        })
                      : _vm.departmentUserSettings &&
                        _vm.departmentUserSettings.rawSettings &&
                        _vm.departmentUserSettings.rawSettings.reports &&
                        _vm.departmentUserSettings.rawSettings.reports
                          .records &&
                        _vm.departmentUserSettings.rawSettings.reports.records
                          .filterByBoundary
                      ? _c("b-icon", {
                          attrs: {
                            icon: _vm.getBooleanValue(
                              _vm.departmentUserSettings.rawSettings.reports
                                .records.filterByBoundary
                            )
                              ? "check"
                              : "times",
                          },
                        })
                      : _c("b-icon", {
                          staticClass: "has-text-grey-light is-italic",
                          attrs: { icon: "check" },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
    _vm.isEditing
      ? _c(
          "div",
          { staticClass: "has-text-right" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                on: {
                  click: function ($event) {
                    return _vm.saveChanges()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.save_changes")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "section",
      { staticClass: "py-4" },
      [
        _c("div", { staticClass: "section-header-level" }, [
          _c("h3", { staticClass: "subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("custom_settings.title")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "buttons ml-4" },
            [
              _vm.$ability.can(
                _vm.$permActions.UPDATE,
                _vm.$permSubjects.ADMIN_USERS_SETTINGS
              )
                ? _c("b-button", {
                    staticClass: "is-hidden-mobile",
                    attrs: {
                      type: "is-primary",
                      label: _vm.$t("generals.add"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  })
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.UPDATE,
                _vm.$permSubjects.ADMIN_USERS_SETTINGS
              )
                ? _c("b-button", {
                    staticClass: "is-hidden-tablet",
                    attrs: { type: "is-primary", "icon-left": "plus" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.isAdding
          ? _c("section", [
              _c("h3", { staticClass: "subtitle is-5" }, [
                _vm._v(_vm._s(_vm.$t("custom_settings.add_new"))),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-justify-content-flex-start is-flex-direction-row",
                },
                [
                  _c(
                    "b-field",
                    { attrs: { grouped: "", "group-multiline": "" } },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "is-flex-grow-2 mr-1 mb-5",
                          attrs: {
                            label: _vm.$t("custom_settings.key"),
                            "label-position": "on-border",
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t("custom_settings.key")
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("custom_settings.key")
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              type: "textarea",
                              expanded: "",
                              name: _vm.$t("custom_settings.key"),
                            },
                            model: {
                              value: _vm.selectedFieldKey,
                              callback: function ($$v) {
                                _vm.selectedFieldKey = $$v
                              },
                              expression: "selectedFieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          staticClass: "is-flex-grow-3 mr-1 mb-5",
                          attrs: {
                            label: _vm.$t("custom_settings.value"),
                            "label-position": "on-border",
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t("custom_settings.value")
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t("custom_settings.value")
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              type: "textarea",
                              name: _vm.$t("custom_settings.value"),
                              expanded: "",
                            },
                            model: {
                              value: _vm.selectedFieldValue,
                              callback: function ($$v) {
                                _vm.selectedFieldValue = $$v
                              },
                              expression: "selectedFieldValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttons is-centered" },
                [
                  _c("b-button", {
                    attrs: {
                      type: "is-danger",
                      label: _vm.$t("generals.close"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelAdding()
                      },
                    },
                  }),
                  _c("b-button", {
                    attrs: {
                      type: "is-success",
                      label: _vm.$t("generals.save"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveNewSetting()
                      },
                    },
                  }),
                ],
                1
              ),
              _c("hr"),
            ])
          : _vm._e(),
        _c(
          "b-table",
          {
            staticClass: "res-table",
            attrs: { data: _vm.customDepartmentUserSettings, striped: "" },
          },
          [
            _c("b-table-column", {
              attrs: { field: "Key", label: _vm.$t("custom_settings.key") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [_c("span", [_vm._v(" " + _vm._s(custom.row.key))])]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: {
                field: "Value",
                label: _vm.$t("company_settings.value"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [
                      _vm.fieldEditing == custom.row.key
                        ? _c(
                            "b-field",
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "textarea",
                                  name: `${custom.row.key}_value`,
                                  expanded: "",
                                },
                                model: {
                                  value: _vm.selectedFieldValue,
                                  callback: function ($$v) {
                                    _vm.selectedFieldValue = $$v
                                  },
                                  expression: "selectedFieldValue",
                                },
                              }),
                            ],
                            1
                          )
                        : [
                            _c("p", { staticClass: "text-wrap-anywhere" }, [
                              _vm._v(" " + _vm._s(custom.row.value) + " "),
                            ]),
                          ],
                    ]
                  },
                },
              ]),
            }),
            _c("b-table-column", {
              attrs: { label: _vm.$t("generals.action") },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (custom) {
                    return [
                      _vm.fieldEditing == custom.row.key
                        ? [
                            _c("b-button", {
                              attrs: {
                                "icon-right": "times-circle",
                                type: "is-white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEdit()
                                },
                              },
                            }),
                            _c("b-button", {
                              attrs: {
                                type: "is-primary",
                                label: _vm.$t("generals.save"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveEdit(custom.row.key)
                                },
                              },
                            }),
                          ]
                        : [
                            _vm.$ability.can(
                              _vm.$permActions.UPDATE,
                              _vm.$permSubjects.ADMIN_USERS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "pencil-alt",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSettings(custom.row.key)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$ability.can(
                              _vm.$permActions.DELETE,
                              _vm.$permSubjects.ADMIN_USERS_SETTINGS
                            )
                              ? _c("b-button", {
                                  attrs: {
                                    "icon-right": "trash",
                                    type: "is-white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmSettingDelete(
                                        custom.row.key
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("custom_settings.empty_table")) + " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }