import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueUtilities from '@/services/VueUtilities';
import DeviceRepository from '@/services/repository/DeviceRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import ObserverType from '@/entities/enums/observerType';
var deviceRepository;
let CreateDeviceModal = class CreateDeviceModal extends Vue {
    constructor() {
        super(...arguments);
        this.ObserverType = ObserverType;
        this.macAddress = null;
        this.hwKey = null;
        this.serialNumber = null;
        this.registrationKey = null;
        this.observerType = ObserverType.STANDARD;
        this.isLoading = false;
    }
    created() {
        deviceRepository = new DeviceRepository(this);
    }
    createLogger() {
        if (this.isLoading === true)
            return;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let deviceDto = {
                    MacAddress: this.macAddress,
                    SerialNumber: this.serialNumber,
                    HardwareKey: this.hwKey,
                    RegistrationKey: this.registrationKey,
                    Type: this.observerType
                };
                let createResult = await deviceRepository.createAdminDevice(deviceDto);
                this.isLoading = false;
                if (createResult == true) {
                    VueUtilities.openSuccessToast(this, this.$t('admin.component.devices.create_device.success'));
                    this.resultRetrieved(true);
                }
                else if (createResult.code == ApiResponseCodes.CANNOT_PUBLISH) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.cannot_publish'));
                }
                else if (createResult.code == ApiResponseCodes.REG_KEY_ALREADY_USED) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.reg_key_used'));
                }
                else if (createResult.code == ApiResponseCodes.REG_KEY_STILL_ACTIVE) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.reg_key_active'));
                }
                else if (createResult.code == ApiResponseCodes.CANNOT_DELETE_DEVICE) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.cant_delete'));
                }
                else if (createResult.code == ApiResponseCodes.VALIDATION_ERROR) {
                    let fields = Object.keys(createResult.errors).join(',');
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.validation_error', { fields: fields }));
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.devices.create_device.failure'));
                }
            }
        });
    }
    resultRetrieved(event) {
        this.$emit('modalClosed', event);
    }
};
CreateDeviceModal = __decorate([
    Component({})
], CreateDeviceModal);
export default CreateDeviceModal;
