var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "component.settings.profile.user_settings.change_password.title"
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c(
        "div",
        [
          _c("ValidationObserver", { ref: "passwordFields" }, [
            _c(
              "div",
              { staticClass: "field" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: _vm.$t(
                      "component.settings.profile.user_settings.change_password.current"
                    ),
                    rules: "required",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "b-field",
                            {
                              staticClass: "required",
                              attrs: {
                                label: _vm.$t(
                                  "component.settings.profile.user_settings.change_password.current"
                                ),
                                type: { "is-danger": errors.length > 0 },
                                message: errors,
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: _vm.$t(
                                    "component.settings.profile.user_settings.change_password.current"
                                  ),
                                },
                                model: {
                                  value: _vm.currentPassword,
                                  callback: function ($$v) {
                                    _vm.currentPassword = $$v
                                  },
                                  expression: "currentPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: _vm.$t(
                      "component.settings.profile.user_settings.change_password.new"
                    ),
                    rules: "required|password|min:8",
                    vid: "confirmation",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "b-field",
                            {
                              staticClass: "required",
                              attrs: {
                                label: _vm.$t(
                                  "component.settings.profile.user_settings.change_password.new"
                                ),
                                type: { "is-danger": errors.length > 0 },
                                message: errors,
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: _vm.$t(
                                    "component.settings.profile.user_settings.change_password.new"
                                  ),
                                },
                                model: {
                                  value: _vm.newPassword,
                                  callback: function ($$v) {
                                    _vm.newPassword = $$v
                                  },
                                  expression: "newPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: _vm.$t(
                      "component.settings.profile.user_settings.change_password.repeat"
                    ),
                    rules: "required|confirmed:confirmation",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "b-field",
                            {
                              staticClass: "required",
                              attrs: {
                                label: _vm.$t(
                                  "component.settings.profile.user_settings.change_password.repeat"
                                ),
                                type: { "is-danger": errors.length > 0 },
                                message: errors[0],
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: _vm.$t(
                                    "component.settings.profile.user_settings.change_password.repeat"
                                  ),
                                },
                                model: {
                                  value: _vm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.confirmPassword = $$v
                                  },
                                  expression: "confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.changePassword()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "component.settings.profile.user_settings.change_password.confirm"
                )
              )
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }