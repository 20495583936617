var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "section-header-level mx-3 mx-0-desktop" }, [
        _c("h1", { staticClass: "title is-3 my-1" }, [
          _vm._v(" " + _vm._s(_vm.$t("admin.component.user.title")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.$ability.can(
              _vm.$permActions.CREATE,
              _vm.$permSubjects.ADMIN_USERS
            )
              ? _c(
                  "b-button",
                  {
                    staticClass: "is-hidden-mobile",
                    attrs: { outlined: "", type: "is-primary" },
                    on: {
                      click: function ($event) {
                        return _vm.preRegisterUser()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("admin.component.user.preregisterUserBtn"))
                    ),
                  ]
                )
              : _vm._e(),
            _vm.$ability.can(
              _vm.$permActions.MAILINGLIST,
              _vm.$permSubjects.ADMIN_USERS
            )
              ? _c(
                  "b-button",
                  {
                    staticClass: "is-hidden-mobile",
                    attrs: {
                      type: "is-primary",
                      "icon-pack": "fa",
                      "icon-left": "download",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.exportMailingList()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("admin.component.company.export_mailing_list")
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "b-dropdown",
              {
                attrs: {
                  position: "is-bottom-left",
                  "append-to-body": "",
                  "aria-role": "list",
                },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "admin.component.devices.detail.actions.title"
                              ),
                              position: "is-left",
                              type: "is-dark",
                            },
                          },
                          [
                            _c(
                              "b-button",
                              { staticStyle: { "font-size": "1rem" } },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    type: "is-primary",
                                    icon: "ellipsis-v",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm.$ability.can(
                  _vm.$permActions.CREATE,
                  _vm.$permSubjects.ADMIN_USERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.createNewUser()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "user-plus" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("admin.component.user.createUserBtn")
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.CREATE,
                  _vm.$permSubjects.ADMIN_USERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.preRegisterUser()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "user-cog" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("admin.component.user.preregisterUserBtn")
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.MAILINGLIST,
                  _vm.$permSubjects.ADMIN_USERS
                )
                  ? _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.exportMailingList()
                          },
                        },
                      },
                      [
                        _c("b-icon", { attrs: { icon: "download" } }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.company.export_mailing_list"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "tile is-ancestor is-vertical mt-2" }, [
        _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.ADMIN_USERS)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("AdminUserList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    users: _vm.userList.getData() || [],
                    dateTimeManager: _vm.dateTimeManager,
                    totalSize: _vm.userList.getPagination().total,
                  },
                  on: {
                    onOptionsChange: _vm.onOptionsChange,
                    loadUsers: _vm.loadUsers,
                    changeRouterUrlFromUser: _vm.changeRouterUrlFromUser,
                    reload: _vm.loadUsers,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isPreRegisterUserModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePreRegisterUserModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isPreRegisterUserModalActive = $event
            },
            "close-modal": _vm.closePreRegisterUserModal,
          },
        },
        [
          _c("PreRegisterUserModal", {
            on: { modalClosed: _vm.closePreRegisterUserModal },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCreateUserModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCreateUserModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCreateUserModalActive = $event
            },
            "close-modal": _vm.closeCreateUserModal,
          },
        },
        [
          _c("CreateUserModal", {
            on: { modalClosed: _vm.closeCreateUserModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }