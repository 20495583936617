var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.notification.pause.title")) + " "
          ),
        ]),
      ]),
      _vm.paused
        ? _c("b-message", { attrs: { type: "is-warning" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("component.notification.pause.paused_msg", {
                    time: _vm.dateTimeManager.formatTime(
                      new Date(_vm.pausedTill * 1000),
                      null,
                      "",
                      null
                    ),
                  })
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "buttons is-hidden-mobile" },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.FIFTEEN_MIN
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("component.notification.pause.pause15")) + " "
              ),
            ]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.THIRTY_MIN
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("component.notification.pause.pause30")) + " "
              ),
            ]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.ONE_HOUR
                  )
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("component.notification.pause.pause60")) + " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttons is-hidden-tablet" },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.FIFTEEN_MIN
                  )
                },
              },
            },
            [
              _vm._v(
                "15 " + _vm._s(_vm.$t("component.notification.pause.min")) + " "
              ),
            ]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.THIRTY_MIN
                  )
                },
              },
            },
            [
              _vm._v(
                "30 " + _vm._s(_vm.$t("component.notification.pause.min")) + " "
              ),
            ]
          ),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function ($event) {
                  return _vm.pauseNotifications(
                    _vm.PauseNotificationsInterval.ONE_HOUR
                  )
                },
              },
            },
            [
              _vm._v(
                "60 " + _vm._s(_vm.$t("component.notification.pause.min")) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }