var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h2", { staticClass: "level-item subtitle is-4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.settings.profile.user_settings.title")) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "level-right" }, [
        _vm.requestPending
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-warning has-text-weight-semibold is-size-4",
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.saving_changes")) + " ")]
            )
          : _vm.requestFinished
          ? _c(
              "span",
              {
                staticClass:
                  "level-item has-text-success successfully-saved has-text-weight-semibold is-size-4",
                class: { "hide-opacity": _vm.requestFinished },
              },
              [_vm._v(" " + _vm._s(_vm.$t("generals.changes_saved")) + " ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "section",
      [
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.dateFormat"
              ),
            },
          },
          [
            _c(
              "b-select",
              {
                attrs: {
                  expanded: "",
                  placeholder: _vm.$t("placeholders.select_format"),
                },
                on: { input: _vm.dateFormatSelected },
                model: {
                  value: _vm.selectedDateFormat,
                  callback: function ($$v) {
                    _vm.selectedDateFormat = $$v
                  },
                  expression: "selectedDateFormat",
                },
              },
              _vm._l(_vm.DateFormats, function (option) {
                return _c(
                  "option",
                  { key: option, domProps: { value: option } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            _vm.actualTime,
                            _vm.selectedTimezone,
                            option,
                            ""
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.timeFormat"
              ),
            },
          },
          [
            _c(
              "b-select",
              {
                attrs: {
                  expanded: "",
                  placeholder: _vm.$t("placeholders.select_format"),
                },
                on: { input: _vm.clockFormatSelected },
                model: {
                  value: _vm.selectedClockFormat,
                  callback: function ($$v) {
                    _vm.selectedClockFormat = $$v
                  },
                  expression: "selectedClockFormat",
                },
              },
              _vm._l(_vm.ClockFormats, function (option) {
                return _c(
                  "option",
                  { key: option, domProps: { value: option } },
                  [_vm._v(" " + _vm._s(option) + " ")]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t("component.settings.profile.user_settings.tzInfo"),
            },
          },
          [
            _c("b-checkbox", {
              on: { input: _vm.showTzChanged },
              model: {
                value: _vm.selectedShowTzInfo,
                callback: function ($$v) {
                  _vm.selectedShowTzInfo = $$v
                },
                expression: "selectedShowTzInfo",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.timezone"
              ),
            },
          },
          [
            _c(
              "b-select",
              {
                attrs: {
                  expanded: "",
                  placeholder: _vm.$t("placeholders.select_format"),
                },
                on: { input: _vm.timezoneSelected },
                model: {
                  value: _vm.selectedTimezone,
                  callback: function ($$v) {
                    _vm.selectedTimezone = $$v
                  },
                  expression: "selectedTimezone",
                },
              },
              _vm._l(_vm.TimeZones, function (option) {
                return _c(
                  "option",
                  { key: option, domProps: { value: option } },
                  [_vm._v(" " + _vm._s(option) + " ")]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.selected_format"
              ),
            },
          },
          [
            _c("p", { staticClass: "has-text-grey" }, [
              _vm._v(_vm._s(_vm.actualTimeFormatted)),
            ]),
          ]
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.language"
              ),
            },
          },
          [
            _c(
              "b-radio-button",
              {
                key: _vm.Languages.SLOVAK,
                attrs: { expanded: true, "native-value": _vm.Languages.SLOVAK },
                on: {
                  input: function ($event) {
                    return _vm.changeLanguage(_vm.Languages.SLOVAK)
                  },
                },
                model: {
                  value: _vm.settings.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "language", $$v)
                  },
                  expression: "settings.language",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.slovak")))])]
            ),
            _c(
              "b-radio-button",
              {
                key: _vm.Languages.ENGLISH,
                attrs: {
                  expanded: true,
                  "native-value": _vm.Languages.ENGLISH,
                },
                on: {
                  input: function ($event) {
                    return _vm.changeLanguage(_vm.Languages.ENGLISH)
                  },
                },
                model: {
                  value: _vm.settings.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "language", $$v)
                  },
                  expression: "settings.language",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.english")))])]
            ),
            _c(
              "b-radio-button",
              {
                key: _vm.Languages.CZECH,
                attrs: { expanded: true, "native-value": _vm.Languages.CZECH },
                on: {
                  input: function ($event) {
                    return _vm.changeLanguage(_vm.Languages.CZECH)
                  },
                },
                model: {
                  value: _vm.settings.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "language", $$v)
                  },
                  expression: "settings.language",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.czech")))])]
            ),
          ],
          1
        ),
        _c(
          "b-field",
          {
            attrs: {
              horizontal: "",
              label: _vm.$t(
                "component.settings.profile.user_settings.measurements_sort"
              ),
            },
          },
          [
            _c(
              "b-radio-button",
              {
                key: _vm.MeasurementsSort.ALPHABETICAL,
                attrs: {
                  expanded: true,
                  "native-value": _vm.MeasurementsSort.ALPHABETICAL,
                },
                on: {
                  input: function ($event) {
                    return _vm.changeMeasurementSort(
                      _vm.MeasurementsSort.ALPHABETICAL
                    )
                  },
                },
                model: {
                  value: _vm.settings.preferences.measurementSort,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings.preferences, "measurementSort", $$v)
                  },
                  expression: "settings.preferences.measurementSort",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("sort.alpha")))])]
            ),
            _c(
              "b-radio-button",
              {
                key: _vm.MeasurementsSort.TIME,
                attrs: {
                  expanded: true,
                  "native-value": _vm.MeasurementsSort.TIME,
                },
                on: {
                  input: function ($event) {
                    return _vm.changeMeasurementSort(_vm.MeasurementsSort.TIME)
                  },
                },
                model: {
                  value: _vm.settings.preferences.measurementSort,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings.preferences, "measurementSort", $$v)
                  },
                  expression: "settings.preferences.measurementSort",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("sort.time")))])]
            ),
          ],
          1
        ),
        _vm.departmentUserSettings !== null
          ? _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t(
                        "component.settings.profile.user_settings.events_refresh_interval"
                      )
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t(
                      "component.settings.profile.user_settings.events_refresh_interval"
                    )
                  ),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.settings.profile.user_settings.events_refresh_interval"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                type: "is-dark",
                                label: _vm.$t(
                                  "component.settings.profile.user_settings.refresh_note"
                                ),
                                position: "is-left",
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  size: "is-small",
                                  icon: "question-circle",
                                  type: "is-grey",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3968038808
                ),
              },
              [
                !_vm.requestPending
                  ? _c("b-numberinput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|min_value:1",
                          expression: "'required|numeric|min_value:1'",
                        },
                      ],
                      attrs: {
                        name: _vm.$t(
                          "component.settings.profile.user_settings.events_refresh_interval"
                        ),
                      },
                      on: { input: _vm.changeRefreshInterval },
                      model: {
                        value: _vm.refreshInterval,
                        callback: function ($$v) {
                          _vm.refreshInterval = $$v
                        },
                        expression: "refreshInterval",
                      },
                    })
                  : _c("b-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.refreshInterval,
                        callback: function ($$v) {
                          _vm.refreshInterval = $$v
                        },
                        expression: "refreshInterval",
                      },
                    }),
              ],
              1
            )
          : _vm._e(),
        _vm.departmentUserSettings !== null
          ? _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.settings.profile.user_settings.filterRecords"
                  ),
                },
              },
              [
                _c("b-checkbox", {
                  on: { input: _vm.filterRecordsChanged },
                  model: {
                    value: _vm.filterRecords,
                    callback: function ($$v) {
                      _vm.filterRecords = $$v
                    },
                    expression: "filterRecords",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }