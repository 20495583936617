var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.notification.presets.assign_preset_btn_label")
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-tabs",
          {
            staticClass: "preset",
            attrs: { type: "is-boxed" },
            on: { input: _vm.tabChanged },
            model: {
              value: _vm.activeTab,
              callback: function ($$v) {
                _vm.activeTab = $$v
              },
              expression: "activeTab",
            },
          },
          [
            _c(
              "b-tab-item",
              {
                attrs: {
                  value: "nDepartments",
                  label: _vm.$t(
                    "component.notification.presets.modal.tab_title_user"
                  ),
                },
              },
              [
                _c(
                  "b-field",
                  {
                    staticClass: "pt-4",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.notification.presets.modal.select_preset"
                      ),
                    },
                  },
                  [
                    _c("b-autocomplete", {
                      attrs: {
                        data: _vm.presets,
                        field: "name",
                        placeholder: _vm.$t(
                          "component.notification.presets.table.name"
                        ),
                        "icon-pack": "fas",
                        icon: "search",
                        clearable: "",
                        "open-on-focus": "",
                      },
                      on: { select: _vm.presetNameChanged },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedPresetName,
                        callback: function ($$v) {
                          _vm.selectedPresetName = $$v
                        },
                        expression: "selectedPresetName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.notification.presets.modal.select_user"
                      ),
                    },
                  },
                  [
                    _c("b-autocomplete", {
                      attrs: {
                        data: _vm.filteredUsers,
                        field: "username",
                        placeholder: _vm.$t(
                          "component.userAdministration.list.table.username"
                        ),
                        "icon-pack": "fas",
                        icon: "search",
                        clearable: "",
                        "open-on-focus": "",
                      },
                      on: { select: _vm.userNameChanged },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedUserName,
                        callback: function ($$v) {
                          _vm.selectedUserName = $$v
                        },
                        expression: "selectedUserName",
                      },
                    }),
                  ],
                  1
                ),
                _c("EntitySelectionTable", {
                  attrs: {
                    tableData: _vm.userDepartments,
                    assignedEntities: _vm.selectedDepartments,
                    filters: _vm.filters,
                    choosedEntityColumn: _vm.choosedEntityColumn,
                    isLoading: _vm.isLoading,
                  },
                  on: { entitySelected: _vm.departmentSelected },
                }),
              ],
              1
            ),
            _c(
              "b-tab-item",
              {
                attrs: {
                  value: "nUsers",
                  label: _vm.$t(
                    "component.notification.presets.modal.tab_title_department"
                  ),
                },
              },
              [
                _c(
                  "b-field",
                  {
                    staticClass: "pt-4",
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.notification.presets.modal.select_preset"
                      ),
                    },
                  },
                  [
                    _c("b-autocomplete", {
                      attrs: {
                        data: _vm.presets,
                        field: "name",
                        placeholder: _vm.$t(
                          "component.notification.presets.table.name"
                        ),
                        "icon-pack": "fas",
                        icon: "search",
                        clearable: "",
                        "open-on-focus": "",
                      },
                      on: { select: _vm.presetNameChanged },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedPresetName,
                        callback: function ($$v) {
                          _vm.selectedPresetName = $$v
                        },
                        expression: "selectedPresetName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t(
                        "component.notification.presets.modal.select_department"
                      ),
                    },
                  },
                  [
                    _c("b-autocomplete", {
                      attrs: {
                        data: _vm.filteredDepartments,
                        field: "companyName",
                        placeholder: _vm.$t(
                          "component.companyAdministration.changeName.companyName"
                        ),
                        "icon-pack": "fas",
                        icon: "search",
                        clearable: "",
                        "open-on-focus": "",
                      },
                      on: { select: _vm.departmentNameChanged },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _vm._v(_vm._s(_vm.$t("generals.no_results"))),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedDepartmentName,
                        callback: function ($$v) {
                          _vm.selectedDepartmentName = $$v
                        },
                        expression: "selectedDepartmentName",
                      },
                    }),
                  ],
                  1
                ),
                _c("EntitySelectionTable", {
                  attrs: {
                    tableData: _vm.departmentUsers,
                    assignedEntities: _vm.selectedUsers,
                    filters: _vm.filters,
                    choosedEntityColumn: _vm.choosedEntityColumn,
                    isLoading: _vm.isLoading,
                  },
                  on: { entitySelected: _vm.userSelected },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot is-block" }, [
      _c("div", { staticClass: "level is-mobile" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("generals.close")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "b-button",
              {
                staticClass: "level-item",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.createFromPreset()
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "component.notification.presets.assign_preset_btn_label"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }