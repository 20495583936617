var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("input", {
      ref: "fileInput",
      staticStyle: { position: "absolute", left: "-9999px" },
      attrs: { type: "file", accept: _vm.validTypes, multiple: "" },
      on: { change: _vm.handleFiles, focus: _vm.handleFocus },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }